import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';

@Component({
  selector: 'app-dashboard-itemizado',
  templateUrl: './dashboard-itemizado.component.html',
  styleUrls: ['./dashboard-itemizado.component.css']
})
export class DashboardItemizadoComponent implements OnInit {

  userId: number;
  rolId: number;
  contratosItemizado: any[];
  cantidadAprobados: number = 0;
  detalleItemizadosAprobados: any[];
  selectedFilter: number = 1;
  cantidadTotales: number = 0;


  public selected: string;
  selection = new SelectionModel<any>(false, []);
  dataSource:any;
  dataSourceHeader: string[] = ['NombreContrato', 'EstadoItemizado', 'Acciones'];
  @ViewChild('paginatorGrl', {static: false}) paginatorGrl: MatPaginator;

  constructor(
    private estadoPagoService: EstadoPagoService,
    private router: Router
    ) { 
    this.userId = JSON.parse(localStorage.getItem('userData')).userId;
    this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
  }

  ngOnInit(): void {
    this.getContratosItemizados();
    this.getCantidadAprobados();
  }

  private getCantidadAprobados() {
    this.estadoPagoService.getItemizadosAprobados('CANTIDAD').subscribe(resp => {
      if (this.rolId === 1 || this.rolId === 17 || this.rolId === 20) {
        this.cantidadAprobados = JSON.parse(resp)[0].CANTIDAD; 
      }
    }, error => console.log(error));
  }
  itemizadosAprobados() {
    this.selectedFilter = 2;

    this.estadoPagoService.getItemizadosAprobados('DETALLE').subscribe(resp => {
      if (this.rolId === 1 || this.rolId === 17 || this.rolId === 20) {
        this.detalleItemizadosAprobados = JSON.parse(resp);

        this.dataSource = new MatTableDataSource(this.detalleItemizadosAprobados);
        this.createPaginator(this.paginatorGrl, this.dataSource);
      } 
    }, error => console.log(error));
  }
  getContratosItemizados() {

    this.selectedFilter = 1;

    this.estadoPagoService.getContratosItemizado(this.userId, this.rolId).subscribe(resp => {
      this.contratosItemizado = JSON.parse(resp);
      this.cantidadTotales = this.contratosItemizado.length;
      this.dataSource = new MatTableDataSource(this.contratosItemizado);
      this.createPaginator(this.paginatorGrl, this.dataSource);
    });
  }

  getContratosTodos(){
    
  }
  applyFilterGrl(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }
  irAEtapa(corr_contrato: number, corr_estado_itemizado: number) {
    // Cargar itemizado
    if(corr_estado_itemizado == 115) {
      this.router.navigate(['/itemizado', corr_contrato]);  
    }
    // Cargar articulos
    if(corr_estado_itemizado == 116 || corr_estado_itemizado == 119) {
      this.router.navigate(['/itemizado-detalle', corr_contrato]);  
    }
    // aprobar, rechazar itemizado Ó ver detalle
    if(corr_estado_itemizado == 117 || corr_estado_itemizado == 118) {
      this.router.navigate(['/itemizado-aprobacion', corr_contrato, corr_estado_itemizado]);
    }
  }

}
