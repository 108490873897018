import { Component, OnInit } from '@angular/core';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AnyCnameRecord } from 'dns';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})
export class ChecklistComponent implements OnInit {

  contratos: any[] = [];
  gruposChk: any[] = [];
  itemsAsociados: any[] = [];
  corrContrato: number;
  existeContrato = true;

  constructor(
    private estadoPagoService: EstadoPagoService, 
    private contratosService: ContratoService,
    private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.getContratos();
    this.getGrupoChk(0);    
  } 
  private getContratos() {
    this.contratosService.getContratosBusqueda().subscribe(resp=>{
      this.contratos= JSON.parse(resp);
    });
  }
  private getGrupoChk(contCorrelativo: number) {
    this.estadoPagoService.getGruposChk(contCorrelativo).subscribe(resp => {
      let response = JSON.parse(resp);
      this.gruposChk = [];

      let idx = 0;
      let cabecera = null;
      for(let i in response) {        
        if(idx == 0 || response[i]['GRUPO_NOMBRE'] !== cabecera) {
          this.gruposChk[idx] = {
            Cabecera: response[i]['GRUPO_NOMBRE'],
            Correlativo: null,
            NombreItem: null,
            Obligatorio: null
          };
          idx++;
          this.gruposChk[idx] = {
            Cabecera: null,
            Correlativo: response[i]['ITEM_CORRELATIVO'],
            NombreItem: response[i]['ITEM_NOMBRE'],
            Obligatorio: response[i]['ITEM_OBLIGATORIO']
          };
          idx++;
        } else {
          this.gruposChk[idx] = {
            Cabecera: null,
            Correlativo: response[i]['ITEM_CORRELATIVO'],
            NombreItem: response[i]['ITEM_NOMBRE'],
            Obligatorio: response[i]['ITEM_OBLIGATORIO']
          };
          idx++;
        } 
        cabecera = response[i]['GRUPO_NOMBRE'];
      }
    }, error => console.log(error));
  }
  private itemsRelacionadosContrato(corrContrato: number) {
    this.estadoPagoService.getItemRelacionadosContrato(corrContrato).subscribe(resp => {
      let response = JSON.parse(resp);
      this.itemsAsociados = [];

      let idx = 0;
      let cabecera = null;
      for(let i in response) {        
        if(idx == 0 || response[i]['GRUPO_NOMBRE'] !== cabecera) {
          this.itemsAsociados[idx] = {
            Cabecera: response[i]['GRUPO_NOMBRE'],
            Correlativo: null,
            NombreItem: null,
            Obligatorio: null
          };
          idx++;
          this.itemsAsociados[idx] = {
            Cabecera: null,
            Correlativo: response[i]['ITEM_CORRELATIVO'],
            NombreItem: response[i]['ITEM_NOMBRE'],
            Obligatorio: response[i]['ITEM_OBLIGATORIO']
          };
          idx++;
        } else {
          this.itemsAsociados[idx] = {
            Cabecera: null,
            Correlativo: response[i]['ITEM_CORRELATIVO'],
            NombreItem: response[i]['ITEM_NOMBRE'],
            Obligatorio: response[i]['ITEM_OBLIGATORIO']
          };
          idx++;
        } 
        cabecera = response[i]['GRUPO_NOMBRE'];
      }
    }, error => console.log(error));
  }
  onChangeCto(corrContrato: any) {
    this.corrContrato = corrContrato;
    this.existeContrato = (this.corrContrato) ? true : false;
    if(this.existeContrato) {
      this.itemsRelacionadosContrato(this.corrContrato);
      this.getGrupoChk(this.corrContrato);
    }
  }
  relacionarItem(correlativo: number) {
    if(!this.corrContrato) {
      this.existeContrato = false;
      return;
    }
    
    const post: {
      in_cont_correlativo: number,
      in_itemchk_correlativo: number,
      in_usua_correlativo_creacion: number
    } = {
      in_cont_correlativo: this.corrContrato,
      in_itemchk_correlativo: correlativo,
      in_usua_correlativo_creacion: 0
    };

    this.estadoPagoService.asociaItemContrato(post).subscribe(resp => {
      this.notifyService.showSuccess('Item relacionado con exito.', 'Checklist');
    }, error => {
      this.notifyService.showError('Error al asociar item', 'Checklist');
      console.log(error);
    }, () => {
      this.getGrupoChk(this.corrContrato);
      this.itemsRelacionadosContrato(this.corrContrato);
    });
  }
  eliminaRelacionItem(correlativo: number) {
    const post: {
      in_cont_correlativo: number,
      in_itemchk_correlativo: number,
      in_usua_correlativo_creacion: number,
      iv_estado: string
    } = {
      in_cont_correlativo: this.corrContrato,
      in_itemchk_correlativo: correlativo,
      in_usua_correlativo_creacion: 0,
      iv_estado: 'N'
    };

    this.estadoPagoService.eliminaRelacionContratoItem(post).subscribe(resp => {
      this.notifyService.showSuccess('Item relacionado con exito.', 'Checklist');
    }, error => {
      this.notifyService.showError('Error al asociar item', 'Checklist');
      console.log(error);
    }, () => {
      this.getGrupoChk(this.corrContrato);
      this.itemsRelacionadosContrato(this.corrContrato);
    });
  }
}
