import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { AddonsService } from 'src/app/shared/services/addons.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { FileService } from 'src/app/shared/services/file.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SolpeService } from 'src/app/shared/services/solpe.service';
import * as moment from 'src/assets/plugins/moment/moment';
const FileSaver = require('file-saver');
import { Location } from '@angular/common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ExcelSheetProps, ViewExcelButtonsProps } from 'src/app/shared/view-excel-button/view-excel-buttons.component';
import { Observable } from 'rxjs/internal/Observable';
import { formatCurrency } from 'src/app/shared/functions/common';

@Component({
  selector: 'app-contrato-detalle',
  templateUrl: './contrato-detalle.component.html',
  styleUrls: ['./contrato-detalle.component.css'],
  providers: [ContratoService, EstadoPagoService, SolpeService, FileService]
})
export class ContratoDetalleComponent implements OnInit {
  idContrato :number;
  rolId: number;
  tipo_ep_normal = "NORMAL";
  tipo_ep_incurrido = "INCURRIDO";
  tipo_ep_provision = "PROVISION";
  dataContrato: any = { ESTADO_CORRELATIVO: 0 };
  dataContratoPdf: any = { ESTADO_CORRELATIVO: 0 };
  contractCurrenci;
  solpeCorrelativo;
  itemizado = [];
  avanceTiempo = {DIAS_RESTANTES: '0', PORCENTAJE: 0, MESES_TOTALES: '0', MESES_TRANSCURRIDOS: '0', MESES_RESTANTES: '0'};
  avanceFinanciero = {PORCENTAJE: 0, MONTO: '0'};
  fechaProyectada;
  tipoVencimiento; //1 monto, 2 plazo
  contratoEnRevision = false;

  archivos = [];
  archivosRegistrados = [];
  archivosContrato = [];
  modificacionesContrato: any[];
  solpeContrato: any[] = [];
  dsEPNormal:any;
  ep_nornal: any[];

  boletas: any;
  tipoArchivoBoleta: string = 'boleta';
  //dcBoletas: string[] = ['NroBoleta', 'TIPO','DescripcionBoleta', 'Banco', 'TipoMoneda', 'EstadoBoleta', 'FECHA_INGRESO','FechaVencimiento', 'DiasVencimiento', 'Opciones'];
  dcBoletas: string[] = ['NUM_BOLETA', 'TIPO','FECHA_EMISION', 'BANCO', 'TIPO_MONEDA', 'FECHA_INGRESO','FECHA_VENCIMIENTO', 'DIAS_RESTANTES', 'ADJUNTO'];
  ultimaBoleta;
  diasBoleta = 0;
  formAgregarArchivo: FormGroup;
  formFiniquito: FormGroup;
  DECIMAL_PESOS: number = 2;

  @ViewChild('paginatorEPNormal', {static: false}) paginatorEPNormal: MatPaginator;
  @ViewChild(MatPaginator, {static: true}) paginatorBoletas: MatPaginator;
  @ViewChild('closeModalAgregar') closeModalFiniquito: ElementRef;

  dsHeaderEPNormal: string[] = ['EP_CORRELATIVO','DatosEp', 'EP_PERIODO_DESDE', 'EP_PERIODO_HASTA' ,'Monto', 'Reajuste', 'EP_ESTADO', 'hes_codigo','nro_factura','fecha_pago','Opciones'];
  agregarArchivoVisible: boolean = false;

  fileName;
  type;
  archivos64;
  submittedDocumento:boolean = false;

  dsAdecContrato:any;
  dsHeaderAdecContrato: string[] = ['AdminContratista', 'FechaInicio', 'FechaTermino','Vigente','UsuarioActualizacion','FechaActualizacion'];
  selection = new SelectionModel<any>(false, []);
  adecContrato: any[];
  @ViewChild('paginatorAdec', {static: false}) paginatorAdec: MatPaginator;


  showUpdateEmsa:boolean = false;
  ademsa: any[];
  gestionadores: any[];
  adecEmsaUpdateId;

  //Adec Emsa
  dsAdecEmsaContrato:any;
  dsHeaderAdecEmsaContrato: string[] = ['AdminEmsa', 'FechaInicio', 'FechaTermino','Vigente','UsuarioActualizacion','FechaActualizacion'];
  adecEmsaContrato;
  @ViewChild('paginatorAdecEmsa', {static: false}) paginatorAdecEmsa: MatPaginator;
  submittedEmsa = false;

  totalItemizado: number = 0;
  saldoItemizado: number = 0;
  totalEpFactuerado: number = 0;
  totalFacturadoEp: number = 0;
  epCount:  number = 0;

  accionesPermitidasCorr;
  contratosProveedor: any[];

  userId: number = 0;
  checkAdminEmsa: boolean = false;
  checkAdminEECC: boolean = false;
  checkItemizado: boolean = false;
  checkItemizadoAprobado: boolean = false;
  checkArticulos: boolean = false;
  checkCartaAdjudicacion: boolean = false;
  checkContratoFirmado: boolean = false;
  checkCompletado: boolean = true;
  contratoPublicado: number = 0;

  usuariosEnvioCorreoCreacionList: any[] = [];
  init: boolean = false;
  modificacionContratoCorrelativo: number;

  excelButtonEP: ViewExcelButtonsProps = {
    title: 'Estados de pago',
    filename: 'estados_pago',
    headers: [
      {
        title: 'No.',
        value: 'EP_NUMERO',
      },
      {
        title: 'Descripcion',
        value: 'EP_DESCRIPCION',
      },
      {
        title: 'Desde',
        value: 'EP_PERIODO_DESDE',
      },
      {
        title: 'Hasta',
        value: 'EP_PERIODO_HASTA',
      },
      {
        title: 'Monto',
        value: 'monto',
      },
      {
        title: 'Reajuste',
        value: 'reajuste_ep',
      },
      {
        title: 'Estado',
        value: 'EP_ESTADO',
      },
      {
        title: 'Hes',
        value: 'hes_codigo',
      },
      {
        title: 'Factura',
        value: 'nro_factura',
      },
      {
        title: 'Fecha de pago',
        value: 'fecha_pago',
      },
    ],
    data: []
  }

  excelButtonItemizado: ViewExcelButtonsProps = {
    title: 'Itemizado',
    filename: 'itemizado',
    headers: [
      {
        title: 'POS',
        value: 'Pos',
      },
      {
        title: 'SPOS',
        value: 'Subpos',
      },
      {
        title: 'ITEM',
        value: 'Descripcion',
      },
      {
        title: 'ARTICULO',
        value: 'Articulo',
      },
      {
        title: 'UNIDAD',
        value: 'Unidad',
      },
      {
        title: 'CANTIDAD',
        value: 'Cantidad',
      },
      {
        title: 'PRECIO',
        value: 'PrecioUnitario',
      },
      {
        title: 'TOTAL',
        value: 'Total',
      },
      {
        title: 'AVANCE Q',
        value: 'AvanceQ',
      },
      {
        title: 'SALDO Q',
        value: 'SaldoQ',
      },
      {
        title: 'AVANCE',
        value: 'Avance',
      },
      {
        title: 'AVANCE',
        value: 'Saldo',
      },
      {
        title: 'REAJUSTE',
        value: 'R',
      },
      {
        title: 'SCPQ',
        value: 'Scpq',
      },
    ],
    data: []
  }

  infoSheet: ExcelSheetProps = {
    title: 'Itemizado',
    headers: [
      {
        title: 'Codigo Contrato',
        value: 'CODIGO_CONTRATO'
      },
      {
        title: 'Nombre Contrato',
        value: 'NOMBRE_CONTRATO'
      },
      {
        title: 'Estado',
        value: 'ESTADO_CONTRATO'
      },
      {
        title: 'Origen',
        value: 'ORIGEN'
      },
      {
        title: 'Area',
        value: 'AREA'
      },
      {
        title: 'Rut Proveedor',
        value: 'RUT_PROVEEDOR'
      },
      {
        title: 'Proveedor',
        value: 'PROVEEDOR'
      },
      {
        title: 'Administrador',
        value: 'ADMIN_PROVEEDOR'
      },
      {
        title: 'Adm EMSA',
        value: 'ADMINISTRADOR'
      },
      {
        title: 'Moneda',
        value: 'TIPO_MONEDA'
      },
      {
        title: 'Ultimo EP',
        value: 'ULTIMO_EP'
      },
      {
        title: 'Vencimiento BG',
        value: 'VENCIMIENTO_BG'
      },
      {
        title: 'Monto Original',
        value: 'MONTO_ORIGINAL'
      },
      {
        title: 'Reajuste Original',
        value: 'MONTO_REAJUSTE'
      },
      {
        title: '% Reajuste',
        value: 'PORCENTAJE_REAJUSTE'
      },
      {
        title: 'Modif Original',
        value: 'MONTO_MODIF'
      },
      {
        title: '% Modif',
        value: 'PORCENTAJE_MODIF'
      },
      {
        title: 'Act Original',
        value: 'MONTO_ACTUALIZADO'
      },
      {
        title: 'EP Facturado',
        value: 'MONTO_EP'
      },
      {
        title: '% EP',
        value: 'PORCENTAJE_EP'
      },
      {
        title: 'Saldo Original',
        value: 'MONTO_SALDO'
      },
      {
        title: '% Saldo',
        value: 'PORCENTAJE_SALDO'
      },

      {
        title: 'Monto USD',
        value: 'MONTO_USD'
      },
      {
        title: 'Reajuste USD',
        value: 'REAJUSTE_USD'
      },
      
      {
        title: 'Modif USD',
        value: 'MONTO_MODIF_USD'
      },
      {
        title: 'Act USD',
        value: 'MONTO_ACTUALIZADO_USD'
      },
      {
        title: 'EP USD',
        value: 'MONTO_EP_USD'
      },
      {
        title: 'Saldo USD',
        value: 'MONTO_SALDO_USD'
      },
      {
        title: 'Inicio',
        value: 'FECHA_INICIO_CONTRATO'
      },
      {
        title: 'Termino Actual',
        value: 'FECHA_TERMINO_CONTRATO'
      },
      {
        title: 'Termino Original',
        value: 'FECHA_TERMINO_ORIGINAL'
      },
      {
        title: 'Termino Proyectada',
        value: 'FECHA_TERMINO_PROYECTADA'
      },
      {
        title: 'Avance de tiempo',
        value: 'PORCENTAJE_TIEMPO'
      },
      // {
      //   title: 'Dias restantes',
      //   value: 'DIAS_RESTANTES',
      // },
      {
        title: 'Avance financiero',
        value: 'PORCENTAJE_FINANCIERO'
      },
      {
        title: 'Monto',
        value: 'MONTO'
      },
      {
        title: 'Meses Totales',
        value: 'MESES_TOTALES',
      },
      {
        title: 'Meses Transcurridos',
        value: 'MESES_TRANSCURRIDOS',
      },
      {
        title: 'Meses Restantes',
        value: 'MESES_RESTANTES',
      },
    ],
    data: [],
    totalRowsByColumn: [12,16],
  }

  constructor(
    private route: ActivatedRoute,
    private contratoService: ContratoService,
    public GloblaDataService: GlobalDataService,
    private estadoPagoService: EstadoPagoService,
    private solpeService: SolpeService,
    private fileService: FileService,
    private notifyService: NotificationService,
    private addonsService: AddonsService,
    private location: Location,
    private authService: AuthService
  ) {
      this.userId = JSON.parse(localStorage.getItem('userData')).userId;
      this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
      this.modificacionContratoCorrelativo = 0;

      if(this.GloblaDataService.CurrenciesList.length == 0)
      {
        this.GloblaDataService.GetCurrencie();
      }
      //this.contractCurrenci = this.GloblaDataService.CurrenciesList.find(currencie => currencie.TimoCode == this.dataContrato.TIMO_CORRELATIVO)[0]
      //this.GloblaDataService.USD = this.GloblaDataService.CurrenciesList.find(currencie => currencie.TimoCode == 12);
      this.formAgregarArchivo = new FormGroup({
        grupo : new FormControl(null, Validators.required),
        adjunto : new FormControl(null),
        corr_cont: new FormControl(null),
        descripcion: new FormControl(null),
        isPublic: new FormControl(null)
      });

      

      
      let corrMenu = 2;
      this.authService.getAccionesPorUsuario(corrMenu).subscribe(resp => {
        let acciones: any[] = JSON.parse(resp);
        if (acciones.length > 0) {
          //this.accionesPermitidas = acciones[0].ACCIONES.split(' - ');
          this.accionesPermitidasCorr = acciones[0].ACCIONES_CORRELATIVO.split(' - ');
        }
      },
        error => { console.log(error) });

      this.route.params
      .subscribe(
        (params: Params)=>{
          this.idContrato = params['corr_contrato'];
          this.solpeCorrelativo = params['corr_solpe'];
          if (this.idContrato){
            this.getEPContrato(this.idContrato);
          }

          this.formFiniquito = new FormGroup({
            estado : new FormControl(32, Validators.required),
            adjunto : new FormControl(null),
            corr_cont: new FormControl(this.idContrato),
            observacion: new FormControl(null,Validators.required),
            isPublic: new FormControl(true)
          });
        });
   }

  existeAccion(accion: string) {
    return (this.accionesPermitidasCorr?.includes(accion));
  }

  back(): void {
    this.location.back();
  }

  onVer(){
    if (this.idContrato){
      this.getItemizado(this.idContrato);
      this.getDocumentos(this.idContrato);
      this.getModificacionesContrato();
      this.onGetSolpeContrato(this.solpeCorrelativo);
      this.getBoletasContratoId(this.idContrato);
      this.getContratosProveedor(this.dataContrato?.CORRELATIVO_PROVEEDOR);
      this.postUsuariosEmailCreacionContrato();
      this.getEstadoItemizadoContrato();
    }
  }

  onPublicarContrato(){
    let postData = {
      pin_contrato_correlativo: this.idContrato
    }

    this.contratoService.putPublicarContrato(postData).subscribe(resp => {
      this.onSyncContratoChecklist();
      this.notifyService.showSuccess('Contrato publicado.', 'Publicar');
    });
  }

  getEstadoItemizadoContrato(){
    let postData = {
      pin_contrato_correlativo: this.idContrato
    }

    this.contratoService.postEstadoItemizadoContrato(postData).subscribe(resp => {
      if (JSON.parse(resp)){
        let aprobacionItemizado: any = JSON.parse(resp)[0];
        if (aprobacionItemizado?.domi_correlativo_esdo_itemizado === 118) {
          this.checkItemizadoAprobado = true;
        } else {
          this.checkItemizadoAprobado = false;
        }
      } else {
        this.checkItemizadoAprobado = false;
      }

      this.validarCheckListContrato();
    });
  }

  postUsuariosEmailCreacionContrato(){
    this.contratoService.postUsuariosEmailCreacionContrato().subscribe(resp => {
      this.usuariosEnvioCorreoCreacionList = JSON.parse(resp);
      //console.log(this.usuariosEnvioCorreoCreacionList);
    });
  }

  onSyncContratoChecklist(){
    if (this.idContrato){
      this.getContratoId(this.idContrato);
      this.getItemizado(this.idContrato);
      this.getDocumentos(this.idContrato);

      this.validarCheckListContrato();
    }
  }

  validarCheckListContrato(){
    if (this.checkAdminEmsa === true && this.checkAdminEECC === true && this.checkItemizado === true && this.checkItemizadoAprobado === true && this.checkArticulos === true && this.checkContratoFirmado === true && this.checkCartaAdjudicacion === true) {
      this.checkCompletado = false;
    }else{
      this.checkCompletado = true;
    }
  }

  private getDocumentos(id: number){
    this.contratoService.getContratoDocumentos(id).subscribe(resp=>{
      if (JSON.parse(resp)[0]){
        this.archivos = JSON.parse(resp);
        this.archivosRegistrados = this.archivos.filter(t=>t.EXISTE ==='S');
      }
    });
    this.contratoService.getDocumentosContrato(this.idContrato).subscribe(resp=>{
      this.archivosContrato = JSON.parse(resp);
      let cartaAdjudicacion: any[] = this.archivosContrato.filter(adjunto => adjunto.DOC_CORRELATIVO === 3);
      let contratoFirmado: any[] = this.archivosContrato.filter(adjunto => adjunto.DOC_CORRELATIVO === 4);

      if (cartaAdjudicacion.length > 0) {
        this.checkCartaAdjudicacion = true;
      }else{
        this.checkCartaAdjudicacion = false;
      }

      if (contratoFirmado.length > 0) {
        this.checkContratoFirmado = true;
      }else{
        this.checkContratoFirmado = false;
      }

      this.validarCheckListContrato();
    });
  }

  onDescargar(fileName: string){
    this.fileService.downloadDocumentoContrato(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }
  private getContratoId(id: number){
    this.dataContrato = {};
    this.contratoService.getContratoId(id).subscribe(resp=>{

      if (JSON.parse(resp)[0]){
        let contratoData: any = JSON.parse(resp)[0];

        // calculo de proyecciones
        const fechaDesde = moment(contratoData.FECHA_INICIO_CONTRATO);
        const fechaHasta = moment(contratoData.FECHA_TERMINO_CONTRATO);

        const diasTotales = fechaHasta.diff(fechaDesde, 'days');
        const b = 100;
        const hoy = moment(new Date());
        const diasTranscurridos = hoy.diff(fechaDesde, 'days');

        this.avanceTiempo.MESES_TOTALES = Math.round(fechaHasta.diff(fechaDesde, 'months', true)).toString();
        this.avanceTiempo.MESES_TRANSCURRIDOS = hoy.diff(fechaDesde, 'months').toString();
        this.avanceTiempo.MESES_RESTANTES = Math.round(+this.avanceTiempo.MESES_TOTALES - +this.avanceTiempo.MESES_TRANSCURRIDOS).toString();

        const porcentajeActual = (diasTranscurridos * b) / diasTotales;
        this.avanceTiempo.PORCENTAJE = Math.round(porcentajeActual);
        this.avanceTiempo.DIAS_RESTANTES = Math.round(diasTotales - diasTranscurridos).toString();

        if(porcentajeActual > 100){
          this.avanceTiempo.PORCENTAJE = 100;
        }
        if(+this.avanceTiempo.DIAS_RESTANTES < 0 || this.avanceTiempo.PORCENTAJE < 0){
          this.avanceTiempo.PORCENTAJE = 0;
          this.avanceTiempo.DIAS_RESTANTES = '0';
        }
        if(+this.avanceTiempo.MESES_RESTANTES < 0){
          this.avanceTiempo.MESES_RESTANTES = '0';
        }
        this.avanceFinanciero.PORCENTAJE = (((contratoData?.MONTO_EP - contratoData?.MONTO_REAJUSTE) * b) / contratoData?.SALDO_CONTRATO);
        this.avanceFinanciero.PORCENTAJE = parseFloat(this.avanceFinanciero.PORCENTAJE.toFixed(2));

        this.avanceFinanciero.MONTO = (contratoData?.SALDO_CONTRATO - contratoData?.MONTO_EP + contratoData?.MONTO_REAJUSTE).toString();

        const promedioDiario = contratoData?.MONTO_EP / diasTranscurridos;
        const montoProyectado = promedioDiario * +this.avanceTiempo?.DIAS_RESTANTES;

        const montoProyectadoFinal = montoProyectado + contratoData?.MONTO_EP;

        if(montoProyectadoFinal > contratoData?.MONTO_ACTUALIZADO)
        {
          //vence por monto
          this.tipoVencimiento = 1;
        }
        else
        {
          //vence por plazo
          this.tipoVencimiento = 2;
        }

        const diasConsumoProyectado = Math.round((contratoData?.MONTO_ACTUALIZADO - contratoData?.MONTO_EP) / promedioDiario);
        this.fechaProyectada = hoy.add(diasConsumoProyectado,'d');

        contratoData.FECHA_TERMINO_PROYECTADA = this.tipoVencimiento == 1 ? this.fechaProyectada : contratoData.FECHA_TERMINO_CONTRATO;
        contratoData.FECHA_TERMINO_PROYECTADA = moment(contratoData.FECHA_TERMINO_PROYECTADA).format('DD-MM-YYYY');
        contratoData.FECHA_TERMINO_ORIGINAL = moment(contratoData.FECHA_TERMINO_ORIGINAL).format('DD-MM-YYYY');

        this.contratoPublicado = contratoData.PUBLICADO;

        if (contratoData.ADMINISTRADOR) {
          this.checkAdminEmsa = true;
        }else{
          this.checkAdminEmsa = false;
        }

        if (contratoData.ADMIN_PROVEEDOR) {
          this.checkAdminEECC = true;
        }else{
          this.checkAdminEECC = false;
        }

        contratoData = {
          ...contratoData,
          ...this.avanceTiempo,
          ...this.avanceFinanciero,
          PORCENTAJE_TIEMPO: this.avanceTiempo.PORCENTAJE+'%',
          PORCENTAJE_FINANCIERO: this.avanceFinanciero.PORCENTAJE+'%',
        };

        this.validarCheckListContrato();
        this.setDataContrato(contratoData);

        if(!this.init) {
          this.onVer();
          this.init = true;
        }
      }
    });
  }
  private getModificacionesContrato(){

    this.solpeService.getSolpeModificacionContrato(1,this.rolId,this.idContrato).subscribe(resp=>{

      if (JSON.parse(resp)){
        this.modificacionesContrato = JSON.parse(resp);
      }
    });
  }

  private getItemizado(corrContrato: number) {
    /* this.contratoService.getFootItemizado(corrContrato).subscribe(resp=>{
      this.Footitemizados = JSON.parse(resp);
    }); */
    this.estadoPagoService.getItemizadoDetalle(corrContrato).subscribe(resp => {
      let response = JSON.parse(resp);

      if (response.length > 0) {
        this.checkItemizado = true;

        let validarArticulosItemizado: any[] = response.filter(articulo => articulo.ARTICULO === null);

        if (validarArticulosItemizado.length > 0) {
          this.checkArticulos = false;
        }else{
          this.checkArticulos = true;
        }
      }else{
        this.checkItemizado = false;
      }

      this.validarCheckListContrato();

      let idx = 0;
      let cabecera = null;
      this.itemizado = [];
      this.totalItemizado = 0;
      // creo estructura del itemizado para mostrar de forma ordenada
      for(let i in response) {
        // si es el primer registro o cambia la cabecera del itemizado: registro cabecera
        const item = response[i];
        const Cabecera = item['ITEM_CABECERA'];
        if(idx == 0 || Cabecera !== cabecera) {
          // cabecera
          this.itemizado[idx] = {
            Correlativo: item['ID_CORRELATIVO'],
            Cabecera: Cabecera,
            Pos: item['CABECERA_POSICION'],
            R: null,
            Scpq: null,
            Subpos: item['CABECERA_SUBPOSICION'],
            Descripcion: null,
            Articulo: null,
            Cantidad: null,
            Unidad: null,
            PrecioUnitario: null,
            Total: null,
            AvanceQ: null,
            SaldoQ: null,
            Avance: null,
            Saldo: null,
          };
          // primer subitem
          idx++;
        }
        // sigo registrando subitems hasta que cambie el item principal
        const Cantidad = item['CANTIDAD'];
        const PrecioUnitario = item['PRECIO_UNITARIO'];
        const AvanceQ = item['AVANCE_Q'] || 0;
        const Avance = item['AVANCE'];
        this.itemizado[idx] = {
          Correlativo: item['ID_CORRELATIVO'],
          Cabecera: null,
          Pos: null,
          R: item['REAJUSTE'] ? 'X' : null,
          Scpq: item['SCPQ'] ? 'X' : null,
          Subpos: item['SUBPOSICION'],
          Descripcion: item['DESCRIPCION'],
          Articulo: item['ARTICULO'],
          Cantidad: Cantidad,
          Unidad: item['UNIDAD'],
          PrecioUnitario: PrecioUnitario,
          Total: PrecioUnitario * Cantidad,
          AvanceQ: AvanceQ,
          SaldoQ: Cantidad - AvanceQ,
          Avance: Avance,
          Saldo: (PrecioUnitario * Cantidad) - Avance,
        };
        idx++;
        cabecera = Cabecera;

        // ((item.PrecioUnitario * item.Cantidad) - item.Consumo)
        this.totalItemizado = this.totalItemizado + ((PrecioUnitario * Cantidad));
        this.saldoItemizado = this.saldoItemizado + ((PrecioUnitario * Cantidad) - Avance);
      }
      this.excelButtonItemizado.data = this.itemizado;
    });
  }
  onGetSolpeContrato(solpeCorrelativo: number){
    this.contratoService.getSolpeContrato(solpeCorrelativo).subscribe(resp =>{
      //console.log(JSON.parse(resp));
      this.solpeContrato = JSON.parse(resp);
    });
  }

  public aplicarModificacion(idSolpe: number){
    let postData = {
      in_solpe_correlativo: idSolpe,
      in_cargo_correlativo: this.rolId,
      in_usua_correlativo: idSolpe
    };
    this.idContrato
    //alert(idSolpe);

    let currentModificacionContrato: any[] = this.modificacionesContrato.filter(modificacion => modificacion.CORRELATIVO === idSolpe);

    //console.log(currentModificacionContrato);

    // if (currentModificacionContrato[0].alcance !== null) {
    //   if (currentModificacionContrato[0].CORRELATIVO_ESDO_ITEMIZADO !== 123) {

    //     this.notifyService.showWarning('El itemizado para la modificacion de contrato no es valido para aplicar la modificacion', 'Modificacion de contrato');
    //     return;
    //   } else {
    //     //console.log('es valido para modificar');

    //     this.solpeService.postAplicaModificacionContrato(postData).subscribe(resp=>{
    //       this.notifyService.showSuccess('Modificación de contrato aplicada con exito.', 'Crear Modificacion');
    //       // notificacion de modificacion de contrato a admin. EMSA
    //       this.solpeService.postEnviarCorreoInfoEmsaAplicacionModificacionContrato({in_cont_correlativo: this.idContrato}).subscribe(resp=>{
    //         this.notifyService.showSuccess('Notificacion de Modificación de contrato enviada.', 'Notificacion de Modificacion');
    //       }, err => {
    //         this.notifyService.showError('Error al enviar la notificacion de modificación de contrato', 'Notificacion de modificacion');
    //       });

    //     }, err => {
    //       this.notifyService.showError('Error al crear modificación de contrato', 'Crear Modificacion');
    //       //console.log(err);
    //     }, () => {
    //       this.getContratoId(this.idContrato);
    //       this.getModificacionesContrato();
    //     });
    //   }
    // } else {
    //   //console.log('es valido para modificar');
    //   this.solpeService.postAplicaModificacionContrato(postData).subscribe(resp=>{
    //     this.notifyService.showSuccess('Modificación de contrato aplicada con exito.', 'Crear Modificacion');
    //     // notificacion de modificacion de contrato a admin. EMSA
    //     this.solpeService.postEnviarCorreoInfoEmsaAplicacionModificacionContrato({in_cont_correlativo: this.idContrato}).subscribe(resp=>{
    //       this.notifyService.showSuccess('Notificacion de Modificación de contrato enviada.', 'Notificacion de Modificacion');
    //     }, err => {
    //       this.notifyService.showError('Error al enviar la notificacion de modificación de contrato', 'Notificacion de modificacion');
    //     });

    //   }, err => {
    //     this.notifyService.showError('Error al crear modificación de contrato', 'Crear Modificacion');
    //     //console.log(err);
    //   }, () => {
    //     this.getContratoId(this.idContrato);
    //     this.getModificacionesContrato();
    //   });
    // }

    this.solpeService.postAplicaModificacionContrato(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Modificación de contrato aplicada con exito.', 'Crear Modificacion');

      if (currentModificacionContrato[0].fecha_termino !== null || currentModificacionContrato[0].monto_actualizado !== null) {
        // NOTIFICACION QUE SE ENVIA A LOS SELECCIONADOS EN LOS MANTENDORES DE CORREOS ADEMAS DE LOS ADMIN EMSA Y EECC
        this.solpeService.postEnviarCorreoInfoVigenteAplicacionModificacionContrato({in_cont_correlativo: this.idContrato, in_cont_fase: 9}).subscribe(resp=>{
          this.notifyService.showSuccess('Notificacion de Modificación de contrato enviada.', 'Notificacion de Modificacion');
        }, err => {
          this.notifyService.showError('Error al enviar la notificacion de modificación de contrato', 'Notificacion de modificacion');
        });

      } else {
        // notificacion de modificacion de contrato a admin. EMSA
        this.solpeService.postEnviarCorreoInfoEmsaAplicacionModificacionContrato({in_cont_correlativo: this.idContrato}).subscribe(resp=>{
          this.notifyService.showSuccess('Notificacion de Modificación de contrato enviada.', 'Notificacion de Modificacion');
        }, err => {
          this.notifyService.showError('Error al enviar la notificacion de modificación de contrato', 'Notificacion de modificacion');
        });

      }

    }, err => {
      this.notifyService.showError('Error al crear modificación de contrato', 'Crear Modificacion');
      //console.log(err);
    }, () => {
      this.getContratoId(this.idContrato);
      this.getModificacionesContrato();
    });
  }

  private getEPContrato(cont_correlativo: number){
    this.contratoService.getEPContrato("NORMAL", cont_correlativo).subscribe(resp =>{
      this.ep_nornal = JSON.parse(resp);
      this.epCount = this.ep_nornal.length;
      let totalizadoEp: number = 0;

      if (this.ep_nornal.length > 0) {
        for (let index = 0; index < this.ep_nornal.length; index++) {
          const element = this.ep_nornal[index];

          if (element.ESTADO_ESTADO_PAGO === 109) {
            this.totalFacturadoEp = this.totalFacturadoEp + element.monto;
          }
        }
      }

      this.ep_nornal = this.ep_nornal.map(ep => {
        return {
          ...ep,
          EP_NUMERO: ep.EP_NUMERO === null ? ep.EP_CORRELATIVO : ep.EP_NUMERO,
          monto: +ep.monto,
          reajuste_ep: +ep.reajuste_ep
        }
      });
      
      let epDtaExcel: any[] = this.ep_nornal.map(ep => {
        return {
          ...ep,
          EP_NUMERO: ep.EP_NUMERO === null ? ep.EP_CORRELATIVO : ep.EP_NUMERO,
          monto: ep.monto.toString().replace(/\./g, ","),
          reajuste_ep: formatCurrency(+ep.reajuste_ep, this.contractCurrenci?.Decimal)
        }
      });

      // sacar el total de los ep completo no importanto si esta facturado o no
      this.ep_nornal.map(ep => {
        totalizadoEp = totalizadoEp + ep.monto;
      });

      epDtaExcel = [
        ...epDtaExcel,
        {},
        {
          monto: 'TOTAL EP'
        },
        {
          monto: totalizadoEp.toString().replace(/\./g, ",")
        },
        {},
        {
          monto: 'TOTAL FACTURADO'
        },
        {
          monto: this.totalFacturadoEp.toString().replace(/\./g, ",")
        },
      ];

      this.dsEPNormal = new MatTableDataSource(this.ep_nornal);
      this.excelButtonEP.data = epDtaExcel;
      if(this.ep_nornal.length > 0){
        //this.createPaginator(this.paginatorEPNormal, this.dsEPNormal);
      }

      this.getContratoId(this.idContrato);
    })
  }
  applyFilterEPIncurrido(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsEPNormal.filter = filterValue.trim().toLowerCase();
  }
  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }

  applyFilterBoletas(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.boletas.filter = filterValue.trim().toLowerCase();
  }

  onDescargarBoleta(fileName: string){
    this.fileService.downloadFile(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }
  private getBoletasContratoId(filtro: number){
    //console.log(filtro)
    this.contratoService.getBoletasContratoId(filtro).subscribe(resp=>{
      //console.log(JSON.parse(resp));
      this.boletas = new MatTableDataSource(JSON.parse(resp));
      if (this.boletas){
        //this.paginatorBoletas._intl.itemsPerPageLabel = 'Registros por página';
        //this.paginatorBoletas._intl.firstPageLabel = 'Primera pagina';
        //this.paginatorBoletas._intl.lastPageLabel = 'Última pagina';
        //this.paginatorBoletas._intl.nextPageLabel = 'Siguiente';
        //this.paginatorBoletas._intl.previousPageLabel = 'Anterior';
        //this.boletas.paginatorBoletas = this.paginatorBoletas;
        this.ultimaBoleta = JSON.parse(resp)[0];
        if(this.ultimaBoleta)
        {
          const fechaBoleta = moment(this.ultimaBoleta?.FECHA_VENCIMIENTO);
          this.diasBoleta = fechaBoleta.diff(moment(new Date()), 'days');

          if(this.diasBoleta < 0)
          {
            this.diasBoleta = 0;
          }

          const VENCIMIENTO_BG = `${fechaBoleta.format('DD/MM/YYYY')} (${this.diasBoleta} dias)`;
          this.dataContrato.VENCIMIENTO_BG = VENCIMIENTO_BG;
          //this.mergeInfoSheet(this.dataContrato);
        }
      }
    });
  }

  cargarModalArchivo(correlativoCont: number) {
    this.formAgregarArchivo.controls.corr_cont.setValue(correlativoCont);
    this.agregarArchivoVisible = !this.agregarArchivoVisible;
    //this.getDocumentosSolpe(correlativoSolpe);
  }
  onDelete(fileName: string, correlativo: number){
    this.fileService.deleteFile({in_file_name: fileName, in_file_type: "contratos", in_correlativo: correlativo}).subscribe(resp => {
      this.notifyService.showSuccess('Eliminado ...', 'Documento eliminado');
      this.getDocumentos(this.idContrato);
    }, err =>{
      this.getDocumentos(this.idContrato);
      this.notifyService.showError(err.error.message, 'Erro al eliminar archivo');
    });
  }

  onAgregarArchivo() {
    this.submittedDocumento = true;
    if (this.formAgregarArchivo.invalid) {
      return;
    }

    if(this.fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.fileName , type : this.type , files: this.archivos64}
      this.addonsService.postFileContrato(postDataFile).subscribe(resp =>{
        this.postDocumento(JSON.parse(resp));
        this.getDocumentos(this.idContrato);
        //this.closebutton.nativeElement.click();
      });
    }
    return;
  }

  private postDocumento(nombreArchivo: string) {
    const postData : {
      pin_cont_correlativo: number,
      pin_doc_correlativo: number,
      piv_nom_archivo: string,
      pin_usua_correlativo: number,
      piv_descripcion: string,
      piv_esPublico: string
    } = {
      pin_cont_correlativo: this.idContrato,
      pin_doc_correlativo: +this.formAgregarArchivo.value.grupo,
      piv_nom_archivo: nombreArchivo,
      pin_usua_correlativo: 0,
      piv_descripcion: this.formAgregarArchivo.value.descripcion,
      piv_esPublico: this.formAgregarArchivo.value.isPublic ? 'S' : 'N'
    };
    this.contratoService.postcreaArchivoContrato(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Documento creado con exito.', 'Crear Documento');
    }, err =>{
      this.notifyService.showError('Error al crear documento', 'Crear Documento');
      console.log(err);
    });
    this.getDocumentos(this.idContrato);
    this.resetFormArchivo();
  }
  private resetFormArchivo(){
    this.formAgregarArchivo = new FormGroup({
      grupo : new FormControl(null, Validators.required),
      adjunto : new FormControl(null),
      corr_cont: new FormControl(null),
      descripcion: new FormControl(null),
      isPublic: new FormControl(null)
    });
    this.submittedDocumento = false;
    this.fileName = null;
  }

  detectFile(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.archivos64 = bs64
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
        }
      };
    }
  }
  onCloseArchivo() {
    this.resetFormArchivo();
  }
  get fd() { return this.formAgregarArchivo.controls; }

  getAdecContrato() {
    this.contratoService.getAdecContrato(this.idContrato).subscribe(resp => {
      this.adecContrato = JSON.parse(resp);
      this.dsAdecContrato = new MatTableDataSource(this.adecContrato);
      //this.createPaginator(this.paginatorAdec, this.dsAdecContrato);
    });
  }

  onGetAdemsaUpdateId(ademsaId: number){
    this.adecEmsaUpdateId = ademsaId;
  }
  onGuardarAdminEmsa(){
    if (this.adecEmsaUpdateId > 0 ){
      const postData : {
        in_cont_correlativo :  number,
        in_admin_emsa :  number,
        in_usua_correlativo_creacion :  number
      } = {
        in_cont_correlativo :  this.idContrato,
        in_admin_emsa :  this.adecEmsaUpdateId,
        in_usua_correlativo_creacion : 0 // se cambia por el id en el servicio
      }
      this.contratoService.postAdecEmsaContrato(postData).subscribe(resp=>{
        if (JSON.parse(resp)[0].CORRELATIVO){
          this.notifyService.showSuccess('Administrador EMSA actualizado con exito.', 'Actualizar Contrato');
          this.getAdecEmsaContrato();
          this.showUpdateEmsa = !this.showUpdateEmsa;
        }
      });
    }
  }
  getAdecEmsaContrato() {
    this.contratoService.getAdecEmsaContrato(this.idContrato).subscribe(resp => {
      this.adecEmsaContrato = JSON.parse(resp);
      this.dsAdecEmsaContrato = new MatTableDataSource(this.adecEmsaContrato);
      //this.createPaginator(this.paginatorAdecEmsa, this.dsAdecEmsaContrato);
    });
  }
  get f() {
    //return this.formAgregarContrato.controls;
    return this.formAgregarArchivo.controls;

  }
  getAdemsa() {
    if(this.ademsa?.length == 0 || this.ademsa == null || this.ademsa == undefined)
    {
      this.contratoService.getAdemsa().subscribe(resp => {
        this.ademsa = JSON.parse(resp);
      });
    }
  }

  ngOnInit(): void {
  }

  private getContratosProveedor(corrProveedor: number) {
    if(!corrProveedor) return;
    this.solpeService.getContratosProveedor(corrProveedor).subscribe(resp => {
      this.contratosProveedor = JSON.parse(resp);
    });
  }

  setDataContrato(dataContrato: any) {

    const montos = this.setMontos(dataContrato);

    this.dataContrato = {
      ...dataContrato,
      ...montos,
      FECHA_INICIO_CONTRATO: moment(dataContrato.FECHA_INICIO_CONTRATO).format('DD-MM-YYYY'),
      FECHA_TERMINO_CONTRATO: moment(dataContrato.FECHA_TERMINO_CONTRATO).format('DD-MM-YYYY'),
      ULTIMO_EP: dataContrato.ULTIMO_EP ? moment(dataContrato.ULTIMO_EP).format('DD-MM-YYYY') : 'No tiene',
      VENCIMIENTO_BG: dataContrato?.VENCIMIENTO_BG ? dataContrato?.VENCIMIENTO_BG : 'No tiene',
    }

    this.contractCurrenci = this.GloblaDataService.CurrenciesList?.find(currencie => currencie.TimoCode == dataContrato.TIMO_CORRELATIVO);

    const dataCabeceraCont = {
      ...this.dataContrato,
      MONTO: this.dataContrato.MONTO.toString().replace(/\./g, ","),
      MONTO_SALDO: this.dataContrato.MONTO_SALDO.toString().replace(/\./g, ","),
      MONTO_EP: this.dataContrato.MONTO_EP.toString().replace(/\./g, ","),
      MONTO_USD: this.dataContrato.MONTO_USD.toFixed(2).replace(/\./g, ","),
      REAJUSTE_USD: this.dataContrato.REAJUSTE_USD.toFixed(2).replace(/\./g, ","),
      MONTO_MODIF_USD: this.dataContrato.MONTO_MODIF_USD.toFixed(2).replace(/\./g, ","),
      MONTO_ACTUALIZADO_USD: this.dataContrato.MONTO_ACTUALIZADO_USD.toFixed(2).replace(/\./g, ","),
      MONTO_EP_USD: this.dataContrato.MONTO_EP_USD.toFixed(2).replace(/\./g, ","),
      MONTO_SALDO_USD: this.dataContrato.MONTO_SALDO_USD.toFixed(2).replace(/\./g, ","),
    };

    this.mergeInfoSheet(dataCabeceraCont);
    this.closeModalFiniquito.nativeElement.click();
  }

  // dataContrato.MONTO_EP
  setMontos(dataContrato: any) {
    let MONTO_ORIGINAL = dataContrato.MONTO_ORIGINAL;
    let MONTO_REAJUSTE = dataContrato.MONTO_REAJUSTE;
    let MONTO_MODIF = dataContrato.MONTO_ACTUALIZADO - dataContrato.MONTO_ORIGINAL;
    let MONTO_ACTUALIZADO = dataContrato.MONTO_ACTUALIZADO;
    let MONTO_EP = this.totalFacturadoEp;
    let MONTO_SALDO = dataContrato.SALDO_CONTRATO - this.totalFacturadoEp + dataContrato.MONTO_REAJUSTE;
    let MONTO_SALDO_REAJUSTE = dataContrato.SALDO_CONTRATO - this.totalFacturadoEp;

    if(dataContrato.TIMO_CORRELATIVO != 9) {
      MONTO_ORIGINAL = MONTO_ORIGINAL * dataContrato.VALOR_MONEDA_CONTRATO;
      MONTO_REAJUSTE = MONTO_REAJUSTE * dataContrato.VALOR_MONEDA_CONTRATO;
      MONTO_MODIF = MONTO_MODIF * dataContrato.VALOR_MONEDA_CONTRATO;
      MONTO_ACTUALIZADO = MONTO_ACTUALIZADO * dataContrato.VALOR_MONEDA_CONTRATO;
      MONTO_EP = MONTO_EP * dataContrato.VALOR_MONEDA_CONTRATO;
      MONTO_SALDO = MONTO_SALDO * dataContrato.VALOR_MONEDA_CONTRATO;
      MONTO_SALDO_REAJUSTE = MONTO_SALDO_REAJUSTE * dataContrato.VALOR_MONEDA_CONTRATO;
    }

    const MONTO_USD = (MONTO_ORIGINAL / dataContrato.VALOR_USD);
    const REAJUSTE_USD = (MONTO_REAJUSTE / dataContrato.VALOR_USD);
    const MONTO_MODIF_USD = (MONTO_MODIF / dataContrato.VALOR_USD);
    const MONTO_ACTUALIZADO_USD = (MONTO_ACTUALIZADO / dataContrato.VALOR_USD);
    const MONTO_EP_USD = (MONTO_EP / dataContrato.VALOR_USD);
    const MONTO_SALDO_USD = (MONTO_SALDO / dataContrato.VALOR_USD);
    const MONTO_SALDO_USD_REAJUSTE = (MONTO_SALDO_REAJUSTE / dataContrato.VALOR_USD);


    MONTO_MODIF = dataContrato.MONTO_ACTUALIZADO - dataContrato.MONTO_ORIGINAL;
    MONTO_SALDO = dataContrato.SALDO_CONTRATO - this.totalFacturadoEp + dataContrato.MONTO_REAJUSTE;
    MONTO_SALDO_REAJUSTE = dataContrato.SALDO_CONTRATO - this.totalFacturadoEp;

    const PORCENTAJE_REAJUSTE = Math.round(((dataContrato.MONTO_REAJUSTE) * 100) / dataContrato.SALDO_CONTRATO);
    const PORCENTAJE_MODIF = Math.round(((MONTO_MODIF) * 100) / dataContrato.SALDO_CONTRATO);
    const PORCENTAJE_EP = Math.round(((this.totalFacturadoEp) * 100) / dataContrato.SALDO_CONTRATO);
    const PORCENTAJE_SALDO = Math.round(((MONTO_SALDO) * 100) / dataContrato.SALDO_CONTRATO);
    const PORCENTAJE_SALDO_REAJUSTE = Math.round(((MONTO_SALDO_REAJUSTE) * 100) / dataContrato.SALDO_CONTRATO);
  

    const montos = {
      MONTO_ORIGINAL: dataContrato.MONTO_ORIGINAL,
      MONTO_USD: MONTO_USD,
      MONTO_REAJUSTE: dataContrato.MONTO_REAJUSTE,
      REAJUSTE_USD: REAJUSTE_USD,
      PORCENTAJE_REAJUSTE,
      MONTO_MODIF: MONTO_MODIF,
      MONTO_MODIF_USD: MONTO_MODIF_USD,
      PORCENTAJE_MODIF,
      MONTO_ACTUALIZADO: dataContrato.MONTO_ACTUALIZADO,
      MONTO_ACTUALIZADO_USD: MONTO_ACTUALIZADO_USD,
      MONTO_EP: this.totalFacturadoEp,
      MONTO_EP_USD: MONTO_EP_USD,
      PORCENTAJE_EP,
      MONTO_SALDO: MONTO_SALDO,
      MONTO_SALDO_REAJUSTE: MONTO_SALDO_REAJUSTE,
      MONTO_SALDO_USD: MONTO_SALDO_USD,
      MONTO_SALDO_USD_REAJUSTE: MONTO_SALDO_USD_REAJUSTE,
      PORCENTAJE_SALDO,
      PORCENTAJE_SALDO_REAJUSTE
    }
    return montos;
  }

  mergeInfoSheet(data: any) {
    this.infoSheet.data = [Object.assign(this.infoSheet.data[0] || {}, data)];
    this.excelButtonEP.infoSheet = this.infoSheet;
    this.excelButtonItemizado.infoSheet = this.infoSheet;
  }

  onPasarRevision()
  {
    //alert("yes");
    //postContratoRevision
    this.contratoService.postContratoRevision({pin_contrato_correlativo: this.idContrato}).subscribe(resp =>{
      this.getContratoId(this.idContrato);
    });
  }

  onCambiarRequiereBg(event: any) {
    const postDataReqDocGarantia = {
      requiereDocGarantia: event.checked === true ? '1' : '0',
      correlativoContrato: this.idContrato
    }

    this.contratoService.updateContratoRequiereDocGarantia(postDataReqDocGarantia).subscribe(resp =>{
      this.notifyService.showSuccess('Se actualizo correctamente', 'Requiere Doc. Garantia');
      this.getContratoId(this.idContrato);
    });
  }


  onAgregarFiniquito() {
    this.submittedDocumento = true;
    if (this.formFiniquito.invalid) {
      return;
    }

    if(this.fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = 
      { fileName: this.fileName , type : this.type , files: this.archivos64}
      this.addonsService.postFileContrato(postDataFile).subscribe(resp =>{
        this.postDocumentoFiniquito(JSON.parse(resp));
        this.getDocumentos(this.idContrato);
        //this.closebutton.nativeElement.click();
      });
    }
    return;
  }

  private postDocumentoFiniquito(nombreArchivo: string) {
    const postData : {
      pin_cont_correlativo: number,
      pin_doc_correlativo: number,
      piv_nom_archivo: string,
      pin_usua_correlativo: number,
      piv_descripcion: string,
      piv_esPublico: string
    } = {
      pin_cont_correlativo: this.idContrato,
      pin_doc_correlativo: 5,
      piv_nom_archivo: nombreArchivo,
      pin_usua_correlativo: 0,
      piv_descripcion: '',
      piv_esPublico: 'N'
    };
    this.contratoService.postcreaArchivoContrato(postData).subscribe(resp=>{
      const postFiniquito: {correlativoContrato: number,
        correlativoEstado: number,
        observacion: string} = {
          correlativoContrato:  this.idContrato,
          correlativoEstado: this.formFiniquito.value.estado,
          observacion: this.formFiniquito.value.observacion
         }
         /* correlativoEstado: this.formFiniquito.value.Estado */
      this.contratoService.postFiniquitoContrato(postFiniquito).subscribe(resp => {
        this.getDocumentos(this.idContrato);
        this.getContratoId(this.idContrato);
        this.notifyService.showSuccess('Contrato finiquitado con Éxito.', 'Contrato');
        //this.notifyService.showSuccess('Documento creado con exito.', 'Crear Documento');
      },err => {
        
      });
      
    }, err =>{
      this.notifyService.showError('Error al crear documento', 'Crear Documento');
    });
    
    this.resetFormArchivo();
  }

  onAplicarModificacionContrato(modificacionCorrelativo: number) {
    this.modificacionContratoCorrelativo = modificacionCorrelativo;
  }
}
