import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { GlobalDataService, ICurrencies } from 'src/app/shared/services/global-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SolpeService } from 'src/app/shared/services/solpe.service';

@Component({
  selector: 'app-itemizado-aprobacion',
  templateUrl: './itemizado-aprobacion.component.html',
  styleUrls: ['./itemizado-aprobacion.component.css']
})
export class ItemizadoAprobacionComponent implements OnInit {

  corrContrato: number;
  contrato = { NOMBRE_CONTRATO: '', PROVEEDOR: '', TIPO_MONEDA: '', MONTO_REAJUSTE: 0, SALDO_CONTRATO: 0, TIMO_CORRELATIVO: 0 };
  itemizado: any[] = [];
  articulos: any[];
  data_articulos: any[] = [];
  corrEstadoItemizado: number;
  public contractCurrenci: ICurrencies;

  constructor(
    private activatedRoute: ActivatedRoute,
    private contratoService: ContratoService,
    private estadoPagoService: EstadoPagoService,
    private solpeService: SolpeService,
    private notifyService: NotificationService,
    private router: Router,
    public GloblaDataService: GlobalDataService,
  ) { 

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.corrContrato = params['corr_contrato'];
      this.corrEstadoItemizado = params['corr_estado_itemizado'];
    });

    this.getContrato();
    this.getItemizado();
  }

  private getContrato() {
    this.contratoService.getContratoId(this.corrContrato).subscribe(resp => {
      this.contrato = JSON.parse(resp)[0];
      this.contractCurrenci = this.GloblaDataService.CurrenciesList?.find(currencie => currencie.TimoCode == this.contrato.TIMO_CORRELATIVO);
    }, error => console.log(error));
  }
  private getItemizado() {
    this.estadoPagoService.getItemizadoDetalle(this.corrContrato).subscribe(resp => {
      let response = JSON.parse(resp);

      let idx = 0;
      let cabecera = null;
      // creo estructura del itemizado para mostrar de forma ordenada
      for(let i in response) {        
        // si es el primer registro o cambia la cabecera del itemizado: registro cabecera y primer subitem
        if(idx == 0 || response[i]['ITEM_CABECERA'] !== cabecera) {
          // cabecera
          this.itemizado[idx] = {
            Cabecera: response[i]['ITEM_CABECERA'],
            Subpos: null,
            Descripcion: null,
            Unidad: null,
            Cantidad: null,
            PrecioUnitario: null,
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: null,
            Scpq: null,
            Articulo: null
          };
          // primer subitem
          idx++;
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ'],
            Articulo: response[i]['ARTICULO']
          };
          idx++;
        // de lo contrario sigo registrando subitems hasta que cambie el item principal
        } else {
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ'],
            Articulo: response[i]['ARTICULO']
          };
          idx++;
        } 
        cabecera = response[i]['ITEM_CABECERA'];
        this.data_articulos[response[i]['ID_CORRELATIVO']] = 0;
      }
    }); 
  }

  aprobarItemizado() {
    this.estadoPagoService.postRegistraEstadoItemizado({ in_item_correlativo: 0, corr_contrato: this.corrContrato, in_domi_correlativo_esdo_itemizado: 118, in_usua_correlativo_creacion: 0, corr_solpe: 0}).
    subscribe(resp => {
      this.notifyService.showSuccess('Itemizado Aprobado', 'Itemizado');
      this.router.navigate(['/dashboard-itemizado']);
    }, error => console.log(error));

    this.contratoService.validaEtapaCorreoContrato({in_cont_correlativo: this.corrContrato, in_domi_correlativo_esdo_itemizado: 118}).subscribe(resp => {});
  }
  rechazarItemizado() {
    this.estadoPagoService.postRegistraEstadoItemizado({ in_item_correlativo: 0, corr_contrato: this.corrContrato, in_domi_correlativo_esdo_itemizado: 119, in_usua_correlativo_creacion: 0, corr_solpe: 0 }).
    subscribe(resp => {
      this.notifyService.showWarning('Itemizado Rechazado', 'Itemizado');
      this.router.navigate(['/dashboard-itemizado']);
    }, error => console.log(error));

    this.contratoService.validaEtapaCorreoContrato({in_cont_correlativo: this.corrContrato, in_domi_correlativo_esdo_itemizado: 119}).subscribe(resp => {});
  }

}
