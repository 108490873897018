import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

export interface Log {
  userId?: number,
  userName: string,
  action: string,
  ip: string,
  datetime: string,
  data?: any
}

interface Link {
  url: string;
  label: number;
  page: number;
  activate: boolean;
}

export interface Pagination {
  first_page_url: string;
  from: number;
  items_per_page: number;
  last_page: number;
  links: Link[];
  next_page_url: string;
  to: number;
  total: number;
}

export interface LogsResponse {
  logs: Log[];
  pagination: Pagination;
}

@Injectable({ providedIn: 'root' })
export class AuditoriaService {
  constructor(private httpService: HttpService) {}
  logs() {
    return this.httpService.get({
      url: 'audit/logs'
    }) as Observable<LogsResponse>;
  }
}