import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddonsService } from 'src/app/shared/services/addons.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as moment from 'src/assets/plugins/moment/moment';
import { BoletasService } from '../../shared/services/boletas.service';
import { ContratoService } from '../../shared/services/contrato.service';

@Component({
  selector: 'app-create-boleta',
  templateUrl: './create-boleta.component.html',
  styleUrls: ['./create-boleta.component.css']
})
export class CreateBoletaComponent implements OnInit {

  bancos: any[] = [];
  monedas: any[] = [];
  tipos: any[] = [];
  contratos: any[] = [];

  boletaForm : FormGroup;
  submitted = false;

  archivos64 :any;
  fileName :string;
  type :string;
  idRol: number;

  constructor(private boletasService: BoletasService,
    private notifyService: NotificationService,
    public fb: FormBuilder,
    private addonsService : AddonsService,
    private contratosService: ContratoService) {
      this.idRol = JSON.parse(localStorage.getItem('userData')).rolId;
  }

  ngOnInit(): void {
    this.resetForm();
    this.getBancos();
    this.getMonedas();
    this.getTipos();
    this.getContratos();

    this.boletaForm = this.fb.group({
      contrato: ['', [Validators.required]],
      banco: ['', [Validators.required]],
      nro_boleta: ['', [Validators.required]],
      monto: ['', [Validators.required]],
      moneda: ['', [Validators.required]],
      fecha_emision: ['', [Validators.required]],
      fecha_ingreso: ['', [Validators.required]],
      fecha_vencimiento: ['', [Validators.required]],
      fecha_prorroga: [''],
      fecha_devolucion: [''],
      descripcion: ['', [Validators.required]],
      observacion: ['', [Validators.required]],
      tipo: ['', [Validators.required]],
    });
  }

  private resetForm(){
    this.fileName = null;
    this.boletaForm = new FormGroup({
      'contrato' : new FormControl(0),
      'banco' : new FormControl(0),
      'nro_boleta' : new FormControl(null),
      'monto' : new FormControl(null),
      'moneda' : new FormControl(0),
      'fecha_emision' : new FormControl(null),
      'fecha_ingreso' : new FormControl(null),
      'fecha_vencimiento' : new FormControl(null),
      'fecha_prorroga' : new FormControl(null),
      'fecha_devolucion' : new FormControl(null),
      'descripcion' : new FormControl(null),
      'observacion' : new FormControl(null),
      'tipo' : new FormControl(0)
    });
  }

  private getBancos() {
    this.boletasService.getTipoDominios('TIBA').subscribe(resp => {
      this.bancos = JSON.parse(resp);
    });
  }

  private getMonedas() {
    this.boletasService.getTipoDominios('TIMO').subscribe(resp => {
      this.monedas = JSON.parse(resp);
    });
  }

  private getTipos() {
    this.boletasService.getTipoDominios('TIADJ').subscribe(resp => {
      this.tipos = JSON.parse(resp);
    });
  }

  private getContratos() {
    this.contratosService.getContratos(1,'N',this.idRol).subscribe(resp => {
      this.contratos = JSON.parse(resp);
    });
  }

  detectFiles(event: any){
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.archivos64 = bs64
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
        }
      };
    }
  }

  get f() { return this.boletaForm.controls; }

  onCrear() {
    this.submitted = true;
    if (this.boletaForm.invalid) {
      return;
    }

    if(this.fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.fileName , type : this.type , files: this.archivos64}
      this.addonsService.postFileBoleta(postDataFile).subscribe(resp =>{
        if (JSON.parse(resp)){
          const nombreArchivo = JSON.parse(resp);
          this.postBoleta(nombreArchivo);
        }
      });
    }
    else {
      this.postBoleta(null);
    }
  }

  private postBoleta(nombreArchivo : string)   {
        /* let fecha_prorroga = (this.boletaForm.value.fecha_prorroga) ? moment(this.boletaForm.value.fecha_prorroga).format('DD-MM-YYYY') : null;
        let fecha_devolucion = (this.boletaForm.value.fecha_devolucion) ? moment(this.boletaForm.value.fecha_devolucion).format('DD-MM-YYYY') : null; */
        let fecha_prorroga = (this.boletaForm.value.fecha_prorroga) ? moment(this.boletaForm.value.fecha_prorroga).format('YYYY-MM-DD') : null;
        let fecha_devolucion = (this.boletaForm.value.fecha_devolucion) ? moment(this.boletaForm.value.fecha_devolucion).format('DD-MM-YYYY') : null;
        const postData : {
            iv_contrato : number,
            iv_banco : number,
            iv_nro_boleta : string,
            iv_moneda : number,
            iv_monto : number,
            iv_fecha_emision : string,
            iv_fecha_ingreso : string,
            iv_fecha_vencimiento : string,
            iv_fecha_prorroga? : string,
            iv_fecha_devolucion? : string,
            iv_descripcion : string,
            iv_observacion : string,
            iv_adjunto? : string,
            iv_tipo :number,
            in_usua_correlativo_creacion: number,
            in_tipo_documento: number
          } = {
          iv_contrato: this.boletaForm.value.contrato,
          iv_banco: this.boletaForm.value.banco,
          iv_nro_boleta: this.boletaForm.value.nro_boleta,
          iv_moneda: this.boletaForm.value.moneda,
          iv_monto: this.boletaForm.value.monto,
          iv_fecha_emision: moment(this.boletaForm.value.fecha_emision).format('YYYY-MM-DD'),
          iv_fecha_ingreso: moment(this.boletaForm.value.fecha_ingreso).format('YYYY-MM-DD'),
          iv_fecha_vencimiento: moment(this.boletaForm.value.fecha_vencimiento).format('YYYY-MM-DD'),
          iv_fecha_prorroga: fecha_prorroga,
          iv_fecha_devolucion: fecha_devolucion,
          iv_descripcion: this.boletaForm.value.descripcion,
          iv_observacion: this.boletaForm.value.observacion,
          iv_adjunto: nombreArchivo,
          iv_tipo: this.boletaForm.value.tipo,
          in_usua_correlativo_creacion: 0,
          in_tipo_documento: this.boletaForm.value.tipoDocumento
        };
        this.boletasService.postBoleta(postData).subscribe(resp=>{
          this.resetForm();
          this.notifyService.showSuccess('Boleta creada con exito.', 'Crear Boleta de Garantía');
        }, err =>{
          this.notifyService.showError('Error al crear boleta', 'Crear Boleta de Garantía');
          console.log(err);
        });
  }
}
