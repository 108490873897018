import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilterEp'
})

export class TableFilterEpPipe implements PipeTransform {
  transform(list: any[], value: string) {
    return value ? list.filter(item => item.EP_HES.toString().toLowerCase().includes(value.toString().toLowerCase()) || item.CONTRATO.toString().toLowerCase().includes(value.toString().toLowerCase()) || item.EP_DESCRIPCION.toString().toLowerCase().includes(value.toString().toLowerCase()) || item.PROVEEDOR.toString().toLowerCase().includes(value.toString().toLowerCase()) || item.RUT_PROVEEDOR_DIGITO.toString().toLowerCase().includes(value.toString().toLowerCase())) : list;
  }
}
