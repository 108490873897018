import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { AddonsService } from 'src/app/shared/services/addons.service';
import { BoletasService } from 'src/app/shared/services/boletas.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { FileService } from 'src/app/shared/services/file.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as moment from 'src/assets/plugins/moment/moment';
import { DataService } from '../../../shared/services/data.service';


@Component({
  selector: 'app-modificaciones-contrato',
  templateUrl: './modificaciones-contrato.component.html',
  styleUrls: ['./modificaciones-contrato.component.css']
})
export class ModificacionesContratoComponent implements OnInit {

  idContrato: number;
  modificaciones: any[] = [];
  formAgregarModificacion : FormGroup;
  formEditarModificacion: FormGroup;
  formAgregarArchivo : FormGroup;
  monedas: any[] = [];
  tipoModificacion: any[] = []
  dataContrato: any;
  archivos: any;

  selection = new SelectionModel<any>(false, []);
  sortedData: any;
  modificacionesInit: any;

  /** variables para subida de archivos */
  archivos64 :any;
  fileName :string;
  type :string;
  documento: string = null;
  modificacion_archivos64 :any;
  modificacion_fileName :string;
  modificacion_type :string;
  solpe_archivos64 :any;
  solpe_fileName :string;
  solpe_type :string;
  general_archivos64 :any;
  general_fileName :string;
  general_type :string;
  submitted = false;
  submittedDocumento = false;

  dataSource:any;
  dataSourceDocumentos: any;
  dataSourceHeader: string[] = ['Descripcion', 'Mod', 'Nro', 'Monto', 'Moneda', 'Actualizado', 'Ingreso', 'Inicio', 'Termino', 'Administrador' , 'Alcance', 'Estado', 'Acciones'];
  dataSourceHeaderDocumentos: string[] = ['Grupo', 'Archivo', 'Acciones'];
  @ViewChild('paginatorModifGrl', {static: false}) paginatorModifGrl: MatPaginator;
  @ViewChild('closeModalAgregar') closeModalAgregar: ElementRef;
  @ViewChild('closeModalEditar') closeModalEditar: ElementRef;
  @ViewChild('closeModalAgregarArchivo') closeModalAgregarArchivo: ElementRef;

  constructor(
    private dataService : DataService,
    private contratoService: ContratoService,
    private boletasService: BoletasService,
    private addonsService : AddonsService,
    private notifyService: NotificationService,
    private fileService: FileService,
    public fb: FormBuilder
    ) {
    this.idContrato = this.dataService.idContrato;
  }

  ngOnInit(): void {
    this.getContrato();
    this.getModificaciones();
    this.getMonedas();
    this.getTipoModificacion()
    this.getDocumentos();
    this.getDocumentosContrato();
    this.resetForm();
    this.resetFormEditar();
    this.resetFormArchivo();
  }
  private getContrato(){
    this.dataContrato = {};
    this.contratoService.getContratoId(this.idContrato).subscribe(resp=>{
      if (JSON.parse(resp)[0]){
        this.dataContrato = JSON.parse(resp)[0];
      }
    });
  }
  private getDocumentos(){
    this.contratoService.getContratoDocumentos(this.idContrato).subscribe(resp=>{
      if (JSON.parse(resp)[0]){
        this.archivos = JSON.parse(resp);
      }
    });
  }
  private getDocumentosContrato(){
    this.contratoService.getDocumentosContrato(this.idContrato).subscribe(resp=>{
        this.dataSourceDocumentos = JSON.parse(resp);
    });
  }
  private resetForm() {
    this.formAgregarModificacion = new FormGroup({
      nro_modificacion : new FormControl(null, Validators.required),
      descripcion : new FormControl(null, Validators.required),
      moneda : new FormControl(null, Validators.required),
      nro_solpe : new FormControl(null, Validators.required),
      tipo : new FormControl(1, Validators.required),
      monto_modificacion : new FormControl({value: null, disabled: true}),
      monto_actualizado : new FormControl({value: null, disabled: true}),
      fecha_modificacion : new FormControl(null, Validators.required),
      fecha_inicio : new FormControl({value: null, disabled: true}),
      fecha_termino : new FormControl({value: null, disabled: true}),
      administrador : new FormControl(null, Validators.required),
      alcance : new FormControl({value: null, disabled: true}),
      adjunto_modificacion : new FormControl(null),
      adjunto_solpe : new FormControl(null),
      adjunto_general : new FormControl(null)
    });

    this.formAgregarModificacion.addControl('chk_Monto', new FormControl(false))
    this.formAgregarModificacion.addControl('chk_Fecha', new FormControl(false))
    this.formAgregarModificacion.addControl('chk_Alcance', new FormControl(false))

    this.submitted = false;
    this.modificacion_fileName = null;
    this.solpe_fileName = null;
    this.general_fileName = null;
  }
  private resetFormEditar() {
    this.formEditarModificacion = new FormGroup({
      nro_modificacion_edit : new FormControl(null, Validators.required),
      descripcion_edit : new FormControl(null, Validators.required),
      moneda_edit : new FormControl(null, Validators.required),
      chk_monto_edit : new FormControl(false),
      chk_fecha_edit : new FormControl(false),
      chk_alcance_edit : new FormControl(false),
      nro_solpe_edit : new FormControl(null, Validators.required),
      tipo_edit : new FormControl(1, Validators.required),
      monto_modificacion_edit : new FormControl({value: null, disabled: true}),
      monto_actualizado_edit : new FormControl({value: null, disabled: true}),
      fecha_modificacion_edit : new FormControl(null, Validators.required),
      fecha_inicio_edit : new FormControl({value: null, disabled: true}),
      fecha_termino_edit : new FormControl({value: null, disabled: true}),
      administrador_edit : new FormControl(null, Validators.required),
      alcance_edit : new FormControl({value: null, disabled: true}),
      adjunto_modificacion_edit : new FormControl(null),
      adjunto_solpe_edit : new FormControl(null),
      adjunto_general_edit : new FormControl(null),
      corr_modificacion : new FormControl(0),
    });
    this.submitted = false;
    this.modificacion_fileName = null;
    this.solpe_fileName = null;
    this.general_fileName = null;
  }
  private resetFormArchivo() {
    this.formAgregarArchivo = new FormGroup({
      grupo : new FormControl(null, Validators.required),
      adjunto : new FormControl(null)
    });
    this.submittedDocumento = false;
    this.fileName = null;
  }
  private getMonedas() {
    this.boletasService.getTipoDominios('TIMO').subscribe(resp => {
      this.monedas = JSON.parse(resp);
    });
  }
  private getTipoModificacion() {
    this.boletasService.getTipoDominios('TIPOMODIFICA').subscribe(resp => {
      this.tipoModificacion = JSON.parse(resp);
    });
  }
  applyFilterModificacionesGrl(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }
  sortData(sort: Sort) {
    const data = this.modificacionesInit.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorModifGrl, this.dataSource);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Descripcion': return compare(a.DESCRIPCION, b.DESCRIPCION, isAsc);
        case 'Mod': return compare(a.TIPO, b.TIPO, isAsc);
        case 'Nro': return compare(a.CORRELATIVO, b.CORRELATIVO, isAsc);
        case 'Monto': return compare(a.MONTO_MODIFICACION, b.MONTO_MODIFICACION, isAsc);
        case 'Moneda': return compare(a.TIPO_MONEDA, b.TIPO_MONEDA, isAsc);
        case 'Actualizado': return compare(a.MONTO_ACTUALIZADO, b.MONTO_ACTUALIZADO, isAsc);
        case 'Ingreso': return compare(a.FECHA_MODIFICACION, b.FECHA_MODIFICACION, isAsc);
        case 'Inicio': return compare(a.FECHA_INICIO, b.FECHA_INICIO, isAsc);
        case 'Termino': return compare(a.FECHA_TERMINO, b.FECHA_TERMINO, isAsc);
        case 'Administrador': return compare(a.TIPO, b.TIPO, isAsc);
        case 'Alcance': return compare(a.ALCANCE, b.ALCANCE, isAsc);
        //case 'Estado': return compare(a.FECHA_PRORROGA, b.FECHA_PRORROGA, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.dataSource =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorModifGrl, this.dataSource);
    }
  }
  private getModificaciones() {
    this.contratoService.getModificacionesContrato('CONTRATO',this.idContrato).subscribe(resp => {
      this.modificacionesInit = JSON.parse(resp);
      this.dataSource = new MatTableDataSource(JSON.parse(resp));
      this.createPaginator(this.paginatorModifGrl, this.dataSource);
    })
  }
  onChangeMonto(event: any) {
    if(event.currentTarget.checked) {
      this.formAgregarModificacion.controls.monto_modificacion.enable();
      this.formAgregarModificacion.controls.monto_actualizado.enable();
      //this.formAgregarModificacion.controls.monto_modificacion.setValidators([Validators.required]);
      //this.formAgregarModificacion.updateValueAndValidity();
    } else {
      this.formAgregarModificacion.controls.monto_modificacion.disable();
      this.formAgregarModificacion.controls.monto_actualizado.disable();
    }
  }
  onChangeFecha(event: any) {
    if(event.currentTarget.checked) {
      this.formAgregarModificacion.controls.fecha_inicio.enable();
      this.formAgregarModificacion.controls.fecha_termino.enable();
    } else {
      this.formAgregarModificacion.controls.fecha_inicio.disable();
      this.formAgregarModificacion.controls.fecha_termino.disable();
    }
  }
  onChangeAlcance(event: any) {
    if(event.currentTarget.checked) {
      this.formAgregarModificacion.controls.alcance.enable();
    } else {
      this.formAgregarModificacion.controls.alcance.disable();
    }
  }
  onChangeTipo(event:any) {
    if(event.currentTarget.value == 83) {
      if(event.currentTarget.checked) {
        this.formAgregarModificacion.controls.monto_modificacion.enable();
        this.formAgregarModificacion.controls.monto_actualizado.enable();
        //this.formAgregarModificacion.controls.monto_modificacion.setValidators([Validators.required]);
        //this.formAgregarModificacion.updateValueAndValidity();
      } else {
        this.formAgregarModificacion.controls.monto_modificacion.disable();
        this.formAgregarModificacion.controls.monto_actualizado.disable();
      }
    }

    if(event.currentTarget.value == 84) {
      if(event.currentTarget.checked) {
        this.formAgregarModificacion.controls.fecha_inicio.enable();
        this.formAgregarModificacion.controls.fecha_termino.enable();
      } else {
        this.formAgregarModificacion.controls.fecha_inicio.disable();
        this.formAgregarModificacion.controls.fecha_termino.disable();
      }
    }

    if(event.currentTarget.value == 85) {
      if(event.currentTarget.checked) {
        this.formAgregarModificacion.controls.alcance.enable();
      } else {
        this.formAgregarModificacion.controls.alcance.disable();
      }
    }
  }
  detectFileModificacion(event: any){
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.modificacion_archivos64 = bs64
              this.modificacion_fileName = event.target.files[0].name;
              this.modificacion_type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
        }
      };
    }
  }
  detectFileSolpe(event: any){
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.solpe_archivos64 = bs64
              this.solpe_fileName = event.target.files[0].name;
              this.solpe_type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
        }
      };
    }
  }
  detectFileGeneral(event: any){
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.general_archivos64 = bs64
              this.general_fileName = event.target.files[0].name;
              this.general_type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
        }
      };
    }
  }
  onCrear() {
    this.submitted = true;
    if (this.formAgregarModificacion.invalid) {
      return;
    }

    let nombreArchivoModificacion, nombreArchivoSolpe, nommbreArchivoGeneral : string = null;
    if(this.modificacion_fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.modificacion_fileName , type : this.modificacion_type , files: this.modificacion_archivos64}
      this.addonsService.postFileModificacion(postDataFile).subscribe(resp =>{
        nombreArchivoModificacion = JSON.parse(resp);
      });
    }
    if(this.solpe_fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.solpe_fileName , type : this.solpe_type , files: this.solpe_archivos64}
      this.addonsService.postFileModificacion(postDataFile).subscribe(resp =>{
        nombreArchivoSolpe = JSON.parse(resp);
      });
    }
    if(this.general_fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.general_fileName , type : this.general_type , files: this.general_archivos64}
      this.addonsService.postFileModificacion(postDataFile).subscribe(resp =>{
        nommbreArchivoGeneral = JSON.parse(resp);
      });
    }

    this.postModificacion(nombreArchivoModificacion, nombreArchivoSolpe, nommbreArchivoGeneral);
  }
  private postModificacion(nombreArchivoModificacion: string, nombreArchivoSolpe: string, nombreArchivoGeneral: string) {
      let fecha_modificacion = (this.formAgregarModificacion.value.fecha_modificacion) ? moment(this.formAgregarModificacion.value.fecha_modificacion).format('DD-MM-YYYY') : null;
      let fecha_inicio = (this.formAgregarModificacion.value.fecha_inicio) ? moment(this.formAgregarModificacion.value.fecha_inicio).format('DD-MM-YYYY') : null;
      let fecha_termino = (this.formAgregarModificacion.value.fecha_termino) ? moment(this.formAgregarModificacion.value.fecha_termino).format('DD-MM-YYYY') : null;

      let monto, fecha, alcance: string = ''
      if(this.formAgregarModificacion.value.chk_Monto) monto = 'Monto'
      if(this.formAgregarModificacion.value.chk_Fecha) fecha = 'Fecha'
      if(this.formAgregarModificacion.value.chk_Alcance) alcance = 'Alcance'
      let tipo_modificaciones = monto + ',' + fecha + ',' + alcance

      const postData : {
            in_cont_correlativo: number,
            in_nro_modificacion: number,
            iv_descripcion: string,
            in_domi_timo_correlativo: number,
            in_solpe_nro: number,
            in_domi_timod_correlativo: number,
            in_monto_modificacion: number,
            in_monto_actualizado: number,
            iv_fecha_modificacion: string,
            iv_fecha_inicio: string,
            iv_fecha_termino: string,
            in_admin_correlativo: number,
            iv_alcance: string,
            iv_arch_modificacion: string,
            iv_arch_solpe: string,
            iv_arch_general: string,
            iv_domi_correlativo_tipomodifica: string
          } = {
            in_cont_correlativo: this.idContrato,
            in_nro_modificacion: this.formAgregarModificacion.value.nro_modificacion,
            iv_descripcion: this.formAgregarModificacion.value.descripcion,
            in_domi_timo_correlativo: this.formAgregarModificacion.value.moneda,
            in_solpe_nro: this.formAgregarModificacion.value.nro_solpe,
            in_domi_timod_correlativo: this.formAgregarModificacion.value.tipo,
            in_monto_modificacion: this.formAgregarModificacion.value.monto_modificacion,
            in_monto_actualizado: this.formAgregarModificacion.value.monto_actualizado,
            iv_fecha_modificacion: fecha_modificacion,
            iv_fecha_inicio: fecha_inicio,
            iv_fecha_termino: fecha_termino,
            in_admin_correlativo: this.formAgregarModificacion.value.administrador,
            iv_alcance: this.formAgregarModificacion.value.alcance,
            iv_arch_modificacion: nombreArchivoModificacion,
            iv_arch_solpe: nombreArchivoSolpe,
            iv_arch_general: nombreArchivoGeneral,
            iv_domi_correlativo_tipomodifica: tipo_modificaciones
        };
        this.contratoService.crearModificacionContrato(postData).subscribe(resp=>{
          this.notifyService.showSuccess('Modificación creada con exito.', 'Crear Modificación');
        }, err =>{
          this.notifyService.showError('Error al crear modificación', 'Crear Modificación');
          console.log(err);
        });
        this.getModificaciones();
        this.closeModalAgregar.nativeElement.click();
  }
  onClose() {
    this.resetForm();
  }
  detectFile(event: any){
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.archivos64 = bs64
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
        }
      };
    }
  }
  onAgregarArchivo() {
    this.submittedDocumento = true;
    if (this.formAgregarArchivo.invalid) {
      return;
    }

    if(this.fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.fileName , type : this.type , files: this.archivos64}
      this.addonsService.postFileArchivoModificacion(postDataFile).subscribe(resp =>{
        this.postDocumento(JSON.parse(resp));
      });
    }
    return;
  }
  private postDocumento(nombreArchivo: string) {
    const postData : {
      in_cont_correlativo: number,
      in_doc_correlativo: number,
      iv_nom_archivo: string,
      in_usua_correlativo: number
    } = {
      in_cont_correlativo: this.idContrato,
      in_doc_correlativo: this.formAgregarArchivo.value.grupo,
      iv_nom_archivo: nombreArchivo,
      in_usua_correlativo: 0
    };

    this.contratoService.crearArchivoContrato(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Documento creado con exito.', 'Crear Documento');
    }, err =>{
      this.notifyService.showError('Error al crear documento', 'Crear Documento');
      console.log(err);
    });
    this.getDocumentosContrato();
    this.closeModalAgregarArchivo.nativeElement.click();
  }
  onCloseArchivo() {
    this.resetFormArchivo();
  }
  onDescargar(fileName: string) {
    this.fileService.downloadDocumentoContrato(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }
  onDelete(idModificacion: number) {
    const postData : {
      in_moco_correlativo: number,
      iv_vigente: string
    } = {
      in_moco_correlativo: idModificacion,
      iv_vigente: 'N'
    };

    this.contratoService.deleteModificacionContrato(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Documento eliminado con exito.', 'Eliminar Documento');
    }, err =>{
      this.notifyService.showError('Error al eliminar documento', 'Eliminar Documento');
      console.log(err);
    });
    this.getModificaciones();
  }
  onChangeMontoEdit(event: any) {
    if(event.currentTarget.checked) {
      this.formEditarModificacion.controls.monto_modificacion_edit.enable();
      this.formEditarModificacion.controls.monto_actualizado_edit.enable();
      //this.formAgregarModificacion.controls.monto_modificacion.setValidators([Validators.required]);
      //this.formAgregarModificacion.updateValueAndValidity();
    } else {
      this.formEditarModificacion.controls.monto_modificacion_edit.disable();
      this.formEditarModificacion.controls.monto_actualizado_edit.disable();
    }
  }
  onChangeFechaEdit(event: any) {
    if(event.currentTarget.checked) {
      this.formEditarModificacion.controls.fecha_inicio_edit.enable();
      this.formEditarModificacion.controls.fecha_termino_edit.enable();
    } else {
      this.formEditarModificacion.controls.fecha_inicio_edit.disable();
      this.formEditarModificacion.controls.fecha_termino_edit.disable();
    }
  }
  onChangeAlcanceEdit(event: any) {
    if(event.currentTarget.checked) {
      this.formEditarModificacion.controls.alcance_edit.enable();
    } else {
      this.formEditarModificacion.controls.alcance_edit.disable();
    }
  }
  editarModificacion(idModificacion: number) {
    this.contratoService.getModificacionesContrato('MODIFICACION',idModificacion).subscribe(resp => {
      this.formEditarModificacion.controls.nro_modificacion_edit.setValue(JSON.parse(resp)[0].NRO_MODIFICACION);
      this.formEditarModificacion.controls.descripcion_edit.setValue(JSON.parse(resp)[0].DESCRIPCION);
      this.formEditarModificacion.controls.moneda_edit.setValue(JSON.parse(resp)[0].TIPO_MONEDA_CORR);
      this.formEditarModificacion.controls.nro_solpe_edit.setValue(JSON.parse(resp)[0].SOLPE_NRO);
      this.formEditarModificacion.controls.tipo_edit.setValue(JSON.parse(resp)[0].TIPO_CORRELATIVO);
      this.formEditarModificacion.controls.fecha_modificacion_edit.setValue(JSON.parse(resp)[0].FECHA_MODIFICACION);
      this.formEditarModificacion.controls.administrador_edit.setValue(JSON.parse(resp)[0].admin_correlativo);

      if(JSON.parse(resp)[0].ALCANCE) {
        this.formEditarModificacion.controls.chk_alcance_edit.setValue(1);
        this.formEditarModificacion.controls.alcance_edit.enable();
        this.formEditarModificacion.controls.alcance_edit.setValue(JSON.parse(resp)[0].ALCANCE);
      }
      if(JSON.parse(resp)[0].MONTO_ACTUALIZADO || JSON.parse(resp)[0].MONTO_MODIFICACION) {
        this.formEditarModificacion.controls.chk_monto_edit.setValue(1);
        this.formEditarModificacion.controls.monto_actualizado_edit.enable();
        this.formEditarModificacion.controls.monto_modificacion_edit.enable();
        this.formEditarModificacion.controls.monto_actualizado_edit.setValue(JSON.parse(resp)[0].MONTO_ACTUALIZADO);
        this.formEditarModificacion.controls.monto_modificacion_edit.setValue(JSON.parse(resp)[0].MONTO_MODIFICACION);
      }
      if(JSON.parse(resp)[0].FECHA_INICIO || JSON.parse(resp)[0].FECHA_TERMINO) {
        this.formEditarModificacion.controls.chk_fecha_edit.setValue(1);
        this.formEditarModificacion.controls.fecha_inicio_edit.enable();
        this.formEditarModificacion.controls.fecha_termino_edit.enable();
        this.formEditarModificacion.controls.fecha_inicio_edit.setValue(JSON.parse(resp)[0].FECHA_INICIO);
        this.formEditarModificacion.controls.fecha_termino_edit.setValue(JSON.parse(resp)[0].FECHA_TERMINO);
      }

      this.formEditarModificacion.controls.corr_modificacion.setValue(idModificacion);
    });
  }
  onEditar() {
    this.submitted = true;
    if (this.formEditarModificacion.invalid) {
      return;
    }

    let nombreArchivoModificacion, nombreArchivoSolpe, nommbreArchivoGeneral : string = null;
    if(this.modificacion_fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.modificacion_fileName , type : this.modificacion_type , files: this.modificacion_archivos64}
      this.addonsService.postFileModificacion(postDataFile).subscribe(resp =>{
        nombreArchivoModificacion = JSON.parse(resp);
      });
    }
    if(this.solpe_fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.solpe_fileName , type : this.solpe_type , files: this.solpe_archivos64}
      this.addonsService.postFileModificacion(postDataFile).subscribe(resp =>{
        nombreArchivoSolpe = JSON.parse(resp);
      });
    }
    if(this.general_fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.general_fileName , type : this.general_type , files: this.general_archivos64}
      this.addonsService.postFileModificacion(postDataFile).subscribe(resp =>{
        nommbreArchivoGeneral = JSON.parse(resp);
      });
    }

    this.postEditarModificacion(nombreArchivoModificacion, nombreArchivoSolpe, nommbreArchivoGeneral);
  }
  private postEditarModificacion(nombreArchivoModificacion: string, nombreArchivoSolpe: string, nombreArchivoGeneral: string) {
    let fecha_modificacion = (this.formEditarModificacion.value.fecha_modificacion_edit) ? moment(this.formEditarModificacion.value.fecha_modificacion_edit).format('DD-MM-YYYY') : null;
    let fecha_inicio = (this.formEditarModificacion.value.fecha_inicio_edit) ? moment(this.formEditarModificacion.value.fecha_inicio_edit).format('DD-MM-YYYY') : null;
    let fecha_termino = (this.formEditarModificacion.value.fecha_termino_edit) ? moment(this.formEditarModificacion.value.fecha_termino_edit).format('DD-MM-YYYY') : null;
        const postData : {
            in_moco_correlativo: number,
            in_cont_correlativo: number,
            in_nro_modificacion: number,
            iv_descripcion: string,
            in_domi_timo_correlativo: number,
            in_solpe_nro: number,
            in_domi_timod_correlativo: number,
            in_monto_modificacion: number,
            in_monto_actualizado: number,
            iv_fecha_modificacion: string,
            iv_fecha_inicio: string,
            iv_fecha_termino: string,
            in_admin_correlativo: number,
            iv_alcance: string,
            iv_arch_modificacion: string,
            iv_arch_solpe: string,
            iv_arch_general: string
          } = {
            in_moco_correlativo: this.formEditarModificacion.value.corr_modificacion,
            in_cont_correlativo: this.idContrato,
            in_nro_modificacion: this.formEditarModificacion.value.nro_modificacion_edit,
            iv_descripcion: this.formEditarModificacion.value.descripcion_edit,
            in_domi_timo_correlativo: this.formEditarModificacion.value.moneda_edit,
            in_solpe_nro: this.formEditarModificacion.value.nro_solpe_edit,
            in_domi_timod_correlativo: this.formEditarModificacion.value.tipo_edit,
            in_monto_modificacion: this.formEditarModificacion.value.monto_modificacion_edit,
            in_monto_actualizado: this.formEditarModificacion.value.monto_actualizado_edit,
            iv_fecha_modificacion: fecha_modificacion,
            iv_fecha_inicio: fecha_inicio,
            iv_fecha_termino: fecha_termino,
            in_admin_correlativo: this.formEditarModificacion.value.administrador_edit,
            iv_alcance: this.formEditarModificacion.value.alcance_edit,
            iv_arch_modificacion: nombreArchivoModificacion,
            iv_arch_solpe: nombreArchivoSolpe,
            iv_arch_general: nombreArchivoGeneral
        };
        this.contratoService.updateModificacionContrato(postData).subscribe(resp=>{
          this.notifyService.showSuccess('Modificación editada con exito.', 'Editar Modificación');
        }, err =>{
          this.notifyService.showError('Error al editar modificación', 'Editar Modificación');
          console.log(err);
        });
        this.getModificaciones();
        this.closeModalEditar.nativeElement.click();
  }
  onCloseEditar() {
    this.resetFormEditar();
  }
  get f() { return this.formAgregarModificacion.controls; }
  get fe() { return this.formEditarModificacion.controls; }
  get fd() { return this.formAgregarArchivo.controls; }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

