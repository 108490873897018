<div id="content" class="content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Mantenedor de Items</h4>
        </div>
        <div class="panel-body">
            <h4>Grupos e Itemes Checklist</h4>
            <p class="text-right"><a class="btn btn-primary" routerLink="/checklist">Volver</a></p>

            <br><br>

            <div class="row">
                <div class="col-md-4">
                    <form [formGroup]="formGrupo" (ngSubmit)="onAgregarGrupo()" novalidate>
                        <div class="row">
                            <div class="col-md-8">
                                <input name="grupo" formControlName="grupo" class="form-control" placeholder="Agregar Grupo" [ngClass]="{ 'is-invalid': submitted && f.grupo.errors}" />
                            </div>
                            <div class="col-md-4">
                                <button class="btn btn-primary btn-block" type="submit" *ngIf="!editar">Agregar</button>
                                <button class="btn btn-primary btn-block" type="submit" *ngIf="editar">Editar</button>
                            </div>
                        </div>
                    </form>
                    <br>
                    <table class="table">
                        <thead class="thead-dark">
                            <th>Grupos</th>
                            <th class="text-right">Acciones</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of grupos">
                                <td class="text-center" [ngStyle]="{'background-color': item.GRUPOCHK_NOMBRE == nombreGrupo ? '#f5f5f5' : 'white'}">
                                    <a (click)="desplegarItems(item.GRUPOCHK_CORRELATIVO, item.GRUPOCHK_NOMBRE)" style="cursor: pointer;">
                                        {{ item.GRUPOCHK_NOMBRE }}
                                    </a>
                                </td>
                                <td class="text-right" [ngStyle]="{'background-color': item.GRUPOCHK_NOMBRE == nombreGrupo ? '#f5f5f5' : 'white'}">
                                    <a (click)="onEditarGrupo(item.GRUPOCHK_CORRELATIVO, item.GRUPOCHK_NOMBRE)" style="cursor: pointer; color:#F59D1A;"><i class="fa fa-edit"></i></a>&nbsp;&nbsp;&nbsp;
                                    <a (click)="onEliminarGrupo(item.GRUPOCHK_CORRELATIVO)" style="cursor: pointer; color:#FF5B57;"><i class="fa fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-8">
                    <form [formGroup]="formItem" (ngSubmit)="onAgregarItem()" novalidate *ngIf="corrGrupo != 0">
                        <div class="row">
                            <div class="col-md-7">
                                <input name="item" formControlName="item" class="form-control" placeholder="Agregar Item" [ngClass]="{ 'is-invalid': submittedItem && fi.item.errors}" />
                            </div>
                            <div class="col-md-2">
                                <input type="checkbox" class="mt-2" formControlName="obligatorio" value="1" />&nbsp;
                                <label for="obligatorio">Obligatorio</label>
                            </div>
                            <div class="col-md-3">
                                <button class="btn btn-primary btn-block" type="submit" *ngIf="!editarItem">Agregar</button>
                                <button class="btn btn-primary btn-block" type="submit" *ngIf="editarItem">Editar</button>
                            </div>
                        </div>
                    </form>
                    <br>
                    <table class="table" *ngIf="items.length > 0">
                        <thead class="thead-dark">
                            <th>Itemes</th>
                            <th class="text-right">Acciones</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let i of items">
                                <td [ngClass]="{'text-success' : i.ITEMCHKOBLIGATORIO_CORRELATIVO == 'S'}">{{ i.ITEMCHKNOMBRE_CORRELATIVO }}</td>
                                <td class="text-right">
                                    <a (click)="onEditarItem(i.ITEMCHK_CORRELATIVO, i.ITEMCHKNOMBRE_CORRELATIVO, i.ITEMCHKOBLIGATORIO_CORRELATIVO)" style="cursor: pointer; color:#F59D1A;"><i class="fa fa-edit"></i></a>&nbsp;&nbsp;&nbsp;
                                    <a (click)="onEliminarItem(i.ITEMCHK_CORRELATIVO)" style="cursor: pointer; color:#FF5B57;"><i class="fa fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>