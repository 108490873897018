<a class="d-none" href="#modal-articulo" data-toggle="modal" #openModalGuardarArticulo></a>
<a class="d-none" href="#modal-zona" data-toggle="modal" #openModalGuardarZona></a>
<a class="d-none" href="#modal-blanco" data-toggle="modal" #openModalGuardarBlanco></a>
<a class="d-none" href="#modal-grafo" data-toggle="modal" #openModalGuardarGrafo></a>

<div id="content" class="content">
  <div class="bg-white p3-rem rounded">
    <div class="row mb-4">
      <div class="col-12 col-md-6">
        <h4 class="normalText">
          Mantenedores
        </h4>
      </div>
      <div class="col-12 col-md-6 text-right mb-4">
        <app-view-excel-buttons [ngClass]="{ 'mr-4': actionButton }" *ngIf="excelButton" [props]="excelButton"
          variant="tag">
        </app-view-excel-buttons>
        <app-action-button *ngIf="actionButton" [props]="actionButton"></app-action-button>
      </div>
    </div>
    <div class="row">
      <div class="tab-content">
        <div class="tab-pane fade" [ngClass]="{'active show': selectedTab === 'articulos'}" id="default-tab-1">
          <div class="row">
            <div class="col col-md-12">
              <app-table [header]="dcArticulos" [dataInit]="articulos" (reloadActions)="setActionsArticulo($event)">
                <app-nav-tabs [tabs]="tabs" [selectedTab]="selectedTab" (changeTab)="changeTab($event)"></app-nav-tabs>
              </app-table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="{'active show': selectedTab === 'zonas'}" id="default-tab-2">
          <div class="row">
            <div class="col col-md-12">
              <app-table [header]="dcZonas" [dataInit]="zonas" (reloadActions)="setActionsZona($event)">
                <app-nav-tabs [tabs]="tabs" [selectedTab]="selectedTab" (changeTab)="changeTab($event)"></app-nav-tabs>
              </app-table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="{'active show': selectedTab === 'blancos'}" id="default-tab-3">
          <div class="row">
            <div class="col col-md-12">
              <app-table [header]="dcBlancos" [dataInit]="blancos" (reloadActions)="setActionsBlanco($event)">
                <app-nav-tabs [tabs]="tabs" [selectedTab]="selectedTab" (changeTab)="changeTab($event)"></app-nav-tabs>
              </app-table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="{'active show': selectedTab === 'grafos'}" id="default-tab-4">
          <div class="row">
            <div class="col col-md-12">
              <app-table [header]="dcGrafos" [dataInit]="grafos" (reloadActions)="setActionsGrafo($event)">
                <app-nav-tabs [tabs]="tabs" [selectedTab]="selectedTab" (changeTab)="changeTab($event)"></app-nav-tabs>
              </app-table>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="{'active show': selectedTab === 'proyectos'}" id="default-tab-4">
          <div class="row">
            <div class="col col-md-12">
              <app-table [header]="dcProyectos" [dataInit]="proyectos" (reloadActions)="setActions('proyecto')">
                <app-nav-tabs [tabs]="tabs" [selectedTab]="selectedTab" (changeTab)="changeTab($event)"></app-nav-tabs>
              </app-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de Articulo -->
    <div class="modal fade" id="modal-articulo">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Datos del Articulo</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
              (click)="onCloseArticulo()">×</button>
          </div>
          <div class="modal-body">
            <form [formGroup]="formArticulo" (ngSubmit)="onGuardarArticulo()" novalidate>
              <input type="hidden" #correlativo>
              <div class="row form-group">
                <label for="codigo" class="col-md-12 col-form-label">Codigo</label>
                <div class="col-md-12">
                  <input type="text" name="codigo" formControlName="codigo" #codigo class="form-control"
                    [ngClass]="{ 'is-invalid': submittedArticulo && fa.codigo.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="nombre" class="col-md-12 col-form-label">Nombre</label>
                <div class="col-md-12">
                  <input type="text" name="nombre" formControlName="nombre" #nombre class="form-control"
                    [ngClass]="{ 'is-invalid': submittedArticulo && fa.nombre.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="activo" class="col-md-12 col-form-label">Estado</label>
                <div class="col-md-12">
                  <ng-select2 formControlName="activo" [data]="activos" #activo
                    class="select2-selection select2-selection--single" width="100%">
                  </ng-select2>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-2"><a href="javascript:;" (click)="onCloseArticulo()" #closeModalGuardarArticulo
                    class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                <div class="col-md-5">
                  <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146=""
                      class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Guardar Articulo</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal de Zona -->
    <div class="modal fade" id="modal-zona">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Datos de la Zona</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
              (click)="onCloseZona()">×</button>
          </div>
          <div class="modal-body">
            <form [formGroup]="formZona" (ngSubmit)="onGuardarZona()" novalidate>
              <input type="hidden" #correlativo>
              <div class="row form-group">
                <label for="codigo" class="col-md-12 col-form-label">Codigo</label>
                <div class="col-md-12">
                  <input type="text" name="codigo" formControlName="codigo" #codigo class="form-control"
                    [ngClass]="{ 'is-invalid': submittedZona && fz.codigo.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="nombre" class="col-md-12 col-form-label">Nombre</label>
                <div class="col-md-12">
                  <input type="text" name="nombre" formControlName="nombre" #nombre class="form-control"
                    [ngClass]="{ 'is-invalid': submittedZona && fz.nombre.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="activo" class="col-md-12 col-form-label">Estado</label>
                <div class="col-md-12">
                  <ng-select2 formControlName="activo" [data]="activos" #activo
                    class="select2-selection select2-selection--single" width="100%">
                  </ng-select2>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-2"><a href="javascript:;" (click)="onCloseZona()" #closeModalGuardarZona
                    class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                <div class="col-md-5">
                  <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146=""
                      class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Guardar Zona</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal de Blanco -->
    <div class="modal fade" id="modal-blanco">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Datos del Blanco</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
              (click)="onCloseBlanco()">×</button>
          </div>
          <div class="modal-body">
            <form [formGroup]="formBlanco" (ngSubmit)="onGuardarBlanco()" novalidate>
              <input type="hidden" #correlativo>
              <div class="row form-group">
                <label for="codigo" class="col-md-12 col-form-label">Codigo</label>
                <div class="col-md-12">
                  <input type="text" name="codigo" formControlName="codigo" #codigo class="form-control"
                    [ngClass]="{ 'is-invalid': submittedBlanco && fb.codigo.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="nombre" class="col-md-12 col-form-label">Nombre</label>
                <div class="col-md-12">
                  <input type="text" name="nombre" formControlName="nombre" #nombre class="form-control"
                    [ngClass]="{ 'is-invalid': submittedBlanco && fb.nombre.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="activo" class="col-md-12 col-form-label">Estado</label>
                <div class="col-md-12">
                  <ng-select2 formControlName="activo" [data]="activos" #activo
                    class="select2-selection select2-selection--single" width="100%">
                  </ng-select2>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-2"><a href="javascript:;" (click)="onCloseBlanco()" #closeModalGuardarBlanco
                    class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                <div class="col-md-5">
                  <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146=""
                      class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Guardar Blanco</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal de Grafo -->
    <div class="modal fade" id="modal-grafo">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Datos del Grafo</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
              (click)="onCloseGrafo()">×</button>
          </div>
          <div class="modal-body">
            <form [formGroup]="formGrafo" (ngSubmit)="onGuardarGrafo()" novalidate>
              <input type="hidden" #correlativo>
              <div class="row form-group">
                <label for="codigo" class="col-md-12 col-form-label">Codigo Grafo</label>
                <div class="col-md-12">
                  <input type="text" name="codigo" formControlName="codigo" #codigo class="form-control"
                    [ngClass]="{ 'is-invalid': submittedZona && fz.codigo.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="proyecto" class="col-md-12 col-form-label">Proyecto</label>
                <div class="col-md-10">
                  <ng-select2 formControlName="proyecto" [data]="proyectos" #proyecto
                    class="select2-selection select2-selection--single" width="100%">
                  </ng-select2>
                </div>
                <div class="col-md-2">
                  <a class="btn btn-primary w-100" href="#modal-proyecto" data-toggle="modal"
                    #openModalGuardarProyecto>Nuevo</a>
                </div>
              </div>
              <div class="row form-group">
                <label for="desc1" class="col-md-12 col-form-label">Desc. 1</label>
                <div class="col-md-12">
                  <input type="text" name="desc1" formControlName="desc1" #desc1 class="form-control"
                    [ngClass]="{ 'is-invalid': submittedZona && fz.desc1.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="desc2" class="col-md-12 col-form-label">Desc. 2</label>
                <div class="col-md-12">
                  <input type="text" name="desc2" formControlName="desc2" #desc2 class="form-control"
                    [ngClass]="{ 'is-invalid': submittedZona && fz.desc2.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="responsable" class="col-md-12 col-form-label">Responsable</label>
                <div class="col-md-12">
                  <ng-select2 formControlName="responsable" [data]="responsables" #responsable
                    class="select2-selection select2-selection--single" width="100%">
                  </ng-select2>
                </div>
              </div>
              <div class="row form-group">
                <label for="activo" class="col-md-12 col-form-label">Estado</label>
                <div class="col-md-12">
                  <ng-select2 formControlName="activo" [data]="activos" #activo
                    class="select2-selection select2-selection--single" width="100%">
                  </ng-select2>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-2"><a href="javascript:;" (click)="onCloseGrafo()" #closeModalGuardarGrafo
                    class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                <div class="col-md-5">
                  <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146=""
                      class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Guardar Grafo</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal de Proyecto -->
    <div class="modal fade" id="modal-proyecto">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Datos del Proyecto</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
              (click)="onCloseProyecto()">×</button>
          </div>
          <div class="modal-body">
            <form [formGroup]="formProyecto" (ngSubmit)="onGuardarProyecto()" novalidate>
              <input type="hidden" #correlativo>
              <div class="row form-group">
                <label for="codigo" class="col-md-12 col-form-label">Codigo</label>
                <div class="col-md-12">
                  <input type="text" name="codigo" formControlName="codigo" #codigo class="form-control"
                    [ngClass]="{ 'is-invalid': submittedProyecto && fp.codigo.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="nombre" class="col-md-12 col-form-label">Nombre</label>
                <div class="col-md-12">
                  <input type="text" name="nombre" formControlName="nombre" #nombre class="form-control"
                    [ngClass]="{ 'is-invalid': submittedProyecto && fp.nombre.errors}" />
                </div>
              </div>
              <div class="row form-group">
                <label for="activo" class="col-md-12 col-form-label">Estado</label>
                <div class="col-md-12">
                  <ng-select2 formControlName="activo" [data]="activos" #activo
                    class="select2-selection select2-selection--single" width="100%">
                  </ng-select2>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-2"><a href="javascript:;" (click)="onCloseProyecto()" #closeModalGuardarProyecto
                    class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                <div class="col-md-5">
                  <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146=""
                      class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Guardar Proyecto</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>