<div id="content" class="content">
  <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
    <!-- begin panel-heading -->
    <div class="panel-heading ui-sortable-handle">
      <h4 class="panel-title normalText">Lista de correos</h4>
      <div class="panel-heading-btn">
        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
      </div>
    </div>
    <!-- end panel-heading -->
    <!-- begin panel-body -->
    <div class="panel-body">
      <div class="row">
        <div class="col col-md-6">
          <input class="form-control" style="margin-bottom: 10px; " matInput (keyup)="applyFilterEmail($event) " placeholder="Buscar ... " #input>
        </div>
        <div class="col-md-4"></div>
        <div class="col col-ml-2">
          <button class="btn btn-primary btn-block" (click)="onAdd()"><i class="fas fa-lg fa-fw m-r-10 fa-envelope-open"></i>Nuevo</button>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-12">
          <table mat-table [dataSource]="emails" matSort (matSortChange)="sortData($event)" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
            <ng-container matColumnDef="Correlativo">
              <th class=" normalText" mat-header-cell *matHeaderCellDef mat-sort-header="Correlativo">#</th>
              <td mat-cell *matCellDef="let element " style="padding: 10px !important; text-align: center;"> {{element.CORRELATIVO}} </td>
            </ng-container>
            <ng-container matColumnDef="Sistema">
              <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Sistema">Sistema</th>
              <td style="padding: 10px !important; text-align: center;" mat-cell *matCellDef="let element "> {{element.SIST_NOMBRE}} </td>
            </ng-container>
            <ng-container matColumnDef="Descripcion">
              <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Descripcion">Descripcion</th>
              <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.DESCRIPCION}} </td>
            </ng-container>
            <ng-container matColumnDef="Opciones">
              <th class=" normalText" style="padding: 10px !important; width: 20%; text-align: center;" mat-header-cell *matHeaderCellDef>Opciones</th>
              <td mat-cell *matCellDef="let element " style="text-align: center; padding-left: 20px;">
                <a style="cursor: pointer;" (click)="onActivarModuloDest(element.CORRELATIVO)" title="Agregar destinatarios">
                  <!---<i class="fas fa-lg fa-fw m-r-10 fa-edit" style="color: green;"></i>-->
                  <i class="fas fa-lg fa-fw m-r-10 fa-user-plus" style="color: #2C6D80;"></i>
                </a>
                <a style="cursor: pointer;" (click)="onEditar(element.CORRELATIVO)" title="Vista previa">
                  <i class="fas fa-lg fa-fw m-r-10 fa-eye" style="color: #F59C1A;"></i>
                </a>
                <a style="cursor: pointer;" (click)="onEliminar(element.CORRELATIVO)" title="Eliminar">
                  <i class="fas fa-lg fa-fw m-r-10 fa-trash-alt" style="color: #FF5B57;"></i>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dcEmails"></tr>
            <tr mat-row *matRowDef="let row; columns: dcEmails;" id="row" style="cursor: pointer;" (click)="selectionLstCorreos.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selectionLstCorreos.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row " *matNoDataRow>
              <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
            </tr>
          </table>
          <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>


  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a href="#email-tab-1" data-toggle="tab" class="nav-link active">
        <span class="d-sm-block d-none">Mantenedor de Correo</span>
      </a>
    </li>
    <li class="nav-item" *ngIf="idEmail">
      <a href="#email-tab-2" data-toggle="tab" class="nav-link">
        <span class="d-sm-block d-none">Destinatarios de correo #{{idEmail}}</span>
      </a>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane fade active show" id="email-tab-1">
      <div class="row" style="margin-bottom: 10px;">
        <div class="col col-md-12">
          <form [formGroup]="emailForm" novalidate (ngSubmit)="onCrear()">
            <input type="hidden" class="form-control m-b-5" formControlName="correlativo">
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">#</label>
              <div class="col-md-10">
                <input #id type="text" class="form-control m-b-5" disabled>
              </div>
            </div>
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">Descripcion</label>
              <div class="col-md-10">
                <input #descp type="text" class="form-control m-b-5" placeholder="Descripcion" formControlName="descripcion">
              </div>
            </div>
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">Uso</label>
              <div class="col-md-10">
                <input type="text" class="form-control m-b-5" placeholder="Uso" formControlName="uso">
              </div>
            </div>
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">Instancia</label>
              <div class="col-md-10">
                <input type="text" class="form-control m-b-5" placeholder="Instancia" formControlName="instancia">
              </div>
            </div>
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">Datos variables</label>
              <div class="col-md-10">
                <input type="text" class="form-control m-b-5" placeholder="Datos variables" formControlName="datosVariables">
              </div>
            </div>
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">Destinatarios</label>
              <div class="col-md-10">
                <textarea class="form-control" rows="2" placeholder="Destinatarios" formControlName="destinatarios"></textarea>
              </div>
            </div>
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">Asunto</label>
              <div class="col-md-10">
                <input type="text" class="form-control m-b-5" placeholder="Asunto del correo" formControlName="asunto">
              </div>
            </div>
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">Mensaje</label>
              <div class="col-md-10">
                <textarea class="form-control" rows="5" placeholder="Mensaje de correo" formControlName="mensaje"></textarea>
              </div>
            </div>
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">Pie de página</label>
              <div class="col-md-10">
                <textarea class="form-control" #pieMsj rows="3" placeholder="Pie de página" formControlName="pie"></textarea>
              </div>
            </div>
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-2">Sistema</label>
              <div class="col-md-10">
                <select class="form-control" formControlName="sistema">
                  <option *ngFor="let item of sistemas" value="{{ item.CORRELATIVO}}">{{ item.DESCRIPCION}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col col-md-6"></div>
              <div class="col-md-3">
                <ng-container *ngIf="visiblePreview">
                  <button class="btn btn-info btn-block" (click)="openModal(template)">
                    <i class="fas fa-lg fa-fw m-r-10 fa-eye" ></i>Vista previa
                  </button>
                </ng-container>
              </div>
              <div class="col-md-3">
                <ng-container *ngIf="visible">
                  <button class="btn btn-primary btn-block" type="submit"> <i class="fas fa-lg fa-fw m-r-10 fa-save" ></i>Guardar</button>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="email-tab-2">
      <div class="row">
        <div class="col-md-6">
          <ul class="nav nav-pills mb-2">
            <li class="nav-item">
              <a href="#default-tab-1" data-toggle="tab" class="nav-link active">
                <span class="d-sm-none"><i class="fas fa-lg fa-fw m-r-10 fa-users"></i>Responsabilidad</span>
                <span class="d-sm-block d-none"><i class="fas fa-lg fa-fw m-r-10 fa-users"></i>Responsabilidad</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="#default-tab-2" data-toggle="tab" class="nav-link">
                <span class="d-sm-none"><i class="fas fa-lg fa-fw m-r-10 fa-user"></i>Personas</span>
                <span class="d-sm-block d-none"><i class="fas fa-lg fa-fw m-r-10 fa-user"></i>Personas</span>
              </a>
            </li>
          </ul>
          <div class="tab-content p-15 rounded bg-white mb-4">
            <div class="tab-pane fade active show" id="default-tab-1">
              <div class="row">
                <div class="col col-md-6">
                    <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterResponsabilidad($event)" placeholder="Buscar ... " #input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <table mat-table [dataSource]="dsDesGrupos" matSort class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                    <ng-container matColumnDef="select">
                      <mat-header-cell *matHeaderCellDef style="margin-top: 15px !important;">
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" style="margin-top: 15px;">
                        <mat-checkbox style="margin-left: 50% !important;" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Codigo">
                      <td mat-cell *matCellDef="let element " style="display: none;">
                        {{element.CORRELATIVO}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Tipo">
                      <td mat-cell *matCellDef="let element " style="display: none;">
                        {{element.TIPO}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Destinatario">
                      <td mat-cell *matCellDef="let element " style="padding-left: 10px;">
                        {{element.DESTINATARIO}}
                      </td>
                    </ng-container>
                    <tr style="cursor: pointer; " mat-row *matRowDef="let row; columns: dcDesGrupos;" (click)="selection.toggle(row)"></tr>
                  </table>
                  <mat-paginator #paginatorGrupo [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9"></div>
                <div class="col-md-3">
                  <button class="btn btn-primary btn-block" (click)="addSelectedRowsGrupo()">Agregar seleccion <i class="fas fa-lg fa-fw m-r-10 fa-arrow-right"></i></button>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="default-tab-2">
              <div class="row">
                <div class="col col-md-6">
                    <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterPersonas($event)" placeholder="Buscar ... " #input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <table mat-table [dataSource]="dsDesUsuarios" matSort class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                    <ng-container matColumnDef="select">
                      <mat-header-cell *matHeaderCellDef style="margin-top: 15px !important;">
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row" style="margin-top: 15px !important">
                        <mat-checkbox style="margin-left: 50% !important;" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Codigo">
                      <td mat-cell *matCellDef="let element " style="display: none;">
                        {{element.CORRELATIVO}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Tipo">
                      <td mat-cell *matCellDef="let element " style="display: none;">
                        {{element.TIPO}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="Destinatario">
                      <td mat-cell *matCellDef="let element " style="padding-left: 10px;">
                        {{element.DESTINATARIO}}
                      </td>
                    </ng-container>
                    <tr style="cursor: pointer; " mat-row *matRowDef="let row; columns: dcDesUsuarios;" (click)="selection.toggle(row)"></tr>
                  </table>
                  <mat-paginator #paginatorUser [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-3">
                  <button mat-raised-button class="btn btn-info btn-block" (click)="openModalDestinatario(templateDestinatario)">
                    <i class="fas fa-lg fa-fw m-r-10 fa-user-plus"></i> Nuevo
                  </button>
                </div>
                <div class="col-md-3">
                  <button class="btn btn-primary btn-block" (click)="addSelectedRowsUser()">
                    Agregar seleccion <i class="fas fa-lg fa-fw m-r-10 fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <legend>Lista de Destinatarios</legend>
            </div>
          </div>
          <div *ngIf="dsDestinatarios">
            <div class="row" style="margin-top: 15px;;">
              <div class="col col-md-6">
                  <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterDestinatarios($event)" placeholder="Buscar ... " #input>
              </div>
            </div>
            <div class="row">
              <div class="col col-md-12">
                              <!--<button mat-raised-button class="btn btn-light" style="margin-top: 10px; margin-bottom: 5px;" (click)="removeSelectedRows()">
                                  <i class="far fa-lg fa-fw m-r-10 fa-trash-alt"></i> Quitar destinatarios seleccionados
                                </button>-->
                <table mat-table [dataSource]="dsDestinatarios" matSort class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                  <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef style="margin-top: 15px !important;">
                      <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" style="margin-top: 15px !important;">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                      </mat-checkbox>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Codigo">
                    <td mat-cell *matCellDef="let element " style="display: none;">
                      {{element.CORRELATIVO}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Tipo">
                    <td mat-cell *matCellDef="let element " style="padding-left: 10px;">
                      {{element.TIPO}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Destinatario">
                    <td mat-cell *matCellDef="let element " style="padding-left: 10px;">
                      {{element.DESTINATARIO}}
                    </td>
                  </ng-container>
                  <tr style="cursor: pointer; " mat-row *matRowDef="let row; columns: dcDestinatarios;" (click)="selection.toggle(row)"></tr>
                </table>
                <mat-paginator #paginatorEnd [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
              </div>
            </div>
            <div class="row" *ngIf="destinatarios.length > 0">
              <div class="col-md-6"></div>
              <div class="col col-md-3">
                <button mat-raised-button class="btn btn-light btn-block" (click)="removeSelectedRows()">
                  <i class="far fa-lg fa-fw m-r-10 fa-trash-alt"></i> Quitar selección
                </button>
              </div>
              <div class="col col-md-3">
                <button class="btn btn-primary btn-block" (click)="onAddDestinatario()"><i class="fas fa-lg fa-fw m-r-10 fa-save"></i> Guardar lista</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- end col-6 -->
    </div>
    <!-- end tab-pane -->
  </div>


    <ng-template #template>
        <div class="modal-header">
            <h4 class="modal-title pull-left">Vista previa correo</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <!-- begin wrapper -->
            <div class="wrapper" *ngIf="objEmail">
                <h3 class="m-t-0 m-b-15 f-w-500">Asunto: {{objEmail.ASUNTO}}
                </h3>
                <ul class="media-list underline m-b-15 p-b-15">
                    <li class="media media-sm clearfix">
                        <div class="media-body">
                            <div class="email-from text-inverse f-s-14 m-b-3 f-w-600">
                                De prueba@prueba.cl
                            </div>
                            <div class="email-to">
                                Para: {{objEmail.DESTINATARIOS}}
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="row">
                    <div class="col col-md-4" style="padding-left: 9px;
                    padding-right: 0px;
                    margin: 0px;">
                        <strong>Cuerpo del mensaje</strong>
                    </div>
                    <div class="col col-md-8" style="padding-left: 0px;
                    margin: 0px;">
                        <hr>
                    </div>
                </div>
                <p class="text-inverse" style="text-align: justify;">
                    {{objEmail.CUERPO_EMAIL}}
                </p>
                <div class="row">
                    <div class="col col-md-4" style="padding-left: 9px;
                    padding-right: 0px;
                    margin: 0px;">
                        <strong>Pie del mensaje</strong>
                    </div>
                    <div class="col col-md-8" style="padding-left: 0px;
                    margin: 0px;">
                        <hr>
                    </div>
                </div>
                <p class="text-inverse" style="text-align: justify;">
                    {{objEmail.PIE_EMAIL}}
                </p>
            </div>
            <!-- end wrapper -->
            <div class="row">
                <div class="col col-md-12" style="text-align: center;">
                    <button class="btn btn-success" (click)="onEnviarMail(objEmail.CORRELATIVO)">Enviar correo</button>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #templateDestinatario>
        <div>
            <div class="modal-header">
                <h4 class="modal-title pull-left">Agregar correo</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="personaEmailForm" novalidate (ngSubmit)="onAddSingleEmail()">
                    <div class="row">
                        <div class="col col-md-4">
                            Correo:
                        </div>
                        <div class="col col-md-8">
                            <input style="margin-bottom: 10px;" #newEmail id="newEmail" formControlName="newEmail" class="form-control" type="email" placeholder="ejemplo@ejemplo.cl">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col col-md-4">
                            Nombre:
                        </div>
                        <div class="col col-md-8">
                            <input style="margin-bottom: 10px;" #nombre id="nombre" formControlName="nombre" class="form-control" type="email" placeholder="Nombre">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-4">
                            Apellido Paterno:
                        </div>
                        <div class="col col-md-8">
                            <input style="margin-bottom: 10px;" #apaterno id="apaterno" formControlName="apaterno" class="form-control" type="email" placeholder="Apellido paterno">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-4">
                            Apellido Materno:
                        </div>
                        <div class="col col-md-8">
                            <input style="margin-bottom: 10px;" #amaterno id="amaterno" formControlName="amaterno" class="form-control" type="email" placeholder="Apellido materno">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                            <button class="btn btn-primary btn-sm btn-block" type="submit">Agregar a la lista</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</div>
