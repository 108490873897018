<div id="content" class="content">
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12 ui-sortable">
            <!-- begin panel -->

            <!-- end panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                <!-- begin panel-heading -->
                <div class="panel-heading ui-sortable-handle">
                    <h4 class="panel-title normalText">Lista de correos enviados</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="panel-body">
                    <div class="row">
                        <div class="col col-md-12">
                            <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterEmail($event) " placeholder="Buscar ... " #input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12">
                            <table mat-table [dataSource]="emails" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">

                                <ng-container matColumnDef="Correlativo">
                                    <th class=" normalText" mat-header-cell *matHeaderCellDef>#</th>
                                    <td mat-cell *matCellDef="let element "> {{element.MAME_CORRELATIVO}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Sistema">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Sistema</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.NOMBRE_PROCESO}} </td>
                                </ng-container>
                                <ng-container matColumnDef="UsuarioEmisor">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Emisor</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.EMISOR}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Destinatario">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Destinatario</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.DESTINATARIO}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Fecha">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Fecha</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.FECHA_ENVIO}}</td>
                                </ng-container>
                                <ng-container matColumnDef="Descripcion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Descripcion</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.DESC_MAIL}} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="dcEmails"></tr>
                                <tr mat-row *matRowDef="let row; columns: dcEmails;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                            </table>
                            <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                </div>
                <!-- end panel-body -->
            </div>
        </div>
        <!-- end col-6 -->
    </div>
</div>