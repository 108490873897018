import { Component, OnInit } from '@angular/core';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-edit-contrato',
  templateUrl: './edit-contrato.component.html',
  styleUrls: ['./edit-contrato.component.css']
})
export class EditContratoComponent implements OnInit {
  idContrato :number;
  contrato:[]=[];
  constructor( private route: ActivatedRoute, 
    private contratoService: ContratoService) { }

  ngOnInit(): void {

    this.route.params
    .subscribe(
      (params: Params)=>{
        this.idContrato = params['idContrato'];
        if (this.idContrato){
          this.contratoService.getContratoId(this.idContrato).subscribe(resp =>{
            this.contrato = JSON.parse(resp);
          });
        }
      });
  }

}
