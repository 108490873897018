<div class="login-content">
  <form #postLogin="ngForm" (ngSubmit)="onLogin(postLogin.value)" class="margin-bottom-0">
    <div class="form-group m-b-20">
      <input type="text" class="form-control form-control-lg" name="lEmail" id="lEmail" placeholder="Email" required
        ngModel />
    </div>
    <div class="form-group m-b-20">
      <input type="password" class="form-control form-control-lg" name="lPassword" id="lPassword"
        placeholder="Contraseña" required ngModel />
    </div>
    <div class="form-group m-b-20">
      <a href="javascript:void(0);" (click)="goToForgotPassword()">Olvide mi contraseña</a>
    </div>
    <div class="login-buttons">
      <button type="submit" class="btn btn-success btn-block btn-lg" [disabled]="!postLogin.valid">Iniciar
        sesion</button>
    </div>
  </form>
</div>