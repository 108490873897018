<div id="content" class="content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle" style="background: #f59c1a;">
            <h4 class="panel-title normalText" >Estados de Pago Incurrido</h4>
        </div>
        <div class="panel-body ">
            <h4>Generación de Estado de Pago Incurrido <br> {{ contrato.NOMBRE_CONTRATO }} <br> {{ contrato.PROVEEDOR }}</h4>

            <br>
            <form [formGroup]="form" (ngSubmit)="onGenerar()" novalidate>
                <div class="row">
                    <div class="col-md-6">

                        <div class="row form-group">
                            <label for="nombre" class="col-md-4 col-form-label">Texto</label>
                            <div class="col-md-8">
                                <input type="text" #nombre formControlName="nombre" class="form-control" placeholder="Ingrese texto del estado de pago" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors}" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label class="col-md-4 col-form-label">Período Desde/Hasta</label>
                            <div class="col-md-4 input-group">
                                <input type="date" formControlName="fecha_desde" class="form-control m-b-5" #fecha_desde [ngClass]="{ 'is-invalid': submitted && f.fecha_desde.errors}">
                            </div>
                            <div class="col-md-4">
                                <input type="date" formControlName="fecha_hasta" class="form-control m-b-5" #fecha_hasta [ngClass]="{ 'is-invalid': submitted && f.fecha_hasta.errors}">
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="timo" class="col-md-4 col-form-label">Moneda</label>
                            <div class="col-md-8">
                                <select class="form-control" #timo formControlName="moneda">
                                    <option *ngFor="let item of tipoMoneda" [value]="item.CORRELATIVO">{{item.DESCRIPCION}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label class="col-md-4 col-form-label">Reajuste (%)</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" #reajuste formControlName="reajuste" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label class="col-md-4 col-form-label">Saldo Contrato</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" #saldo_contrato value="{{ contrato.SALDO_CONTRATO  | number }}" disabled />
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6">&nbsp;</div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table">
                            <thead style="background: #f59c1a; border-color:  #f59c1a; color: #fff;">
                                <th>R</th>
                                <th>Q</th>
                                <th>SubPos</th>
                                <th>Desc</th>
                                <th>Unidad</th>
                                <th>Moneda</th>
                                <th>Cantidad</th>
                                <th>P.Unitario</th>
                                <th>Consumo Q</th>
                                <th>Saldo Q</th>
                                <th width="5%">Solicitud Q</th>
                                <th>P x Q EP</th>
                                <th>REAJUSTE</th>
                                <th>TOTAL EP</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of itemizado">
                                    <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>

                                    <td *ngIf="!item.Cabecera && item.R" class="pt-2"><i class="fa fa-check"></i></td>
                                    <td *ngIf="!item.Cabecera && !item.R" class="pt-2">&nbsp;</td>
                                    <td *ngIf="!item.Cabecera && item.Scpq" class="pt-2"><i class="fa fa-check"></i></td>
                                    <td *ngIf="!item.Cabecera && !item.Scpq" class="pt-2">&nbsp;</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Subpos }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Descripcion }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Unidad }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ contrato.TIPO_MONEDA }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.PrecioUnitario | number:'1.2': 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2"></td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">&nbsp;</td>
                                    <td *ngIf="!item.Cabecera">
                                        <!-- <input type="number" (input)="validMaxLength($event);" maxlength="2" class="form-control form-control-sm" #cantidad formControlName="cantidad" name="cantidad" (change)="changeCantidad(item.Correlativo, cantidad.value, item.PrecioUnitario, item.R, reajuste.value)" /> -->
                                        <input type="number" appDecimalPlaces class="form-control form-control-sm" #cantidad formControlName="cantidad" name="cantidad" (change)="changeCantidad(item.Correlativo, cantidad.value, item.PrecioUnitario, item.R, reajuste.value)" />
                                    </td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ valoresCantidades[item.Correlativo] | number:'1.2': 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera && item.R" class="pt-2">{{ reajuste.value | number:'1.2': 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera && !item.R" class="pt-2">0</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ valoresTotales[item.Correlativo] | number:'1.2': 'es-CL' }}</td>
                                </tr>
                                <tr>
                                    <td colspan="10"></td>
                                    <td class="text-right"><strong>Total</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ total | number:'1.2': 'es-CL' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row d-none">
                    <div class="col-md-8"></div>
                    <div class="col-md-4">
                        <h6>Retenciones</h6>
                        <hr>
                        <div class="row form-group">
                            <label for="retencion_laboral" class="col-md-3 col-form-label">Laboral</label>
                            <div class="col-md-4">
                                <p class="mt-2">CLP</p>
                            </div>
                            <div class="col-md-5">
                                <input type="text" #retencion_laboral formControlName="retencion_laboral" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.retencion_laboral.errors}" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="retencion_contractual" class="col-md-3 col-form-label">Contractual</label>
                            <div class="col-md-4">
                                <div class="form-check form-check-inline mt-2">
                                    <input type="radio" value="P" name="rdo_contractual" formControlName="rdo_contractual" id="rdo_porcentaje" class="form-check-input">
                                    <label class="form-check-label" for="rdo_porcentaje">%</label>
                                </div>
                                <div class="form-check form-check-inline mt-2">
                                    <input type="radio" value="M" name="rdo_contractual" formControlName="rdo_contractual" id="rdo_monto" class="form-check-input">
                                    <label class="form-check-label" for="rdo_monto">CLP</label>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <input type="text" #retencion_contractual formControlName="retencion_contractual" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.retencion_contractual.errors}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <button class="btn btn-primary">Generar EP</button>
                </div>
            </form>
        </div>
    </div>
</div>