<a class="d-none" href="#modal-proveedor" data-toggle="modal" #openModalGuardarProveedor></a>
<a class="d-none" href="#modal-adc" data-toggle="modal" #openModalAgregarAdc></a>
<a class="d-none" href="#modal-contratos" data-toggle="modal" #openModalContratos></a>

<div id="content" class="content">
    <div class="">
        <!-- <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Proveedores</h4>
        </div> -->
        <div class="bg-white p3-rem rounded">
            <div class="row mb-4">
                <div class="col-6">
                    <h4>Proveedores</h4>
                </div>
                <div class="col-6 text-right">
                    <app-view-excel-buttons class="mr-4" variant="tag" [props]="excelButtonProveedores">
                    </app-view-excel-buttons>

                    <app-action-button *ngIf="actionButtonProps" [props]="actionButtonProps"></app-action-button>
                </div>
            </div>
            <div class="row">
                <div class="col col-md-12">
                    <app-table [header]="dcProveedores" [dataInit]="proveedores"
                     [pageSize]="25"
                        (reloadActions)="setActions($event)"></app-table>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal de Proveedor -->
<div class="modal fade" id="modal-proveedor">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Datos del Proveedor / Contratista</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                    (click)="onCloseProveedor()">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formProveedor" (ngSubmit)="onGuardarProveedor()" novalidate>
                    <input type="hidden" #corr_proveedor>
                    <div class="row form-group">
                        <label for="proveedor" class="col-md-12 col-form-label">Razón Social</label>
                        <input name="nombre" #nombre formControlName="nombre" class="form-control"
                            [ngClass]="{ 'is-invalid': submittedProveedor && fp.nombre.errors}" />
                    </div>
                    <div class="row form-group">
                        <label for="rut" class="col-md-12 col-form-label">RUT</label>
                        <div class="col-md-12">
                            <input type="text" name="rut" formControlName="rut" #rut class="form-control" maxlength="10"
                                placeholder="formato: 11222333-4"
                                [ngClass]="{ 'is-invalid': submittedProveedor && fp.rut.errors}" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="telefono" class="col-md-12 col-form-label">Teléfono</label>
                        <div class="col-md-12">
                            <input type="text" name="telefono" formControlName="telefono" #telefono class="form-control"
                                [ngClass]="{ 'is-invalid': submittedProveedor && fp.telefono.errors}" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="contacto" class="col-md-12 col-form-label">Nombre Contacto</label>
                        <div class="col-md-12">
                            <input type="text" name="contacto" formControlName="contacto" #contacto class="form-control"
                                [ngClass]="{ 'is-invalid': submittedProveedor && fp.contacto.errors}" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="email" class="col-md-12 col-form-label">Email</label>
                        <div class="col-md-12">
                            <input type="text" name="email" formControlName="email" #email class="form-control"
                                [ngClass]="{ 'is-invalid': submittedProveedor && fp.email.errors}" />
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-primary mr-2" type="submit">Guardar
                            </button>
                            <a href="javascript:;" (click)="onCloseProveedor()"
                                #closeModalGuardarProveedor class="btn tag "
                                data-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!-- Modal de Proveedor -->
<div class="modal fade" id="modal-adc">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Proveedor: {{ nombreProveedor }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <p class="text-muted">

                    </p>
                </div>
                <form [formGroup]="formAgregarAdmc" (ngSubmit)="onAgregarAdmc()" novalidate>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex">
                                <div class="mr-1" style="width:100%">
                                    <ng-select2 [data]="adc" width="100%" #admc name="admc" formControlName="admc"
                                        class="select2-selection select2-selection--single"
                                        [ngClass]="{ 'is-invalid': submitted && f.admc.errors}">
                                    </ng-select2>
                                    <div *ngIf="submitted && f.admc.errors" class="invalid-feedback">
                                        <div>Selecciona un elemento de la lista</div>
                                    </div>
                                </div>
                                <div class="">
                                    <button class="btn tag" type="submit">
                                        Relacionar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4 text-right">
                            
                        </div> -->
                    </div>
                    <input type="hidden" name="corrProveedor" formControlName="corrProveedor" #corrProveedor value="" />
                </form>
                <br><br>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table border">
                            <thead class="">
                                <th class="text-normal">Nombre</th>
                                <!-- <th class="text-normal">Usuario</th> -->
                                <th class=""></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of admcPorProveedor">
                                    <td>
                                        <p class="mb-0">
                                            {{ item.PERS_NOMBRE }}
                                        </p>
                                        <span class="text-muted">
                                            {{ item.USERNAME }}
                                        </span>
                                    </td>
                                    <!-- <td>{{ item.USERNAME }}</td> -->
                                    <td class="text-right">
                                        <div class="btn rounded-icon mr-2" (click)="eliminarAdmc(item.CORRELATIVO)"
                                            title="Eliminar">
                                            <i class="fas fa-trash-alt"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <a href="javascript:;" class="btn tag"
                            data-dismiss="modal">Cerrar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de Contratos por Proveedor -->
<!-- <div class="modal modal-small-message" id="modal-contratos"
    style="margin-left: 10%  !important; margin-right: 20% !important;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Proveedor: {{ nombreProveedor }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="col-md-12" *ngIf="showHistorialContrato">
                    <div class="row mt-2 mb-2">
                        <div class="col-12">
                            <a class="text-primary" 
                            (click)="showHistorialContrato = !showHistorialContrato"
                            href="javascript:void(0);">Volver</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <legend>{{ nombreContrato}} </legend>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 20px;" >
                        <div class="col-md-12" style="text-align: left;padding-top: 5px;">
                            <label class="text-muted">Asignar nuevo administrador</label>
                        </div>
                        <div class="col-md-12">
                            <div class="d-flex">
                                <div style="width:100%" class="mr-1">
                                    <ng-select2 [data]="admcPorProveedorSelect2" width="100%"
                                        class="select2-selection select2-selection--single" [value]="0"
                                        (valueChanged)='onGetAdProveedorUpdateId($event)'>
                                    </ng-select2>
                                </div>
                                <button class="btn tag" (click)="onGuardarAdminContratista()"> 
                                    Asignar
                                </button>
                            </div>
                        </div>
                    </div>

                    <table mat-table [dataSource]="dsAdecContrato" matSort
                        class="table border table-td-valign-middle table-th-valign-middle">
                        <ng-container matColumnDef="AdminContratista">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="AdminContratista">Admin. Contratista</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.NOMBRE_USUA_ADEC }}</td>
                        </ng-container>
                        <ng-container matColumnDef="FechaInicio">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="FechaInicio">Fecha Inicio</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.FECHA_INICIO}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaTermino">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="FechaTermino">Fecha Termino</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.FECHA_TERMINO}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Vigente">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="Vigente">Vigente</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.VIGENTE
                                ==='S' ? 'Si' : 'No' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="UsuarioActualizacion">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="UsuarioActualizacion">Usuario Actualización</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.NOMBRE_USUA_CREACION}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaActualizacion">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="FechaActualizacion">Fecha Actualización</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.FECHA_CREACION}}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="dsHeaderAdecContrato"></tr>
                        <tr mat-row *matRowDef="let row; columns: dsHeaderAdecContrato;" id="row"
                            style="cursor: pointer;" (click)="selection.toggle(row)"
                            [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                    </table>
                </div>

                <div class="row" *ngIf="!showHistorialContrato">
                    <div class="col-md-12">
                        <table class="table border">
                            <thead class="">
                                <th class="text-normal">Contrato</th>
                                <th class="text-normal">Admin Contratista</th>
                                <th ></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of contratosProveedor">
                                    <td>{{ item.CONTRATO }}</td>
                                    <td>{{ item.NOMBRE_ADMIN_EC }}</td>
                                    <td class="text-center">
                                        <span class="btn rounded-icon cursor-pointer" (click)="onVerHistoriaContrato(item.CONT_CORRELATIVO, item.CONTRATO)">
                                            <i class="fas fa-pen"></i>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <a href="javascript:;" class="btn tag" data-dismiss="modal">
                            Cerrar
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Modal Contrato -->
<div class="modal fade" id="modal-contratos">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Proveedor: {{ nombreProveedor }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="col-md-12" *ngIf="showHistorialContrato">
                    <div class="row mt-2 mb-2">
                        <div class="col-12">
                            <a class="text-primary" 
                            (click)="showHistorialContrato = !showHistorialContrato"
                            href="javascript:void(0);">Volver</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <legend>{{ nombreContrato}} </legend>
                        </div>
                    </div>

                    <div class="row" style="margin-bottom: 20px;" >
                        <div class="col-md-12" style="text-align: left;padding-top: 5px;">
                            <label class="text-muted">Asignar nuevo administrador</label>
                        </div>
                        <div class="col-md-12">
                            <div class="d-flex">
                                <div style="width:100%" class="mr-1">
                                    <ng-select2 [data]="admcPorProveedorSelect2" width="100%"
                                        class="select2-selection select2-selection--single" [value]="0"
                                        (valueChanged)='onGetAdProveedorUpdateId($event)'>
                                    </ng-select2>
                                </div>
                                <button class="btn tag" (click)="onGuardarAdminContratista()"> 
                                    Asignar
                                </button>
                            </div>
                        </div>
                    </div>

                    <table mat-table [dataSource]="dsAdecContrato" matSort
                        class="table border table-td-valign-middle table-th-valign-middle">
                        <ng-container matColumnDef="AdminContratista">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="AdminContratista">Admin. Contratista</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.NOMBRE_USUA_ADEC }}</td>
                        </ng-container>
                        <ng-container matColumnDef="FechaInicio">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="FechaInicio">Fecha Inicio</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.FECHA_INICIO}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaTermino">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="FechaTermino">Fecha Termino</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.FECHA_TERMINO}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Vigente">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="Vigente">Vigente</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.VIGENTE
                                ==='S' ? 'Si' : 'No' }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="UsuarioActualizacion">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="UsuarioActualizacion">Usuario Actualización</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.NOMBRE_USUA_CREACION}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FechaActualizacion">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                                mat-sort-header="FechaActualizacion">Fecha Actualización</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{
                                element.FECHA_CREACION}}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="dsHeaderAdecContrato"></tr>
                        <tr mat-row *matRowDef="let row; columns: dsHeaderAdecContrato;" id="row"
                            style="cursor: pointer;" (click)="selection.toggle(row)"
                            [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                    </table>
                </div>

                <div class="row" *ngIf="!showHistorialContrato">
                    <div class="col-md-12">
                        <table class="table border">
                            <thead class="">
                                <th class="text-normal">Contrato</th>
                                <th class="text-normal">Admin Contratista</th>
                                <th ></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of contratosProveedor">
                                    <td>{{ item.CONTRATO }}</td>
                                    <td>{{ item.NOMBRE_ADMIN_EC }}</td>
                                    <td class="text-center">
                                        <span class="btn rounded-icon cursor-pointer" (click)="onVerHistoriaContrato(item.CONT_CORRELATIVO, item.CONTRATO)">
                                            <i class="fas fa-pen"></i>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <a href="javascript:;" class="btn tag" data-dismiss="modal">
                            Cerrar
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>