<div id="content" class="content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Detalle Itemizado</h4>
        </div>
        <div class="panel-body ">

            <h4 *ngIf="corrEstadoItemizado != 123">Aprobar Itemizado</h4>
            <h4 *ngIf="corrEstadoItemizado == 123">Itemizado</h4>
            <br>
            <h5>{{ contrato.NOMBRE_CONTRATO }} <br> {{ contrato.PROVEEDOR }}</h5>
            <br>
            <hr>    
            <p *ngIf="corrEstadoItemizado != 123" class="text-right">
                <button class="btn btn-light" (click)="rechazarItemizado()">Rechazar</button> &nbsp;&nbsp;&nbsp;
                <button class="btn btn-primary" (click)="aprobarItemizado()"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Aprobar</button>
            </p>   
            <div class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead class="thead-dark">
                            <th>R</th>
                            <th>Q</th>
                            <th>SubPos</th>
                            <th>Desc</th>
                            <th>Unidad</th>
                            <th>Moneda</th>
                            <th>Cantidad</th>
                            <th>V. Unitario</th>
                            <th width="25%">Artículo</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of itemizado">
                                <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>

                                <td *ngIf="!item.Cabecera && item.R" class="pt-2"><i class="fa fa-check"></i></td>
                                <td *ngIf="!item.Cabecera && !item.R" class="pt-2">&nbsp;</td>
                                <td *ngIf="!item.Cabecera && item.Scpq" class="pt-2"><i class="fa fa-check"></i></td>
                                <td *ngIf="!item.Cabecera && !item.Scpq" class="pt-2">&nbsp;</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Subpos }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Descripcion }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Unidad }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ contrato.TIPO_MONEDA }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.PrecioUnitario | number:'1.2': 'es-CL' }}</td>      
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Articulo }}</td>                          
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
</div>    
