import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ParametrosService } from '../shared/services/parametros.service';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.css']
})
export class ParametrosComponent implements OnInit {

  roles:any;
  dcRoles: string[] = ['Correlativo', 'Descripcion'];
  @ViewChild('paginatorRol', {static: true}) paginatorRol: MatPaginator;

  sistemas:any;
  dcSistemas: string[] = ['Correlativo', 'Descripcion'];
  @ViewChild('paginatorSistema', {static: true}) paginatorSistema: MatPaginator;

  acciones:any;
  dcAcciones: string[] = ['Correlativo', 'Descripcion'];
  @ViewChild('paginatorAccion', {static: true}) paginatorAccion: MatPaginator;

  modulos:any;
  dcModulo: string[] = ['Correlativo', 'Descripcion'];
  @ViewChild('paginatorModulo', {static: true}) paginatorModulo: MatPaginator;

  cargos:any;
  dcCargo: string[] = ['Correlativo', 'Descripcion'];
  @ViewChild('paginatorCargo', {static: true}) paginatorCargo: MatPaginator;

  perfiles:any;
  dcPerfil: string[] = ['Correlativo', 'Descripcion'];
  @ViewChild('paginatorPerfil', {static: true}) paginatorPerfil: MatPaginator;

  selectionSistemas = new SelectionModel<any>(false, []);
  selectionModulos = new SelectionModel<any>(false, []);
  selectionAcciones = new SelectionModel<any>(false, []);
  selectionResponsabilidades = new SelectionModel<any>(false, []);

  sortedData: any;
  sistemasInit: any;
  modulosInit: any;
  accionesInit: any;
  cargosInit: any;

  constructor(private parametrosService: ParametrosService) { }

  ngOnInit(): void {
    //this.getRoles();
    this.getSistemas();
    this.getAcciones();
    this.getModulos();
    this.getCargos();
    //this.getPerfiles();
  }

  private getRoles(){
    this.parametrosService.getRoles().subscribe(resp=>{
      this.roles=new MatTableDataSource(JSON.parse(resp));
      this.paginatorRol._intl.itemsPerPageLabel = 'Registros por página';
      this.paginatorRol._intl.firstPageLabel = 'Primera pagina';
      this.paginatorRol._intl.lastPageLabel = 'Última pagina';
      this.paginatorRol._intl.nextPageLabel = 'Siguiente';
      this.paginatorRol._intl.previousPageLabel = 'Anterior';
      this.roles.paginator = this.paginatorRol;
    });
  }

  applyFilterRol(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.roles.filter = filterValue.trim().toLowerCase();
  }

  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }

  sortDataSistemas(sort: Sort) {
    const data = this.sistemasInit.slice();
    if (!sort.active || sort.direction === '') {
      this.sistemas =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorSistema, this.sistemas);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Correlativo': return compare(a.CORRELATIVO, b.CORRELATIVO, isAsc);
        case 'Descripcion': return compare(a.DESCRIPCION, b.DESCRIPCION, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.sistemas =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorSistema, this.sistemas);
    }
  }

  private getSistemas(){
    this.parametrosService.getSistemas().subscribe(resp=>{
      this.sistemas=new MatTableDataSource(JSON.parse(resp));
      this.createPaginator(this.paginatorSistema, this.sistemas);
      this.sistemasInit = JSON.parse(resp);
    });
  }

  applyFilterSistema(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.sistemas.filter = filterValue.trim().toLowerCase();
  }

  sortDataAcciones(sort: Sort) {
    const data = this.accionesInit.slice();
    if (!sort.active || sort.direction === '') {
      this.acciones =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorAccion, this.acciones);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Correlativo': return compare(a.CORRELATIVO, b.CORRELATIVO, isAsc);
        case 'Descripcion': return compare(a.NOMBRE, b.NOMBRE, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.acciones =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorAccion, this.acciones);
    }
  }

  private getAcciones(){
    this.parametrosService.getAcciones().subscribe(resp=>{
      this.acciones=new MatTableDataSource(JSON.parse(resp));
      this.createPaginator(this.paginatorAccion, this.acciones);
      this.accionesInit = JSON.parse(resp);
    });
  }

  applyFilterAccion(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.acciones.filter = filterValue.trim().toLowerCase();
  }

  sortDataModulos(sort: Sort) {
    const data = this.modulosInit.slice();
    if (!sort.active || sort.direction === '') {
      this.modulos =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorModulo, this.modulos);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Correlativo': return compare(a.CORRELATIVO, b.CORRELATIVO, isAsc);
        case 'Descripcion': return compare(a.NOMBRE, b.NOMBRE, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.modulos =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorModulo, this.modulos);
    }
  }

  private getModulos(){
    this.parametrosService.getModulos().subscribe(resp=>{
      this.modulos=new MatTableDataSource(JSON.parse(resp));
      this.createPaginator(this.paginatorModulo, this.modulos);
      this.modulosInit = JSON.parse(resp);

    });
  }

  applyFilterModulo(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.modulos.filter = filterValue.trim().toLowerCase();
  }

  sortDataCargos(sort: Sort) {
    const data = this.cargosInit.slice();
    if (!sort.active || sort.direction === '') {
      this.cargos =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorCargo, this.cargos);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Correlativo': return compare(a.CORRELATIVO, b.CORRELATIVO, isAsc);
        case 'Descripcion': return compare(a.DESTINATARIO, b.DESTINATARIO, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.cargos =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorCargo, this.cargos);
    }
  }

  private getCargos(){
    this.parametrosService.getCargos().subscribe(resp=>{
      this.cargos=new MatTableDataSource(JSON.parse(resp));
      this.createPaginator(this.paginatorCargo, this.cargos);
      this.cargosInit = JSON.parse(resp);
    });
  }

  applyFilterCargo(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.cargos.filter = filterValue.trim().toLowerCase();
  }

  private getPerfiles(){
    this.parametrosService.getPerfiles().subscribe(resp=>{
      this.perfiles=new MatTableDataSource(JSON.parse(resp));
      this.paginatorPerfil._intl.itemsPerPageLabel = 'Registros por página';
      this.paginatorPerfil._intl.firstPageLabel = 'Primera pagina';
      this.paginatorPerfil._intl.lastPageLabel = 'Última pagina';
      this.paginatorPerfil._intl.nextPageLabel = 'Siguiente';
      this.paginatorPerfil._intl.previousPageLabel = 'Anterior';
      this.perfiles.paginator = this.paginatorPerfil;
    });
  }

  applyFilterPerfil(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.perfiles.filter = filterValue.trim().toLowerCase();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
