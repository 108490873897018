<div id="content" class="content">
    <div class="panel panel-inverse"  data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle" style="background: #f59c1a;">
            <h4 class="panel-title normalText"  >Estados de Pago Incurrido</h4>
        </div>
        <div class="panel-body ">
            <h4>Estados de Pago Incurrido</h4>
            <br>
            <div class="row" *ngIf="rolId != 20 && rolId != 17 && rolId != 22 && rolId != 13 && rolId != 10">
                <label for="contrato" class="col-md-1 col-form-label">Contrato</label>
                <div class="col-md-4">
                    <ng-select2 [data]="contratos" #contrato class="select2-selection select2-selection--single" width="100%" (valueChanged)="onChangeCto($event)">
                    </ng-select2>
                </div>
            </div>
            <br>
            <p class="text-right" *ngIf="rolId == 2"><button [routerLink]="['/nuevo-ep-incurrido', corrContrato]" class="btn btn-success" [disabled]="!existeContrato" >Nuevo Estado de Pago Incurrido</button></p>

            <table class="table">
                <thead  style="background: #f59c1a; border-color:  #f59c1a; color: #fff;">
                    <th *ngIf="rolId == 20 || rolId == 17 || rolId == 22 || rolId == 13 || rolId == 10">Contrato</th>
                    <th>Descripción</th>
                    <th>Periodo Desde</th>
                    <th>Periodo Hasta</th>
                    <th>Estado</th>
                    <th class="text-right">Acciones</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of estadosPagos">
                        <td *ngIf="rolId == 20 || rolId == 17 || rolId == 22 || rolId == 13 || rolId == 10">{{ item.CONTRATO }}</td>
                        <td>{{ item.EP_DESCRIPCION }}</td>
                        <td>{{ item.EP_PERIODO_DESDE }}</td>
                        <td>{{ item.EP_PERIODO_HASTA }}</td>
                        <td>{{ item.EP_ESTADO }}</td>
                        <td class="text-right">
                            <a [routerLink]="['/estado-pago-checklist', item.EP_CORRELATIVO]" class="btn btn-default btn-sm" style="color: #666;" title="Revisar">
                                <i class="fa fa-lg fa-fw m-r-10 fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>