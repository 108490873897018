import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FileService } from '../services/file.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'view-file-link',
  templateUrl: './view-file-link.component.html',
  styleUrls: ['./view-file-link.component.css'],
  providers: [FileService]
})
export class ViewFileLinkComponent implements OnInit {

  urlVisualizador:string;
  modalRef: BsModalRef;
  @Input() nombreArchivo: string;
  @Input() tipoArchivo: string;
  @Input() descripcionArchivo: string;

  constructor(private notifyService : NotificationService, 
    private fileService: FileService,
    private modalService: BsModalService) { 

    }

  ngOnInit(): void {
  }

  async onVisualizar(template: TemplateRef<any>){
    this.fileService.generateExternalToken({fileName: this.nombreArchivo, fileType: this.tipoArchivo}).subscribe(async resp => {
      let response = JSON.parse(JSON.stringify(resp));
      if(response.hasOwnProperty('token')){
        const urlDocumento = this.fileService.getExternalUrl(response.token);
        this.urlVisualizador = !this.nombreArchivo.includes('xlsx') 
          ? `https://docs.google.com/gview?url=${urlDocumento}&embedded=true#:0.page.20`
          : `https://view.officeapps.live.com/op/embed.aspx?src=${urlDocumento}`;
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' }); 
      }

    });
  }

  onClose(){
    this.modalRef.hide(); 
  }
}
