import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from '../shared/services/notification.service';
import { ParametrosService } from '../shared/services/parametros.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';
import { AuthService } from '../shared/services/auth.service';
import { isInvalidControl, passwordValidation, compare } from '../shared/functions/common';

interface Cargos {
  idCargo: number;
  idUsuario: number;
  idUsuarioCreacion: number;
  ROL_CORRELATIVO: any;
  check: boolean;
}

interface UsuarioData {
  in_usua_correlativo: number,
  iv_username: string,
  iv_password: string,
  iv_fecha_inicio_vigencia: string,
  iv_fecha_termino_vigencia?: string,
  in_usua_correlativo_creacion: string,
  iv_vigencia: string,
  iv_tipo: string,
  iv_nombres: string,
  iv_paterno: string,
  iv_materno: string,
  iv_isEmsa: string,
  iv_rut: string,
  iv_telefono: string,
  iv_jefe_directo: number,
  iv_direccion: string,
  iv_fechaNacimiento: string
}

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  usuarioForm: FormGroup;
  visible: boolean = false;
  @ViewChild('id', { static: false }) id: ElementRef;
  idUser: number = 0;
  nombre_usuario: string;
  usuarios: any;

  isSubmitted: boolean = false;

  cargos: Cargos[];
  usuarioCargos: Cargos[];

  dcUsuarios: string[] = ['Correlativo', 'Usuario', 'Vigente', 'emsa', 'Opciones'];

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  roles: any;
  dcRoles: string[] = ['select', 'Correlativo', 'Descripcion', 'Modulo', 'Opcion', 'Sistema'];
  @ViewChild('paginatorRol', { static: true }) paginatorRol: MatPaginator;

  selectionUsuarios = new SelectionModel<any>(false, []);

  selectionMenu = new SelectionModel<any>(false, []);


  rolesUsuario: any[] = [];
  rolDb: any[] = [];
  dsRolesUsuario: any;
  dsFinal: any;
  dcFinal: string[] = ['select', 'Correlativo', 'Descripcion', 'Modulo', 'Opcion', 'Sistema'];
  @ViewChild('paginatorFinal', { static: true }) paginatorFinal: MatPaginator;

  dsRolesUsuarioId: any;
  dcRolesUsuarioId: string[] = ['Correlativo', 'Descripcion', 'Modulo', 'Opcion', 'Sistema', 'Borrar'];
  @ViewChild('paginatorRolesUsuarioId', { static: true }) paginatorRolesUsuarioId: MatPaginator;

  sistemas: [];
  modulos: [];
  opciones: [];
  menus: [];

  visibleSave: boolean = false;
  opcionesSave: any[] = [];

  sortedData: any;
  usuariosInit: any;
  usuariosJefes: any;
  menuUsuarioInit: any;

  listMenus: any;
  dcMenus: string[] = ['Correlativo', 'Menu', 'Opciones'];
  @ViewChild('paginatorMenu', { static: true }) paginatorMenu: MatPaginator;

  idUsuarioSelected: number;

  constructor(
    private parametrosService: ParametrosService,
    private notifyService: NotificationService,
    private authService: AuthService
  ) {
    this.idUsuarioSelected = 0;
    this.getCargos();
  }

  ngOnInit(): void {
    this.resetForm();
    this.usuarioForm.get('isEmsa').valueChanges.subscribe(value => {
      if (value) {
        this.usuarioForm.get('jefe').setValidators(Validators.required);
        this.usuarioForm.get('fechaNacimiento').setValidators(Validators.required);
        this.usuarioForm.get('direccion').setValidators(Validators.required);
      }else{
        this.usuarioForm.get('jefe').clearValidators();
        this.usuarioForm.get('fechaNacimiento').clearValidators();
        this.usuarioForm.get('direccion').clearValidators();
      }
    });
    this.getUsuarios();
  }

  onAdd() {
    this.visible = true;
    this.idUser = 0;
    this.usuarioCargos = [];
    this.resetForm();
  }
  applyFilterUsuario(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.usuarios.filter = filterValue.trim().toLowerCase();
  }
  applyFilterMenu(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listMenus.filter = filterValue.trim().toLowerCase();
  }

  private resetForm() {
    this.isSubmitted = false;
    this.usuarioForm = new FormGroup({
      'correlativo': new FormControl(null),
      'userName': new FormControl(null, [Validators.email, Validators.required]),
      'pass': new FormControl(null, (this.idUser !== 0 ? this.passwordValidator() : [this.passwordValidator(),Validators.required])),
      'vigente': new FormControl(true),
      'fechaIni': new FormControl(null, Validators.required),
      'fechaFin': new FormControl(null),
      'nombres': new FormControl(null, [Validators.minLength(3),Validators.required]),
      'paterno': new FormControl(null, [Validators.minLength(3),Validators.required]),
      'materno': new FormControl(null, [Validators.required]),
      'isEmsa': new FormControl(null),
      'rut': new FormControl(null, [Validators.pattern('^[0-9]+-[0-9kK]{1}$'), Validators.required]),
      'jefe': new FormControl(null),
      'telefono': new FormControl(null, [Validators.pattern("[0-9 ]{11}"), Validators.required]),
      'direccion': new FormControl(null),
      'fechaNacimiento': new FormControl(null)
    });
  }

  onDelMenuUsua(id: number) {
    this.parametrosService.deleteMenuUsuarioId(this.idUser, id).subscribe(resp => {
      this.getMenuUsuario(this.idUser);
      this.notifyService.showWarning('Menu eliminado', 'Eliminar menu');
    });
  }

  private createPaginator(pag: any, table: any) {
    if (pag?._intl) {
      pag._intl.itemsPerPageLabel = 'Registros por página';
      pag._intl.firstPageLabel = 'Primera pagina';
      pag._intl.lastPageLabel = 'Última pagina';
      pag._intl.nextPageLabel = 'Siguiente';
      pag._intl.previousPageLabel = 'Anterior';
    }
    table.paginator = pag;
  }

  sortDataMenuUsuario(sort: Sort) {
    const data = this.menuUsuarioInit.slice();
    if (!sort.active || sort.direction === '') {
      this.listMenus = new MatTableDataSource(data);
      this.createPaginator(this.paginatorMenu, this.listMenus);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Correlativo': return compare(a.CORRELATIVO, b.CORRELATIVO, isAsc);
        case 'Username': return compare(a.USERNAME, b.USERNAME, isAsc);
        case 'Vigente': return compare(a.VIGENTE, b.VIGENTE, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData) {
      this.listMenus = new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorMenu, this.listMenus);
    }
  }

  private getMenuUsuario(id: number) {
    this.parametrosService.getMenuUsuario(id).subscribe(resp => {
      this.listMenus = new MatTableDataSource(JSON.parse(resp));
      this.createPaginator(this.paginatorMenu, this.listMenus);
      this.menuUsuarioInit = JSON.parse(resp);
    });
  }

  sortDataUsuarios(sort: Sort) {
    const data = this.usuariosInit.slice();
    if (!sort.active || sort.direction === '') {
      this.usuarios = new MatTableDataSource(data);
      this.createPaginator(this.paginator, this.usuarios);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Correlativo': return compare(a.Correlativo, b.Correlativo, isAsc);
        case 'Menu': return compare(a.Usuario, b.Usuario, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData) {
      this.usuarios = new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginator, this.usuarios);
    }
  }

  private getUsuarios() {
    this.parametrosService.getUsuarios().subscribe(resp => {
      this.usuarios = new MatTableDataSource(JSON.parse(resp));
      this.createPaginator(this.paginator, this.usuarios);
      this.usuariosInit = JSON.parse(resp);
      this.usuariosJefes = JSON.parse(resp);
      this.usuariosJefes = this.usuariosJefes.filter((obj) => {
        return obj.esJefe == 'S';
      })
      // console.log(this.usuariosJefes);
    });
  }

  onAddRol(id: number, nombre_usuario: string) {
    this.idUser = id;
    this.nombre_usuario = nombre_usuario.toUpperCase();
    if (this.idUser) {
      this.getMenus();
      this.getMenuUsuario(this.idUser);
    }
  }

  private getMenus() {
    this.parametrosService.getRetornaMenus().subscribe(resp => {
      this.menus = JSON.parse(resp);
    });
  }

  onGuardarMenu(idMenu: number) {
    const postData: {
      in_usua_correlativo: number,
      in_menu_correlativo: number,
      in_usua_correlativo_crea: number
    } = { in_usua_correlativo: this.idUser, in_menu_correlativo: idMenu, in_usua_correlativo_crea: 0 };
    this.parametrosService.postRegistraMenuUsuario(postData).subscribe(resp => {
      this.notifyService.showSuccess('Menu agregado al usuario con exito', 'Agregar menu');
      this.getMenuUsuario(this.idUser);
    });
  }

  onEdit(id: number) {
    this.visible = true;
    this.parametrosService.getUsuarioId(id).subscribe(resp => {
      this.idUser = id;
      const user = JSON.parse(resp)[0];
      if (user) {
        let fechaIni = null;
        let fechaFin = null;

        if (user.INICIO_VIGENCIA) {
          fechaIni = user.INICIO_VIGENCIA;
        }
        if (user.TERMINO_VIGENCIA) {
          fechaFin = user.TERMINO_VIGENCIA;
        }
        this.id.nativeElement.value = user.CORRELATIVO;
        this.usuarioForm = new FormGroup({
          'correlativo': new FormControl(user.CORRELATIVO),
          'userName': new FormControl(user.USERNAME, [Validators.email, Validators.required]),
          'pass': new FormControl(user.PASSWORD, this.passwordValidator()),
          'vigente': new FormControl(user.VIGENTE === 'S' ? true : false),
          'fechaIni': new FormControl(fechaIni, Validators.required),
          'fechaFin': new FormControl(fechaFin),
          'nombres': new FormControl(user.NOMBRES, [Validators.minLength(3),Validators.required]),
          'paterno': new FormControl(user.PATERNO, [Validators.minLength(3),Validators.required]),
          'materno': new FormControl(user.MATERNO, [Validators.required]),
          'isEmsa': new FormControl(user.esEmsa === 'S' ? true : false),
          'rut': new FormControl(user.rut + "-" + user.digito_rut, [Validators.pattern('^[0-9]+-[0-9kK]{1}$'), Validators.required]),
          'jefe': new FormControl(user.pers_correlativo_jef_directa === null ? 0 : user.pers_correlativo_jef_directa),
          'telefono': new FormControl(user.telefono, [Validators.pattern("[0-9 ]{11}"), Validators.required]),
          'direccion': new FormControl(user.direccion),
          'fechaNacimiento': new FormControl(user.fecha_nacimiento)
        });

        this.usuarioForm.patchValue(
          {
            fechaNacimiento: user.fecha_nacimiento
          }
        )
        this.getUsuariosCargos(id);
      }
    }, err => {
      this.notifyService.showError('Error al actualizar usuario', 'Crear usuario');
    });
  }

  onCrear() {
    this.isSubmitted = true;

    if (this.usuarioForm.valid) {
      if (this.usuarioForm.value.correlativo) {
        this.editarUsuario();
      } else {
        this.crearUsuario();
      }
    } else {

      if (this.usuarioForm.controls.userName.status === 'INVALID') {
        this.notifyService.showWarning('Verifique que el campo Correo sea valido.', 'Mantenedor de usuarios');
      }

      if (this.usuarioForm.controls.rut.status === 'INVALID') {
        this.notifyService.showWarning('Verifique que el campo RUT sea valido.', 'Mantenedor de usuarios');
      }

      if (this.usuarioForm.controls.nombres.status === 'INVALID') {
        this.notifyService.showWarning('Verifique que el campo Nombres sea valido.', 'Mantenedor de usuarios');
      }

      if (this.usuarioForm.controls.paterno.status === 'INVALID') {
        this.notifyService.showWarning('Verifique que el campo Apellido paterno sea valido.', 'Mantenedor de usuarios');
      }

      if (this.usuarioForm.controls.materno.status === 'INVALID') {
        this.notifyService.showWarning('Verifique que el campo Apellido materno sea valido.', 'Mantenedor de usuarios');
      }

      if (this.usuarioForm.controls.telefono.status === 'INVALID') {
        this.notifyService.showWarning('Verifique que el campo Telefono sea valido.', 'Mantenedor de usuarios');
      }

      if (this.usuarioForm.controls.direccion.status === 'INVALID') {
        this.notifyService.showWarning('Verifique que el campo Direccion sea valido.', 'Mantenedor de usuarios');
      }

      this.notifyService.showWarning('Favor llene los campos obligatorios', 'Mantenedor de usuarios');
    }
  }

  calcularEdad(fecha_nacimiento) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha_nacimiento);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }
    return edad;
  }

  private editarUsuario() {
    const postData: UsuarioData = {
      in_usua_correlativo: this.usuarioForm.value.correlativo,
      iv_username: this.usuarioForm.value.userName,
      iv_password: this.usuarioForm.value.pass,
      iv_fecha_inicio_vigencia: this.usuarioForm.value.fechaIni,
      iv_fecha_termino_vigencia: this.usuarioForm.value.fechaFin,
      in_usua_correlativo_creacion: '',
      iv_vigencia: this.usuarioForm.value.vigente === true ? 'S' : 'N',
      iv_tipo: 'ACTUALIZA',
      iv_nombres: this.usuarioForm.value.nombres,
      iv_paterno: this.usuarioForm.value.paterno,
      iv_materno: this.usuarioForm.value.materno,
      iv_isEmsa: this.usuarioForm.value.isEmsa === true ? 'S' : 'N',
      iv_rut: this.usuarioForm.value.rut,
      iv_telefono: this.usuarioForm.value.telefono,
      iv_jefe_directo: this.usuarioForm.value.jefe === null ? 0 : this.usuarioForm.value.jefe,
      iv_direccion: this.usuarioForm.value.direccion === null ? '' : this.usuarioForm.value.direccion,
      iv_fechaNacimiento: this.usuarioForm.value.fechaNacimiento === null ? '' : this.usuarioForm.value.fechaNacimiento
    };
    postData.iv_password == null ? postData.iv_password = '': postData.iv_password = postData.iv_password;

    if (this.usuarioForm.value.isEmsa) {
      if (this.usuarioForm.value.userName.includes('@em.codelco.cl')) {
        if (this.usuarioForm.value.jefe !== null) {
          if (this.calcularEdad(this.usuarioForm.value.fechaNacimiento) > 18) {
            this.parametrosService.postUsuario(postData).subscribe(resp => {
              const restp = JSON.parse(resp);
              this.idUser = restp[0]?.USUA_CORRELATIVO;
              this.guardarCargos();
              this.getUsuarios();
              this.resetForm();
              this.isSubmitted = false;
              this.notifyService.showSuccess('Usuario Modificado con exito.', 'Actualización usuario');
        
              this.visible = false;
              this.listMenus = false;
            }, err => {
              this.isSubmitted = false;
              this.notifyService.showError('Error al modificar usuario', 'Actualización usuario');
            });
          } else {
            this.isSubmitted = false;
            this.notifyService.showWarning('El usuario debe ser mayor de edad.', 'Actualización usuario');
          }
        } else {
          this.isSubmitted = false;
          this.notifyService.showWarning('Debe asignar un Jefe directo.', 'Actualización usuario');
        }
      } else {
        this.isSubmitted = false;
        this.notifyService.showWarning('Debe ingresar un correo EMSA Valido.', 'Actualización usuario');
      }
    } else {
      this.parametrosService.postUsuario(postData).subscribe(resp => {
        const restp = JSON.parse(resp);
        this.idUser = restp[0]?.USUA_CORRELATIVO;
        this.guardarCargos();
        this.getUsuarios();
        this.resetForm();
        this.isSubmitted = false;
        this.notifyService.showSuccess('Usuario Actualizado con exito.', 'Actualización usuario');
  
        this.visible = false;
        this.listMenus = false;
      }, err => {
        this.isSubmitted = false;
        this.notifyService.showError('Error al Actualizar usuario', 'Actualización usuario');
      });
    }
  }

  private crearUsuario() {
    const postData: UsuarioData = {
      in_usua_correlativo: 0,
      iv_username: this.usuarioForm.value.userName,
      iv_password: this.usuarioForm.value.pass,
      iv_fecha_inicio_vigencia: this.usuarioForm.value.fechaIni,
      iv_fecha_termino_vigencia: this.usuarioForm.value.fechaFin,
      in_usua_correlativo_creacion: '',
      iv_vigencia: this.usuarioForm.value.vigente === true ? 'S' : 'N',
      iv_tipo: 'INSERTA',
      iv_nombres: this.usuarioForm.value.nombres,
      iv_paterno: this.usuarioForm.value.paterno,
      iv_materno: this.usuarioForm.value.materno,
      iv_isEmsa: this.usuarioForm.value.isEmsa === true ? 'S' : 'N',
      iv_rut: this.usuarioForm.value.rut,
      iv_telefono: this.usuarioForm.value.telefono,
      iv_jefe_directo: this.usuarioForm.value.jefe === null ? 0 : this.usuarioForm.value.jefe,
      iv_direccion: this.usuarioForm.value.direccion === null ? '' : this.usuarioForm.value.direccion,
      iv_fechaNacimiento: this.usuarioForm.value.fechaNacimiento === null ? '' : this.usuarioForm.value.fechaNacimiento
    };

    if (this.usuarioForm.value.isEmsa) {
      if (this.usuarioForm.value.userName.includes('@em.codelco.cl')) {
        if (this.usuarioForm.value.jefe !== null) {

          if (this.calcularEdad(this.usuarioForm.value.fechaNacimiento) > 18) {
            this.parametrosService.postUsuario(postData).subscribe(resp => {
              const restp = JSON.parse(resp);
              this.idUser = restp[0]?.USUA_CORRELATIVO;
              this.guardarCargos();
              this.getUsuarios();
              this.resetForm();
              this.isSubmitted = false;
              this.notifyService.showSuccess('Usuario creado con exito.', 'Crear usuario');
        
              this.visible = false;
              this.listMenus = false;
            }, err => {
              this.isSubmitted = false;
              this.notifyService.showError('Error al crear usuario', 'Crear usuario');
            });
          }else{
            this.isSubmitted = false;
            this.notifyService.showWarning('El usuario debe ser mayor de edad.', 'Crear usuario');
          }
        } else {
          this.isSubmitted = false;
          this.notifyService.showWarning('Debe asignar un Jefe directo.', 'Crear usuario');
        }
      } else {
        this.isSubmitted = false;
        this.notifyService.showWarning('Debe ingresar un correo EMSA Valido.', 'Crear usuario');
      }
    } else {
      this.parametrosService.postUsuario(postData).subscribe(resp => {
        const restp = JSON.parse(resp);
        this.idUser = restp[0]?.USUA_CORRELATIVO;
        this.guardarCargos();
        this.getUsuarios();
        this.resetForm();
        this.isSubmitted = false;
        this.notifyService.showSuccess('Usuario creado con exito.', 'Crear usuario');
  
        this.visible = false;
        this.listMenus = false;
      }, err => {
        this.isSubmitted = false;
        this.notifyService.showError('Error al crear usuario', 'Crear usuario');
      });
    }
  }

  validateIsEmsa(){
    if (this.usuarioForm.value.isEmsa) {
      console.log('usuario emsa');
    } else {
      console.log('usuario no es emsa');
    }
  }

  onDelUser(id: number) {
    this.idUsuarioSelected = id;
  }

  onDel() {
    this.parametrosService.deleteUserId(this.idUsuarioSelected).subscribe(resp => {
      this.idUsuarioSelected = 0;
      this.getUsuarios();
      this.resetForm();
      this.notifyService.showWarning('Usuario inhabilitado con exito.', 'Inabilitar usuario');
    }, err => {
      this.idUsuarioSelected = 0;
      this.notifyService.showError('Error al inhabilitado usuario', 'Inabilitar usuario');
    });
  }

  getCargos() {
    this.parametrosService.getCargos().subscribe(resp => {
      this.cargos = JSON.parse(resp);
      //console.log(this.cargos);
      //this.createPaginator(this.paginatorCargo, this.cargos);
      //this.cargosInit = JSON.parse(resp);
    });
  }
  getUsuariosCargos(idUsuario: number) {
    this.usuarioCargos = [];
    this.authService.getCargosPorUsuario(idUsuario).subscribe(resp => {
      this.usuarioCargos = JSON.parse(resp);

      //console.log("Cargos usuario");
      //console.log(this.usuarioCargos);
      //this.idCargoActual = JSON.parse(localStorage.getItem('userData')).rolId;
      //this.nombreCargoActual = JSON.parse(localStorage.getItem('userData')).rolName;
    }, error => {
      console.log(error)
    });
  }
  onCheck(id: number) {
    if (this.usuarioCargos) {
      //let acciones = this.accionesUsuario.split(' - ');
      /* if(this.cargos.includes(this.usuarioCargos))
        return true; */
      if (this.getCargoByFind(id) !== undefined)
        return true;
    }
    return false;
  }
  getCargoByFind(id: number) {
    return this.usuarioCargos.find(x => x.ROL_CORRELATIVO === id);
  }
  onCheckAccion(item: number, checked: boolean) {
    /* idCargo: number;
    idUsuario: number;
    idUsuarioCreacion: number;
    ROL_CORRELATIVO: any;
    check: boolean; */
    if (this.usuarioCargos.length === 0) {
      this.usuarioCargos.push({ idCargo: item, check: checked, idUsuario: this.idUser, idUsuarioCreacion: this.idUser, ROL_CORRELATIVO: item });
    } else {
      const index = this.usuarioCargos.findIndex(x => x.ROL_CORRELATIVO === item)//(this.opcionesSave, 'value',item); //
      if (index !== -1) {
        this.usuarioCargos[index].check = checked;
      } else {
        this.usuarioCargos.push({ idCargo: item, check: checked, idUsuario: this.idUser, idUsuarioCreacion: this.idUser, ROL_CORRELATIVO: item });
      }
    }
  }
  guardarCargos() {
    /*
  idCargo: number;
  idUsuario: number;
  idUsuarioCreacion: number;
  ROL_CORRELATIVO: any;
  check: boolean;
    */
    if (this.usuarioCargos) {
      this.usuarioCargos.forEach(element => {
        let activo = (element.check) ? 'S' : 'N'
        if (element.check == undefined) activo = 'S';
        const postData: {
          pin_cargo_correlativo: number,
          pin_usua_correlativo: number,
          pin_usua_crea: number,
          piv_activo: string
        } = {
          pin_cargo_correlativo: element.ROL_CORRELATIVO,
          pin_usua_correlativo: this.idUser,
          pin_usua_crea: 0,
          piv_activo: activo
        };

        this.parametrosService.postCargosUsuarios(postData).subscribe(resp => {
          const data = JSON.parse(resp);
          if (data) {
            if (data[0].ACC_USUA_MENU_CORRELATIVO === 0) {
              //this.notifyService.showError('La accion ya existe en la tabla', 'Agregar accion');
            } else {
              //this.getMenuAccionesGeneralUsuario(idMenu);
              this.notifyService.showSuccess('Cargo agregado con exito', 'Agregar cargo');
            }
            this.opcionesSave = [];
          }
        });
      });
    }
  }

  passwordValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((this.idUser != 0 && control?.value === '') || (this.idUser != 0 && control?.value === null)) return null;

      const value = control.value;
      let valid = true;
      let message = "";

      const validation = passwordValidation(value);
      valid = validation.valid;
      message = validation.message;

      return !valid ? { message } : null;
    };
  }

  isInvalid(input: string) {
    return isInvalidControl(input, this.usuarioForm);
  }
}
