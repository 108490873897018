import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { FileService } from 'src/app/shared/services/file.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationService } from '../services/notification.service';
const FileSaver = require('file-saver');
@Component({
  selector: 'view-file-button',
  templateUrl: './view-file-button.component.html',
  styleUrls: ['./view-file-button.component.css'],
  providers: [FileService]
})
export class ViewFileButtonsComponent implements OnInit {
  urlVisualizador:string;
  modalRef: BsModalRef;
  @Input() nombreArchivo: string;
  @Input() tipoArchivo: string;

  constructor(
    private notifyService : NotificationService, 
    private fileService: FileService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {}

  onDescargar(){    
    this.fileService.downloadFile(this.nombreArchivo).subscribe(resp => {
      FileSaver.saveAs(resp, this.nombreArchivo);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
		}, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    }); 
  }

  onVisualizarOld(template: TemplateRef<any>){
    const urlDocumento = this.fileService.getUrlFile(this.nombreArchivo, this.tipoArchivo);
    this.urlVisualizador = !urlDocumento.includes('xlsx') 
      ? `https://docs.google.com/gview?url=${urlDocumento}&embedded=true#:0.page.20`
      : `https://view.officeapps.live.com/op/embed.aspx?src=${urlDocumento}`;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' }); 
  }

  async onVisualizar(template: TemplateRef<any>){
    this.fileService.generateExternalToken({fileName: this.nombreArchivo, fileType: this.tipoArchivo}).subscribe(async resp => {
      let response = JSON.parse(JSON.stringify(resp));
      if(response.hasOwnProperty('token')){
        const urlDocumento = this.fileService.getExternalUrl(response.token);
        this.urlVisualizador = !this.nombreArchivo.includes('xlsx') 
          ? `https://docs.google.com/gview?url=${urlDocumento}&embedded=true#:0.page.20`
          : `https://view.officeapps.live.com/op/embed.aspx?src=${urlDocumento}`;
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' }); 
      }

    });
  }

  onClose(){
    this.modalRef.hide(); 
  }
}

