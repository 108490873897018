import { Component, OnInit } from '@angular/core';
import { FileService, iFile } from '../shared/services/file.service';
import { NotificationService } from '../shared/services/notification.service';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-help-files',
  templateUrl: './help-files.component.html',
  styleUrls: ['./help-files.component.css'],
  providers: [FileService]
})
export class HelpFilesComponent implements OnInit {
  aFiles: iFile[]
  constructor(private fileService: FileService, private notifyService: NotificationService) { 
    this.aFiles = [];
    fileService.getFileOnDirGeneral().subscribe(res =>{
      this.aFiles = res
    })
  }

  ngOnInit(): void {
  }

  onDescargar(fileName: string){

    if(fileName) {
      this.fileService.getFile({fileName: fileName, fileType: 'general'}).subscribe(resp => {
        FileSaver.saveAs(resp, fileName);
        this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
      }, err =>{
        this.notifyService.showError(err.error.message, 'Descargar archivos');
      });
    } else {
      this.notifyService.showInfo('Boleta de garantia no tiene archivos adjuntos', 'Descargar archivos');
    }
  }

  formatSizeUnits(bytes, decimals = 2){
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

}
