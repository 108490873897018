<div id="content" class="content">
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6  SISTEMAS-->
        <div class="col-xl-12 ui-sortable">
            <!-- begin panel -->

            <!-- end panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                <!-- begin panel-heading -->
                <div class="panel-heading ui-sortable-handle">
                    <h4 class="panel-title normalText">Funcionalidad</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="panel-body">
                    <div class="row">
                        <div class="col col-md-12">
                            <form [formGroup]="rolesForm" novalidate (ngSubmit)="onCrear()">
                                <div class="form-group row m-b-15">
                                    <label class="col-form-label col-md-3">Sistema</label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="sistema" #sistema (change)="onBuscarModulo(sistema.value)">
                                            <option value="0">Seleccione ...</option>
                                            <option *ngFor="let item of sistemas" [value]="item.CORRELATIVO">{{item.DESCRIPCION}}</option>
										</select>
                                    </div>
                                </div>
                                <div class="form-group row m-b-15">
                                    <label class="col-form-label col-md-3">Modulo</label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="modulo" #modulo (change)="onBuscarOpcion(modulo.value)">
                                            <option value="0">Seleccione ...</option>
											<option *ngFor="let item of modulos" [value]="item.MODU_CORRELATIVO">{{item.MODU_NOMBRE}}</option>
										</select>
                                    </div>
                                </div>
                                <div class="form-group row m-b-15">
                                    <label class="col-form-label col-md-3">Opción</label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="opciones">
                                            <option value="0">Seleccione ...</option>
											<option *ngFor="let item of opciones" [value]="item.OP_CORRELATIVO">{{item.OP_NOMBRE}} </option>
										</select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-md-12" style="text-align: center;">
                                        <button class="btn btn-success"><i class="fas fa-lg fa-fw m-r-10 fa-save"></i>Guardar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col col-md-12">
                            <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterRol($event) " placeholder="Buscar ... " #input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12">
                            <table mat-table [dataSource]="rolesGrid" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">

                                <ng-container matColumnDef="Eliminar" style="display: none;">
                                    <th class=" normalText" mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element " style="text-align: center;">
                                        <a style="cursor: pointer;" (click)="onDel(element.MENU_CORRELATIVO)"><i class="fas fa-lg fa-fw m-r-10 fa-trash-alt" style="color: red;"></i></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Correlativo">
                                    <th style="display: none;" class=" normalText" mat-header-cell *matHeaderCellDef>#</th>
                                    <td style="display: none;" mat-cell *matCellDef="let element "> {{element.MENU_CORRELATIVO}} </td>
                                </ng-container>
                                <ng-container matColumnDef="RolDescripcion">
                                    <th class=" normalText" style="padding: 10px !important; display: none;" mat-header-cell *matHeaderCellDef>Rol</th>
                                    <td style="padding: 10px !important;  display: none;" mat-cell *matCellDef="let element "> {{element.ROL_DESCRIPCION}}</td>
                                </ng-container>
                                <ng-container matColumnDef="SistemaDescripcion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Sistema</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.SIST_DESCRIPCION}}</td>
                                </ng-container>
                                <ng-container matColumnDef="ModuloDescripcion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Modulo</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.MODULO_DESCRIPCION}}</td>
                                </ng-container>
                                <ng-container matColumnDef="OpcionDescripcion">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Opcion</th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.OPCION_DESCRIPCION}}</td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="dcRoles"></tr>
                                <tr mat-row *matRowDef="let row; columns: dcRoles;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                            </table>
                            <mat-paginator #paginatorRol [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                </div>
                <!-- end panel-body -->
            </div>
        </div>
        <!-- end col-6 -->
    </div>
</div>