import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { GlobalDataService } from 'src/app/shared/services/global-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router, private notifyService: NotificationService, private globalDataService: GlobalDataService) { }

  ngOnInit(): void {
    this.globalDataService.loadIp();
  }
  onLogin(postLogin: any) {
    this.authService.signin(postLogin).subscribe(resp => {
      this.router.navigate(['/home']);
    }, err => {
      const message = err?.error?.message || 'Error al iniciar sesion';
      this.notifyService.showError(message, 'Inicio sesion');
    });
  }

  goToForgotPassword() {
    this.router.navigate(['/auth/forgot-password']);
  }
}
