import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface IHttpService {
  url: string;
  data?: any;
}

@Injectable({ providedIn: 'root' })
export class HttpService {

  constructor(private http: HttpClient) { }

  private getUrl(url: string): string {
    return environment.apiUrl + url;
  }

  private getToken(): string {
    return JSON.parse(localStorage.getItem('userData'))?._token;
  }

  private getHeaders() {
    const token = this.getToken();
    const headers = token && {
      'Authorization': 'Bearer ' + token
    }
    return headers;
  }

  get({ url }: IHttpService) {
    return this.http.get(
      this.getUrl(url), {
      headers: this.getHeaders(),
    })
      .pipe(
        tap(resData => {
          return resData;
        }));
  }

  post({ url, data }: IHttpService) {
    return this.http.post(
      this.getUrl(url), data, {
      headers: this.getHeaders(),
    })
      .pipe(
        tap(resData => {
          return resData;
        }));
  }

  put({ url, data }: IHttpService) {
    return this.http.put(
      this.getUrl(url), data, {
      headers: this.getHeaders(),
    })
      .pipe(
        tap(resData => {
          return resData;
        }));
  }
}