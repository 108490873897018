<div id="content" class="content">
  <div class="row">
    <div class="col-md-12 ui-sortable">
      <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <!-- <div class="panel-heading ui-sortable-handle">
          <h4 class="panel-title normalText">Asignar acciones</h4>
          <div class="panel-heading-btn">
            <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
          </div>
        </div> -->
        <div class="p3-rem rounded bg-white">
          <div class="row">
            <div class="col-12">
              <h4 class="panel-title normalText">Asignar acciones</h4>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-5">
              <div class="row">
                <label for="usuario" class="col-md-3">Usuario</label>
                <div class="col-md-8">
                  <!-- <ng-select2 [data]="usuarios" width="100%" class="select2-selection select2-selection--single" #usuario (valueChanged)="onChangeUser($event)">
                  </ng-select2> -->
                  <ng-select2 [data]="usuarios" width="100%" class="select2-selection select2-selection--single" #usuario (valueChanged)="onChangeUser($event)">
                  </ng-select2>
                </div>
              </div>
              <br/>
              <div class="row">
                <label for="accion" class="col-md-3">Funcionalidad</label>
                <div class="col-md-8">
                  <select class="form-control" #accion (change)="onViewAcciones(accion.value)">
                    <option  value="0">Seleccione ...</option>
                    <option *ngFor="let item of menus" value="{{ item.MENU_USUA_CORRELATIVO}}">{{ item.SIST_NOMBRE}} - {{ item.MODU_NOMBRE}}</option>
                  </select>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-3">
                  <ng-container>
                    <button class="btn btn-primary btn-block" type="button" (click)="onAddAccion(accion.value)"> <i class="fas fa-lg fa-fw m-r-10 fa-save" ></i> Guardar</button>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="col-md-7">
              <div class="row">
                  <div class="col-3" *ngFor="let item of acciones">
                    <input type="checkbox" #check id="inlineCssCheckbox{{item.correlativo}}" [checked]="onCheck(item.nombre)" (click)="onCheckAccion(item.correlativo,check.checked )" />&nbsp;
                    <label for="inlineCssCheckbox{{item.correlativo}}"> {{item.nombre}}</label>
                  </div>

              </div>
            </div>
          </div>

          <hr>
          <div class="row">
            <div class="col-12 col-md-8"></div>
            <div class="col-12 col-md-4">
              <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterAccionUsuarioGrl($event) " placeholder="Buscar ... " #input>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <table mat-table [dataSource]="menuAccionesGrl" matSort (matSortChange)="sortData($event)" 
              class="table table-striped border table-td-valign-middle table-th-valign-middle ">
                <ng-container matColumnDef="Correlativo">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef style="text-align: center;" mat-sort-header="Correlativo">#</th>
                  <td mat-cell *matCellDef="let element " style="padding-right: 10px; text-align: center;"> {{element.MENU_CORRELATIVO}} </td>
                </ng-container>
                <ng-container matColumnDef="Sistema">
                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Sistema">FUNCIONALIDAD</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.SIST_MODULO_OPC}} </td>
                </ng-container>
                <ng-container matColumnDef="Acciones">
                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Acciones</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.ACCIONES}} </td>
                </ng-container>
                <ng-container matColumnDef="Opciones">
                  <th class=" normalText" style="padding: 10px !important; width: 10%;  text-align: center;" mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element " style="text-align: center;">
                    <!--<a style="cursor: pointer;" (click)="onDelAccionUsua(element.ADUM_CORRELATIVO)">-->
                    <!-- <i class="fas fa-lg fa-fw m-r-10 fa-trash-alt" style="color: #FF5B57;" title="Eliminar"></i> -->
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dcAccionesGrl"></tr>
                <tr style="cursor: pointer;" (click)="openModal(template)" mat-row *matRowDef="let row; columns: dcAccionesGrl;" id="row" style="cursor: pointer;" (click)="selectionAcciones.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selectionAcciones.isSelected(row)}"
                                    (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row " *matNoDataRow>
                  <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                </tr>
              </table>
              <mat-paginator #paginatorAccionesGrl [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <!-- end row -->
  <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Ver acciones</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col col-md-12">
          <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterAccion($event) " placeholder="Buscar ... " #input>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-12">
          <table mat-table [dataSource]="listAcciones" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
            <ng-container matColumnDef="Correlativo">
              <th class=" normalText" mat-header-cell *matHeaderCellDef style="text-align: center; font-size: small;">#</th>
              <td mat-cell *matCellDef="let element " style="padding-right: 10px; text-align: center; font-size: small;"> {{element.ADUM_CORRELATIVO}} </td>
            </ng-container>
            <ng-container matColumnDef="Menu">
              <th class=" normalText" style="padding: 10px !important; font-size: small;" mat-header-cell *matHeaderCellDef>Funcionalidad</th>
              <td style="padding: 10px !important;font-size: small;" mat-cell *matCellDef="let element "> {{element.SIST_NOMBRE}} - {{element.MODU_NOMBRE}} -{{element.OP_NOMBRE}} -{{element.ACC_NOMBRE}} <i class="fas fa-lg fa-fw m-r-10 fa-{{element.ACC_ICONO}}"></i> </td>
            </ng-container>
            <ng-container matColumnDef="Opciones">
              <th class=" normalText" style="padding: 10px !important; width: 10%;  text-align: center; font-size: small;" mat-header-cell *matHeaderCellDef>Opciones</th>
              <td mat-cell *matCellDef="let element " style="text-align: center;">
                <a style="cursor: pointer;" (click)="onDelAccionUsua(element.ADUM_CORRELATIVO)">
                  <i class="fas fa-lg fa-fw m-r-10 fa-trash-alt" style="color: red;"></i>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dcAcciones"></tr>
            <tr mat-row *matRowDef="let row; columns: dcAcciones;" id="row" style="cursor: pointer;" (click)="selectionAccionPopUp.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selectionAccionPopUp.isSelected(row)}" (mouseover)="row.hovered = true"
                            (mouseout)="row.hovered = false"></tr>
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row " *matNoDataRow>
              <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </ng-template>
</div>
