<div id="content" class="content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="bg-white p3-rem rounded">
            <div class="row">
                <div class="col-8">
                    <h4>Estados de Pago</h4>
                </div>
                <div class="col-4 text-right align-self-end">
                    <app-view-excel-buttons
                        *ngIf="estadosPagos && estadosPagos.length > 0"
                        [props]="excelButtonEstadoPago"
                        variant="tag"
                    >
                    </app-view-excel-buttons>
                    <button *ngIf="rolId == 3" [routerLink]="['/nuevo-ep', corrContrato]" class="btn btn-primary ml-2" 
                    [disabled]="((!existeContrato) || tieneEpPendiente() > 0) || validateDocGarantiaContrato == false" >Agregar</button>
                    

                </div>
                <div class="col-12" *ngIf="rolId == 3">
                    <!-- alert alert-yellow -->
                    <p class="alert alert-yellow p-10 mt-4" *ngIf="tieneEpPendiente() > 0">
                        <i class="fas fa-exclamation mr-2 ml-2"></i>  
                        No puede registrar un estado de pago hasta completar el actual;
                        para ver su progreso diríjase a la 
                        <a class="cursor" href="javascript:;"
                        routerLink="/detalle-contrato/{{corrContrato}}/0" title="Ver Detalle"
                        >ficha del contrato</a>
                    </p>
                    <!-- <p class="tab-yellow">No puede registrar otro estado de pago hasta completar el actual {{tieneEpPendiente()}}</p> -->
                </div>
                <div class="col-12" *ngIf="rolId == 3">
                    <p *ngIf="validateDocGarantiaContrato == false" class="alert alert-yellow p-10 mt-4">
                        <i class="fas fa-exclamation mr-2 ml-2"></i>  
                        Estimado usuario, el documento de garantía del contrato no es válido, por favor comuniquese con su Administrador de Contratos para más información. No podrá ingresar un nuevo estado pago hasta solucionar esta situación.
                    </p>
                </div>
            </div>
            <div class="row mb-4 mt-4">
                <div class="col-12" *ngIf="rolId != 20 && rolId != 17 && rolId != 22 && rolId != 13 && rolId != 10"><label for="contrato" class="col-form-label mb-0">Contrato</label></div>
                <div class="col-12 col-md-8">
                    <div class="row" *ngIf="rolId != 20 && rolId != 17 && rolId != 22 && rolId != 13 && rolId != 10">
                            <div class="col-8">
                                <ng-select2 [disabled]="mostrarTodos"
                                [data]="contratos" #contrato class="select2-selection select2-selection--single" width="100%" (valueChanged)="onChangeCto($event)">
                                </ng-select2>
                            </div>
                            <div class="col-4">
                                <div class="form-check mt-1">
                                    <input class="form-check-input" type="checkbox" #check id="inlineCss" (click)="onMostrarTodos()">
                                    <label class="form-check-label mt-1" for="inlineCss">
                                        Mostrar todos
                                    </label>
                                </div>
                            </div>

                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <input class="form-control" [(ngModel)]="filterEp" name="filterEp" id="filterEp" placeholder="Buscar ... ">
                </div>
            </div>
            <table class="table border">
                <thead style="font-size: 12px;">
                    <th class="text-normal">Contrato</th>
                    <th class="text-normal">Descripción</th>
                    <th class="text-normal">Proveedor</th>
                    <th class="text-normal">RUT</th>
                    <th class="text-normal">Monto</th>
                    <th class="text-normal">Estado</th>
                    <th class="text-normal">HES</th>
                    <th class="text-normal">Transcurridos</th>
                    <th class="text-center text-normal"></th>
                </thead>
                <tbody>
                    <tr style="font-size: 14px;" *ngFor="let item of estadosPagos | tableFilterEp: filterEp">
                        <td>{{ item.CONTRATO }}</td>
                        <td>{{ item.EP_DESCRIPCION }}</td>
                        <td>{{ item.PROVEEDOR }}</td>
                        <td style="width: 140px;">{{ item.RUT_PROVEEDOR_DIGITO }}</td>
                        <td>{{ item?.Simbol }} {{ item.MONTO }}</td>
                        <td style="width: 270px;">
                            <span class="mb-0 tab tab-green">
                                {{ item.EP_ESTADO }}
                            </span>
                        </td>
                        <td>
                            {{item.EP_HES}}
                        </td>
                        <td>
                            {{item.DIAS_TRANSCURRIDOS}}
                        </td>
                        <td class="text-center">
                            <a class="btn rounded-icon" [routerLink]="['/estado-pago-checklist', item.EP_CORRELATIVO]" title="Ver Detalle">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>