import { Component, OnInit, Input } from '@angular/core';

export interface ActionButtonProps {
  title: string;
  action: () => void;
  icon?: string;
}

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css']
})
export class ActionButtonComponent implements OnInit {
  @Input() props: ActionButtonProps;
  @Input() variant = 'primary';
  constructor() { }

  ngOnInit(): void { }

}
