import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { NavTab } from './components/nav-tab/nav-tab.component';

@Component({
  selector: 'app-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.css']
})
export class NavTabsComponent implements OnInit {
  constructor() { }
  @Input() tabs: NavTab[];
  @Input() selectedTab: string;
  @Output() changeTab = new EventEmitter<string>();

  ngOnInit(): void { }

  onChangeTab(id: string) {
    this.changeTab.emit(id);
  }

}
