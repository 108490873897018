<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12 ui-sortable">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                <div class="panel-heading ui-sortable-handle">
                    <h4 class="panel-title normalText">Periodos</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
  
                <div class="panel-body">
                    <h3>Periodos Año: 2021</h3>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table">
                                <thead class="thead-dark">
                                    <th>#</th>
                                    <th>Mes</th>
                                    <th>Año</th>
                                    <th>Día Aprobación EMSA</th>
                                    <th>Día Envio ADC</th>
                                    <th>Día HES</th>
                                    <th class="text-right">Acción</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of periodos">
                                        <td>{{ item.MES }}</td>              
                                        <td>{{ item.PERI_DESCRIPCION }}</td>     
                                        <td>{{ item.ANIO }}</td>     
                                        <td>{{ item.DIA_APROB_EMSA_NOMBRE }} {{ item.DIA_APROB_EMSA }}</td>     
                                        <td>{{ item.DIA_ENVIO_ADC_NOMBRE }} {{ item.DIA_ENVIO_ADC }}</td>          
                                        <td>{{ item.DIA_HES_NOMBRE }} {{ item.DIA_HES }}</td>       
                                        <td class="text-right">
                                            <button style="color:white" href="#modal-fechas" data-toggle="modal" (click)="cargarModal(item.PERI_CORRELATIVO, item.MES)" class="btn btn-info btn-sm" [disabled]="item.DIA_APROB_EMSA_NOMBRE">
                                                Asignar Fechas
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>                  
                  

<!-- Modal Fechas -->
<div class="modal fade" id="modal-fechas">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Asignar Fechas de Periodo</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formFechas" (ngSubmit)="onAsignarFechas()" novalidate>
                    <div class="row form-group">
                        <label for="dia_aprobacion" class="col-md-2 col-form-label">Día Aprobación EMSA</label>
                        <div class="col-md-2">
                            <input type="number" name="dia_aprobacion" formControlName="dia_aprobacion" #dia_aprobacion class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dia_aprobacion.errors}" />
                        </div>
                        <label for="dia_envio" class="col-md-2 col-form-label">Día Envio ADC</label>
                        <div class="col-md-2">
                            <input type="number" name="dia_envio" formControlName="dia_envio" #dia_envio class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dia_envio.errors}"/>
                        </div>
                        <label for="dia_hes" class="col-md-2 col-form-label">Día HES</label>
                        <div class="col-md-2">
                            <input type="number" name="dia_hes" formControlName="dia_hes" #dia_hes class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dia_hes.errors}"/>
                        </div>
                    </div>
                    <input name="peri_correlativo" formControlName="peri_correlativo" type="hidden"/>
                    <br>
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-2"><a href="javascript:;" #closeModal (click)="onClose()" class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                        <div class="col-md-4">
                            <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>