import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { GlobalDataService, ICurrencies } from 'src/app/shared/services/global-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-crear-itemizado',
  templateUrl: './crear-itemizado.component.html',
  styleUrls: ['./crear-itemizado.component.css']
})
export class CrearItemizadoComponent implements OnInit {

  contrato = { NOMBRE_CONTRATO: '', PROVEEDOR: '', TIPO_MONEDA: '', MONTO_REAJUSTE: 0, SALDO_CONTRATO: 0, TIMO_CORRELATIVO: 0, MONTO_CONTRATO: 0, SOLPE_CORRELATIVO: 0 };
  corrContrato: number;
  corrSolpe: number;
  formItemizado: FormGroup;
  submitted = false;
  fileName :string;
  arrayBuffer:any;
  file:File;
  data: any[] = [];
  totalMontoItemizado: number = 0;
  itemizadoExistente: any[] = [];
  isItemizadoExiste: boolean = false;
  solpe: any[] = [];
  montoModificacionSolpe: number = 0;
  itemizado: any[] = [];
  public contractCurrenci: ICurrencies;

  constructor(
    private contratosService: ContratoService, 
    private notifyService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private contratoService: ContratoService,
    private estadoPagoService: EstadoPagoService,
    public GloblaDataService: GlobalDataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.corrContrato = params['corr_contrato'];
      this.corrSolpe = params['corr_solpe'];
    });

    this.getContrato();
    this.getItemizado();
    this.resetForm();    
  }

  getItemizado() {
    /* this.contratoService.getFootItemizado(corrContrato).subscribe(resp=>{
      this.Footitemizados = JSON.parse(resp);
    }); */
    this.estadoPagoService.getItemizadoDetalle(this.corrContrato).subscribe(resp => {
      let response = JSON.parse(resp);

      if (response.length > 0) {
        let idx = 0;
        let cabecera = null;
        this.itemizado = [];
        // creo estructura del itemizado para mostrar de forma ordenada
        for(let i in response) {        
          // si es el primer registro o cambia la cabecera del itemizado: registro cabecera y primer subitem
          if(idx == 0 || response[i]['ITEM_CABECERA'] !== cabecera) {
            // cabecera
            this.itemizado[idx] = {
              Pos: response[i]['CABECERA_POSICION'],
              Cabecera: response[i]['ITEM_CABECERA'],
              Subpos: null,
              Descripcion: null,
              Unidad: null,
              Cantidad: null,
              PrecioUnitario: null,
              Correlativo: response[i]['ID_CORRELATIVO'],
              R: null,
              Scpq: null,
              Consumo: null
            };
            // primer subitem
            idx++;
            this.itemizado[idx] = {
              Pos: null,
              Cabecera: null,
              Subpos: response[i]['SUBPOSICION'],
              Descripcion: response[i]['DESCRIPCION'],
              Unidad: response[i]['UNIDAD'],
              Cantidad: response[i]['CANTIDAD'],
              PrecioUnitario: response[i]['PRECIO_UNITARIO'],
              Correlativo: response[i]['ID_CORRELATIVO'],
              R: response[i]['REAJUSTE'],
              Scpq: response[i]['SCPQ'],
              ConsumoQ: response[i]['AVANCE_Q'],
              Consumo: response[i]['AVANCE']
            };
            idx++;
          // de lo contrario sigo registrando subitems hasta que cambie el item principal
          } else {
            this.itemizado[idx] = {
              Pos: null,
              Cabecera: null,
              Subpos: response[i]['SUBPOSICION'],
              Descripcion: response[i]['DESCRIPCION'],
              Unidad: response[i]['UNIDAD'],
              Cantidad: response[i]['CANTIDAD'],
              PrecioUnitario: response[i]['PRECIO_UNITARIO'],
              Correlativo: response[i]['ID_CORRELATIVO'],
              R: response[i]['REAJUSTE'],
              Scpq: response[i]['SCPQ'],
              ConsumoQ: response[i]['AVANCE_Q'],
              Consumo: response[i]['AVANCE']
            };
            idx++;
          } 
          cabecera = response[i]['ITEM_CABECERA'];
        }
      }
    }); 
  }

  private getContrato() {
    this.contratoService.getContratoId(this.corrContrato).subscribe(resp => {
      this.contrato = JSON.parse(resp)[0];

      this.contractCurrenci = this.GloblaDataService.CurrenciesList?.find(currencie => currencie.TimoCode == this.contrato.TIMO_CORRELATIVO);

      this.contratoService.postSolpeModificacionContratoPorCorrelativo({pin_solpe_correlativo: this.corrSolpe}).subscribe(resp => {
        this.solpe = JSON.parse(resp);
        for (let index = 0; index < this.solpe.length; index++) {
          const element = this.solpe[index];
          if (element.MONTO_MODIFICACION !== 0) {
            this.montoModificacionSolpe = element.MONTO_MODIFICACION;
          }
        } 
      }, error => console.log(error));
    }, error => console.log(error));
  }

  private resetForm() {
    this.formItemizado = new FormGroup({
      contrato: new FormControl(null, Validators.required),
      adjunto: new FormControl(null, Validators.required)
    });
    this.fileName = null;
    this.submitted = false;
  }

  get f() { return this.formItemizado.controls; }
  incomingfile(event) {
    this.file = event.target.files[0]; 
    this.fileName = this.file.name; 
  }

  cargarExcel() {
    let fileReader = new FileReader();
    let totalItemizado: number = 0;

    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type:"binary"});
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let sheetData = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      this.data = this.limpiarEspaciosItemizado(sheetData);
      this.data = this.validateDataExcel(this.data);

      console.log(this.itemizado);

      if (this.data.length > 0) {

        for (let index = 0; index < this.itemizado.length; index++) {
          const element = this.itemizado[index];
          
          if (element.Pos !== null) {
            const posCoincidente: any[] = this.data.filter(item => item.POS == element.Pos);

            if (posCoincidente.length > 0) {
              this.notifyService.showWarning('La posición ' + element.Pos + ' ya fue registrada en el item ' + element.Cabecera, 'Itemizado.');
              this.data = [];
              this.totalMontoItemizado = 0;
              return;
            }
          }
        }

        if(this.data.length > 0){
          for (let index = 0; index < this.data.length; index++) {
            const element = this.data[index];
            if (element.CANTIDAD && element.PRECIO) {
              let totalCorrida = (element.CANTIDAD * element.PRECIO);
              totalItemizado = totalItemizado + totalCorrida;
            }
          }
        }
  
        this.totalMontoItemizado = totalItemizado;
  
        if (this.totalMontoItemizado > this.montoModificacionSolpe) {
          this.notifyService.showWarning('El monto total del itemizado es mayor al monto de la SOLPE', 'Itemizado.');
        }
      }else{
        this.notifyService.showWarning('El itemizado no es valido por favor verifiquelo e intente nuevamente.', 'Itemizado.');
      }
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  limpiarEspaciosItemizado(datosItemizado: any[]){
    try {
      for (let index = 0; index < datosItemizado.length; index++) {
        const element = datosItemizado[index];
        if (element.POS) {
          element.SPOS = element.SPOS.trim();
        }else{
          element.ITEM = element.ITEM.trim();
          element.UNIDAD = element.UNIDAD.trim();
  
          if (element.SCPQ) {
            element.SCPQ = element.SCPQ.trim();
          }

          if (element.REAJUSTE) {
            element.REAJUSTE = element.REAJUSTE.trim();
          }
        }
      }
  
      return datosItemizado;

    } catch (error) {
      this.notifyService.showWarning('El Itemizado ingresado no es valido, por favor verifiquelo e intente nuevamente', 'Itemizado');
      return [];
    }
  }

  isNumber(num) {
		return !isNaN(parseFloat(num)) && isFinite(num);
	}

  validateDataExcel(data: any[]){
    let result: any[] = data;

    if(data.length > 0){
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        
        if (element.POS) {
          if (this.isNumber(element.POS) === false) {
            result = [];
            break;
          }
        }else{
          if (this.isNumber(element.SPOS) === false) {
            result = [];
            break;
          }

          if (this.isNumber(element.CANTIDAD) === false) {
            result = [];
            break;
          }

          if (this.isNumber(element.PRECIO) === false) {
            result = [];
            break;
          }
        }
      }
    }else{
      result = [];
    }

    return result;
  }

  validateAndSave() {    
    let postDataDetalle : {
      in_item_correlativo: number,
      in_subposicion: number,
      iv_descripcion: string,
      iv_unidad: string,
      in_cantidad: number,
      in_precio_unitario: number,
      in_total: number,
      in_reajuste: number,
      in_scpq: number
    };

    let item: number;

    for(let i = 0; i < this.data.length; i++) {      
      if(this.data[i]['POS']) {
        this.contratosService.crearCabeceraItemizado({in_subposicion: this.data[i]['POS'], iv_descripcion: this.data[i]['SPOS'], in_precio_unitario:this.data[i]['PRECIO']}).subscribe(resp => {
          item = JSON.parse(resp)[0].ITEM_CORRELATIVO;      
          this.notifyService.showSuccess('Cabecera de itemizado creado con exito.', 'Itemizado');
        }, error => console.log(error),
        () => {
          this.contratosService.relacionaContratoItemizadoMod({in_cont_correlativo: this.corrContrato, in_item_correlativo: item, in_usua_correlativo_creacion: 0, in_solpe_correaltivo: this.corrSolpe}).subscribe(resp => {});
          
          for(let j = i + 1; j < this.data.length; j++) {
            if(!this.data[j]['POS']) {
              postDataDetalle = {
                in_item_correlativo: item,
                in_subposicion: this.data[j]['SPOS'],
                iv_descripcion: this.data[j]['ITEM'],
                iv_unidad: this.data[j]['UNIDAD'],
                in_cantidad: this.data[j]['CANTIDAD'],
                in_precio_unitario: this.data[j]['PRECIO'],
                in_total: 0,
                in_reajuste: (this.data[j]['REAJUSTE'] == 'x' || this.data[j]['REAJUSTE'] == 'X') ? 1 : 0,
                in_scpq: (this.data[j]['SCPQ'] == 'x' || this.data[j]['SCPQ'] == 'X') ? 1 : 0
              };
              this.contratosService.registraDetalleItemizado(postDataDetalle).subscribe(resp => {
                this.notifyService.showSuccess('Detalle de itemizado creado con exito.', 'Detalle Itemizado');
              }, error => {
                console.log(error)
              });
            } else {
              break;
            }
          }          
        });   
      }       
    }

    this.contratosService.validaEtapaCorreoContrato({in_cont_correlativo: this.corrContrato, in_domi_correlativo_esdo_itemizado: 121}).subscribe(resp => {
      this.router.navigate(['/dashboard-itemizado-mod']);
    });       
  }  
}
 