import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { ParametrosService } from '../shared/services/parametros.service';
import { NotificationService } from '../shared/services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { Select2OptionData } from 'ng-select2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort } from '@angular/material/sort';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-acciones',
  templateUrl: './acciones.component.html',
  styleUrls: ['./acciones.component.css']
})
export class AccionesComponent implements OnInit  {
  usuarios:any;
  menus:any;
  acciones:any[]=[];
  accionesUsuario: any;
  opcionesSave:any[] = [];
  listAcciones:any;

  dcUsuarios: string[] = ['Correlativo','Usuario','Vigente', 'Fecha','Opciones'];
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  modalRef: BsModalRef;
  idUser:number;
  idMenu: number;

  //dcAcciones: string[] = ['Opciones','Correlativo','Menu'];
  dcAcciones: string[] = ['Correlativo','Menu','Opciones'];
  @ViewChild('paginatorAcciones', {static: true}) paginatorAcciones: MatPaginator;

  public selected: string;

  menuAccionesGrl:any;
  //dcAccionesGrl: string[] = ['Opciones','Correlativo','Sistema', 'Acciones'];
  dcAccionesGrl: string[] = ['Correlativo','Sistema', 'Acciones','Opciones'];
  @ViewChild('paginatorAccionesGrl', {static: false}) paginatorAccionesGrl: MatPaginator;


  selectionAcciones = new SelectionModel<any>(false, []);

  selectionAccionPopUp = new SelectionModel<any>(false, []);

  sortedData: any;
  menuAccionesInit: any;

  constructor(private parametrosService: ParametrosService, private notifyService: NotificationService,private modalService: BsModalService) { }

  ngOnInit(): void {
    this.getUsuarios();
  }

  onDelAccionUsua(id: number){
    this.parametrosService.deleteMenuAccionId(id).subscribe(resp=>{
      this.getAccionesUsuarioMenu(this.idUser,this.idMenu);
      this.getMenuAccionesGeneralUsuario(this.idMenu);
      this.notifyService.showWarning('Accion eliminada con exito', 'Eliminar accion');
    });
  }

  applyFilterUsuario(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.usuarios.filter = filterValue.trim().toLowerCase();
  }
  private getUsuarios(){
    this.parametrosService.getUsuarios().subscribe(resp=>{
      this.usuarios= JSON.parse(resp);
    });
  }

  openModal(template: TemplateRef<any>) {
    //this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
    //this.getAccionesUsuarioMenu(this.idUser, this.idMenu);

  }

  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }

  sortData(sort: Sort) {
    const data = this.menuAccionesInit.slice();
    if (!sort.active || sort.direction === '') {
      this.menuAccionesGrl =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorAccionesGrl, this.menuAccionesGrl);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Correlativo': return compare(a.MENU_CORRELATIVO, b.MENU_CORRELATIVO, isAsc);
        case 'Sistema': return compare(a.SIST_MODULO_OPC, b.SIST_MODULO_OPC, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.menuAccionesGrl =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorAccionesGrl, this.menuAccionesGrl);
    }
  }

  private getMenuAccionesGeneralUsuario(idU : number){
    this.parametrosService.getMenuAccionesUsuario(idU).subscribe(resp=>{
        this.menuAccionesGrl=new MatTableDataSource(JSON.parse(resp));
        this.createPaginator(this.paginatorAccionesGrl, this.menuAccionesGrl);
        this.menuAccionesInit = JSON.parse(resp);
        this.accionesUsuario = this.menuAccionesInit[0]['ACCIONES'];
    });
  }

  onChangeUser(idU : number){
    this.idUser = idU;
    if(idU){
      this.getMenuUsuario(this.idUser);
    }
  }
  private getMenuUsuario(idUsuario: number){
    this.parametrosService.getMenuUsuario(idUsuario).subscribe(resp=>{
      if(JSON.parse(resp).length > 0){
        this.notifyService.showInfo('Lista de menu del usuario obtenida correctamente.', 'Obtener menu usuario');
      }else{
        this.notifyService.showWarning('Usuario sin menus asociados', 'Obtener menu usuario');
        this.acciones = [];
        this.listAcciones = [];
      }
      this.menus = JSON.parse(resp);
    });
  }

  onAddAccion( idMenu:number){
    if(this.opcionesSave){
      this.opcionesSave.forEach(element => {
        let activo = (element.check) ? 'S' : 'N'
        const  postData : {
          in_mdu_correlativo: number,
          in_accion_correlativo: number ,
          in_usua_correlativo_creacion : number,
          iv_activo: string
        } = {
          in_mdu_correlativo: idMenu,
          in_accion_correlativo: element.value,
          in_usua_correlativo_creacion:0,
          iv_activo: activo
        };

        this.parametrosService.postRegistraAccionUsuario(postData).subscribe(resp=>{
          if (JSON.parse(resp)){
            if (JSON.parse(resp)[0].ACC_USUA_MENU_CORRELATIVO === 0 ){
              this.notifyService.showError('La accion ya existe en la tabla', 'Agregar accion');
            }else{
              this.getMenuAccionesGeneralUsuario(idMenu);
              this.notifyService.showSuccess('Accion agregada con exito', 'Agregar accion');
            }
            this.opcionesSave =[];
          }
        });
      });
    }
  }

  onViewAcciones(accion: number ){
    this.idMenu = accion;
    this.getAcciones();
    this.getMenuAccionesGeneralUsuario(accion);
  }

  private getAcciones(){
    this.parametrosService.getAcciones().subscribe(resp=>{
      this.acciones=JSON.parse(resp);
    });
  }

  private findIndexByProperty(data, key, value) {
    for (var i = 0; i < data.length; i++) {
        if (data[i][key] == value) {
            return i;
        }
    }
    return -1;
  }
  onCheckAccion(item: number, checked: boolean){
    if (this.opcionesSave.length === 0 ){
      this.opcionesSave.push({ value: item, check: checked});
    }else{
      const index = this.findIndexByProperty(this.opcionesSave, 'value',item);
      if (index > -1) {
        this.opcionesSave[index].check = checked;
      } else {
        this.opcionesSave.push({ value: item, check: checked});
      }
    }

  }

  applyFilterAccion(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listAcciones.filter = filterValue.trim().toLowerCase();
  }
  applyFilterAccionUsuarioGrl(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.menuAccionesGrl.filter = filterValue.trim().toLowerCase();
  }

  private getAccionesUsuarioMenu(id: number, accion: number){

    this.parametrosService.getAccionesUsuarioMenu(id, accion).subscribe(resp=>{
      this.listAcciones=new MatTableDataSource(JSON.parse(resp));
      if (this.listAcciones){
        /*this.paginatorAcciones._intl.itemsPerPageLabel = 'Registros por página';
        this.paginatorAcciones._intl.firstPageLabel = 'Primera pagina';
        this.paginatorAcciones._intl.lastPageLabel = 'Última pagina';
        this.paginatorAcciones._intl.nextPageLabel = 'Siguiente';
        this.paginatorAcciones._intl.previousPageLabel = 'Anterior';*/
        this.listAcciones.paginator = this.paginatorAcciones;
      }

    });
  }
  onCheck(accion: string) {
    if(this.accionesUsuario) {
      let acciones = this.accionesUsuario.split(' - ');
      if(acciones.includes(accion))
        return true;
    }
    return false;
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
