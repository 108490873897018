import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';

@Component({
  selector: 'app-all-ep-incurrido',
  templateUrl: './all-ep-incurrido.component.html',
  styleUrls: ['./all-ep-incurrido.component.css']
})
export class AllEpIncurridoComponent implements OnInit {

     /** Acciones permitidas para el usuario segun su rol */
     accionesPermitidas: string[] = []; 
     accionesPermitidasCorr: string[] = [];
     userId: number;
     rolId: number;
  
    corrContrato: number;
    estadosPagos: any[];
    contratos: any[];
    existeContrato = false;
    
  constructor(    
    private estadoPagoService: EstadoPagoService, 
    private authService: AuthService,
    private contratosService: ContratoService,) { 
      this.userId = JSON.parse(localStorage.getItem('userData')).userId;
      this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
      let corrMenu = 3;
      this.authService.getAccionesPorUsuario(corrMenu).subscribe(resp => {
        let acciones: any[] = JSON.parse(resp);
        if(acciones.length > 0) {
          this.accionesPermitidas = acciones[0].ACCIONES.split(' - ');
          this.accionesPermitidasCorr = acciones[0].ACCIONES_CORRELATIVO.split(' - ');
        }
      }, 
      error => {console.log(error)});
    }

  ngOnInit(): void {
    this.getContratos();
    if(this.rolId == 17 || this.rolId == 20 || this.rolId == 22 || this.rolId == 13 || this.rolId == 10) {
      this.getEstadosPago(0);
    }
  }

  private getContratos() {
    this.contratosService.getContratosBusquedaEP(this.userId, this.rolId).subscribe(resp=>{
      this.contratos= JSON.parse(resp);
    });
  }
  onChangeCto(corrContrato: any) {
    this.corrContrato = corrContrato;
    this.existeContrato = (this.corrContrato) ? true : false;
    if(this.existeContrato) {
      this.getEstadosPago(this.corrContrato);
    }
  }
  private getEstadosPago(con_correlativo: number) {
    this.estadoPagoService.getEstadosPagoIncurrido(con_correlativo, this.userId, this.rolId).subscribe(resp => {
      this.estadosPagos = JSON.parse(resp);
    });
  }

}
