import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';

@Component({
  selector: 'app-dashboard-itemizado-mod',
  templateUrl: './dashboard-itemizado-mod.component.html',
  styleUrls: ['./dashboard-itemizado-mod.component.css']
})
export class DashboardItemizadoModComponent implements OnInit {
  userId: number;
  rolId: number;
  solpesItemizado: any[];
  cantidadAprobados: number = 0;
  detalleItemizadosAprobados: any[];
  selectedFilter: number = 1;
  cantidadTotales: number = 0;

  public selected: string;
  selection = new SelectionModel<any>(false, []);
  dataSource:any;
  dataSourceHeader: string[] = ['NombreContrato', 'codigoSolpe', 'Solpe', 'EstadoItemizado', 'Acciones'];
  @ViewChild('paginatorGrl', {static: false}) paginatorGrl: MatPaginator;

  constructor(
    private estadoPagoService: EstadoPagoService,
    private router: Router
    ) { 
    this.userId = JSON.parse(localStorage.getItem('userData')).userId;
    this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
  }

  ngOnInit(): void {
    this.getSolpeModItemizado();
    this.getCantidadAprobados();
  }

  private getCantidadAprobados() {
    // this.estadoPagoService.getItemizadosAprobados('CANTIDAD-SOLPE').subscribe(resp => {
    //   this.cantidadAprobados = JSON.parse(resp)[0].CANTIDAD;
    // }, error => console.log(error));
    this.estadoPagoService.getItemizadosAprobados('DETALLE-SOLPE').subscribe(resp => {
      let aprobadas: any[] = JSON.parse(resp);
      this.cantidadAprobados = aprobadas.length;
    }, error => console.log(error));
  }
  itemizadosAprobados() {

    this.selectedFilter = 2;
    
    this.estadoPagoService.getItemizadosAprobados('DETALLE-SOLPE').subscribe(resp => {
      this.detalleItemizadosAprobados = JSON.parse(resp);
      this.dataSource = new MatTableDataSource(this.detalleItemizadosAprobados);
      this.createPaginator(this.paginatorGrl, this.dataSource);
    }, error => console.log(error));
  }

  getSolpeModItemizado() {

    this.selectedFilter = 1;
    
    this.estadoPagoService.getSolpeModItemizado(this.userId, this.rolId).subscribe(resp => {
      this.solpesItemizado = JSON.parse(resp);
      this.cantidadTotales = this.solpesItemizado.length;
      this.dataSource = new MatTableDataSource(this.solpesItemizado);
      this.createPaginator(this.paginatorGrl, this.dataSource);
    });
  }
  applyFilterGrl(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }
  irAEtapa(corr_contrato: number, corr_estado_itemizado: number, corr_solpe: number) {
    // Cargar itemizado
    if(corr_estado_itemizado == 118) {
      this.router.navigate(['/crear-itemizado', corr_contrato,corr_solpe]);  
    }
    // Cargar articulos
    if(corr_estado_itemizado == 121) {
      this.router.navigate(['/itemizado-detalle-mod', corr_contrato,corr_solpe]);  
    }
    // aprobar, rechazar itemizado Ó ver detalle
    if(corr_estado_itemizado == 122 || corr_estado_itemizado == 123) {
      this.router.navigate(['/itemizado-aprobacion-mod', corr_contrato, corr_estado_itemizado, corr_solpe]);
    }
  }
}
