import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SolpeService } from 'src/app/shared/services/solpe.service';

@Component({
  selector: 'app-itemizado-detalle-mod',
  templateUrl: './itemizado-detalle-mod.component.html',
  styleUrls: ['./itemizado-detalle-mod.component.css']
})
export class ItemizadoDetalleModComponent implements OnInit {
  corrContrato: number;
  corrSolpe: number;
  contrato = { NOMBRE_CONTRATO: '', PROVEEDOR: '', TIPO_MONEDA: '', MONTO_REAJUSTE: 0, SALDO_CONTRATO: 0, TIMO_CORRELATIVO: 0 };
  itemizado: any[] = [];
  articulos: any[];
  data_articulos: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private contratoService: ContratoService,
    private estadoPagoService: EstadoPagoService,
    private solpeService: SolpeService,
    private notifyService: NotificationService
  ) { 

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.corrContrato = params['corr_contrato'];
      this.corrSolpe = params['corr_solpe'];
    });

    this.getContrato();
    this.getItemizado();
    this.getArticulos();
  }

  private getContrato() {
    this.contratoService.getContratoId(this.corrContrato).subscribe(resp => {
      this.contrato = JSON.parse(resp)[0];
    }, error => console.log(error));
  }
  private getArticulos() {
    this.solpeService.getArticulos().subscribe(resp => {
      this.articulos = JSON.parse(resp)
    })
  }
  private getItemizado() {
    this.estadoPagoService.getItemizadoDetalleMod(this.corrContrato, this.corrSolpe).subscribe(resp => {
      let response = JSON.parse(resp);

      let idx = 0;
      let cabecera = null;
      // creo estructura del itemizado para mostrar de forma ordenada
      for(let i in response) {        
        // si es el primer registro o cambia la cabecera del itemizado: registro cabecera y primer subitem
        if(idx == 0 || response[i]['ITEM_CABECERA'] !== cabecera) {
          // cabecera
          this.itemizado[idx] = {
            Cabecera: response[i]['ITEM_CABECERA'],
            Subpos: null,
            Descripcion: null,
            Unidad: null,
            Cantidad: null,
            PrecioUnitario: null,
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: null,
            Scpq: null
          };
          // primer subitem
          idx++;
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ']
          };
          idx++;
        // de lo contrario sigo registrando subitems hasta que cambie el item principal
        } else {
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ']
          };
          idx++;
        } 
        cabecera = response[i]['ITEM_CABECERA'];
        //this.data_articulos[response[i]['ID_CORRELATIVO']] = 0;

        
      }
      let m = 0;
      for(let item of this.itemizado) {
        if(item.Cabecera == null)
        {
          this.data_articulos[m] = { articulo: 0, item: item.Correlativo };
          m++;
        }
        
      }
    }); 
  }

  public getItemByid(idItemizado)
  {
    let index = this.data_articulos.map(function(el)
    {
      return el.item
    }
    ).indexOf(idItemizado)

    return index;
  }

  /* onChangeAllArt(corrArticulo : number){
    for(let item of this.itemizado) {
      this.data_articulos[item.Correlativo] = corrArticulo;
    }
  } */

  onChangeAllArt(corrArticulo : number){
    //const index = this.usuarioCargos.findIndex(x => x.ROL_CORRELATIVO === item)
    if(corrArticulo)
    {
      let i = 0;
      for(let item of this.data_articulos) {
        this.data_articulos[i].articulo = corrArticulo;
        i++;
      }
      console.log(this.data_articulos);
    }
  }
  validarArticulosSeleccionados() {
    for(let item of this.itemizado) {
      if (this.data_articulos[item.Correlativo] == 0) 
        return false;
    }
    return true;
  }3001
  guardar() {
    
    if(!this.validarArticulosSeleccionados()) {
      this.notifyService.showInfo('Debe seleccionar todos los artículos antes de continuar', 'Detalle Itemizado');
      return;
    }

    this.data_articulos.forEach(articulo => {
      
    });

    for(let i = 0; i < this.data_articulos.length; i++) {
      //if(this.data_articulos[i]) {
        let postData : { 
          in_detalle_itemizado: number,
          in_articulo_correlativo: number,
          in_usua_correlativo_creacion: number,
          iv_origen: string
        } = {
          in_detalle_itemizado: this.data_articulos[i].item,
          in_articulo_correlativo: this.data_articulos[i].articulo,
          in_usua_correlativo_creacion: 0,
          iv_origen: "SOLPE"
        };

        this.estadoPagoService.postRegItemizadoArticulo(postData).subscribe(resp => {
          this.notifyService.showSuccess('Articulo relacionado con exito.', 'Detalle Itemizado');
        }, error => {
          console.log(error)
        });
      //}
    }


    this.contratoService.validaEtapaCorreoContrato({in_cont_correlativo: this.corrContrato, in_domi_correlativo_esdo_itemizado: 122}).subscribe(resp => {});
  }
}

