<div id="content" class="content">
  <div class="row">
      <div class="col-xl-12 ui-sortable">
          <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
              <div class="panel-heading ui-sortable-handle">
                  <h4 class="panel-title normalText">Modificaciones</h4>
                  <div class="panel-heading-btn">
                      <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                  </div>
              </div>

              <div class="panel-body">
                <h3 style="color: #666;">
                  Modificaciones y Docuimentos <br>
                  Contrato: {{ dataContrato.NOMBRE_CONTRATO }} / {{ dataContrato.MONTO_ORIGINAL }} {{ dataContrato.TIPO_MONEDA }} <br>
                  {{ dataContrato.RUT_PROVEEDOR }} {{ dataContrato.PROVEEDOR }} <br>
                  Fecha Vencimiento: {{ dataContrato.FECHA_TERMINO_CONTRATO }}
                </h3>
                <br>
                <hr>
                <br>
                  <div class="row">
                      <div class="col col-md-4">
                          <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterModificacionesGrl($event)" placeholder="Buscar ... " #input>
                      </div>
                      <div class="col-md-3"></div>
                      <div class="col-md-1">
                        <a class="btn btn-info btn-block" routerLink="/contratos">
                          <i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-angle-double-left"></i> Volver
                        </a>&nbsp;&nbsp;
                    </div>
                      <div class="col-md-2">
                          <a class="btn btn-primary btn-block" href="#modal-modificacion" data-toggle="modal">
                            <i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-edit"></i> Agregar Modificacion
                          </a>&nbsp;&nbsp;
                      </div>
                      <div class="col-md-2">
                        <a class="btn btn-primary btn-block" href="#modal-archivo" data-toggle="modal">
                          <i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-file"></i> Agregar Documento
                        </a>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col col-md-12">
                          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                              <ng-container matColumnDef="Descripcion">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Descripcion">Descripcion</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.DESCRIPCION }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Mod">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Mod">Mod</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.TIPO }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Nro">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Nro">Nro</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.CORRELATIVO }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Monto">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Monto">Monto</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.MONTO_MODIFICACION }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Moneda">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Moneda">Moneda</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.TIPO_MONEDA }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Actualizado">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Actualizado">Actualizado</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.MONTO_ACTUALIZADO }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Ingreso">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Ingreso">Ingreso</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_MODIFICACION }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Inicio">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Inicio">Inicio</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_INICIO }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Termino">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Termino">Termino</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.FECHA_TERMINO }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Administrador">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Administrador">Administrador</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.TIPO }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Alcance">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Alcance">Alcance</th>
                                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.ALCANCE }}</td>
                              </ng-container>
                              <ng-container matColumnDef="Estado">
                                <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Estado">Estado</th>
                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element "></td>
                              </ng-container>
                              <ng-container matColumnDef="Acciones">
                                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
                                  <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                                      <div class="btn-group">
                                          <a href="#modal-editar-modificacion" (click)="editarModificacion(element.CORRELATIVO)" data-toggle="modal" class="btn btn-default btn-sm text-center" style="color: #666;" title="Editar"><i class="fa fa-lg fa-fw m-r-10 fa-edit"></i></a>
                                          <a [routerLink]="" (click)="onDelete(element.CORRELATIVO)" class="btn btn-default btn-sm text-center" style="color: #666;" title="Eliminar"><i class="fa fa-lg fa-fw m-r-10 fa-trash-alt"></i></a>
                                      </div>
                                  </td>
                              </ng-container>

                              <tr mat-header-row *matHeaderRowDef="dataSourceHeader"></tr>
                              <tr mat-row *matRowDef="let row; columns: dataSourceHeader;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

                              <tr class="mat-row " *matNoDataRow>
                                  <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                              </tr>
                          </table>

                          <mat-paginator #paginatorModifGrl [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <div class="row">
    <div class="col-xl-12 ui-sortable">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
            <div class="panel-heading ui-sortable-handle">
                <h4 class="panel-title normalText">Documentos</h4>
                <div class="panel-heading-btn">
                    <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                </div>
            </div>

            <div class="panel-body">
                <div class="row">
                    <div class="col col-md-12">
                        <table mat-table [dataSource]="dataSourceDocumentos" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                            <ng-container matColumnDef="Grupo">
                                <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Grupo</th>
                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.GRUPO }}</td>
                            </ng-container>
                            <ng-container matColumnDef="Archivo">
                                <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Adjunto</th>
                                <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_ARCHIVO }}</td>
                            </ng-container>
                            <ng-container matColumnDef="Acciones">
                                <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef>Acciones</th>
                                <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                                    <div class="btn-group">
                                        <a [routerLink]="" (click)="onDescargar(element.NOMBRE_ARCHIVO)" class="btn btn-default btn-sm text-center" style="color: #666;" title="Descargar">
                                          <i class="fa fa-lg fa-fw m-r-10 fa-cloud-download-alt"></i> Descargar
                                        </a>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="dataSourceHeaderDocumentos"></tr>
                            <tr mat-row *matRowDef="let row; columns: dataSourceHeaderDocumentos;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

                            <tr class="mat-row " *matNoDataRow>
                                <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>



<!-- Modal de crear modificacion -->
<div class="modal fade" id="modal-modificacion">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">Agregar Modificación</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body">
              <form [formGroup]="formAgregarModificacion" (ngSubmit)="onCrear()" novalidate>
                <div class="row form-group">
                  <label class="col-md-2 col-form-label">Nro Modificación</label>
                  <div class="col-md-10">
                      <input type="number" formControlName="nro_modificacion" #nro_modificacion class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nro_modificacion.errors}"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="descripcion" class="col-md-2 col-form-label">Descripción Modificación</label>
                  <div class="col-md-10">
                      <textarea class="form-control" rows="3" formControlName="descripcion" #descripcion [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors}"></textarea>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="moneda" class="col-md-2 col-form-label">Moneda</label>
                  <div class="col-md-10">
                    <select class="form-control" formControlName="moneda" #moneda [ngClass]="{ 'is-invalid': submitted && f.moneda.errors}">
                      <option value="0">Seleccione ...</option>
                      <option *ngFor="let item of monedas" [value]="item.CORRELATIVO">{{item.DESCRIPCION}}</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-md-2 col-form-label">Modificación</label>
                  <div class="col-md-10" style="padding-top: 8px;">
                      <div *ngFor="let item of tipoModificacion">
                        <div class="form-check form-check-inline">
                          <input type="checkbox" value="{{item.CORRELATIVO}}" id="chk_{{item.DESCRIPCION}}" class="form-check-input" formControlName="chk_{{item.DESCRIPCION}}" (change)="onChangeTipo($event)">
                          <label class="form-check-label" for="chk_{{item.DESCRIPCION}}">{{item.DESCRIPCION}}</label>
                        </div>
                      </div>
                      <!--<div class="form-check form-check-inline">
                        <input type="checkbox" value="1" id="chk_monto" class="form-check-input" formControlName="chk_monto" (change)="onChangeMonto($event)">
                        <label class="form-check-label" for="chk_monto">Monto</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" value="1" id="chk_fecha" class="form-check-input" formControlName="chk_fecha" (change)="onChangeFecha($event)">
                        <label class="form-check-label" for="chk_fecha">Fecha</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" value="1" id="chk_alcance" class="form-check-input" formControlName="chk_alcance" (change)="onChangeAlcance($event)">
                        <label class="form-check-label" for="chk_alcance">Alcance</label>
                      </div>-->
                  </div>
                </div>
                <div class="row form-group">
                  <label for="nro_solpe" class="col-md-2 col-form-label">Solpe Nro</label>
                  <div class="col-md-10">
                    <select class="form-control" formControlName="nro_solpe" #nro_solpe [ngClass]="{ 'is-invalid': submitted && f.nro_solpe.errors}">
                      <option value="1">456</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="tipo" class="col-md-2 col-form-label">Tipo</label>
                  <div class="col-md-10">
                    <select class="form-control" formControlName="tipo" #tipo [ngClass]="{ 'is-invalid': submitted && f.tipo.errors}">
                      <option value="1">En Curso</option>
                      <option value="2">Cerrada</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-md-2 col-form-label">Monto Modificación</label>
                  <div class="col-md-10">
                      <input type="number" formControlName="monto_modificacion" #monto_modificacion class="form-control" [ngClass]="{ 'is-invalid': submitted && f.monto_modificacion.errors}"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-md-2 col-form-label">Monto Actualizado</label>
                  <div class="col-md-10">
                      <input type="number" formControlName="monto_actualizado" #monto_actualizado class="form-control" [ngClass]="{ 'is-invalid': submitted && f.monto_actualizado.errors}"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="fecha_modificacion" class="col-md-2 col-form-label">Fecha Modificación</label>
                  <div class="col-md-10 input-group">
                      <input type="date" class="form-control m-b-5" placeholder="Fecha de Modificación" formControlName="fecha_modificacion" #fecha_modificacion [ngClass]="{ 'is-invalid': submitted && f.fecha_modificacion.errors}">
                  </div>
                </div>
                <div class="row form-group">
                  <label for="fecha_inicio" class="col-md-2 col-form-label">Fecha Inicio</label>
                  <div class="col-md-4 input-group">
                      <input type="date" class="form-control m-b-5" placeholder="Fecha de Inicio" formControlName="fecha_inicio" #fecha_inicio>
                  </div>
                  <label for="fecha_termino" class="col-md-2 col-form-label">Fecha Término</label>
                  <div class="col-md-4">
                      <input type="date" class="form-control m-b-5" placeholder="Fecha de Término" formControlName="fecha_termino" #fecha_termino>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="administrador" class="col-md-2 col-form-label">Administrador</label>
                  <div class="col-md-10">
                    <select class="form-control" formControlName="administrador" #administrador [ngClass]="{ 'is-invalid': submitted && f.administrador.errors}">
                      <option value="1">Administrador</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="alcance" class="col-md-2 col-form-label">Alcance</label>
                  <div class="col-md-10">
                      <textarea class="form-control" rows="3" formControlName="alcance" #alcance></textarea>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="adjunto_modificacion" class="col-md-2 col-form-label">Modificación</label>
                  <div class="col-md-10">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" name="adjunto_modificacion" id="adjunto_modificacion" (change)="detectFileModificacion($event)" aria-describedby="inputGroupFileAddon01">
                          <label class="custom-file-label" for="adjunto_modificacion">{{ modificacion_fileName }}</label>
                      </div>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="adjunto_solpe" class="col-md-2 col-form-label">Solpe</label>
                  <div class="col-md-10">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" name="adjunto_solpe" id="adjunto_solpe" (change)="detectFileSolpe($event)" aria-describedby="inputGroupFileAddon01">
                          <label class="custom-file-label" for="adjunto_solpe">{{ solpe_fileName }}</label>
                      </div>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="adjunto_general" class="col-md-2 col-form-label">General</label>
                  <div class="col-md-10">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" name="adjunto_general" id="adjunto_general" (change)="detectFileGeneral($event)" aria-describedby="inputGroupFileAddon01">
                          <label class="custom-file-label" for="adjunto_general">{{ general_fileName }}</label>
                      </div>
                  </div>
                </div>

                  <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-2"><a href="javascript:;" (click)="onClose()" #closeModalAgregar class="btn btn-white btn-block" data-dismiss="modal">Cerrar</a></div>
                      <div class="col-md-4 text-right">
                          <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>


<!-- Modal de editar modificacion -->
<div class="modal fade" id="modal-editar-modificacion">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">Editar Modificación</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body">
              <form [formGroup]="formEditarModificacion" (ngSubmit)="onEditar()" novalidate>
                <div class="row form-group">
                  <label class="col-md-2 col-form-label">Nro Modificación</label>
                  <div class="col-md-10">
                      <input type="number" formControlName="nro_modificacion_edit" #nro_modificacion_edit class="form-control" [ngClass]="{ 'is-invalid': submitted && fe.nro_modificacion_edit.errors}"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="descripcion_edit" class="col-md-2 col-form-label">Descripción Modificación</label>
                  <div class="col-md-10">
                      <textarea class="form-control" rows="3" formControlName="descripcion_edit" #descripcion_edit [ngClass]="{ 'is-invalid': submitted && fe.descripcion_edit.errors}"></textarea>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="moneda_edit" class="col-md-2 col-form-label">Moneda</label>
                  <div class="col-md-10">
                    <select class="form-control" formControlName="moneda_edit" #moneda_edit [ngClass]="{ 'is-invalid': submitted && fe.moneda_edit.errors}">
                      <option value="0">Seleccione ...</option>
                      <option *ngFor="let item of monedas" [value]="item.CORRELATIVO">{{item.DESCRIPCION}}</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-md-2 col-form-label">Modificación</label>
                  <div class="col-md-10" style="padding-top: 8px;">
                      <div class="form-check form-check-inline">
                        <input type="checkbox" value="1" id="chk_monto_edit" class="form-check-input" formControlName="chk_monto_edit" (change)="onChangeMontoEdit($event)">
                        <label class="form-check-label" for="chk_monto_edit">Monto</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" value="1" id="chk_fecha_edit" class="form-check-input" formControlName="chk_fecha_edit" (change)="onChangeFechaEdit($event)">
                        <label class="form-check-label" for="chk_fecha_edit">Fecha</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input type="checkbox" value="1" id="chk_alcance_edit" class="form-check-input" formControlName="chk_alcance_edit" (change)="onChangeAlcanceEdit($event)">
                        <label class="form-check-label" for="chk_alcance_edit">Alcance</label>
                      </div>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="nro_solpe_edit" class="col-md-2 col-form-label">Solpe Nro</label>
                  <div class="col-md-10">
                    <select class="form-control" formControlName="nro_solpe_edit" #nro_solpe_edit [ngClass]="{ 'is-invalid': submitted && fe.nro_solpe_edit.errors}">
                      <option value="1">456</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="tipo_edit" class="col-md-2 col-form-label">Tipo</label>
                  <div class="col-md-10">
                    <select class="form-control" formControlName="tipo_edit" #tipo_edit [ngClass]="{ 'is-invalid': submitted && fe.tipo_edit.errors}">
                      <option value="1">En Curso</option>
                      <option value="2">Cerrada</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-md-2 col-form-label">Monto Modificación</label>
                  <div class="col-md-10">
                      <input type="number" formControlName="monto_modificacion_edit" #monto_modificacion_edit class="form-control" [ngClass]="{ 'is-invalid': submitted && fe.monto_modificacion_edit.errors}"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-md-2 col-form-label">Monto Actualizado</label>
                  <div class="col-md-10">
                      <input type="number" formControlName="monto_actualizado_edit" #monto_actualizado_edit class="form-control" [ngClass]="{ 'is-invalid': submitted && fe.monto_actualizado_edit.errors}"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="fecha_modificacion_edit" class="col-md-2 col-form-label">Fecha Modificación</label>
                  <div class="col-md-10 input-group">
                      <input type="date" class="form-control m-b-5" placeholder="Fecha de Modificación" formControlName="fecha_modificacion_edit" #fecha_modificacion_edit [ngClass]="{ 'is-invalid': submitted && fe.fecha_modificacion_edit.errors}">
                  </div>
                </div>
                <div class="row form-group">
                  <label for="fecha_inicio_edit" class="col-md-2 col-form-label">Fecha Inicio</label>
                  <div class="col-md-4 input-group">
                      <input type="date" class="form-control m-b-5" placeholder="Fecha de Inicio" formControlName="fecha_inicio_edit" #fecha_inicio_edit>
                  </div>
                  <label for="fecha_termino_edit" class="col-md-2 col-form-label">Fecha Término</label>
                  <div class="col-md-4">
                      <input type="date" class="form-control m-b-5" placeholder="Fecha de Término" formControlName="fecha_termino_edit" #fecha_termino_edit>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="administrador_edit" class="col-md-2 col-form-label">Administrador</label>
                  <div class="col-md-10">
                    <select class="form-control" formControlName="administrador_edit" #administrador_edit [ngClass]="{ 'is-invalid': submitted && fe.administrador_edit.errors}">
                      <option value="1">Administrador</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="alcance_edit" class="col-md-2 col-form-label">Alcance</label>
                  <div class="col-md-10">
                      <textarea class="form-control" rows="3" formControlName="alcance_edit" #alcance_edit></textarea>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="adjunto_modificacion_edit" class="col-md-2 col-form-label">Modificación</label>
                  <div class="col-md-10">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" name="adjunto_modificacion_edit" id="adjunto_modificacion_edit" (change)="detectFileModificacion($event)" aria-describedby="inputGroupFileAddon01">
                          <label class="custom-file-label" for="adjunto_modificacion_edit">{{ modificacion_fileName }}</label>
                      </div>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="adjunto_solpe_edit" class="col-md-2 col-form-label">Solpe</label>
                  <div class="col-md-10">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" name="adjunto_solpe_edit" id="adjunto_solpe_edit" (change)="detectFileSolpe($event)" aria-describedby="inputGroupFileAddon01">
                          <label class="custom-file-label" for="adjunto_solpe_edit">{{ solpe_fileName }}</label>
                      </div>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="adjunto_general_edit" class="col-md-2 col-form-label">General</label>
                  <div class="col-md-10">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" name="adjunto_general_edit" id="adjunto_general_edit" (change)="detectFileGeneral($event)" aria-describedby="inputGroupFileAddon01">
                          <label class="custom-file-label" for="adjunto_general_edit">{{ general_fileName }}</label>
                      </div>
                  </div>
                </div>
                <input type="hidden" name="corr_modificacion" id="corr_modificacion" formControlName="corr_modificacion" #corr_modificacion value=""/>
                  <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-2"><a href="javascript:;" (click)="onCloseEditar()" #closeModalEditar class="btn btn-white btn-block" data-dismiss="modal">Cerrar</a></div>
                      <div class="col-md-4 text-right">
                          <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Modificar</button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>


<!-- Modal de cargar documento -->
<div class="modal fade" id="modal-archivo">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">Agregar Documento</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </div>
          <div class="modal-body">
              <form [formGroup]="formAgregarArchivo" (ngSubmit)="onAgregarArchivo()" novalidate>
                <div class="row form-group">
                  <label for="grupo" class="col-md-3 col-form-label">Grupo</label>
                  <div class="col-md-9">
                    <select class="form-control" formControlName="grupo" #grupo [ngClass]="{ 'is-invalid': submittedDocumento && fd.grupo.errors}">
                      <option value="0">Seleccione ...</option>
                      <option *ngFor="let item of archivos" [value]="item.CORRELATIVO">{{item.GRUPO}}</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label for="adjunto" class="col-md-3 col-form-label">Documento</label>
                  <div class="col-md-9">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" name="adjunto" id="adjunto" (change)="detectFile($event)" aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submittedDocumento && fd.adjunto.errors}">
                          <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                      </div>
                  </div>
                </div>

                  <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-2"><a href="javascript:;" (click)="onCloseArchivo()" #closeModalAgregarArchivo class="btn btn-white btn-block" data-dismiss="modal">Cerrar</a></div>
                      <div class="col-md-4 text-right">
                          <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>
