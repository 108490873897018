<div id="content" class="content">
  <div class="row">
    <div class="col-md-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a href="#default-tab-1" data-toggle="tab" class="nav-link active">
            <span class="d-sm-block d-none">Lista de sistemas</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#default-tab-2" data-toggle="tab" class="nav-link">
            <span class="d-sm-block d-none">Lista de modulos</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#default-tab-3" data-toggle="tab" class="nav-link">
            <span class="d-sm-block d-none">Lista de acciones</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="#default-tab-4" data-toggle="tab" class="nav-link">
            <span class="d-sm-block d-none">Lista de responsabilidades</span>
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane fade active show" id="default-tab-1">
          <div class="row">
            <div class="col col-md-4">
                <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterSistema($event) " placeholder="Buscar ... " #input>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <table mat-table [dataSource]="sistemas" matSort (matSortChange)="sortDataSistemas($event)" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                <ng-container matColumnDef="Correlativo" style="display: none;">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef mat-sort-header="Correlativo">#</th>
                  <td mat-cell *matCellDef="let element "> {{element.CORRELATIVO}} </td>
                </ng-container>
                <ng-container matColumnDef="Descripcion">
                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Descripcion">Descripcion</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> {{element.DESCRIPCION}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dcSistemas"></tr>
                <tr mat-row *matRowDef="let row; columns: dcSistemas;" id="row" style="cursor: pointer;" (click)="selectionSistemas.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selectionSistemas.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
              </table>
              <mat-paginator #paginatorSistema [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="default-tab-2">
          <div class="row">
            <div class="col col-md-4">
              <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterModulo($event) " placeholder="Buscar ... " #input>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <table mat-table [dataSource]="modulos" matSort (matSortChange)="sortDataModulos($event)" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                <ng-container matColumnDef="Correlativo" style="display: none;">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef mat-sort-header="Correlativo">#</th>
                  <td mat-cell *matCellDef="let element "> {{element.correlativo}} </td>
                </ng-container>
                <ng-container matColumnDef="Descripcion">
                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Descripcion">Descripcion</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element "><i class="fas fa-lg fa-fw m-r-10 fa-{{element.ICONO}}"></i> {{element.nombre }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dcModulo"></tr>
                <tr mat-row *matRowDef="let row; columns: dcModulo;" id="row" style="cursor: pointer;" (click)="selectionModulos.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selectionModulos.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
              </table>
              <mat-paginator #paginatorModulo [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="default-tab-3">
          <div class="row">
            <div class="col col-md-4">
              <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterAccion($event) " placeholder="Buscar ... " #input>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <table mat-table [dataSource]="acciones" matSort (matSortChange)="sortDataAcciones($event)" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                <ng-container matColumnDef="Correlativo" style="display: none;">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef mat-sort-header="Correlativo">#</th>
                  <td mat-cell *matCellDef="let element "> {{element.correlativo }} </td>
                </ng-container>
                <ng-container matColumnDef="Descripcion">
                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Descripcion">Descripcion</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element "><i class="fas fa-lg fa-fw m-r-10 fa-{{element.ICONO}}"></i> {{element.nombre}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dcAcciones"></tr>
                <tr mat-row *matRowDef="let row; columns: dcAcciones;" id="row" style="cursor: pointer;" (click)="selectionAcciones.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selectionAcciones.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
              </table>
              <mat-paginator #paginatorAccion [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="default-tab-4">
          <div class="row">
            <div class="col col-md-4">
              <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterCargo($event) " placeholder="Buscar ... " #input>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <table mat-table [dataSource]="cargos" matSort (matSortChange)="sortDataCargos($event)" class="table table-striped table-bordered table-td-valign-middle table-th-valign-middle ">
                <ng-container matColumnDef="Correlativo" style="display: none;">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef mat-sort-header="Correlativo">#</th>
                  <td mat-cell *matCellDef="let element "> {{element.CORRELATIVO}} </td>
                </ng-container>
                <ng-container matColumnDef="Descripcion">
                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Descripcion">Descripcion</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{element.DESTINATARIO}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dcCargo"></tr>
                <tr mat-row *matRowDef="let row; columns: dcCargo;" id="row" style="cursor: pointer;" (click)="selectionResponsabilidades.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selectionResponsabilidades.isSelected(row)}" (mouseover)="row.hovered = true"
                          (mouseout)="row.hovered = false"></tr>
              </table>
              <mat-paginator #paginatorCargo [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
