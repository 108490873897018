<!-- begin login-content -->
<div class="login-content">
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPassword()" class="margin-bottom-0" autocomplete="off">
    <div class="form-group m-b-20">
      <input type="text" class="form-control form-control-lg" placeholder="Email" formControlName="lEmail"
        [class.is-invalid]="isInvalid('lEmail')" autocomplete="new-password" />
      <span class="invalid-feedback" *ngIf="isInvalid('lEmail')">
        {{ this.forgotPasswordForm.get('lEmail').errors.required ? 'Debe ingresar un correo' : 'Debe ingresar un correo
        valido' }}
      </span>
    </div>
    <div class="form-group m-b-20">
      <button type="button" id="enviar_correo" class="btn btn-primary d-block w-100 btn fs-14px"
        [disabled]="!forgotPasswordForm.get('lEmail').touched || isInvalid('lEmail') || isSendingCode"
        (click)="onSendCode()">
        <i class="fa fa-envelope"></i> Enviarme Código de Seguridad
      </button>
    </div>
    <div class="form-group m-b-20">
      <input type="text" class="form-control form-control-lg" placeholder="Ingrese Código de Verificación"
        formControlName="lCode" [class.is-invalid]="isInvalid('lCode')" autocomplete="new-password" />
      <span class="invalid-feedback" *ngIf="isInvalid('lCode')">
        Debe ingresar el Código de Verificación
      </span>
    </div>
    <div class="form-group m-b-20">
      <input type="password" class="form-control form-control-lg" placeholder="Nueva Contraseña"
        formControlName="lPassword" [class.is-invalid]="isInvalid('lPassword')" autocomplete="new-password" />
      <span class="invalid-feedback" *ngIf="isInvalid('lPassword')">
        {{ this.forgotPasswordForm.get('lPassword').errors.message }}
      </span>
    </div>
    <div class="form-group m-b-20">
      <input type="password" class="form-control form-control-lg" placeholder="Repita Contraseña"
        formControlName="lPassword2" [class.is-invalid]="isInvalid('lPassword2')" autocomplete="new-password" />
      <span class="invalid-feedback" *ngIf="isInvalid('lPassword2')">
        {{ this.forgotPasswordForm.get('lPassword2').errors.message }}
      </span>
    </div>
    <div class="login-buttons m-b-10">
      <button type="submit" class="btn btn-success btn-block btn-lg"
        [disabled]="!forgotPasswordForm.valid || isSaving">Enviar</button>
    </div>
    <div class="form-group">
      Olvídalo, <a href="javascript:void(0);" (click)="backToLogin()">envíame de vuelta</a> a la pantalla de inicio de
      sesión.
    </div>
  </form>
</div>