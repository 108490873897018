<div id="content" class="content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <!-- <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Estados de Pago</h4>
        </div> -->
        <div class="bg-white p3-rem rounded">
            <div class="row">
                <div class="col-6">
                    <h3>Contrato {{contrato.CODIGO_CONTRATO}} {{ contrato.NOMBRE_CONTRATO }}</h3>
                    <p style="font-size: 15px;" class="text-muted">Periodo del contrato: {{ fechaInicioContrato | date: 'dd/MM/yyyy' }} - {{ fechaTerminoContrato | date: 'dd/MM/yyyy' }}</p>
                    <p style="font-size: 15px;" class="text-muted">{{ contrato.PROVEEDOR }}</p>
                </div>
                <div class="col-6 text-right">
                    <!-- <p class="mb-0 text-muted">Generación de Estado de Pago</p> -->
                    <span style="font-size: 14px;" class="mb-0 tab tab-green">Generación de Estado de Pago</span>
                </div>
            </div>

            <br>
            <form [formGroup]="form" (ngSubmit)="onGenerar()" novalidate>
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <label style="font-size: 14px;" for="nombre" class="col-md-4 col-form-label">Descripción del estado de pago:</label>
                            <div class="col-md-8">
                                <input type="text" #nombre formControlName="nombre" class="form-control" placeholder="Descripción" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors}" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label style="font-size: 14px;" class="col-md-4 col-form-label">Período Desde/Hasta</label>
                            <div class="col-md-4 input-group">
                                <input type="date" formControlName="fecha_desde" class="form-control m-b-5" #fecha_desde [ngClass]="{ 'is-invalid': submitted && f.fecha_desde.errors}">
                            </div>
                            <div class="col-md-4">
                                <input type="date" formControlName="fecha_hasta" class="form-control m-b-5" #fecha_hasta [ngClass]="{ 'is-invalid': submitted && f.fecha_hasta.errors}">
                            </div>
                        </div>
                        <div class="row form-group">
                            <label style="font-size: 14px;" for="timo" class="col-md-4 col-form-label">Moneda</label>
                            <div class="col-md-8">
                                <select class="form-control" #timo formControlName="moneda">
                                    <option *ngFor="let item of tipoMoneda" [value]="item.correlativo">{{item.descripcion}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label style="font-size: 14px;" class="col-md-4 col-form-label">Reajuste (%)</label>
                            <div class="col-md-8">
                                <input type="number" class="form-control" #reajuste appDecimalPlaces  formControlName="reajuste" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label style="font-size: 14px;" class="col-md-4 col-form-label">Saldo Contrato</label>
                            <div class="col-md-8">
                                <input type="text" class="form-control" #saldo_contrato value="{{ (contrato.MONTO_ACTUALIZADO - contrato.MONTO_EP + contrato.MONTO_REAJUSTE) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}" disabled />
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">&nbsp;</div>
                </div>
                <br>
                <!-- <div formArrayName="cantidad">
                    <div class="row">
                        <div class="col-12" *ngFor="let item of form?.get('cantidad')['controls']; let i = index">
                            <div formGroupName="{{i}}">

                                {{i}} {{item.value.id}}
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12" formArrayName="cantidad">
                        <table class="table border">
                            <thead class="">
                                <th class="text-normal">R</th>
                                <th class="text-normal">Q</th>
                                <th class="text-normal">SubPos</th>
                                <th class="text-normal">Descripción</th>
                                <th class="text-normal">Unidad</th>
                                <th class="text-normal">Moneda</th>
                                <th class="text-normal text-right">Cantidad</th>
                                <th class="text-normal text-right">P. Unitario</th>
                                <th class="text-normal text-right">Consumo Q</th>
                                <th class="text-normal text-right">Saldo Q</th>
                                <th class="text-normal" width="5%">Solicitud Q</th>
                                <th class="text-normal text-right">P x Q EP</th>
                                <th class="text-normal text-right">REAJUSTE</th>
                                <th class="text-normal text-right">TOTAL EP</th>
                            </thead>
                            <tbody>

                                <tr *ngFor="let item of itemizado; let i = index">
                                <!-- <tr *ngFor="let item of form.get('cantidad')['controls']; let i = index"> -->
                                    <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>
                                    <td *ngIf="!item.Cabecera && item.R" class="pt-2"><i class="fa fa-check"></i></td>
                                    <td *ngIf="!item.Cabecera && !item.R" class="pt-2">&nbsp;</td>
                                    <td *ngIf="!item.Cabecera && item.Scpq" class="pt-2"><i class="fa fa-check"></i></td>
                                    <td *ngIf="!item.Cabecera && !item.Scpq" class="pt-2">&nbsp;</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Subpos }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Descripcion }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Unidad }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ contrato.TIPO_MONEDA }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.AvanceQ | number:'1.2': 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ (item.Cantidad - item.AvanceQ) | number:'1.2': 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" style="width: 10%;">

                                            <span formGroupName="{{i}}">
                                                <input type="hidden" formControlName="id">
                                                <!-- <input type="number" min="0" class="form-control form-control-sm" formControlName="value" name="value" /> -->
                                                <!-- <input *ngIf="item.Scpq" type="number" (input)="validMaxLength($event)" appDecimalPlaces step="0.01" min="0" class="form-control form-control-sm" #cantidad formControlName="value" name="value" (change)="changeCantidad(item.Correlativo, cantidad.value, item.PrecioUnitario, item.R, reajuste.value, item.Scpq,(item.Cantidad - item.AvanceQ), this)" [ngClass]="{'is-invalid': !form.get('cantidad')['controls'][i].controls.value.valid && (form.get('cantidad')['controls'][i].controls.value.dirty || form.get('cantidad')['controls'][i].controls.value.touched)}"/>
                                                <input *ngIf="!item.Scpq" type="number" (input)="validMaxLength($event)" appDecimalPlaces step="0.01" min="0" max="{{item.Cantidad - item.AvanceQ}}" class="form-control form-control-sm" #cantidad formControlName="value" name="value" (change)="changeCantidad(item.Correlativo, cantidad.value, item.PrecioUnitario, item.R, reajuste.value, item.Scpq,(item.Cantidad - item.AvanceQ), this)" [ngClass]="{'is-invalid': !form.get('cantidad')['controls'][i].controls.value.valid && (form.get('cantidad')['controls'][i].controls.value.dirty || form.get('cantidad')['controls'][i].controls.value.touched) || ((item.Cantidad - item.AvanceQ) < form.get('cantidad')['controls'][i].controls.value.value) }"/> -->
                                                <input *ngIf="item.Scpq" type="number" appDecimalPlaces step="0.01" min="0" class="form-control form-control-sm" #cantidad formControlName="value" name="value" (change)="changeCantidad(item.Correlativo, cantidad.value, item.PrecioUnitario, item.R, reajuste.value, item.Scpq,(item.Cantidad - item.AvanceQ), this)" [ngClass]="{'is-invalid': !form.get('cantidad')['controls'][i].controls.value.valid && (form.get('cantidad')['controls'][i].controls.value.dirty || form.get('cantidad')['controls'][i].controls.value.touched)}"/>
                                                <input *ngIf="!item.Scpq" type="number" appDecimalPlaces step="0.01" min="0" max="{{item.Cantidad - item.AvanceQ}}" class="form-control form-control-sm" #cantidad formControlName="value" name="value" (change)="changeCantidad(item.Correlativo, cantidad.value, item.PrecioUnitario, item.R, reajuste.value, item.Scpq,(item.Cantidad - item.AvanceQ), this)" [ngClass]="{'is-invalid': !form.get('cantidad')['controls'][i].controls.value.valid && (form.get('cantidad')['controls'][i].controls.value.dirty || form.get('cantidad')['controls'][i].controls.value.touched) || ((item.Cantidad - item.AvanceQ) < form.get('cantidad')['controls'][i].controls.value.value) }"/>
                                                <!-- <input type="number" class="form-control form-control-sm" #cantidad formControlName="cantidad" name="cantidad" (change)="changeCantidad(item.Correlativo, cantidad.value, item.PrecioUnitario, item.R, reajuste.value, item.Scpq,(item.Cantidad - item.AvanceQ), this)" /> -->
                                            </span>
                                    </td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ (valoresCantidades[this.getItemCantidadByid(item.Correlativo)].cantidad * item.PrecioUnitario) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera && item.R" class="pt-2 text-right">{{ reajuste.value | number:'1.2': 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera && !item.R" class="pt-2 text-right">0</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ valoresTotales[this.getItemTotalesByid(item.Correlativo)].monto | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>

                                </tr>

                                <tr class="text-right">
                                    <td colspan="10"></td>
                                    <td><strong>Total</strong></td>
                                    <td>{{ this.montoSinReajuste() | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                    <td></td>
                                    <td>{{ total | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-4 d-none">
                    <div class="col-md-8"></div>
                    <div class="col-md-4">
                        <h5>Retenciones</h5>
                        <div class="row form-group">
                            <label for="retencion_laboral" class="col-md-3 col-form-label">Laboral</label>
                            <div class="col-md-4">
                                <p class="mt-2">CLP</p>
                            </div>
                            <div class="col-md-5">
                                <input type="text" #retencion_laboral formControlName="retencion_laboral" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.retencion_laboral.errors}" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="retencion_contractual" class="col-md-3 col-form-label">Contractual</label>
                            <div class="col-md-4">
                                <div class="form-check form-check-inline mt-2">
                                    <input type="radio" value="P" name="rdo_contractual" formControlName="rdo_contractual" id="rdo_porcentaje" class="form-check-input">
                                    <label class="form-check-label" for="rdo_porcentaje">%</label>
                                </div>
                                <div class="form-check form-check-inline mt-2">
                                    <input type="radio" value="M" name="rdo_contractual" formControlName="rdo_contractual" id="rdo_monto" class="form-check-input">
                                    <label class="form-check-label" for="rdo_monto">CLP</label>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <input type="text" #retencion_contractual formControlName="retencion_contractual" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.retencion_contractual.errors}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <button class="btn btn-primary" [disabled]="submitted === true">Generar EP</button>
                </div>
            </form>
        </div>
    </div>
</div>
<ng-template #modalRegister>
    <h5>Este contrato ya posee un estado de pago generado</h5>
    <p>Será redirigido a este para que pueda modificarlo y enviarlo.</p>
    <mat-dialog-actions align="end">
        <button class="btn btn-primary mr-2" mat-button matDialogClose="yes">Continuar</button>
        <!-- <button class="btn btn-light" mat-button matDialogClose="no">No</button> -->
    </mat-dialog-actions>
</ng-template>