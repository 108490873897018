import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { NotificationService } from '../../shared/services/notification.service';
import { AuthService } from '../../shared/services/auth.service';
import { passwordValidation, isInvalidControl, getUser } from '../../shared/functions/common';
import { ChangePasswordRequestData } from './../../shared/services/auth.service';
@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.css']
})
export class ModalChangePasswordComponent implements OnInit {
  force: boolean = false;
  modalRef: BsModalRef;
  changePasswordForm: FormGroup;
  isSaving: boolean = false;
  @ViewChild('template') template: TemplateRef<ModalChangePasswordComponent>;

  constructor(private authService: AuthService, private router: Router, private modalService: BsModalService, private notifyService: NotificationService) { }

  isInvalid(input: string) {
    return isInvalidControl(input, this.changePasswordForm);
  }

  passwordValidator(pass2?: boolean) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      let valid = true;
      let message = "";

      const validation = passwordValidation(value);
      valid = validation.valid;
      message = validation.message;

      if (valid && pass2) {
        const pass = this.changePasswordForm && this.changePasswordForm.get('lPassword') ? this.changePasswordForm.get('lPassword').value : null;
        if (value !== pass) {
          valid = false;
          message = "Las contraseñas no coinciden";
        }
      }

      return !valid ? { message } : null;
    };
  }

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      lOldPassword: new FormControl('', [
        Validators.required,
      ]),
      lPassword: new FormControl('', [
        this.passwordValidator(),
      ]),
      lPassword2: new FormControl('', [
        this.passwordValidator(true),
      ]),
    });
  }

  onChangePassword() {
    this.isSaving = true;
    const values = this.changePasswordForm.value;
    const user = getUser();

    const postChangePassword: ChangePasswordRequestData = {
      userId: user.userId,
      oldPassword: values.lOldPassword,
      newPassword: values.lPassword,
      reNewPassword: values.lPassword2,
    }
    this.authService.changePassword(postChangePassword).subscribe(resp => {

      const userData = this.authService.getUserDataStorage()
      userData.cambioClave = false
      this.authService.saveUserDataStorage(userData)

      this.notifyService.showSuccess('La contraseña se ha actualizado exitosamente.', 'Cambiar contraseña');
      //this.modalRef.hide(); // TODO: Hacer funcion para cerrar modal sin recargar la pagina

      window.location.reload();
    }, err => {
      this.isSaving = false;
      this.notifyService.showError('Error al cambiar contraseña', 'Cambiar contraseña');
    });
  }

  openModal(force: boolean = false) {
    this.force = force;
    const modalOptions: ModalOptions = force ? {
      backdrop: 'static',
      keyboard: false
    } : { class: 'modal-lg' };
    this.modalRef = this.modalService.show(this.template, modalOptions);
  }
}
