import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { AddonsService } from 'src/app/shared/services/addons.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BoletasService } from 'src/app/shared/services/boletas.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { DataService } from 'src/app/shared/services/data.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { FileService } from 'src/app/shared/services/file.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as moment from 'src/assets/plugins/moment/moment';
import { SolpeService } from '../../shared/services/solpe.service';
import { formatCurrency } from 'src/app/shared/functions/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-solpe',
  templateUrl: './solpe.component.html',
  styleUrls: ['./solpe.component.css']
})
export class SolpeComponent implements OnInit {

  /** Acciones permitidas para el usuario segun su rol */
  moduloCorrelativo: number;
  accionesPermitidas: string[] = [];
  accionesPermitidasCorr: string[] = [];
  userId: number;
  rolId: number;
  arrCargosFullPrivilegio: number[] = [];

  solpes: any[] = [];
  solpesExcel: any[] = [];
  formAgregarSolpe : FormGroup;
  empresas_propuestas: string[] = [];
  lblFechaTerminoSolpe: string = 'Fecha estimada inicio de servicio';
  mnjTipoCompras = false;
  formaPagosMis = false;
  tipoContrato = false;
  verFechasSolpeServicios = false;
  monedas: any[];
  tipos: any[];
  formas: any[];
  username: string;
  contratos: any;
  submitted = false;
  totales = { PARA_GESTION: 0, VIGENTE: 0, CERRADAS: 0, RECHAZADAS: 0, OCULTAS: 0 };
  totalSolpes = { total: 0 };
  contratoInfo;

  /** Archivos */
  documentosSolpe: any[];
  formAgregarArchivo: FormGroup;
  archivos: any[];
  archivos64 :any;
  fileName :string;
  type :string;
  submittedDocumento = false;

  /** Comentarios */
  comentariosSolpe: any[];
  formAgregarComentario: FormGroup;
  submittedComentario = false;

  selection = new SelectionModel<any>(false, []);

  dataSource:any;
 /*  dataSourceHeader: string[] = ['Codigo', 'Nombre', 'TipoCtt', 'Forma', 'Ctt', 'Oc', 'FechaTermino', 'Solicitante',
  'Monto', 'Moneda', 'Estado' , 'RespAbast', 'Acciones']; */
  // dataSourceHeader: string[] = ['Codigo', 'Nombre', 'Oc', 'FechaTermino', 'Solicitante', 'GCC',
  // 'Monto', 'Estado' , 'Acciones'];
  dataSourceHeader: string[] = ['Codigo', 'Nombre', 'Oc', 'fechaEstimadaInicioServicio', 'duracionEstimadaServicio', 'Solicitante', 'GCC',
  'Monto', 'Estado' , 'Acciones'];
  dataSourceHeaderMod: string[] = ['Codigo', 'Nombre', 'Ctt', 'Oc', 'fechaEstimadaInicioServicio', 'duracionEstimadaServicio', 'Solicitante', 'GCC',
  'Monto', 'Estado' , 'Acciones'];
  @ViewChild('paginatorSolpesGrl', {static: false}) paginatorSolpesGrl: MatPaginator;
  @ViewChild('closeModalAgregar') closeModalAgregar: ElementRef;
  @ViewChild('closeModalAgregarArchivo') closeModalAgregarArchivo: ElementRef;
  @ViewChild('closeModalAgregarComentario') closeModalAgregarComentario: ElementRef;
  @ViewChild('closeModalOcultar') closeModalOcultar: ElementRef;

  sortedData: any;
  solpeInit: any;

  selectedFilter: number = 1;
  corrSolpe: number = 0;
  typeSolpe: number;

  errors = {
    startDate: null,
    endDate: null,
    userName: null,
  }
  startDate:string = "";
  endDate:string = "";
  dateFormat = 'DD/MM/YYYY HH:mm:ss';

  strCodigoSolpe: string;
  strNombreSolpe: string;
  solpeCorrelativoVisualizar: number;
  visible: number = 0;
  comentarioFueraProceso: string;

  constructor(
    private boletasService: BoletasService,
    private notifyService: NotificationService,
    private addonsService : AddonsService,
    private solpeService: SolpeService,
    private fileService: FileService,
    private excelService: ExcelService,
    private dataService: DataService,
    private contratosService: ContratoService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
      this.userId = JSON.parse(localStorage.getItem('userData')).userId;
      this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
      this.moduloCorrelativo = 1; // SOLPE
      this.getAccionesUsuario(this.moduloCorrelativo);

      this.activatedRoute.params.subscribe(params => {
        this.solpes = [];
        this.solpeInit = [];

        //this.totales.PARA_GESTION = this.solpes.length;//ESTADO_CORRELATIVO
        this.totalSolpes.total = 0;
        const Rechazadas = 0
        const Cerradas = 0
        const Aplicadas = 0

        this.totales.PARA_GESTION = 0
        this.totales.CERRADAS = 0
        this.totales.RECHAZADAS = 0;
        this.totales.OCULTAS = 0;

        this.dataSource = new MatTableDataSource([]);
        //this.createPaginator(this.paginatorSolpesGrl, this.dataSource);


        this.typeSolpe =  params['solpe_type'];
        this.getSolpesNew()
        this.selectedFilter = 1;

        this.strCodigoSolpe = '';
        this.strNombreSolpe = '';
        this.solpeCorrelativoVisualizar = 0;
        this.visible = 0;
        this.comentarioFueraProceso = '';
      });

    }
  private getAccionesUsuario(moduloCorrelativo: number) {
    this.authService.getAccionesPorUsuario(moduloCorrelativo).subscribe(resp => {
      let acciones: any[] = JSON.parse(resp);
      if(acciones.length > 0) {
        this.accionesPermitidas = acciones[0].ACCIONES.split(' - ');
        this.accionesPermitidasCorr = acciones[0].ACCIONES_CORRELATIVO.split(' - ');
      }
    },
    error => {console.log(error)},
    () => {
      this.cargosFullPrivilegio();
    });
  }
  existeAccion(accion: string) {
    return (this.accionesPermitidasCorr.includes(accion));
  }
  ngOnInit(): void {
    this.username = JSON.parse(localStorage.getItem('userData')).userName;
    this.resetForm();
    this.resetFormArchivo();
    this.resetFormComentario();
    this.getTipos();
    this.getFormas();
    this.getMonedas();
    this.getArchivos();
    this.getContratos();
  }

  visualizarSolpe(solpeCorrelativo: number, solpeCodigo: string, solpeNombre: string, visible: number) {
    this.visible = visible;
    this.solpeCorrelativoVisualizar = solpeCorrelativo;
    this.strCodigoSolpe = solpeCodigo;
    this.strNombreSolpe = solpeNombre;
  }

  onCambiarEstadoSolpeOcultar() {

    if (this.comentarioFueraProceso === '') {
      this.notifyService.showWarning('Debe Ingresar una observación.', 'Solpe');
    }else{
      this.solpeService.updateSolpeOculta({solpe_correlativo: this.solpeCorrelativoVisualizar, visible: this.visible}).subscribe(resp => {

        const postDataComentario : {
          in_solpe_correlativo: number,
          iv_comentario: string,
          in_usua_correlativo_creacion: number
        } = {
          in_solpe_correlativo: this.solpeCorrelativoVisualizar,
          iv_comentario: this.comentarioFueraProceso,
          in_usua_correlativo_creacion: 0
        };
    
        this.solpeService.postComentarioSolpeFueraProceso(postDataComentario).subscribe(resp=>{
          // correlativo 166 = PASO A SOLPE OCULTA, 167 = PASO A SOLPE EN PROCESO
          // se modifican los textos ya que si la solpe se pasa a estado fuera de proceso no podra pasar otra vez a "En proceso"
          const postData = {
            pin_solpe_correlativo: this.solpeCorrelativoVisualizar, 
            vn_me_correlativo_cargos: this.visible == 0 ? 166 : 167, 
            vn_me_correlativo_solicitante: this.visible == 0 ? 166 : 167
          };
    
          this.solpeService.postEnviaCorreoSolpeFueraProceso(postData).subscribe(resp => {
            this.notifyService.showWarning('La SOLPE ahora esta Fuera del Proceso', 'Solpe');
            this.getSolpesNew();
            if (this.visible == 1) {
              this.selectedFilter = 1
            }
            this.comentarioFueraProceso = '';
            this.closeModalOcultar.nativeElement.click();

          }, error => console.log(error),
          () => {
            // nada
          });
        }, err =>{
          this.notifyService.showError('Error al crear comentario', 'Crear Comentario');
          console.log(err);
        });

      }, error => console.log(error),
      () => {
        // nada
      });
    }
  }

  private cargosFullPrivilegio() {
    this.solpeService.getCargosFullPrivilegio(this.moduloCorrelativo, this.rolId).subscribe(resp => {
        let cargos = JSON.parse(resp);
        cargos.map(resp => {
          this.arrCargosFullPrivilegio.push(resp.CARGO);
        });
    }, error => console.log(error),
    () => {
      //this.getSolpes();
      //this.getTotalesSolpe();
    });
  }
  exportAsXLSX():void {
    this.solpesExcel = this.formatDataExcel(this.dataSource.filteredData);
    this.excelService.exportAsExcelFile(this.solpesExcel, 'solpes');
    this.notifyService.showSuccess('Descargando...', 'Descargar archivo');
  }
  private formatDataExcel(datos: any[]) {
    let new_data: any[] = [];

    for(let i in datos) {
      const data = datos[i];
      new_data[i] = {
        Código: data["SOLPE_CODIGO"],
        Nombre: data['NOMBRE_SOLPE'],
        'Fecha Creación': moment(data['FECHA_INGRESO_SOLPE']).format('DD-MM-YYYY'),
        Tipo: data["TIPO_SOLPE"],
        Forma: data["FORMA_SOLPE"],
        Ctt: data["CODIGO_CONTRATO"] !== 'N/A' ? data["CODIGO_CONTRATO"] : '',
        'Número documento': data["OC"],
        'Fecha estimada inicio de servicio': data['fecha_estimada_inicio_servicio'] !== null ? moment(data['fecha_estimada_inicio_servicio']).format('DD-MM-YYYY') : '',
        'Duración estimada de servicio': data['duracion_estimada_servicio'] !== null ? data['duracion_estimada_servicio'] : '',
        Moneda: data['Simbol'] !== null ? data['Simbol'] : '',
        Monto: data['MONTO_SOLPE'] !== null ? formatCurrency(data['MONTO_SOLPE']) : '',
        Estado: data['ESTADO_SOLPE'],
        Solicitante: data['USUA_SOLICITANTE'],
        'Ges. de Contratos y Compras': data['GCC'] ?? 'Por Definir'
      };
    }

    return new_data;
  }
  applyFilterSolpesGrl(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }
  sortData(sort: Sort) {
    let data = this.solpeInit.slice();

    switch(this.selectedFilter)
    {
      case 1://abiertas
        data = this.solpes.filter(t=> ((t.ESTADO_CORRELATIVO !== 81) && (t.ESTADO_CORRELATIVO !== 110) && (t.ESTADO_CORRELATIVO !== 130) && (t.VISIBLE === 1)));
        break;
      case 2: //cerradas
        data = this.solpes.filter(t=> ((t.ESTADO_CORRELATIVO == 110) || (t.ESTADO_CORRELATIVO == 130)) && (t.VISIBLE === 1));
        break;
      case 3://todos
        data = this.solpes.filter(t=> (t.VISIBLE === 1));
        break;
      case 4://Rechazo
        data = this.solpes.filter(t=> (t.ESTADO_CORRELATIVO == 81) && (t.VISIBLE === 1));
        break;
      case 5://ocultas
        data = this.solpes.filter(t=> (t.VISIBLE == 0));
        break;
    }

    if (!sort.active || sort.direction === '') {
      this.dataSource =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorSolpesGrl, this.dataSource);
      return;
    }



    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Codigo': return compare(a.SOLPE_CODIGO, b.SOLPE_CODIGO, isAsc);
        case 'TipoCtt': return compare(a.TIPO_SOLPE, b.TIPO_SOLPE, isAsc);
        case 'Forma': return compare(a.FORMA_SOLPE, b.FORMA_SOLPE, isAsc);
        case 'FechaInicio': return compare(a.FECHA_INGRESO_SOLPE, b.FECHA_INGRESO_SOLPE, isAsc);
        case 'FechaTermino': return compare(a.FECHA_TERMINO_SOLPE, b.FECHA_TERMINO_SOLPE, isAsc);
        case 'Nombre': return compare(a.NOMBRE_SOLPE, b.NOMBRE_SOLPE, isAsc);
        case 'Solicitante': return compare(a.USUA_SOLICITANTE, b.USUA_SOLICITANTE, isAsc);
        case 'Monto': return compare(a.MONTO_SOLPE, b.MONTO_SOLPE, isAsc);
        case 'Moneda': return compare(a.TIMO_DESCRIPCION, b.TIMO_DESCRIPCION, isAsc);
        case 'Oc': return compare(a.OC, b.OC, isAsc);
        case 'GCC': return compare(a.GCC, b.GCC, isAsc);
        /*case 'Estado': return compare(a.FECHA_EMISION, b.FECHA_EMISION, isAsc);
        case 'RespAbast': return compare(a.FECHA_INGRESO, b.FECHA_INGRESO, isAsc);
        case 'V': return compare(a.FECHA_VENCIMIENTO, b.FECHA_VENCIMIENTO, isAsc);
        case 'NroDoc': return compare(a.FECHA_PRORROGA, b.FECHA_PRORROGA, isAsc);*/
        default: return 0;
      }
    });
    if (this.sortedData){
      this.dataSource =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorSolpesGrl, this.dataSource);
    }
  }
  private resetForm(){
    this.formAgregarSolpe = new FormGroup({
      'solicitante' : new FormControl({value: this.username, disabled: true}),
      'tipo_solicitud' : new FormControl(null, Validators.required),
      'forma' : new FormControl(null),
      'fecha_ingreso' : new FormControl({value: moment().format('YYYY-MM-DD'), disabled: true}, Validators.required),
      'fecha_termino' : new FormControl(null),
      'fecha_estimada_inicio_servicio' : new FormControl(null),
      'duracion_estimada_servicio' : new FormControl(null),
      'nombre' : new FormControl(null, Validators.required),
      'descripcion' : new FormControl(null, Validators.required),
      'monto' : new FormControl(null),
      'moneda' : new FormControl(null),
      'agregar_empresa': new FormControl(null),
      'rut_empresa' : new FormControl(null),
      'razon_social' : new FormControl(null),
      'contrato': new FormControl(null)
    });

    this.submitted = false;
  }
  private resetFormArchivo(){
    this.formAgregarArchivo = new FormGroup({
      grupo : new FormControl(null, Validators.required),
      adjunto : new FormControl(null),
      corr_solpe: new FormControl(null)
    });
    this.submittedDocumento = false;
    this.fileName = null;
  }
  private resetFormComentario(){
    this.formAgregarComentario = new FormGroup({
      comentario : new FormControl(null, Validators.required),
      corr_solpe: new FormControl(null)
    });
    this.submittedComentario = false;
  }
  private getMonedas() {
    this.boletasService.getTipoDominios('TIMO').subscribe(resp => {
      this.monedas = JSON.parse(resp);
    });
  }
  private getTipos() {
    this.boletasService.getTipoDominios('TIPOSOLPE').subscribe(resp => {
      this.tipos = JSON.parse(resp);
    });
  }
  private getFormas() {
    this.boletasService.getTipoDominios('FORSOLPE').subscribe(resp => {
      this.formas = JSON.parse(resp);
    });
  }
  private getArchivos() {
    this.boletasService.getTipoDominios('DOCSOLPE').subscribe(resp => {
      this.archivos = JSON.parse(resp);
    });
  }

  private getContratos() {
    this.contratosService.getContratosBusquedaUsuario(this.userId).subscribe(resp=>{
      this.contratos= JSON.parse(resp);
    });
  }
  onChangeTipo() {
    if(this.formAgregarSolpe.controls.tipo_solicitud.value == 61) {
      this.lblFechaTerminoSolpe = '';
      this.mnjTipoCompras = true;
      this.tipoContrato = false;
      this.verFechasSolpeServicios = false;
    }
    else if(this.formAgregarSolpe.controls.tipo_solicitud.value == 82) {
      this.tipoContrato = true;
      this.verFechasSolpeServicios = false;

    }
    else if(this.formAgregarSolpe.controls.tipo_solicitud.value == 62) {
      this.lblFechaTerminoSolpe = 'Fecha estimada inicio de servicio';
      this.tipoContrato = false;
      this.verFechasSolpeServicios = true;
    }
    else if(this.formAgregarSolpe.controls.tipo_solicitud.value == 63) {
      this.lblFechaTerminoSolpe = 'Fecha estimada inicio de servicio';
      this.tipoContrato = false;
      this.verFechasSolpeServicios = true;
    }
    else {
      this.lblFechaTerminoSolpe = '';
      this.mnjTipoCompras = false;
      this.tipoContrato = false;
      this.verFechasSolpeServicios = false;
    }

    if(this.formAgregarSolpe.controls.tipo_solicitud.value == 82) {
      this.formAgregarSolpe = new FormGroup({
        'solicitante' : new FormControl({value: this.username, disabled: true}),
        'tipo_solicitud' : new FormControl(this.formAgregarSolpe.controls.tipo_solicitud.value, Validators.required),
        'forma' : new FormControl(null),
        'fecha_ingreso' : new FormControl({value: moment().format('YYYY-MM-DD'), disabled: true}, Validators.required),
        'fecha_termino' : new FormControl(null),
        'fecha_estimada_inicio_servicio' : new FormControl(null),
        'duracion_estimada_servicio' : new FormControl(null),
        'nombre' : new FormControl(null, Validators.required),
        'descripcion' : new FormControl(null, Validators.required),
        'monto' : new FormControl(null),
        'moneda' : new FormControl(null),
        'agregar_empresa': new FormControl(null),
        'rut_empresa' : new FormControl(null),
        'razon_social' : new FormControl(null),
        'contrato': new FormControl(null, Validators.required)
      });
      /* this.formAgregarSolpe.controls.contrato.setValidators();
      this.formAgregarSolpe.controls.contrato.updateValueAndValidity(); */
    }
    if(this.formAgregarSolpe.controls.tipo_solicitud.value != 82) {
      this.formAgregarSolpe = new FormGroup({
        'solicitante' : new FormControl({value: this.username, disabled: true}),
        'tipo_solicitud' : new FormControl(this.formAgregarSolpe.controls.tipo_solicitud.value, Validators.required),
        'forma' : new FormControl(null, Validators.required),
        'fecha_ingreso' : new FormControl({value: moment().format('YYYY-MM-DD'), disabled: true}, Validators.required),
        'fecha_termino' : new FormControl(null),
        'fecha_estimada_inicio_servicio' : new FormControl(null),
        'duracion_estimada_servicio' : new FormControl(null),
        'nombre' : new FormControl(null, Validators.required),
        'descripcion' : new FormControl(null, Validators.required),
        'monto' : new FormControl(null, Validators.required),
        'moneda' : new FormControl(null, Validators.required),
        'agregar_empresa': new FormControl(null),
        'rut_empresa' : new FormControl(null),
        'razon_social' : new FormControl(null),
        'contrato': new FormControl(null)
      });
    }

    if(this.formAgregarSolpe.controls.tipo_solicitud.value == 62) {
      this.formAgregarSolpe = new FormGroup({
        'solicitante' : new FormControl({value: this.username, disabled: true}),
        'tipo_solicitud' : new FormControl(this.formAgregarSolpe.controls.tipo_solicitud.value, Validators.required),
        'forma' : new FormControl(null, Validators.required),
        'fecha_ingreso' : new FormControl({value: moment().format('YYYY-MM-DD'), disabled: true}, Validators.required),
        'fecha_termino' : new FormControl(null),
        'fecha_estimada_inicio_servicio' : new FormControl(null),
        'duracion_estimada_servicio' : new FormControl(null),
        'nombre' : new FormControl(null, Validators.required),
        'descripcion' : new FormControl(null, Validators.required),
        'monto' : new FormControl(null, Validators.required),
        'moneda' : new FormControl(null, Validators.required),
        'agregar_empresa': new FormControl(null),
        'rut_empresa' : new FormControl(null),
        'razon_social' : new FormControl(null),
        'contrato': new FormControl(null)
      });
    }

    if(this.formAgregarSolpe.controls.tipo_solicitud.value == 63) {
      this.formAgregarSolpe = new FormGroup({
        'solicitante' : new FormControl({value: this.username, disabled: true}),
        'tipo_solicitud' : new FormControl(this.formAgregarSolpe.controls.tipo_solicitud.value, Validators.required),
        'forma' : new FormControl(null, Validators.required),
        'fecha_ingreso' : new FormControl({value: moment().format('YYYY-MM-DD'), disabled: true}, Validators.required),
        'fecha_termino' : new FormControl(null),
        'fecha_estimada_inicio_servicio' : new FormControl(null),
        'duracion_estimada_servicio' : new FormControl(null),
        'nombre' : new FormControl(null, Validators.required),
        'descripcion' : new FormControl(null, Validators.required),
        'monto' : new FormControl(null, Validators.required),
        'moneda' : new FormControl(null, Validators.required),
        'agregar_empresa': new FormControl(null),
        'rut_empresa' : new FormControl(null),
        'razon_social' : new FormControl(null),
        'contrato': new FormControl(null)
      });
    }
  }
  onChangeForma() {
    if(this.formAgregarSolpe.controls.forma.value == 66) {
      this.formaPagosMis = true;
      this.formAgregarSolpe.controls.rut_empresa.setValidators(Validators.required);
      this.formAgregarSolpe.controls.razon_social.setValidators(Validators.required);
      this.formAgregarSolpe.controls.rut_empresa.updateValueAndValidity();
      this.formAgregarSolpe.controls.razon_social.updateValueAndValidity();

    } else {
      this.formaPagosMis = false;

      this.formAgregarSolpe.controls.rut_empresa.clearValidators();
      this.formAgregarSolpe.controls.razon_social.clearValidators();
      this.formAgregarSolpe.controls.rut_empresa.updateValueAndValidity();
      this.formAgregarSolpe.controls.razon_social.updateValueAndValidity();
    }

  }
  addEmpresaPropuesta() {
    if (this.formAgregarSolpe.controls.agregar_empresa.value === '' || this.formAgregarSolpe.controls.agregar_empresa.value === null) {
      this.notifyService.showInfo('Debe ingresar una Empresa.', 'Agregar empresa');
    } else {
      this.empresas_propuestas.push(this.formAgregarSolpe.controls.agregar_empresa.value);
      this.formAgregarSolpe.controls.agregar_empresa.setValue(null);
    }
  }
  onCrear() {
    this.submitted = true;
    if (this.formAgregarSolpe.invalid) {
      return;
    }

    // validacion de si excede los 20.000 del MAF
    if (this.formAgregarSolpe.value.forma == 66) {
      this.solpeService.postRetornaMontoUsdSolpe({pin_monto: this.formAgregarSolpe.value.monto, pin_domi_timo_correlativo: this.formAgregarSolpe.value.moneda}).subscribe(resp => {
        let responseValorUsdSolpe: any = JSON.parse(resp);
        let valorUsdSolpe: number = responseValorUsdSolpe.monto_usd_solpe;

        if (valorUsdSolpe > 20000) {
          this.notifyService.showWarning('La SOLPE excede el monto permitido por el MAF para Pagos Misceláneo según MAF', 'Crear Solpe');
          return;
        }

        let rut: number = null;
        let digito: string = null;
        let rut_empresa = this.formAgregarSolpe.value.rut_empresa;

        if(rut_empresa !== null) {
          rut_empresa = rut_empresa.split('-');
          rut = rut_empresa[0];
          digito = rut_empresa[1];
        }

        this.formAgregarSolpe.controls.fecha_ingreso.enable();
        //let fecha_ingreso = (this.formAgregarSolpe.value.fecha_ingreso) ? moment(this.formAgregarSolpe.value.fecha_ingreso).format('DD-MM-YYYY') : null;
        let fecha_ingreso = (this.formAgregarSolpe.value.fecha_ingreso) ? moment(this.formAgregarSolpe.value.fecha_ingreso).format('YYYY-MM-DD') : null;
        let fecha_termino, fecha_entrega_oc, fecha_fin: string;
        if(this.formAgregarSolpe.controls.tipo_solicitud.value == 61) {
          fecha_termino = null;
          //fecha_entrega_oc = (this.formAgregarSolpe.value.fecha_termino) ? moment(this.formAgregarSolpe.value.fecha_termino).format('DD-MM-YYYY') : null;
          fecha_entrega_oc = (this.formAgregarSolpe.value.fecha_termino) ? moment(this.formAgregarSolpe.value.fecha_termino).format('YYYY-MM-DD') : null;
          fecha_fin = fecha_entrega_oc;

        } else {
          //fecha_termino = (this.formAgregarSolpe.value.fecha_termino) ? moment(this.formAgregarSolpe.value.fecha_termino).format('DD-MM-YYYY') : null;
          fecha_termino = (this.formAgregarSolpe.value.fecha_termino) ? moment(this.formAgregarSolpe.value.fecha_termino).format('YYYY-MM-DD') : null;
          fecha_entrega_oc = null;
          fecha_fin = fecha_termino;
        }
        let f_inicio = moment(fecha_ingreso, "YYYY-MM-DD").format();
        let f_termino = moment(fecha_fin, "YYYY-MM-DD").format();

        if(f_termino < f_inicio) {
          this.notifyService.showInfo('Fecha de término no puede ser menor a fecha de inicio', 'Crear Solpe');
          return
        }

            const postData : {
              in_usua_correlativo_solicitante: number,
              in_domi_correlativo_tiposolpe: number,
              in_domi_correlativo_forsolpe: number,
              iv_fecha_ingreso_solpe: string,
              iv_fecha_termino_solpe: string,
              iv_nombre_solpe: string,
              iv_descripcion: string,
              in_monto: number,
              in_domi_corrrelativo_timo: number,
              iv_fecha_entrega_oc: string,
              in_rut_empresa: number,
              iv_dig_rut_empresa: string,
              iv_razon_social: string,
              iv_empresas_propuestas: string,
              in_cont_correlativo: number,
              in_cargo_correlativo: number,
              in_fecha_estimada_inicio_servicio: string,
              in_duracion_estimda_servicio: number
              } = {
                in_usua_correlativo_solicitante: 0,
                in_domi_correlativo_tiposolpe: this.formAgregarSolpe.value.tipo_solicitud,
                in_domi_correlativo_forsolpe: this.formAgregarSolpe.value.forma,
                iv_fecha_ingreso_solpe: fecha_ingreso,
                iv_fecha_termino_solpe: null,
                iv_nombre_solpe: this.formAgregarSolpe.value.nombre,
                iv_descripcion: this.formAgregarSolpe.value.descripcion,
                in_monto: this.formAgregarSolpe.value.monto,
                in_domi_corrrelativo_timo: this.formAgregarSolpe.value.moneda,
                iv_fecha_entrega_oc: fecha_entrega_oc,
                in_rut_empresa: rut,
                iv_dig_rut_empresa: digito,
                iv_razon_social: this.formAgregarSolpe.value.razon_social,
                iv_empresas_propuestas: this.empresas_propuestas.toString(),
                in_cont_correlativo: this.formAgregarSolpe.value.contrato,
                in_cargo_correlativo: this.rolId,
                in_fecha_estimada_inicio_servicio: this.formAgregarSolpe.value.fecha_estimada_inicio_servicio,
                in_duracion_estimda_servicio: this.formAgregarSolpe.value.duracion_estimada_servicio,
            };
            if(postData.in_domi_correlativo_tiposolpe == 82)
            {
              postData.in_domi_correlativo_forsolpe = 0;
            }
            this.solpeService.postSolpe(postData).subscribe(resp => {
              this.notifyService.showSuccess('Solpe creada con exito.', 'Crear Solpe');

              this.dataService.corrSolpe = JSON.parse(resp)[0].SOLPE_CORRELATIVO;
              this.dataService.corrEstadoSolpe = JSON.parse(resp)[0].ESTADO_CORRELATIVO;
              this.router.navigate(['/detalle-solpe/' + JSON.parse(resp)[0].SOLPE_CORRELATIVO]);
            }, err =>{
              this.notifyService.showError('Error al crear Solpe', 'Crear Sple');
              console.log(err);
            });
            //this.getSolpes();
            this.getSolpesNew()
            this.resetForm();
            this.closeModalAgregar.nativeElement.click();
          });
    }else{
      let rut: number = null;
      let digito: string = null;
      let rut_empresa = this.formAgregarSolpe.value.rut_empresa;

      if(rut_empresa !== null) {
        rut_empresa = rut_empresa.split('-');
        rut = rut_empresa[0];
        digito = rut_empresa[1];
      }

      this.formAgregarSolpe.controls.fecha_ingreso.enable();
      //let fecha_ingreso = (this.formAgregarSolpe.value.fecha_ingreso) ? moment(this.formAgregarSolpe.value.fecha_ingreso).format('DD-MM-YYYY') : null;
      let fecha_ingreso = (this.formAgregarSolpe.value.fecha_ingreso) ? moment(this.formAgregarSolpe.value.fecha_ingreso).format('YYYY-MM-DD') : null;
      let fecha_termino, fecha_entrega_oc, fecha_fin: string;
      if(this.formAgregarSolpe.controls.tipo_solicitud.value == 61) {
        fecha_termino = null;
        //fecha_entrega_oc = (this.formAgregarSolpe.value.fecha_termino) ? moment(this.formAgregarSolpe.value.fecha_termino).format('DD-MM-YYYY') : null;
        fecha_entrega_oc = (this.formAgregarSolpe.value.fecha_termino) ? moment(this.formAgregarSolpe.value.fecha_termino).format('YYYY-MM-DD') : null;
        fecha_fin = fecha_entrega_oc;

      } else {
        //fecha_termino = (this.formAgregarSolpe.value.fecha_termino) ? moment(this.formAgregarSolpe.value.fecha_termino).format('DD-MM-YYYY') : null;
        fecha_termino = (this.formAgregarSolpe.value.fecha_termino) ? moment(this.formAgregarSolpe.value.fecha_termino).format('YYYY-MM-DD') : null;
        fecha_entrega_oc = null;
        fecha_fin = fecha_termino;
      }

    /*  let f_inicio = moment(fecha_ingreso, "DD-MM-YYYY").format();
      let f_termino = moment(fecha_fin, "DD-MM-YYYY").format(); */
      /* fecha_ingreso = moment(fecha_ingreso, "YYYY-MM-DD").format();
      fecha_termino = moment(fecha_termino, "YYYY-MM-DD").format(); */
      let f_inicio = moment(fecha_ingreso, "YYYY-MM-DD").format();
      let f_termino = moment(fecha_fin, "YYYY-MM-DD").format();

      if(f_termino < f_inicio) {
        this.notifyService.showInfo('Fecha de término no puede ser menor a fecha de inicio', 'Crear Solpe');
        return
      }

          const postData : {
            in_usua_correlativo_solicitante: number,
            in_domi_correlativo_tiposolpe: number,
            in_domi_correlativo_forsolpe: number,
            iv_fecha_ingreso_solpe: string,
            iv_fecha_termino_solpe: string,
            iv_nombre_solpe: string,
            iv_descripcion: string,
            in_monto: number,
            in_domi_corrrelativo_timo: number,
            iv_fecha_entrega_oc: string,
            in_rut_empresa: number,
            iv_dig_rut_empresa: string,
            iv_razon_social: string,
            iv_empresas_propuestas: string,
            in_cont_correlativo: number,
            in_cargo_correlativo: number,
            in_fecha_estimada_inicio_servicio: string,
            in_duracion_estimda_servicio: number
            } = {
              in_usua_correlativo_solicitante: 0,
              in_domi_correlativo_tiposolpe: this.formAgregarSolpe.value.tipo_solicitud,
              in_domi_correlativo_forsolpe: this.formAgregarSolpe.value.forma,
              iv_fecha_ingreso_solpe: fecha_ingreso,
              iv_fecha_termino_solpe: null,
              iv_nombre_solpe: this.formAgregarSolpe.value.nombre,
              iv_descripcion: this.formAgregarSolpe.value.descripcion,
              in_monto: this.formAgregarSolpe.value.monto,
              in_domi_corrrelativo_timo: this.formAgregarSolpe.value.moneda,
              iv_fecha_entrega_oc: fecha_entrega_oc,
              in_rut_empresa: rut,
              iv_dig_rut_empresa: digito,
              iv_razon_social: this.formAgregarSolpe.value.razon_social,
              iv_empresas_propuestas: this.empresas_propuestas.toString(),
              in_cont_correlativo: this.formAgregarSolpe.value.contrato,
              in_cargo_correlativo: this.rolId,
              in_fecha_estimada_inicio_servicio: this.formAgregarSolpe.value.fecha_estimada_inicio_servicio,
              in_duracion_estimda_servicio: this.formAgregarSolpe.value.duracion_estimada_servicio,
          };
          if(postData.in_domi_correlativo_tiposolpe == 82)
          {
            postData.in_domi_correlativo_forsolpe = 0;
          }
          this.solpeService.postSolpe(postData).subscribe(resp => {
            this.notifyService.showSuccess('Solpe creada con exito.', 'Crear Solpe');

            this.dataService.corrSolpe = JSON.parse(resp)[0].SOLPE_CORRELATIVO;
            this.dataService.corrEstadoSolpe = JSON.parse(resp)[0].ESTADO_CORRELATIVO;
            this.router.navigate(['/detalle-solpe/' + JSON.parse(resp)[0].SOLPE_CORRELATIVO]);
          }, err =>{
            this.notifyService.showError('Error al crear Solpe', 'Crear Sple');
            console.log(err);
          });
          //this.getSolpes();
          this.getSolpesNew()
          this.resetForm();
          this.closeModalAgregar.nativeElement.click();
    }
  }

  onClose() {
    this.resetForm();
    this.formAgregarSolpe.controls.fecha_ingreso.disable();

  }
  revisarSolpe(corr_solpe: number, corr_estado_solpe: number) {
    this.dataService.corrSolpe = corr_solpe
    this.dataService.corrEstadoSolpe = corr_estado_solpe
  }
  /** Archivo */
  private getDocumentosSolpe(in_solpe_correlativo: number) {
    this.solpeService.getDocumentosSolpe(in_solpe_correlativo, this.rolId).subscribe(resp => {
      this.documentosSolpe = JSON.parse(resp);
    });
  }
  cargarModalArchivo(correlativoSolpe: number) {
    this.corrSolpe = correlativoSolpe;
    this.formAgregarArchivo.controls.corr_solpe.setValue(correlativoSolpe);
    this.getDocumentosSolpe(correlativoSolpe);
  }
  detectFile(event: any) {
    const reader = new FileReader();
    let bs64 = null;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        bs64 = reader.result;
        if (bs64 !== null){
              this.archivos64 = bs64
              this.fileName = event.target.files[0].name;
              this.type = event.target.files[0].name.slice((event.target.files[0].name.lastIndexOf(".") - 1 >>> 0) + 2) ;
        }
      };
    }
  }

  onAgregarArchivo() {
    this.submittedDocumento = true;
    if (this.formAgregarArchivo.invalid) {
      return;
    }

    if(this.fileName) {
      const postDataFile : {fileName: string, type: string, files: string } = { fileName: this.fileName , type : this.type , files: this.archivos64}
      this.addonsService.postFileSolpe(postDataFile).subscribe(resp =>{
        let descripciones: any = JSON.parse(resp);
        let nombreArchivo: string = descripciones.nombreArchivo;
        let descripcionArchivo: string = descripciones.descripcionArchivo;
        this.postDocumento(nombreArchivo, descripcionArchivo);
      });
    }
    return;
  }
  private postDocumento(nombreArchivo: string, descripcionArchivo: string) {
    const postData : {
      in_solpe_correlativo: number,
      in_domi_correlativo_docsolpe: number,
      iv_nombre_archivo: string,
      iv_descripcion_archivo: string,
      in_usua_correlativo_creacion: number
    } = {
      in_solpe_correlativo: this.corrSolpe,
      in_domi_correlativo_docsolpe: this.formAgregarArchivo.value.grupo,
      iv_nombre_archivo: nombreArchivo,
      iv_descripcion_archivo: descripcionArchivo,
      in_usua_correlativo_creacion: 0
    };

    this.solpeService.postDocumentoSolpe(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Documento creado con exito.', 'Crear Documento');
    }, err =>{
      this.notifyService.showError('Error al crear documento', 'Crear Documento');
      console.log(err);
    });
    this.getDocumentosSolpe(this.corrSolpe);
    this.resetFormArchivo();
  }
  onCloseArchivo() {
    this.resetFormArchivo();
  }
  onDescargar(fileName: string) {
    this.fileService.downloadDocumentoSolpe(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }

  // Eliminar archivode solpe
  onEliminarArchivoSolpe(correlativo_archivo: number, fileName: string) {
    console.log(correlativo_archivo);

    this.fileService.deleteFile({in_file_name: fileName, in_file_type: "solpe", in_correlativo: correlativo_archivo}).subscribe(resp => {
      this.notifyService.showSuccess('Eliminado ...', 'Documento eliminado');
      this.getDocumentosSolpe(this.corrSolpe);
    }, err =>{
      this.getDocumentosSolpe(this.corrSolpe);
      this.notifyService.showError(err.error.message, 'Erro al eliminar archivo');
    });
  }

  /**Comentarios */
  private getComentariosSolpe(in_solpe_correlativo: number) {
    this.solpeService.getComentariosSolpe(in_solpe_correlativo).subscribe(resp => {
      this.comentariosSolpe = JSON.parse(resp);
    });
  }
  cargarModalComentarios(correlativoSolpe: number) {
    this.corrSolpe = correlativoSolpe;
    this.formAgregarComentario.controls.corr_solpe.setValue(correlativoSolpe);
    this.getComentariosSolpe(correlativoSolpe);
  }
  onAgregarComentario() {
    this.submittedComentario = true;
    if (this.formAgregarComentario.invalid) {
      return;
    }

    const postData : {
      in_solpe_correlativo: number,
      iv_comentario: string,
      in_usua_correlativo_creacion: number
    } = {
      in_solpe_correlativo: this.corrSolpe,
      iv_comentario: this.formAgregarComentario.value.comentario,
      in_usua_correlativo_creacion: 0
    };

    this.solpeService.postComentarioSolpe(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Comentario creado con exito.', 'Crear Comentario');
    }, err =>{
      this.notifyService.showError('Error al crear comentario', 'Crear Comentario');
      console.log(err);
    });
    this.getComentariosSolpe(this.corrSolpe);
    this.resetFormComentario();
  }
  onCloseComentario() {
    this.resetFormComentario();
  }
  GetContrato(idContrato)
  {
    if(idContrato != '') {
      this.contratosService.getContratoId(idContrato).subscribe(resp => {
        this.contratoInfo = JSON.parse(resp)[0];
      });
      //this.getModificacionCto()
    }
    else
    {
      this.contratoInfo= null;
    }
  }

  private isFullCargo() {
    return (this.arrCargosFullPrivilegio.includes(this.rolId));
  }


  get f() { return this.formAgregarSolpe.controls; }
  get fd() { return this.formAgregarArchivo.controls; }
  get fc() { return this.formAgregarComentario.controls; }

  deleteEmpresaSugerida(index: number){
    console.log(index);
    if(index > -1){
      this.empresas_propuestas.splice(index, 1);
    }
  }

  getSolpesNew() {

    if (!this.startDate){
      this.startDate = moment().subtract(90, "days").format('YYYY-MM-DD')
      this.endDate = moment().format('YYYY-MM-DD')
    }
    //if (this.endDate == "")
    this.solpeService.getSolpesByType(this.userId, this.rolId, this.typeSolpe, this.startDate, this.endDate).subscribe(resp => {
      //this.dataSource = new MatTableDataSource(JSON.parse(resp));

      this.solpes = JSON.parse(resp);
      this.solpeInit = JSON.parse(resp);

      //this.totales.PARA_GESTION = this.solpes.length;//ESTADO_CORRELATIVO
      this.totalSolpes.total = this.solpes.filter(t=> (t.VISIBLE == 1)).length;
      const Rechazadas = this.solpes.filter(t=> (t.ESTADO_CORRELATIVO == 81) && (t.VISIBLE === 1)).length;
      const Cerradas = this.solpes.filter(t=> (t.ESTADO_CORRELATIVO == 110) && (t.VISIBLE === 1)).length;
      const Aplicadas = this.solpes.filter(t=> (t.ESTADO_CORRELATIVO == 130) && (t.VISIBLE === 1)).length;
      const SolpesOcultas = this.solpes.filter(t=> (t.VISIBLE == 0)).length;

      this.totales.PARA_GESTION = ((this.totalSolpes.total - Rechazadas) - Cerradas) - Aplicadas;
      this.totales.CERRADAS = Cerradas + Aplicadas;
      this.totales.RECHAZADAS = Rechazadas;
      this.totales.OCULTAS = SolpesOcultas;

      this.dataSource = new MatTableDataSource(this.solpes.filter(t=> ((t.ESTADO_CORRELATIVO !== 81) && (t.ESTADO_CORRELATIVO !== 110) && (t.ESTADO_CORRELATIVO !== 130) && (t.VISIBLE === 1))));
      this.createPaginator(this.paginatorSolpesGrl, this.dataSource);
      //console.log(JSON.parse(resp))
    })
  }

  filterDataSource(estado: number)
  {
    switch(estado)
    {
      case 1://abiertas
        this.dataSource = new MatTableDataSource(this.solpes.filter(t=> ((t.ESTADO_CORRELATIVO !== 81) && (t.ESTADO_CORRELATIVO !== 110) && (t.ESTADO_CORRELATIVO !== 130) && (t.VISIBLE === 1))));
        break;
      case 2: //cerradas
        this.dataSource = new MatTableDataSource(this.solpes.filter(t=> ((t.ESTADO_CORRELATIVO == 110) || (t.ESTADO_CORRELATIVO == 130)) && (t.VISIBLE === 1)));
        break;
      case 3://todos
        this.dataSource = new MatTableDataSource(this.solpes.filter(t=> (t.VISIBLE === 1)));
        break;
      case 4://rechazadas
        this.dataSource = new MatTableDataSource(this.solpes.filter(t=> (t.ESTADO_CORRELATIVO == 81) && (t.VISIBLE === 1)));
        break;
      case 5: //ocultas
        this.dataSource = new MatTableDataSource(this.solpes.filter(t=> (t.VISIBLE == 0)));
        break;
      default:
        this.dataSource = new MatTableDataSource(this.solpes.filter(t=> (t.ESTADO_CORRELATIVO == estado) && (t.VISIBLE === 1)));
        break;
    }
    //this.dataSource = new MatTableDataSource(this.solpes.filter(t=> ((t.ESTADO_CORRELATIVO !== 81) && (t.ESTADO_CORRELATIVO !== 110) && (t.ESTADO_CORRELATIVO !== 130))));
    this.createPaginator(this.paginatorSolpesGrl, this.dataSource);
  }

  endDateChange(event: Event)
  {
    const endDateValue = (event.target as HTMLInputElement).value;

    const startDate = moment();
    const endDate = moment(endDateValue, 'YYYY-MM-DD');

    if (startDate.isAfter(endDate)) {
      //this.errors.endDate = 'La fecha debe ser mayor a la de inicio';
      alert('La fecha debe ser mayor a la de inicio')
      return;
    }


  }
  dateDiff(startDate, endDate)
  {
    //return startDate + ', ' + endDate
    if(!endDate)
    {
      return ''
    }

    const mStartDate = moment(startDate, 'YYYY-MM-DD');
    const mEndDate = moment(endDate, 'YYYY-MM-DD');

    const months = mEndDate.diff(mStartDate, 'months', true)
    const days = mEndDate.diff(mStartDate, 'days')

    return months.toFixed(1) + ` Meses (`+days+` dias)`
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
