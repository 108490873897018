<div id="content" class="content">
    <!-- begin breadcrumb -->
    <ol class="breadcrumb float-xl-right">
        <li class="breadcrumb-item"><a href="javascript:;">Contrato</a></li>
        <li class="breadcrumb-item active">Editar contrato</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Editar contrato #{{idContrato}}</h1>
    <!-- end page-header -->

    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12">
            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-10">
                <!-- begin panel-heading -->
                <div class="panel-heading">
                    <h4 class="panel-title">Default Style</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="panel-body">
                    <form action="/" method="POST">
                        <div class="row">
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Codigo contrato</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Codigo SOLPE</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Nombre del contrato</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Tipo de contrato</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Origen</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Estado del proceso</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Estado del contrato</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Proveedor</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Moneda</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Monto original</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Fecha del contrato</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Fecha de inicio contrato</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Fecha de termino contrato</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Gerencia</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Area</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Administrador</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Gestionador</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                            <div class="col col-xl-4">
                                <label for="exampleInputEmail1">Requiere BG?</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>
                        </div>
                    </form>
                </div>
                <!-- end panel-body -->
            </div>
            <!-- end panel -->
        </div>
        <!-- end col-6 -->
    </div>
    <!-- end row -->
</div>