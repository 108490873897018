import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import { tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

interface AuthResponseData {
  token: string;
  refreshToken: string;
  userId: number;
  expiresIn: number;
  userName: string;
  rolId: number;
  rolName: string;
  cambioClave: boolean;
  esEmsa: string;
  lastConection: string;
}

export interface ChangePasswordRequestData {
  userId: number;
  oldPassword: string;
  newPassword: string;
  reNewPassword: string;
}

export interface ForgotPasswordRequestData {
  username: string;
  code: string;
  password: string;
  repassword: string;
}

export interface RefreshTokenResponseData {
  token: string;
  refreshToken: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;
  private urlService: string = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router, private httpService: HttpService) { }

  signin(postLogin: { email: string; password: string; }) {
    return this.http
      .post<AuthResponseData>(
        this.urlService + 'auth/signin',
        postLogin
      ).pipe(
        tap(resData => {
          this.handleAuth(resData);
        }));
  }
  autoLogin() {
    const userData: User = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }

    const loadedUser = new User(userData);

    if (loadedUser.getToken()) {
      this.user.next(loadedUser);
      const expirationDuration =
        new Date(userData._tokenExpiration).getTime() -
        new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }
  logout() {
    this.user.next(null);
    this.router.navigate(['/auth']);
    localStorage.removeItem('userData');
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }
  autoLogout(expirationDuration: number) {
    clearTimeout(this.tokenExpirationTimer);
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }
  private handleAuth(resData: AuthResponseData) {
    const expiresIn = +resData.expiresIn * 1000;
    const expirationDate = new Date(new Date().getTime() + expiresIn);
    const userData: User = {
      ...resData,
      _token: resData.token,
      _tokenExpiration: expirationDate,
      expiresIn,
      getToken: () => null,
    };
    const user = new User(userData);
    this.user.next(user);
    this.autoLogout(expiresIn);
    this.saveUserDataStorage(user)
  }
  getCargosPorUsuario(in_pers_correlativo: number) {
    return this.http
      .get(this.urlService + 'auth/getCargosPorUsuario/' + in_pers_correlativo)
      .pipe(
        map(resp => {
          let cargos = [];
          for (const item in resp['cargos']) {
            cargos.push({ ...resp['cargos'][item] });
          }
          return JSON.stringify(cargos);
        }));

  }

  getModulosPorCargo(in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'auth/getModulosPorCargo/' + in_cargo_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let modulos = [];
          for (const item in resp['modulos']) {
            modulos.push({ ...resp['modulos'][item] });
          }
          return JSON.stringify(modulos);
        }));

  }
  getAccionesPorUsuario(in_menu_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    const userId = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .get(this.urlService + 'auth/getAccionesPorUsuario/' + userId + '/' + in_menu_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let acciones = [];
          for (const item in resp['acciones']) {
            acciones.push({ ...resp['acciones'][item] });
          }
          return JSON.stringify(acciones);
        }));

  }
  sendCode(postSendCode: { username: string; }) {
    return this.httpService.post({
      url: 'auth/forgot',
      data: postSendCode
    });
  }
  forgotPassword(postForgotPassword: ForgotPasswordRequestData) {
    return this.httpService.post({
      url: 'auth/forgot/change',
      data: postForgotPassword
    });
  }
  changePassword(postChangePassword: ChangePasswordRequestData) {
    return this.httpService.put({
      url: 'auth/changePassword',
      data: postChangePassword
    });
  }

  getUserDataStorage(): User {
    return JSON.parse(localStorage.getItem('userData'))
  }

  saveUserDataStorage(data: User) {
    localStorage.setItem('userData', JSON.stringify(data))
  }

  refreshToken() {
    const refreshToken = this.getUserDataStorage().refreshToken;
    const postRefreshToken = {
      refreshToken,
    }
    return this.httpService.post({
      url: 'auth/refreshToken',
      data: postRefreshToken
    });
  }

  updateToken() {
    this.refreshToken().subscribe((resp: RefreshTokenResponseData) => {
      const data = this.getUserDataStorage();
      const expiresIn = data.expiresIn;
      const expirationDate = new Date(new Date().getTime() + expiresIn);
      const userData: User = {
        ...data,
        _token: resp.token,
        _tokenExpiration: expirationDate,
        refreshToken: resp.refreshToken,
        getToken: () => null,
      };
      const user = new User(userData);
      this.user.next(user);
      this.autoLogout(expiresIn);
      this.saveUserDataStorage(user)
    })
  }
}