import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { getUser } from '../../shared/functions/common';

export class ICurrencies {
  id: number;
  description: string;
  ISOCode: string;
  Simbol: string;
  Decimal: number;
  ExchageRate: number;
  Principal: boolean;
  TimoCode: number;
  decimalPipe: string;
}

interface LoadIp {
  ip: string;
  country: string;
  'geo-ip': string;
  'API Help': string;
}

export const IP_SERVICE_URL = 'https://api.ipify.org/?format=json';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {
  CurrenciesList: ICurrencies[];
  USD: ICurrencies;
  CLP: ICurrencies;
  private urlService: string = environment.apiUrl;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.CurrenciesList = [];
    this.GetCurrencie();
  }

  GetCurrencie() {
    const user = getUser();
    const token = user?._token;
    if (!token) {
      return;
    }
    return this.http
      .get<any>(this.urlService + 'currencies/getCurrencieList', {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .subscribe(response => {
        this.CurrenciesList = response.data;
        this.USD = this.CurrenciesList.find(currencie => currencie.TimoCode === 12);
        this.CLP = this.CurrenciesList.find(currencie => currencie.TimoCode === 9);
      });
    /* .pipe(
        map(resp => {
            let contratos = [];
            for (const item in resp['contratos']) {
              contratos.push({...resp['contratos'][item] });
            }
            return JSON.stringify(contratos);
        })); */
  }
  loadIp() {
    // const ip = this.cookieService.get('ip');
    // if (ip) {
    //   return ip;
    // }
    this.http
      .get(IP_SERVICE_URL).subscribe((value: LoadIp) => {
        this.cookieService.set('ip', value.ip);
        return value.ip;
      });
  }
}
