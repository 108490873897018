<div id="content" class="content">
    <!-- begin row -->
    <div class="row">
        <!-- begin col-6 -->
        <div class="col-xl-12 ui-sortable">
            <!-- begin panel -->

            <!-- end panel -->
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                <!-- begin panel-heading -->
                <!-- <div class="panel-heading ui-sortable-handle">
                    <h4 class="panel-title normalText">Funcionalidad por Usuario</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div> -->
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="p3-rem bg-white rounded">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h4 class="panel-title normalText">Roles por Usuario</h4>
                        </div>
                        <div class="col-12 col-md-6 text-right">
                            <button class="btn tag mb-1 text-primary" (click)="exportAsXLSX()">
                                <i class="fas fa-lg fa-fw m-r-10 fa-file-excel"></i> Exportar
                            </button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 col-md-8">
                        </div>
                        <div class="col-12 col-md-4 text-right"
                        style="display: flex;align-content: end;flex-wrap: wrap;margin-bottom: 10px;"
                        >
                            <input class="form-control" 
                            matInput (keyup)="filterUser($event) " 
                            placeholder="Buscar ... " #input>
                        </div>
                    </div>
                    <!-- <hr> -->
                    <div class="row">
                        <div class="col col-md-12">
                            <table mat-table [dataSource]="dataUsersFilter" matSort 
                            (matSortChange)="sortData($event)" 
                            class="table table-striped border table-td-valign-middle table-th-valign-middle ">
                                <!-- Codigo Column -->

                                <!-- Nombre Column -->
                                <ng-container matColumnDef="CORREO">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="CORREO">
                                        USUARIO
                                    </th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> 
                                        <p class="mb-0">{{element.NOMBRES}} {{element.APELLIDO_PATERNO}} {{element.APELLIDO_MANTERNO}}</p>
                                        <span class="text-muted">{{element.CORREO}}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="CARGO_DECRIPCION">
                                    <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="CARGO_DECRIPCION">
                                        ROL
                                    </th>
                                    <td style="padding: 10px !important;" mat-cell *matCellDef="let element "> 
                                        <span>{{element.CARGO_DECRIPCION}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="NOMBRE">
                                    <th style="padding: 10px !important; text-align: center !important;" mat-header-cell *matHeaderCellDef mat-sort-header>
                                    
                                    </th>
                                    <td style="padding: 10px !important; text-align: center;" mat-cell *matCellDef="let element ">
                                        <!-- <a href="#modal" data-toggle="modal" (click)="cargarAcciones(element.ID, element.ID_MENU)">
                                            <i class="fas fa-lg fa-fw m-r-10 fa-eye" style="color: #F59C1A;"></i>
                                        </a> -->
                                    </td>
                                </ng-container>

                                <!--<div *ngFor="let item of acciones">
                                    <ng-container matColumnDef="{{item}}">
                                        <th class=" normalText text-center" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header>{{item}}</th>
                                        <td style="padding: 10px !important;" class="text-center" mat-cell *matCellDef="let element ">{{ existeTexto(element.ACCION_NOMBRE, item) }}</td>
                                    </ng-container>
                                </div>-->

                                <tr mat-header-row *matHeaderRowDef="dcRolGrl"></tr>
                                <tr mat-row *matRowDef="let row; columns: dcRolGrl;" id="row" style="cursor: pointer;" 
                                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row " *matNoDataRow>
                                    <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                                </tr>
                            </table>

                            <mat-paginator #paginatorFuncionalidadGrl [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>

                </div>
                <!-- end panel-body -->
            </div>
        </div>
        <!-- end col-6 -->

    </div>
    <!-- end row -->

</div>


<!-- <div class="modal fade" id="modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Acciones Usuario {{ nombreUsuario }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="row" *ngFor="let item of acciones">
                    <div class="col-md-5">{{ item }}:</div>
                    <div class="col-md-2"></div>
                    <div class="col-md-3"><i *ngIf="condicionalExisteTexto(accionesUsuarioSelect, item)" class="far fa-lg fa-fw m-r-10 fa-check-square"></i></div>
                </div>
                <br>
                <a href="javascript:;" #closeModalAgregar class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a>
            </div>
        </div>
    </div>
</div> -->