<div id="content" class="content">
    <a routerLink="/dashboard-itemizado" style="margin-right: 10px;" class="btn btn-primary"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"></i> Volver</a>
    <br>
    <br>
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Detalle Itemizado</h4>
        </div>
        <div class="panel-body">
            <h4>Asignar Articulos</h4>
            <br>
            <h5>{{ contrato.NOMBRE_CONTRATO }} <br> {{ contrato.PROVEEDOR }}</h5>
            <br>
            <hr>
            <div class="row">
                <div class="col-md-2">Asignar a Todos:</div>
                <div class="col-md-4">
                    <ng-select2 [data]="articulos" #articulos_all  width="100%" (valueChanged)="onChangeAllArt($event)" name="articulos" class="select2-selection select2-selection--single">
                    </ng-select2>
                </div>
            
                <div class="col-md-6 text-right">
                    <button class="btn btn-primary" data-target="#modalValidarGuardarArticulo" data-toggle="modal"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-save"></i> Guardar</button>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead class="thead-dark">
                            <th>R</th>
                            <th>Q</th>
                            <th>SubPos</th>
                            <th>Descripción</th>
                            <th>Unidad</th>
                            <th>Moneda</th>
                            <th>Cantidad</th>
                            <th>V. Unitario</th>
                            <th width="25%">Seleccionar Articulo</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of itemizado">
                                <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>

                                <td *ngIf="!item.Cabecera && item.R" class="pt-2"><i class="fa fa-check"></i></td>
                                <td *ngIf="!item.Cabecera && !item.R" class="pt-2">&nbsp;</td>
                                <td *ngIf="!item.Cabecera && item.Scpq" class="pt-2"><i class="fa fa-check"></i></td>
                                <td *ngIf="!item.Cabecera && !item.Scpq" class="pt-2">&nbsp;</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Subpos }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Descripcion }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Unidad }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ contrato.TIPO_MONEDA }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>      
                                <td *ngIf="!item.Cabecera">
                                    <ng-select2 [data]="articulos" #articulo width="100%" name="articulo" [(ngModel)]="data_articulos[getItemByid(item.Correlativo)].articulo" class="select2-selection select2-selection--single">
                                    </ng-select2>
                                </td>                          
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <br>
            
        </div>
    </div>
</div>
<!-- Modal para confirmacion de guardado y validacion de itemizado -->
<div class="modal fade" id="modalValidarGuardarArticulo" tabindex="-1" role="dialog" aria-labelledby="modalValidarGuardarArticulo" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modalValidarGuardarArticulo">Validación y Guardado de Articulo</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="padding: 2rem;" class="modal-body">
            <p style="font-size: 16px;">Una vez se guarden los articulos No podrán ser modificados.</p>
            <p style="font-size: 16px;">¿Está seguro que desea guardar los articulos asignados?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="guardar()" class="btn btn-primary" data-dismiss="modal">Guardar</button>
        </div>
      </div>
    </div>
</div>
