<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12 ui-sortable">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                <div class="panel-heading ui-sortable-handle">
                    <h4 class="panel-title normalText">Agregar Documento</h4>
                    <div class="panel-heading-btn">
                        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>

                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <form [formGroup]="boletaForm" (ngSubmit)="onCrear()" novalidate>
                                <br>
                                <h4>Agregar Boleta de Garantía</h4>
                                <br>
                                <div class="row form-group">
                                    <label for="contrato" class="col-md-2 col-form-label">Contrato (Código Interno)</label>
                                    <div class="col-md-10">
                                        <select class="form-control" formControlName="contrato" #contrato [ngClass]="{ 'is-invalid': submitted && f.contrato.errors }">
                                            <option value="0">Seleccione ...</option>
                                            <option *ngFor="let item of contratos" [value]="item.CONTRATO_CORRELATIVO">{{item.CODIGO_CONTRATO}} - {{item.NOMBRE_CONTRATO}}</option>
                                        </select>
                                        <!--<div *ngIf="submitted && f.contrato.errors" class="invalid-feedback">
                                            <div *ngIf="f.contrato.errors.required">Selecciona un elemento de la lista</div>
                                        </div>-->
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label for="banco" class="col-md-2 col-form-label">Banco</label>
                                    <div class="col-md-10">
                                        <select class="form-control" formControlName="banco" #banco [ngClass]="{ 'is-invalid': submitted && f.banco.errors }">
                                            <option value="0">Seleccione ...</option>
                                            <option *ngFor="let item of bancos" [value]="item.CORRELATIVO">{{item.DESCRIPCION}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label class="col-md-2 col-form-label">Número de Boleta</label>
                                    <div class="col-md-10">
                                        <input type="number" formControlName="nro_boleta" #nro_boleta class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nro_boleta.errors }"/>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label for="monto" class="col-md-2 col-form-label">Monto</label>
                                    <div class="col-md-4 input-group">
                                        <input type="number" formControlName="monto" #monto class="form-control" [ngClass]="{ 'is-invalid': submitted && f.monto.errors }" />
                                    </div>
                                    <label for="moneda" class="col-md-2 col-form-label">Moneda</label>
                                    <div class="col-md-4">
                                        <select class="form-control" formControlName="moneda" #moneda [ngClass]="{ 'is-invalid': submitted && f.moneda.errors }">
                                            <option value="0">Seleccione ...</option>
                                            <option *ngFor="let item of monedas" [value]="item.CORRELATIVO">{{item.DESCRIPCION}}</option>
                                        </select>
                                    </div>
                                </div> 
                                <div style="background-color: #f5f5f5; border-radius: 5px; padding: 10px">
                                    <div class="row form-group">
                                        <label for="fecha_emision" class="col-md-2 col-form-label">Fecha de Emisión</label>
                                        <div class="col-md-4 input-group">
                                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Emisión" formControlName="fecha_emision" #fecha_emision [ngClass]="{ 'is-invalid': submitted && f.fecha_emision.errors }">
                                        </div>
                                        <label for="fecha_ingreso" class="col-md-2 col-form-label">Fecha de Ingreso</label>
                                        <div class="col-md-4">
                                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Ingreso" formControlName="fecha_ingreso" #fecha_ingreso [ngClass]="{ 'is-invalid': submitted && f.fecha_ingreso.errors }">
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="fecha_vencimiento" class="col-md-2 col-form-label">Fecha de Vencimiento</label>
                                        <div class="col-md-4 input-group">
                                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Vencimiento" formControlName="fecha_vencimiento" #fecha_vencimiento [ngClass]="{ 'is-invalid': submitted && f.fecha_vencimiento.errors }">
                                        </div>
                                        <label for="fecha_prorroga" class="col-md-2 col-form-label">Fecha de Prórroga</label>
                                        <div class="col-md-4">
                                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Prorroga" formControlName="fecha_prorroga" #fecha_prorroga>
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label for="fecha_devolucion" class="col-md-2 col-form-label">Fecha de Devolución</label>
                                        <div class="col-md-4 input-group">
                                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Devolución" formControlName="fecha_devolucion" #fecha_devolucion>
                                        </div>
                                    </div>
                                </div>    
                                <br>
                                <div class="row form-group">
                                    <label for="descripcion" class="col-md-2 col-form-label">Descripción</label>
                                    <div class="col-md-10">
                                        <textarea class="form-control" rows="3" formControlName="descripcion" #descripcion [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }"></textarea>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label for="observaciones" class="col-md-2 col-form-label">Observaciones</label>
                                    <div class="col-md-10">
                                        <textarea class="form-control" rows="3" formControlName="observacion" #observacion [ngClass]="{ 'is-invalid': submitted && f.observacion.errors }"></textarea>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label for="adjunto" class="col-md-2 col-form-label">Adjunto</label>
                                    <div class="col-md-10">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" name="adjunto" id="adjunto" aria-describedby="inputGroupFileAddon01" (change)="detectFiles($event)">
                                            <label class="custom-file-label" for="adjunto">{{fileName}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <label for="tipo" class="col-md-2 col-form-label">Tipo</label>
                                    <div class="col-md-10">
                                        <select class="form-control" formControlName="tipo" #tipo [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }">
                                            <option value="0">Seleccione ...</option>
                                            <option *ngFor="let item of tipos" [value]="item.CORRELATIVO">{{item.DESCRIPCION}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-8"></div>
                                    <div class="col-md-4 text-right">
                                        <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <br>

                </div>

            </div>
        </div>
    </div>
</div>