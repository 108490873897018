<div id="content" class="content">
    <a routerLink="/dashboard-itemizado" style="margin-right: 10px;" class="btn btn-primary"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"></i> Volver</a>
    <br>
    <br>
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Detalle Itemizado</h4>
        </div>
        <div class="panel-body ">

            <h4 *ngIf="corrEstadoItemizado != 118">Aprobar Itemizado</h4>
            <h4 *ngIf="corrEstadoItemizado == 118">Itemizado</h4>
            <br>
            <h5>{{ contrato.NOMBRE_CONTRATO }} <br> {{ contrato.PROVEEDOR }}</h5>
            <br>
            <hr>    
            <p *ngIf="corrEstadoItemizado != 118" class="text-right">
                <button class="btn btn-light" data-target="#modalRechazarItemizado" data-toggle="modal">Rechazar</button> &nbsp;&nbsp;&nbsp;
                <button class="btn btn-primary" data-target="#modalValidarGuardarItemizado" data-toggle="modal"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Aprobar</button>
            </p>   
            <div class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead class="thead-dark">
                            <th>R</th>
                            <th>Q</th>
                            <th>SubPos</th>
                            <th>Descripción</th>
                            <th>Unidad</th>
                            <th>Moneda</th>
                            <th>Cantidad</th>
                            <th>V. Unitario</th>
                            <th width="25%">Artículo</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of itemizado">
                                <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>

                                <td *ngIf="!item.Cabecera && item.R" class="pt-2"><i class="fa fa-check"></i></td>
                                <td *ngIf="!item.Cabecera && !item.R" class="pt-2">&nbsp;</td>
                                <td *ngIf="!item.Cabecera && item.Scpq" class="pt-2"><i class="fa fa-check"></i></td>
                                <td *ngIf="!item.Cabecera && !item.Scpq" class="pt-2">&nbsp;</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Subpos }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Descripcion }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Unidad }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ contrato.TIPO_MONEDA }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>      
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Articulo }}</td>                          
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
</div>
<!-- Modal para confirmacion de guardado y validacion de itemizado -->
<div class="modal fade" id="modalValidarGuardarItemizado" tabindex="-1" role="dialog" aria-labelledby="modalValidarGuardarItemizado" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modalValidarGuardarItemizado">Validación de Itemizado</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="padding: 2rem;" class="modal-body">
            <p style="font-size: 16px;">Una vez se Apruebe el Itemizado entrará en vigencia y uso.</p>
            <p style="font-size: 16px;">¿Está seguro que desea Aprobar Itemizado?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="aprobarItemizado()" class="btn btn-primary" data-dismiss="modal">Guardar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal Rechazar -->
<div class="modal fade" id="modalRechazarItemizado" tabindex="-1" role="dialog" aria-labelledby="modalRechazarItemizado" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modalRechazarItemizado">Validación de Itemizado</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="padding: 2rem;" class="modal-body">
            <p style="font-size: 16px;">¿Está seguro que desea Rechazar el Itemizado?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="rechazarItemizado()" class="btn btn-primary" data-dismiss="modal">Rechazar</button>
        </div>
      </div>
    </div>
</div>
