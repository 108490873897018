import { Component, OnInit } from '@angular/core';
import { AuditoriaService, Log } from 'src/app/shared/services/auditoria.service';
import { TableHeaderCell } from 'src/app/shared/components/table/table.component';
import { MatSortable } from '@angular/material/sort';
import * as moment from 'src/assets/plugins/moment/moment';
import { ViewExcelButtonsProps } from 'src/app/shared/view-excel-button/view-excel-buttons.component';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  header: TableHeaderCell[] = [
    {
      title: 'Fecha',
      value: 'datetime_filter',
      custom: true,
      width: '20%',
    },
    {
      title: 'Nombre de Usuario',
      value: 'userName',
      width: '20%',
    },
    {
      title: 'Sistema',
      value: 'system',
      width: '10%',
    },
    {
      title: 'Módulo',
      value: 'module',
      width: '20%',
    },
    {
      title: 'Acción',
      value: 'action',
      width: '20%',
    },
    {
      title: 'IP',
      value: 'ip',
      width: '10%',
    }
  ];

  logs: Log[];
  logsInit: Log[];
  sort: MatSortable = {
    id: 'datetime_filter',
    start: 'desc',
    disableClear: false
  };

  userName = '';
  startDate = '';
  endDate = '';
  dateFormat = 'DD/MM/YYYY HH:mm:ss';

  excelButton: ViewExcelButtonsProps = {
    title: 'Logs',
    filename: 'logs',
    headers: [
      {
        title: 'Fecha',
        value: 'datetime',
      },
      {
        title: 'Nombre de Usuario',
        value: 'userName',
      },
      {
        title: 'Sistema',
        value: 'system',
      },
      {
        title: 'Módulo',
        value: 'module',
      },
      {
        title: 'Acción',
        value: 'action',
      },
      {
        title: 'IP',
        value: 'ip',
      }
    ],
    data: []
  }

  errors = {
    startDate: null,
    endDate: null,
    userName: null,
  }

  constructor(private auditoriaService: AuditoriaService) { }

  ngOnInit(): void {
    this.getLogs();
  }

  private getLogs() {
    this.auditoriaService.logs().subscribe(resp => {
      this.logs = resp.logs.map((log) => {
        return {
          ...log,
          datetime_filter: `<span class="d-none">${log.datetime}</span>${moment(log.datetime).format(this.dateFormat)}</span>`,
          datetime: moment(log.datetime).format(this.dateFormat)
        };
      });
      this.logsInit = this.logs.sort((a, b) => moment(b.datetime, this.dateFormat).diff(moment(a.datetime, this.dateFormat)));
      this.excelButton.data = this.logs;
    });
  }

  onFilter() {
    this.errors = {
      startDate: null,
      endDate: null,
      userName: null,
    }
    this.logs = [];
    let logs = this.logsInit;
    if (this.userName) {
      const userName = this.userName.toLowerCase();
      logs = logs.filter((log) => log.userName.toLowerCase().includes(userName));
    }
    if (this.startDate || this.endDate) {
      if (!this.startDate) {
        this.errors.startDate = 'Debe seleccionar una fecha';
        return;
      }
      if (!this.endDate) {
        this.errors.endDate = 'Debe seleccionar una fecha';
        return;
      }

      const startDate = moment(`${this.startDate} 00:00:00`, 'YYYY-MM-DD HH:mm:ss');
      const endDate = moment(`${this.endDate} 23:59:59`, 'YYYY-MM-DD HH:mm:ss');

      if (startDate.isAfter(endDate)) {
        this.errors.endDate = 'La fecha debe ser mayor a la de inicio';
        return;
      }

      logs = logs.filter((log) => moment(log.datetime, this.dateFormat).isBetween(startDate, endDate));
    }
    this.excelButton.data = logs;
    this.logs = logs;
  }

  hasErrors() {
    return Object.values(this.errors).every(item => !item);
  }
}

