import { ActionButtonProps } from './../../shared/components/action-button/action-button.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PostProveedor, SolpeService } from 'src/app/shared/services/solpe.service';
import { TableHeaderCell } from 'src/app/shared/components/table/table.component';
import { ViewExcelButtonsProps } from 'src/app/shared/view-excel-button/view-excel-buttons.component';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.css']
})
export class ProveedoresComponent implements OnInit {
  idAdminContratista: number;
  admcPorProveedorSelect2: any[];
  idProveedor: number;
  idContrato: number;
  nombreContrato: string;
  showHistorialContrato = false;
  showUpdateContratista = false;
  // Adec
  adecContrato: any[];
  /*public selected: string;
  selection = new SelectionModel<any>(false, []);*/
  dsAdecContrato: any;
  dsHeaderAdecContrato: string[] = [
    'AdminContratista',
    'FechaInicio',
    'FechaTermino',
    'Vigente',
    'UsuarioActualizacion',
    'FechaActualizacion'
  ];
  @ViewChild('paginatorAdec', { static: false }) paginatorAdec: MatPaginator;

  contratosProveedor: any[];
  proveedores: any[];
  nombreProveedor: string;
  adc: any[];


  /** Proveedor */
  formProveedor: FormGroup;
  submittedProveedor = false;
  nuevoProveedor = false;
  proveedorRelacionado = false;
  relacionProveedor: any[] = [];

  /** Relaciona Admc Proveedor */
  formAgregarAdmc: FormGroup;
  submitted = false;
  admcPorProveedor: any[];


  public selected: string;
  selection = new SelectionModel<any>(false, []);
  dataSource: any;
  dcProveedores: TableHeaderCell[] = [
    {
      title: '#',
      value: 'id',
      width: '5%',
    },
    {
      title: 'Razón Social',
      value: 'text',
      width: '25%',
    },
    {
      title: 'RUT',
      value: 'rut',
      width: '10%',
    },
    {
      title: 'Teléfono',
      value: 'telefono',
      width: '10%',
    },
    {
      title: 'Contacto',
      value: 'contacto',
      width: '20%',
    },
    {
      title: 'Email',
      value: 'email',
      width: '20%',
    },
    {
      title: 'Acciones',
      value: 'actions',
      custom: true,
      width: '10%',
    },
  ];
  excelButtonProveedores: ViewExcelButtonsProps = {
    title: 'Proveedores',
    filename: 'proveedores',
    headers: this.dcProveedores.filter(row => row.title !== 'Acciones'),
    data: []
  };
  actionButtonProps: ActionButtonProps = {
    title: 'Agregar Proveedor',
    action: () => this.openModalProveedor(),
    icon: 'fa-archive',
  };
  @ViewChild('paginatorGrl', { static: false }) paginatorGrl: MatPaginator;
  @ViewChild('openModalGuardarProveedor') openModalGuardarProveedor: ElementRef;
  @ViewChild('closeModalGuardarProveedor') closeModalGuardarProveedor: ElementRef;
  @ViewChild('openModalAgregarAdc') openModalAgregarAdc: ElementRef;
  @ViewChild('openModalContratos') openModalContratos: ElementRef;

  constructor(
    private contratoService: ContratoService,
    private solpeService: SolpeService,
    private notifyService: NotificationService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.getProveedores();
    this.resetFormProveedor();
    this.getAdc();
    this.resetFormAdmc();
  }

  setActions(_?: Event): void {
    setTimeout(() => {
      const component = this?.elementRef?.nativeElement;
      if (!component) {
        return;
      }
      const btnEdit = component.querySelectorAll('.btn-edit');
      btnEdit.forEach(item => {
        this.renderer.listen(item, 'click', () => {
          const index = item.attributes.href.value;
          this.openModalProveedor(index);
        });
      });

      const btnAdc = component.querySelectorAll('.btn-adc');
      btnAdc.forEach(item => {
        this.renderer.listen(item, 'click', () => {
          const index = item.attributes.href.value;
          this.openModalAdc(index);
        });
      });

      const btnContrato = component.querySelectorAll('.btn-contrato');
      btnContrato.forEach(item => {
        this.renderer.listen(item, 'click', () => {
          const index = item.attributes.href.value;
          this.openModalContratosProveedor(index);
        });
      });
    }, 1000);
  }
 /*  <div class="btn rounded-icon mr-2" (click)="eliminarAdmc(item.CORRELATIVO)"
    title="Eliminar">
    <i class="fas fa-trash-alt"></i>
  </div> */ 
  private getProveedores() {
    this.solpeService.getProveedores().subscribe(resp => {
      this.proveedores = JSON.parse(resp);
      this.excelButtonProveedores.data = this.proveedores;
      this.proveedores = this.proveedores.map((proveedor, i) => {
        return {
          ...proveedor,
          actions: `
            <span class="btn rounded-icon mr-2 btn-edit" href="${i}">
              <i class="fas fa-pen"></i>
            </span>
            <span class="btn rounded-icon mr-2 btn-adc" href="${i}">
              <i class="fas fa-user-shield"></i>
            </span>
            <span class="btn rounded-icon mr-2 btn-contrato" href="${i}">
              <i class="fas fa-file-contract"></i>
            </span>`
        };
      });
      this.setActions();
    }, error => console.log(error));
  }
  applyFilterGrl(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  private createPaginator(pag: any, table: any) {
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }
  /** Proveedor */
  private resetFormProveedor() {
    this.formProveedor = new FormGroup({
      corr_proveedor: new FormControl(null, null),
      nombre: new FormControl(null, Validators.required),
      rut: new FormControl(null, Validators.required),
      telefono: new FormControl(null, Validators.required),
      contacto: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required)
    });
    this.submittedProveedor = false;
  }

  openModalProveedor(index?: number) {
    if (index) {
      const proveedor = this.proveedores[index];
      this.formProveedor = new FormGroup({
        corr_proveedor: new FormControl(proveedor.id, null),
        nombre: new FormControl(proveedor.text, Validators.required),
        rut: new FormControl(proveedor.rut, Validators.required),
        telefono: new FormControl(proveedor.telefono, Validators.required),
        contacto: new FormControl(proveedor.contacto, Validators.required),
        email: new FormControl(proveedor.email, Validators.required)
      });
    }
    this.openModalGuardarProveedor.nativeElement.click();
  }
  onGuardarProveedor() {
    this.submittedProveedor = true;
    if (this.formProveedor.invalid) {
      return;
    }

    let rut: number = null;
    let digito: string = null;
    let rutEmpresa = this.formProveedor.value.rut;

    if (rutEmpresa !== null) {
      rutEmpresa = rutEmpresa.split('-');
      rut = rutEmpresa[0];
      digito = rutEmpresa[1];
    }

    const postData: PostProveedor = {
      in_rut: rut,
      iv_digrut: digito,
      iv_nombre: this.formProveedor.value.nombre,
      iv_email: this.formProveedor.value.email,
      iv_telefono: this.formProveedor.value.telefono,
      iv_contacto: this.formProveedor.value.contacto,
    };
    if (this.formProveedor.value.corr_proveedor) {
      postData.in_correlativo = this.formProveedor.value.corr_proveedor;
    }

    this.solpeService.postProveedor(postData).subscribe(resp => {
      this.notifyService.showSuccess('Proveedor guardado con exito!', 'Guardar Proveedor');
    }, err => {
      this.notifyService.showError('Error al guardar Proveedor', 'Guardar Proveedor');
      console.log(err);
    }, () => {
      this.getProveedores();
    });


    this.resetFormProveedor();
    this.closeModalGuardarProveedor.nativeElement.click();
  }
  onCloseProveedor() {
    this.resetFormProveedor();
  }
  openModalAdc(index: number) {
    const proveedor = this.proveedores[index];
    const { id: corrProveedor, text: nombreProveedor } = proveedor;
    this.resetFormAdmc();
    this.nombreProveedor = nombreProveedor;
    this.idProveedor = corrProveedor;
    this.formAgregarAdmc.controls.corrProveedor.setValue(corrProveedor);
    this.getAdmcProveedor(corrProveedor);
    this.openModalAgregarAdc.nativeElement.click();
  }

  openModalContratosProveedor(index: number) {
    const proveedor = this.proveedores[index];
    const { id: corrProveedor, text: nombreProveedor } = proveedor;
    this.showHistorialContrato = false;
    this.showUpdateContratista = false;
    this.nombreProveedor = nombreProveedor;
    this.idProveedor = corrProveedor;
    this.getContratosProveedor(corrProveedor);
    this.openModalContratos.nativeElement.click();
  }

  private getContratosProveedor(corrProveedor: number) {
    this.solpeService.getContratosProveedor(corrProveedor).subscribe(resp => {
      this.contratosProveedor = JSON.parse(resp);
    });
  }

  private getAdc() {
    this.contratoService.getComboAdmc().subscribe(resp => {
      this.adc = JSON.parse(resp);
    });
  }
  private resetFormAdmc() {
    this.formAgregarAdmc = new FormGroup({
      admc: new FormControl(null, Validators.required),
      corrProveedor: new FormControl(null)
    });
    this.submitted = false;
  }

  onAgregarAdmc() {
    this.submitted = true;
    if (this.formAgregarAdmc.invalid) {
      return;
    }

    const postData: {
      in_proveedor_correlativo: number,
      in_usua_admc_correlativo: number,
      in_usua_correlativo_creacion: number
    } = {
      in_proveedor_correlativo: this.formAgregarAdmc.value.corrProveedor,
      in_usua_admc_correlativo: this.formAgregarAdmc.value.admc,
      in_usua_correlativo_creacion: 0
    };

    this.contratoService.relacionaAdmcProveedor(postData).subscribe(resp => {
      this.notifyService.showSuccess('Administrador seleccionado relacionado con exito!', 'Relacionar Administrador de Contrato');
    }, err => {
      this.notifyService.showError('Error al relacionar Administrador', 'Relacionar Administrador de Contrato');
      console.log(err);
    }, () => {
      this.getAdmcProveedor(postData.in_proveedor_correlativo);
    });

  }
  getAdmcProveedor(corrProveedor: number) {
    this.contratoService.getAdmcsProveedor(corrProveedor).subscribe(resp => {
      this.admcPorProveedor = JSON.parse(resp);
    });
  }

  private getAdecContrato(idContrato: number) {
    this.contratoService.getAdecContrato(idContrato).subscribe(resp => {
      this.adecContrato = JSON.parse(resp);
      this.dsAdecContrato = new MatTableDataSource(this.adecContrato);
      //this.createPaginator(this.paginatorAdec, this.dsAdecContrato);
    });
  }

  onVerHistoriaContrato(idContrato: number, nombreContrato: string) {
    this.showHistorialContrato = true;
    this.nombreContrato = nombreContrato;
    this.idContrato = idContrato;
    this.getAdecContrato(this.idContrato);
    this.getAdmcProveedorSelect2(this.idProveedor);
  }

  getAdmcProveedorSelect2(corrProveedor: number) {
    if (corrProveedor) {
      this.contratoService.getAdmcsProveedorSelect2(corrProveedor).subscribe(resp => {
        this.admcPorProveedorSelect2 = JSON.parse(resp);
      });
    }
  }
  onGetAdProveedorUpdateId(idAdminContratista: number) {
    this.idAdminContratista = idAdminContratista;
  }

  onGuardarAdminContratista() {
    if (this.idAdminContratista > 0) {
      const postData: {
        in_cont_correlativo: number,
        in_admin_ec: number,
        in_usua_correlativo_creacion: number
      } = {
        in_cont_correlativo: this.idContrato,
        in_admin_ec: this.idAdminContratista,
        in_usua_correlativo_creacion: 0
      };
      this.solpeService.postAdminContratistaContrato(postData).subscribe(resp => {
        if (JSON.parse(resp)[0].CORRELATIVO > 0) {
          this.notifyService.showSuccess('Administrador contratista actualizado con exito.', 'Actualizar Contrato');
          this.getAdecContrato(this.idContrato);
          this.showUpdateContratista = false;
        }
      });

    }
  }

  eliminarAdmc(correlativoAdmc: number) {
    const postData: {
      in_usua_admc_correlativo: number,
      in_prov_correlativo: number,
      in_usua_correlativo_creacion: number
    } = {
      in_usua_admc_correlativo: correlativoAdmc,
      in_prov_correlativo: this.formAgregarAdmc.value.corrProveedor,
      in_usua_correlativo_creacion: 0
    };

    this.contratoService.deleteAdmcProveedor(postData).subscribe(resp => {
      this.notifyService.showSuccess('Administrador seleccionado eliminado con exito!', 'Relacionar Administrador de Contrato');
    }, err => {
      this.notifyService.showError('Error al eliminar Administrador', 'Relacionar Administrador de Contrato');
      console.log(err);
    }, () => {
      this.getAdmcProveedor(postData.in_prov_correlativo);
    });
  }
  get fp() { return this.formProveedor.controls; }
  get f() { return this.formAgregarAdmc.controls; }

}
