import { Component, OnInit, ViewChild } from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ParametrosService } from '../shared/services/parametros.service';
import { NotificationService } from '../shared/services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { ExcelService} from '../shared/services/excel.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';

@Component({
  selector: 'app-usuarios-funcionalidad',
  templateUrl: './usuarios-funcionalidad.component.html',
  styleUrls: ['./usuarios-funcionalidad.component.css']
})
export class UsuariosFuncionalidadComponent implements OnInit {

  usuarios:any; // = undefined
  all_users: any;
  idUser:number;
  usuarios_todos:boolean;
  select_usuarios:any = null;
  acciones:string[] = [];
  form:any;
  data:any[] = [];
  nombreUsuario: string = '';
  accionesUsuarioSelect : string;

  public selected: string;

  menuFuncionalidadGrl:any;
  dcFuncionalidadGrl: string[] = ['Username', 'Funcionalidad', 'VerAcciones'];
  cabecerasExcel: string[] = ['Username', 'Funcionalidad', 'VerAcciones'];
  @ViewChild('paginatorFuncionalidadGrl', {static: false}) paginatorFuncionalidadGrl: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<any>(false, []);
  dataForExcel = [];

  sortedData: any;
  funcionalidadInit: any;

  constructor(private parametrosService: ParametrosService, private excelService:ExcelService, private notifyService: NotificationService, private exportXLS : ExportExcelService) {
    this.getAcciones();
  }

  ngOnInit(): void {
    this.getUsuarios();
    this.resetForm();

    //this.menuFuncionalidadGrl = new MatTableDataSource();
    //this.menuFuncionalidadGrl.sort = this.sort;
  }
  private getAcciones() {
    this.parametrosService.getAcciones().subscribe(resp=>{
      let acciones = JSON.parse(resp);
      for(let i in acciones) {
        //this.acciones[i] = acciones[i]['NOMBRE'];
        this.acciones[i] = acciones[i]['nombre'];
      }
    });
  }
  exportAsXLSX():void {
    this.getFuncionalidadesUsuarios(this.idUser);
    this.data = this.formatDataExcel(this.data);
    //this.excelService.exportAsExcelFile(this.data, 'data');
    this.registrarCabecerasGrilla();
    this.onExport(this.cabecerasExcel, this.acciones);
    this.notifyService.showSuccess('Descargando...', 'Descargar archivo');
  }
  private  onExport(cabeceras: string[], acciones: any[]) {
    const dataXLS = this.data;
    if (dataXLS){
      dataXLS.forEach((row: any) => {
        this.dataForExcel.push(Object.values(row))
      })
      let reportData = {
        title: 'Usuarios Funcionalidad #',
        data: this.dataForExcel,
        headers: Object.values(cabeceras)
      }
      this.exportXLS.exportExcelFuncionalidad(reportData, acciones);
      this.dataForExcel.length = 0;
    }
  }
  private formatDataExcel(datos: any[]) {
    let new_data: any[] = [];
    let existe_accion: string;

    for(let i in datos) {
      new_data[i] = {
        Usuarios : datos[i]["USERNAME"],
        Funcionalidad : datos[i]["FUNCIONALIDAD"],
        Acciones: []
      };

      this.acciones.forEach(element => {
        existe_accion = this.existeTexto(datos[i]["ACCION_NOMBRE"], element);
        new_data[i].Acciones.push({
          [element]: existe_accion
        });
      });

    }

    return new_data;
  }
  existeTexto(cadena: string, buscar: string) {
    if(cadena !== undefined) {
      let acciones = cadena.split(' - ');
      if(acciones.includes(buscar)) {
        return "X";
      }
    }
    return "";
  }
  condicionalExisteTexto(cadena: string, buscar: string) {
    //let acciones = cadena.split('-');
    if(cadena !== undefined) {
      let acciones = cadena.split(' - ');
      if(acciones.includes(buscar)) {
        return true;
      }
    }
    return false;
  }
  private resetForm(){
    this.form = new FormGroup({
      field: new FormControl(null)
    });
  }
  private registrarCabecerasGrilla() {
    this.cabecerasExcel.length = 0;
    this.cabecerasExcel = ['Username', 'Funcionalidad'];
    Array.prototype.push.apply(this.cabecerasExcel, this.acciones);
  }
  onChangeUser(idU : number){
    this.idUser = idU;
    if(idU){
      this.getFuncionalidadesUsuarios(this.idUser);
    }
    //this.registrarCabecerasGrilla();
  }
  onChangeUsers(event : any){
    this.usuarios_todos = event.currentTarget.checked;
    this.idUser = 0;
    if(this.usuarios_todos) {
      this.select_usuarios = null;
      this.form.controls.field.disable();
      this.resetForm();
      this.getFuncionalidadesUsuarios(this.idUser);
    } else {
      this.select_usuarios = null;
      this.menuFuncionalidadGrl = null;
      this.form.controls.field.enable();
      this.resetForm();
    }
  }
  applyFilterUsuario(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.usuarios.filter = filterValue.trim().toLowerCase();
  }
  private getUsuarios(){
    this.parametrosService.getUsuarios().subscribe(resp=>{
      this.all_users= JSON.parse(resp);
    });
  }
  applyFilterFuncionalidadUsuarioGrl(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.menuFuncionalidadGrl.filter = filterValue.trim().toLowerCase();
  }
  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }
  sortData(sort: Sort) {
    const data = this.funcionalidadInit.slice();
    if (!sort.active || sort.direction === '') {
      this.menuFuncionalidadGrl =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorFuncionalidadGrl, this.menuFuncionalidadGrl);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Username': return compare(a.USERNAME, b.USERNAME, isAsc);
        case 'Funcionalidad': return compare(a.FUNCIONALIDAD, b.FUNCIONALIDAD, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.menuFuncionalidadGrl =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorFuncionalidadGrl, this.menuFuncionalidadGrl);
    }
  }
  private getFuncionalidadesUsuarios(idU : number){
    this.parametrosService.getFuncionalidadesUsuario(idU).subscribe(resp => {

        this.menuFuncionalidadGrl = new MatTableDataSource(JSON.parse(resp));
        this.createPaginator(this.paginatorFuncionalidadGrl, this.menuFuncionalidadGrl);

        this.data = JSON.parse(resp);
        this.funcionalidadInit = JSON.parse(resp);
    });
  }
  cargarAcciones(id: number, idMenu: number) {
    this.parametrosService.getAccionesMenuUsuario(id, idMenu).subscribe(resp => {
      let response = JSON.parse(resp);
      this.nombreUsuario = response[0]['USUARIO'];
      this.accionesUsuarioSelect = response[0]['ACCIONES'];
    });
  }
}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
