import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-modal-documents',
  templateUrl: './modal-documents.component.html',
  styleUrls: ['./modal-documents.component.css']
})
export class ModalDocumentsComponent implements OnInit {

  sfileUrl: string | URL;
  sPreviewurl: string | URL;
  sFileType: string;
  sSanitorUrl: any;
  isFile: boolean;
  isImagen: boolean;

  constructor(public objDialogRef: MatDialogRef<ModalDocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private domSanitor: DomSanitizer) { }

  ngOnInit(): void {
    this.sfileUrl = this.data.fileUrl;
    this.sFileType = this.data.fileType;
    this.sPreviewurl = "htts://docs.google.com/viewerng/viewer?url=" + this.sfileUrl + "&embedded=true";
    this.sSanitorUrl = this.domSanitor.bypassSecurityTrustResourceUrl(this.sPreviewurl);
    this.isFile = true;
    this.isImagen = false;
  }

}
