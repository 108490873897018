<div id="content" class="content">
    <a routerLink="/dashboard-itemizado-mod" style="margin-right: 10px;" class="btn btn-primary"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"></i> Volver</a>
    <br>
    <br>
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Itemizado</h4>
        </div>
        <div class="panel-body">
            <form [formGroup]="formItemizado" (ngSubmit)="cargarExcel()" novalidate>
                <div class="row">
                    <div class="col-md-4">
                        <h4>
                            {{ contrato.NOMBRE_CONTRATO }} 
                            <br> 
                            {{ contrato.PROVEEDOR }}
                            <!-- <br>
                            <br>
                            Monto actual del Contrato: {{contrato.MONTO_CONTRATO | number}}
                            <br>
                            Monto de la Solpe: {{montoModificacionSolpe | number}}
                            <br>
                            Total del Itemizado cargado: {{totalMontoItemizado | number}} -->
                            
                        </h4>
                        <br>
                            <div class="row">
                                <div class="col-6">
                                    <h5>Monto actual del Contrato:</h5>
                                    <h5>Monto de la Solpe:</h5>
                                    <h5>Itemizado modificacion de contrato:</h5>
                                </div>
                                <div class="col-6">
                                    <h5>{{contrato.MONTO_CONTRATO | number:'1.2': 'es-CL'}}</h5>
                                    <h5>{{montoModificacionSolpe | number:'1.2': 'es-CL'}}</h5>
                                    <h5>{{totalMontoItemizado | number:'1.2': 'es-CL'}}</h5>
                                </div>
                            </div>
                    </div>
                    <label for="adjunto" class="col-md-1 col-form-label">Adjunto</label>
                    <div class="col-md-4">
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" formControlName="adjunto" name="adjunto" id="adjunto" (change)="incomingfile($event)" accept=".xlsx" [ngClass]="{ 'is-invalid': submitted && f.adjunto.errors}">
                            <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Mostrar</button>
                        <a class="btn btn-primary btn-block text-white" data-target="#modalVerItemizado" data-toggle="modal"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-eye"></i> Ver Itemizado</a>
                    </div>
                </div>
            </form>
            <br><br>
            <hr>
            <div *ngIf="data.length > 0">
                <table class="table">
                    <thead class="thead-dark">
                        <th>POS</th>
                        <th>SPOS</th>
                        <th>ITEM</th>
                        <th>UNIDAD</th>
                        <th class="text-right">CANTIDAD</th>
                        <th class="text-right">PRECIO</th>
                        <th class="text-right">REAJUSTE</th>
                        <th>SCPQ</th>
                        <th class="text-right">TOTAL</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data" [ngStyle]="{'background-color': item.POS ? '#E9ECEF' : 'white' }">
                            <td>{{ item.POS }}</td>
                            <td>{{ item.SPOS }}</td>
                            <td>{{ item.ITEM }}</td>
                            <td>{{ item.UNIDAD }}</td>
                            <td class="text-right">{{ item.CANTIDAD | number:'1.2': 'es-CL'}}</td>
                            <td class="text-right">{{ item.PRECIO | number:'1.2': 'es-CL'}}</td>
                            <td class="text-right">{{ item.REAJUSTE }}</td>
                            <td>{{ item.SCPQ }}</td>
                            <td *ngIf="item.CANTIDAD !== null && item.PRECIO !== null" class="text-right">{{(item.CANTIDAD * item.PRECIO) | number:'1.2': 'es-CL'}}</td>
                            <td *ngIf="item.CANTIDAD === null && item.PRECIO === null"></td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <div class="text-right">
                    <h3>TOTAL: {{totalMontoItemizado | number:'1.2': 'es-CL'}}</h3>
                </div>
                <hr>
                <p class="text-right">
                    <button class="btn btn-primary" type="submit" data-target="#modalValidarGuardarItemizado" data-toggle="modal"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-save"></i> Guardar</button>
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Modal para confirmacion de guardado y validacion de itemizado -->
<div class="modal fade" id="modalValidarGuardarItemizado" tabindex="-1" role="dialog" aria-labelledby="modalValidarGuardarItemizado" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modalValidarGuardarItemizado">Validación y Guardado de Itemizado</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="padding: 2rem;" class="modal-body">
            <p style="font-size: 16px;">Al guardar el itemizado de la modificacion de contrato los items se agregaran al itemizado vigente.</p>
            <p style="font-size: 16px;">¿Está seguro que desea guardar este itemizado?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="validateAndSave()" class="btn btn-primary" data-dismiss="modal">Guardar</button>
        </div>
      </div>
    </div>
</div>
<!-- Modal para ver el itemizado a cargado -->
<div class="modal fade" id="modalVerItemizado" tabindex="-1" role="dialog" aria-labelledby="modalVerItemizado" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modalVerItemizado">Itemizado</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="padding: 2rem;" class="modal-body">
            <ng-container *ngIf="itemizado.length > 0">
                <div class="row">
                    <div class="col-md-12 table_container">
                        <table class="table">
                            <thead class="thead-dark">
                                <th>R</th>
                                <th>Q</th>
                                <th>SubPos</th>
                                <th>Desc</th>
                                <th>Unidad</th>
                                <th>Cantidad</th>
                                <th>V. Unitario</th>
                                <th>Total</th>
                                <th>Consumo</th>
                                <th>Saldo</th>
                                <th>Consumo Q</th>
                                <th>Saldo Q</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of itemizado">
                                    <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>
            
                                    <td *ngIf="!item.Cabecera && item.R" class="pt-2"><i class="fa fa-check"></i></td>
                                    <td *ngIf="!item.Cabecera && !item.R" class="pt-2">&nbsp;</td>
                                    <td *ngIf="!item.Cabecera && item.Scpq" class="pt-2"><i class="fa fa-check"></i></td>
                                    <td *ngIf="!item.Cabecera && !item.Scpq" class="pt-2">&nbsp;</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Subpos }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Descripcion }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Unidad }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                    <!-- <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td> -->
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.PrecioUnitario | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ (item.PrecioUnitario * item.Cantidad) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.Consumo | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ ((item.PrecioUnitario * item.Cantidad) - item.Consumo) | currency: this.contractCurrenci?.ISOCode: '': this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ item.ConsumoQ | number:'1.2': 'es-CL' }}</td>
                                    <td *ngIf="!item.Cabecera" class="pt-2 text-right">{{ (item.Cantidad - item.ConsumoQ) | number:'1.2': 'es-CL' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
               <!--  <div class="row">
                    <legend>Total</legend>
                    <table class="table table-striped table-bordered table-td-valign-middle ">
                        <thead>
                            <th colspan="5" style="width: 48%;">&nbsp;</th>
                            <th style="width: 11%;">Total</th>
                            <th colspan="2" style="width:83px;">&nbsp;</th>
                            <th style="width: 9%;">Avance</th>
                            <th style="width: 9%;">Saldo</th>
                            <th colpsan="2" style="width:6%;">&nbsp;</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="5">&nbsp;</td>
                                <td>{{ this.getSumTotal() | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL'}}</td>
                                <td colspan="2">&nbsp;</td>
                                <td>{{ this.getSumAvance() | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                <td>{{ this.getSaldo() | currency: this.contractCurrenci?.ISOCode: this.contractCurrenci?.Simbol: this.contractCurrenci?.decimalPipe : 'es-CL' }}</td>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
            </ng-container>
            <div *ngIf="itemizado.length <= 0">
                <p class="text-center">No hay ningún itemizado cargado.</p>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
</div>