import { Component, OnInit } from '@angular/core';
import { ContratoService } from 'src/app/shared/services/contrato.service';

@Component({
  selector: 'app-create-contrato',
  templateUrl: './create-contrato.component.html',
  styleUrls: ['./create-contrato.component.css']
})
export class CreateContratoComponent implements OnInit {

  constructor(private contratoService : ContratoService) { }

  ngOnInit(): void {
  }

  onCreateContrato(){
    const postData : {
      iv_nombre_contrato : string,
      iv_codigo_contrato : string,
      in_tico_correlativo: number,
      iv_proveedor : string,
      in_monto: number,
      in_timo_correlativo: number,
      iv_fecha_contrato : string,
      iv_fecha_inicio_contrato : string,
      iv_fecha_termino_contrato : string,
      in_requiere_bg: number,
      in_tige_correlativo: number,
      in_tiar_correlativo: number,
      in_admin_correlativo: number,
      in_gest_correlativo: number,
      in_tior_correlativo: number,
      in_tiesproc_correlativo: number,
      in_esdo_correlativo: number
    } = { 
    iv_nombre_contrato: '',
    iv_codigo_contrato: '',
    in_tico_correlativo: 1,
    iv_proveedor: '',
    in_monto: 1000,
    in_timo_correlativo: 1,
    iv_fecha_contrato: '',
    iv_fecha_inicio_contrato: '',
    iv_fecha_termino_contrato: '',
    in_requiere_bg: 1,
    in_tige_correlativo: 1,
    in_tiar_correlativo: 1,
    in_admin_correlativo: 1,
    in_gest_correlativo: 1,
    in_tior_correlativo: 1,
    in_tiesproc_correlativo: 1,
    in_esdo_correlativo : 1};

    this.contratoService.postContrato(postData).subscribe(resp =>{
      //console.log(JSON.parse(resp));
    });
  }
}
