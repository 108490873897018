<div id="content" class="content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="p3-rem bg-white rounded" id="printComponent">
            <div class="row mb-4">
                <div class="col-8">
                    <h4 class="mb-0">
                        Manuales
                    </h4>
                </div>
                <div class="col-4 text-right">
                    
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="table_container">
                        <table class="table border">
                            <thead class="thead-darkk">
                                <tr>
                                    <th>Nombre</th>
                                    <th>Tamaño</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let doc of aFiles">
                                    <td>
                                        <span class="d-flex">
                                            <ng-container [ngSwitch]="doc.fileName.split('.').pop()">
                                                <span *ngSwitchCase="'jpg'">
                                                    <i class="fas fa-file-image fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchCase="'jpeg'">
                                                    <i class="fas fa-file-image fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchCase="'png'">
                                                    <i class="fas fa-file-image fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchCase="'pdf'">
                                                    <i class="fas fa-file-pdf text-danger fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchCase="'xlsx'">
                                                    <i class="fas fa-file-excel text-success fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchCase="'xls'">
                                                    <i class="fas fa-file-excel text-success fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchCase="'docx'">
                                                    <i class="fas fa-file-word text-blue fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchCase="'doc'">
                                                    <i class="fas fa-file-word text-blue fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchCase="'pptx'">
                                                    <i class="fas fa-file-powerpoint text-primary fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchCase="'ppt'">
                                                    <i class="fas fa-file-powerpoint text-primary fa-lg mr-3 ml-2"></i>
                                                </span>
                                                <span *ngSwitchDefault>
                                                    <i class="fas fa-file fa-lg mr-3 ml-2"></i>
                                                </span>
                                            </ng-container>
                                            {{doc.fileName}}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="text-muted d-flex">
                                            {{formatSizeUnits(doc.stats.size)}}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-end">
                                            <view-file-button 
                                                [nombreArchivo]="doc.fileName"
                                                [tipoArchivo]="'general'"
                                            ></view-file-button>
                                            <a (click)="onDescargar(doc.fileName)" class="btn rounded-icon mr-2"  title="descargar">
                                                <i class="fas fa-file-download"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template>
                
</ng-template>


