import { Component, OnInit } from '@angular/core';
import { FileService } from '../shared/services/file.service';
import * as FileSaver from 'file-saver';
import { NotificationService } from '../shared/services/notification.service';
import { ContratoService } from '../shared/services/contrato.service';

@Component({
  selector: 'app-manuales-procedimientos',
  templateUrl: './manuales-procedimientos.component.html',
  styleUrls: ['./manuales-procedimientos.component.css']
})
export class ManualesProcedimientosComponent implements OnInit {
  manualesProcedimientos: any[];
  manualesProcedimientosFiltered: any[];
  esEmsa:number;

  constructor(
    private fileService: FileService,
    private notifyService: NotificationService,
    private contratoService: ContratoService,
  ) {
    this.manualesProcedimientos = [];
    this.manualesProcedimientosFiltered = [];
    this.esEmsa = 0;
  }

  ngOnInit(): void {
    this.esEmsa = JSON.parse(localStorage.getItem('userData')).esEmsa == 'S' ? 1 : 0;
    this.getManualesYProcedimientos();
  }

  getManualesYProcedimientos() {
    this.contratoService.getSelectManualesProcedimientos().subscribe(
      resp => {
        const manualesProcedimientos: any[] = JSON.parse(resp);

        if (manualesProcedimientos.length > 0) {
          if (this.esEmsa == 1) {
            this.manualesProcedimientos = manualesProcedimientos.filter(data => {
              return data.usuario_emsa == 1;
            });
            this.manualesProcedimientosFiltered = this.manualesProcedimientos;
          }else{
            this.manualesProcedimientos = manualesProcedimientos.filter(data => {
              return data.usuario_emsa == 0;
            });
            this.manualesProcedimientosFiltered = this.manualesProcedimientos;
          }
        }else{
          this.manualesProcedimientos = [];
          this.manualesProcedimientosFiltered = [];
        }
      },
      error => {
        this.manualesProcedimientos = [];
        this.manualesProcedimientosFiltered = [];
        this.notifyService.showError(error.error.message, 'Error al obtener los manuales y procedimientos');
      }
    );
  }

  onDescargar(fileName: string) {
    this.fileService.downloadDocumentoManualesProcedimientos(fileName).subscribe(resp => {
      FileSaver.saveAs(resp, fileName);
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });
  }

  applyFilterManualesProcedicimientos(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    if(filterValue != '')
    {
      this.manualesProcedimientosFiltered = this.manualesProcedimientos.filter(data => {
        return (
          (
            data?.manual_descripcion.toString().toLowerCase().includes(filterValue) || 
            data?.tipo_descripcion.toString().toLowerCase().includes(filterValue)
          )
        );
      });
    }
    else
    {
      this.manualesProcedimientosFiltered = this.manualesProcedimientos;
    }
  }

}
