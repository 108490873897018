<div id="content" class="content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Itemizado</h4>
        </div>
        <div class="panel-body ">

            <div class="row">
                <div class="col-md-6">
                    <!-- <div class="widget widget-stats bg-info">
                        <div class="stats-icon"><i class="fa fa-inbox"></i></div>
                        <div class="stats-primary">
                            <h4>Aprobados</h4>
                            <h3>{{ cantidadAprobados }}</h3>
                        </div>
                        <div class="stats-link">
                            <a [routerLink]="" (click)="itemizadosAprobados()"  style="cursor: pointer;">Ver detalle <i class="fa fa-arrow-alt-circle-right"></i></a>
                        </div>
                    </div> -->
                    <button [ngClass]="{'text-primary text-bolder': selectedFilter === 1}" class="btn tag mb-1" style="margin-right: 5px;" (click)="getContratosItemizados()">En Proceso <span [ngClass]="{'bg-light-orange': selectedFilter === 1}" class="counter">{{cantidadTotales}}</span> </button>
                    <button [ngClass]="{'text-primary text-bolder': selectedFilter === 2}" class="btn tag mb-1" style="margin-right: 5px;" (click)="itemizadosAprobados()">Aprobados <span [ngClass]="{'bg-light-orange': selectedFilter === 2}" class="counter">{{cantidadAprobados}}</span> </button>
                </div>
                <div class="col-md-6">
                    <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterGrl($event)" placeholder="Buscar ... " #input>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col col-md-4">
                    <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterGrl($event)" placeholder="Buscar ... " #input>
                </div>
            </div> -->

           <div class="row">
                <div class="col col-md-12">
                    <table mat-table [dataSource]="dataSource" matSort class="table table-striped table-td-valign-middle table-th-valign-middle">
                        <ng-container matColumnDef="NombreContrato">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="NombreContrato">Contrato</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.NOMBRE_CONTRATO }}</td>
                        </ng-container>
                        <ng-container matColumnDef="EstadoItemizado">
                            <th class=" normalText" style="padding:
                                10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="EstadoItemizado">Estado Itemizado</th>
                            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.ESTADO_ITEMIZADO}}
                            </td>
                        </ng-container>                                
                        <ng-container matColumnDef="Acciones">
                            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
                            <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                                <div class="btn-group">
                                    <button *ngIf="element.CORRELATIVO_ESDO_ITEMIZADO != 118" class="btn btn-sm btn-primary" (click)="irAEtapa(element.CORRELATIVO, element.CORRELATIVO_ESDO_ITEMIZADO)">Ir a Etapa</button>   
                                    <button *ngIf="element.CORRELATIVO_ESDO_ITEMIZADO == 118" class="btn btn-sm btn-primary" (click)="irAEtapa(element.CORRELATIVO, element.CORRELATIVO_ESDO_ITEMIZADO)">Ver Detalle</button>                                          
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="dataSourceHeader"></tr>
                        <tr mat-row *matRowDef="let row; columns: dataSourceHeader;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

                        <tr class="mat-row " *matNoDataRow>
                            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
                        </tr>
                    </table>

                    <mat-paginator #paginatorGrl [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>        
        
