<div id="content" class="content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Detalle Itemizado</h4>
        </div>
        <div class="panel-body ">

            <h4>Asignar Articulos</h4>
            <br>
            <h5>{{ contrato.NOMBRE_CONTRATO }} <br> {{ contrato.PROVEEDOR }}</h5>
            <br>
            <hr>
            <div class="row">
                <div class="col-md-2">Asignar a Todos:</div>
                <div class="col-md-4">
                    <ng-select2 [data]="articulos" #articulos_all  width="100%" (valueChanged)="onChangeAllArt($event)" name="articulos" class="select2-selection select2-selection--single">
                    </ng-select2>
                </div>
            
                <div class="col-md-6 text-right">
                    <a routerLink="/dashboard-itemizado-mod" class="btn btn-warning">Volver</a> &nbsp;&nbsp;&nbsp;
                    <button class="btn btn-primary" type="submit" (click)="guardar()"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Guardar</button>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead class="thead-dark">
                            <th>R</th>
                            <th>Q</th>
                            <th>SubPos</th>
                            <th>Desc</th>
                            <th>Unidad</th>
                            <th>Moneda</th>
                            <th>Cantidad</th>
                            <th>V. Unitario</th>
                            <th width="25%">Seleccionar Articulo</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of itemizado">
                                <td *ngIf="item.Cabecera" colspan="14"><strong>{{ item.Cabecera }}</strong></td>

                                <td *ngIf="!item.Cabecera && item.R" class="pt-2"><i class="fa fa-check"></i></td>
                                <td *ngIf="!item.Cabecera && !item.R" class="pt-2">&nbsp;</td>
                                <td *ngIf="!item.Cabecera && item.Scpq" class="pt-2"><i class="fa fa-check"></i></td>
                                <td *ngIf="!item.Cabecera && !item.Scpq" class="pt-2">&nbsp;</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Subpos }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Descripcion }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Unidad }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ contrato.TIPO_MONEDA }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.Cantidad | number:'1.2': 'es-CL' }}</td>
                                <td *ngIf="!item.Cabecera" class="pt-2">{{ item.PrecioUnitario | number:'1.2': 'es-CL' }}</td>      
                                <td *ngIf="!item.Cabecera">
                                    <ng-select2 [data]="articulos" #articulo width="100%" name="articulo" 
                                    [(ngModel)]="data_articulos[getItemByid(item.Correlativo)].articulo" 
                                    class="select2-selection select2-selection--single">
                                    </ng-select2>
                                </td>                          
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <br>
            
        </div>
    </div>
</div>    
