import { Component, OnInit, ViewChild } from '@angular/core';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-view-email',
  templateUrl: './view-email.component.html',
  styleUrls: ['./view-email.component.css']
})
export class ViewEmailComponent implements OnInit {
  emails:any;
  dcEmails: string[] = ['Correlativo','Sistema', 'UsuarioEmisor','Destinatario','Fecha','Descripcion'];

  selection = new SelectionModel<any>(false, []);


  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  constructor(private contratoService: ContratoService) { }

  ngOnInit(): void {
    this.getEmails();
  }


  private getEmails(){
    this.contratoService.getEmailsLog().subscribe(resp=>{      
      this.emails=new MatTableDataSource(JSON.parse(resp));
      this.paginator._intl.itemsPerPageLabel = 'Registros por página';
      this.paginator._intl.firstPageLabel = 'Primera pagina';
      this.paginator._intl.lastPageLabel = 'Última pagina';
      this.paginator._intl.nextPageLabel = 'Siguiente';
      this.paginator._intl.previousPageLabel = 'Anterior';
      this.emails.paginator = this.paginator; 
    });
  }

  applyFilterEmail(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.emails.filter = filterValue.trim().toLowerCase();
  }

}
