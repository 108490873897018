import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import * as moment from 'src/assets/plugins/moment/moment';
import { ViewExcelButtonsProps } from 'src/app/shared/view-excel-button/view-excel-buttons.component';
import { formatCurrency } from 'src/app/shared/functions/common';

@Component({
  selector: 'app-all-ep',
  templateUrl: './all-ep.component.html',
  styleUrls: ['./all-ep.component.css']
})
export class AllEpComponent implements OnInit {
   /** Acciones permitidas para el usuario segun su rol */
   accionesPermitidas: string[] = [];
   accionesPermitidasCorr: string[] = [];
   userId: number;
   rolId: number;

  corrContrato: number;
  estadosPagos: any[];
  contratos: any[];
  existeContrato = false;
  mostrarTodos = false;
  filterEp: string;
  validateDocGarantiaContrato: boolean;

  excelButtonEstadoPago: ViewExcelButtonsProps = {
    title: 'Estados de pago',
    filename: 'estados_pago',
    headers: [
      {
        title: 'Código Contrato',
        value: 'CODIGO_CONTRATO',
      },
      {
        title: 'Contrato',
        value: 'NOMBRE_CONTRATO',
      },
      {
        title: 'Estado Contrato',
        value: 'ESTADO_CONTRATO',
      },
      {
        title: 'Fecha Vencimiento Contrato',
        value: 'FECHA_TERMINO_CONTRATO',
      },
      {
        title: 'Adm EMSA',
        value: 'ADMINISTRADOR',
      },
      {
        title: 'Correlativo',
        value: 'EP_CORRELATIVO',
      },
      {
        title: 'Descripción',
        value: 'EP_DESCRIPCION',
      },
      {
        title: 'Proveedor',
        value: 'PROVEEDOR',
      },
      {
        title: 'RUT',
        value: 'RUT_PROVEEDOR_DIGITO',
      },
      {
        title: 'Desde',
        value: 'EP_PERIODO_DESDE',
      },
      {
        title: 'Hasta',
        value: 'EP_PERIODO_HASTA',
      },
      {
        title: 'Monto',
        value: 'MONTO',
      },
      {
        title: 'Moneda',
        value: 'Simbol',
      },
      {
        title: 'Estado',
        value: 'EP_ESTADO',
      },
      {
        title: 'HES',
        value: 'EP_HES',
      },
      {
        title: 'Transcurridos',
        value: 'DIAS_TRANSCURRIDOS',
      }
    ],
    data: []
  }

  constructor(
    private estadoPagoService: EstadoPagoService,
    private authService: AuthService,
    private contratosService: ContratoService
    ) {

    this.userId = JSON.parse(localStorage.getItem('userData')).userId;
    this.rolId = JSON.parse(localStorage.getItem('userData')).rolId;
    let corrMenu = 3;
    this.validateDocGarantiaContrato = true;
    this.authService.getAccionesPorUsuario(corrMenu).subscribe(resp => {
      let acciones: any[] = JSON.parse(resp);
      if(acciones.length > 0) {
        this.accionesPermitidas = acciones[0].ACCIONES.split(' - ');
        this.accionesPermitidasCorr = acciones[0].ACCIONES_CORRELATIVO.split(' - ');
      }
    },
    error => {console.log(error)});

  }
  existeAccion(accion: string) {
    return (this.accionesPermitidasCorr.includes(accion));
  }

  ngOnInit(): void {
    this.getContratos();
    if(this.rolId == 17 || this.rolId == 20 || this.rolId == 22 || this.rolId == 13 || this.rolId == 10) {
      this.getEstadosPago(0);
    }
  }
  private getContratos() {
    this.contratosService.getContratosBusquedaEP(this.userId, this.rolId).subscribe(resp=>{
      this.contratos= JSON.parse(resp);
      if (this.contratos.length > 0 && this.rolId === 3) {
        this.onChangeCto(this.contratos[0].id);
      }
    });
  }
  onChangeCto(corrContrato: any) {
    this.corrContrato = corrContrato;
    this.existeContrato = (this.corrContrato) ? true : false;
    if(this.existeContrato) {
      this.getEstadosPago(this.corrContrato);

      // validacion de documento de garantia del contrato
      this.estadoPagoService.postRetornaDocumentosGaratiaContrato({in_pin_contrato: this.corrContrato}).subscribe(resp => {
        const documentosGarantiaContrato = JSON.parse(resp).documentosGaranticaContrato;
        const data = this.contratos?.filter(c=> (c.id == this.corrContrato));

        if (data[0].requiere_bg == '1') {
          if (documentosGarantiaContrato.length > 0) {
            for (let index = 0; index < documentosGarantiaContrato.length; index++) {
              const element = documentosGarantiaContrato[index];
              
              if (element.fecha_devolucion == null) {
                const fechaTerminoDocGarantia = new Date(element.fecha_vencimiento);
                const fechaTerminoContrato = new Date(data[0].fecha_termino_contrato);

                if (fechaTerminoDocGarantia >= fechaTerminoContrato) {
                  this.validateDocGarantiaContrato = true;
                  break;
                }else{
                  this.validateDocGarantiaContrato = false;
                }
              }
            }
          }else{
            this.validateDocGarantiaContrato = false;
          }
        }else{
          this.validateDocGarantiaContrato = true;
        }
      });
    }
  }
  private getEstadosPago(con_correlativo: number) {
    this.estadoPagoService.getEstadosPago(con_correlativo, this.userId, this.rolId).subscribe(resp => {
      this.setEstadosPagos(JSON.parse(resp));
    });
  }

  private getEpList()
  {
    this.estadoPagoService.getEpList(this.existeAccion("27")).subscribe(resp => {
      this.setEstadosPagos(JSON.parse(resp));
    });
  }
  onMostrarTodos()
  {
    this.mostrarTodos = !this.mostrarTodos;

    if(this.mostrarTodos)
    {
      this.getEpList()
    }
    else
    {
      this.setEstadosPagos([]);
    }
  }
  calDiasRestantes(fecha_inicio: string)
  {
    const hoy = moment(new Date());
    let diasTranscurridos = hoy.diff(fecha_inicio, 'days');
    return diasTranscurridos
  }

  setEstadosPagos(data: any) {
    this.estadosPagos = data.map(estadoPago => {
      return {
        ...estadoPago,
        MONTO: formatCurrency(+estadoPago.MONTO, estadoPago?.ISOCode === 'CLP' ? 0 : 2),
        DIAS_TRANSCURRIDOS: `${this.calDiasRestantes(estadoPago.CREACION)} dias`,
        RUT_PROVEEDOR_DIGITO: `${estadoPago.rut}-${estadoPago.dig_rut}`,
      }
    });
    this.excelButtonEstadoPago.data = this.estadosPagos;
  }

  tieneEpPendiente()
  {
    // diferente de hes o facturado
    const data = this.contratos?.filter(c=> (c.id == this.corrContrato));
    return data[0]?.ep_pendiente
    //return this.estadosPagos?.filter(t=> (t.EP_ESTADO_CORRELATIVO !== 108) && (t.EP_ESTADO_CORRELATIVO !== 109)).length;
  }
}
