import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { IP_SERVICE_URL } from '../shared/services/global-data.service';

@Injectable()
export class HttpPluginInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private cookieService: CookieService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {
      ...request.headers['headers'],
    };

    const token = JSON.parse(localStorage.getItem('userData'))?._token;
    if(token) headers['Authorization'] = 'Bearer ' + token

    if (request.url !== IP_SERVICE_URL) {
      const ip = this.cookieService.get('ip');
      headers.ip = ip;
    }

    request = request.clone({
      setHeaders: {
        ...headers
      }
    });

    return next.handle(request)
      .pipe(
        catchError((err: HttpErrorResponse) => {

          if (err.status === 401) {
            this.authService.logout();
          }

          return throwError(err);
        })
      );
  }
}
