<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title">Cambiar contraseña</h4>
    <button *ngIf="!force" type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()" novalidate>
      <div class="row form-group">
        <label for="lPassword" class="col-md-12 col-form-label">Contraseña Anterior</label>
        <div class="col-md-12">
          <input type="password" class="form-control form-control-lg" placeholder="Contraseña Anterior"
            formControlName="lOldPassword" [class.is-invalid]="isInvalid('lOldPassword')" autocomplete="new-password" />
          <span class="invalid-feedback" *ngIf="isInvalid('lOldPassword')">
            Debe ingresar la contraseña anterior
          </span>
        </div>
      </div>
      <div class="row form-group">
        <label for="lPassword" class="col-md-12 col-form-label">Nueva Contraseña</label>
        <div class="col-md-12">
          <input type="password" class="form-control form-control-lg" placeholder="Nueva Contraseña"
            formControlName="lPassword" [class.is-invalid]="isInvalid('lPassword')" autocomplete="new-password" />
          <span class="invalid-feedback" *ngIf="isInvalid('lPassword')">
            {{ this.changePasswordForm.get('lPassword').errors.message }}
          </span>
        </div>
      </div>
      <div class="row form-group">
        <label for="lPassword2" class="col-md-12 col-form-label">Repita Contraseña</label>
        <div class="col-md-12">
          <input type="password" class="form-control form-control-lg" placeholder="Repita Contraseña"
            formControlName="lPassword2" [class.is-invalid]="isInvalid('lPassword2')" autocomplete="new-password" />
          <span class="invalid-feedback" *ngIf="isInvalid('lPassword2')">
            {{ this.changePasswordForm.get('lPassword2').errors.message }}
          </span>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-2"><a *ngIf="!force" href="javascript:;" (click)="modalRef.hide()"
            class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
        <div class="col-md-5">
          <button class="btn btn-primary btn-block" type="submit" [disabled]="!changePasswordForm.valid || isSaving"><i
              _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Actualizar</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>