import { Component, HostListener, Input, OnChanges } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
declare let Swal: any;

@Component({
  selector: 'app-modal-reload-session',
  templateUrl: './modal-reload-session.component.html',
  styleUrls: ['./modal-reload-session.component.css']
})
export class ModalReloadSessionComponent implements OnChanges {
  //Tiempo antes de que el modal del inactividad aparezca
  inactivityTimerTime = 1200000; //120000 = 2 minutos, 3600000 = 60 minutos;
  //Cantidad de segundos que estara abierto el modal de inactividad antes de cerrar la sesión
  modalCounterTime = 60000; //60000 = 60 segundos

  modalCounterInterval = null;
  inactivityTimerInterval = null;
  sessionDurationTimerInterval = null;
  @Input() isAuth = false;

  constructor(private authService: AuthService) { }

  ngOnChanges(): void {
    this.setSessionDurationTimer();
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keypress', ['$event'])
  onEvent(_) {
    if (!this.isAuth) return;
    this.setInactivityTimer();
  }

  setInactivityTimer() {
    clearInterval(this.inactivityTimerInterval);
    if (!this.isAuth) return;
    this.inactivityTimerInterval = this.setTimerModal(this.inactivityTimerTime);
  }

  setSessionDurationTimer() {
    clearInterval(this.sessionDurationTimerInterval);
    if (!this.isAuth) return;
    const { expiresIn } = this.authService.getUserDataStorage();
    const sessionDurationTime = expiresIn - this.modalCounterTime - 120000;
    if (sessionDurationTime > 0) this.sessionDurationTimerInterval = this.setTimerModal(sessionDurationTime);
  }

  setTimers() {
    this.setInactivityTimer();
    this.setSessionDurationTimer();
  }

  setTimerModal(timer: number) {
    const modal = this;
    return setInterval(function () {
      if (typeof Swal === "undefined" || Swal.isVisible() || !modal.isAuth) return;
      clearInterval(this.modalCounterInterval);
      Swal.fire({
        icon: "warning",
        title: "Su sesión será finalizada",
        html: `<p class="text-muted font-size14">en <b>${(modal.modalCounterTime / 1000)}</b> Segundos.</p>

        `,
        padding: '2em',
        timer: modal.modalCounterTime,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonColor: '#E75300',
        confirmButtonText: "Continuar",
        denyButtonColor: 'transparent',
        denyButtonText: "Cerrar sesión",
        customClass: {
          confirmButton: 'btn-primary font-size14',
          denyButton: 'tag text-orange text-primary font-size14',
          title: 'font-size14'
        },
        allowEscapeKey: false,
        allowOutsideClick: false,
        willOpen: () => {
          this.modalCounterInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                const textContent = (Swal.getTimerLeft() / 1000).toFixed();
                b.textContent = textContent;
              }
            }
          }, 1000);
        },
      }).then((result) => {
        if (result.isDismissed || result.isDenied) {
          modal.authService.logout();
        } else {
          modal.updateToken();
        }
      });
    }, timer);
  }

  updateToken() {
    this.authService.updateToken();
    this.setTimers();
  }
}