<div id="content" class="content">
    <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="panel-heading   ui-sortable-handle">
            <h4 class="panel-title normalText">Checklist</h4>
        </div>
        <div class="panel-body">
            <h4>Asociación de Checklist a Contrato</h4>
            <br>
            <div class="row">
                <label for="contrato" class="col-md-1 col-form-label"><strong>Contrato</strong></label>
                <div class="col-md-4">
                    <ng-select2 [data]="contratos" width="100%" #contrato (valueChanged)="onChangeCto($event)" class="select2-selection select2-selection--single">
                    </ng-select2>
                    <br>
                    <div *ngIf="!existeContrato" class="alert alert-danger">Selecciona un elemento de la lista</div>
                </div>
                <div class="col-md-5"></div>
                <div class="col-md-2">
                    <a class="btn btn-primary btn-block" routerLink="/mantenedor-items">Modificar Items</a>
                </div>
            </div>

            <br><br>

            <div class="row">
                <div class="col-md-6">
                    <h5>Items Disponibles</h5>
                    <table class="table">
                        <thead>
                            <td style="background-color: black; color:white; font-weight: bold;">Disponibles</td>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of gruposChk">
                                <td *ngIf="item.Cabecera" style="background-color: gray; color:white; font-weight:bold">{{ item.Cabecera }}</td>
                                <td *ngIf="!item.Cabecera"><a (click)="relacionarItem(item.Correlativo)" [ngClass]="{'text-success': item.Obligatorio == 'S'}" style="cursor: pointer;">{{ item.NombreItem }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <h5>Items del Contrato</h5>
                    <table class="table">
                        <thead>
                            <td style="background-color: black; color:white; font-weight: bold;">Contrato</td>
                        </thead>
                        <tbody>
                            <td *ngIf="itemsAsociados.length == 0">Ningún item asociado</td>
                            <tr *ngFor="let item of itemsAsociados">
                                <td *ngIf="item.Cabecera" style="background-color: gray; color:white; font-weight:bold">{{ item.Cabecera }}</td>
                                <td *ngIf="!item.Cabecera"><a (click)="eliminaRelacionItem(item.Correlativo)" [ngClass]="{'text-success': item.Obligatorio == 'S'}" style="cursor: pointer;">{{ item.NombreItem }}</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>