<div id="content" class="content">
  <div class="row">
    <div class="col-md-12 ui-sortable">
      <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="bg-white p3-rem rounded">
          <div class="row mb-4">
            <div class="col-12 col-md-6">
              <h4 class="normalText">Logs</h4>
            </div>
          </div>
          <div class="row" *ngIf="logs">
            <label for="startDate" class="col-md-1 col-form-label">
              <strong>Fecha Inicio</strong>
            </label>
            <div class="col-md-3">
              <input type="date" class="form-control m-b-5" placeholder="Fecha Inicio" [(ngModel)]="startDate"
                [ngClass]="{ 'is-invalid': errors.startDate }" (input)="onFilter()">
              <div class="alert" [ngClass]="{ 'alert-danger': errors.startDate }">{{ errors.startDate }}</div>
            </div>
            <label for="endDate" class="col-md-1 col-form-label">
              <strong>Fecha Final</strong>
            </label>
            <div class="col-md-3">
              <input type="date" class="form-control m-b-5" placeholder="Fecha Final" [(ngModel)]="endDate"
                [ngClass]="{ 'is-invalid': errors.endDate }" (input)="onFilter()">
              <div class="alert alert-danger" [ngClass]="{ 'alert-danger': errors.endDate }">{{ errors.endDate }}</div>
            </div>
            <label for="userName" class="col-md-1 col-form-label">
              <strong>Usuario</strong>
            </label>
            <div class="col-md-3">
              <input class="form-control m-b-5" placeholder="Nombre de Usuario" [(ngModel)]="userName"
                [ngClass]="{ 'is-invalid': errors.userName }" (input)="onFilter()">
              <div class="alert alert-danger" [ngClass]="{ 'alert-danger': errors.userName }">{{ errors.userName }}
              </div>
            </div>
          </div>
          <app-table *ngIf="logs" [header]="header" [dataInit]="logs" [sortInit]="sort"
            [excelButton]="hasErrors() ? excelButton : null"></app-table>
        </div>
      </div>
    </div>
  </div>
</div>