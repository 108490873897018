import { Injectable } from '@angular/core';
import { Cell, Workbook, Worksheet, Alignment } from 'exceljs';
import * as fs from 'file-saver';

type DataRow = (string | number)[]

export interface excelDataProps {
  title?: string;
  headers: string[];
  data: DataRow[];
  filename?: string;
  totalRowsByColumn?: number[];
}

interface excelMultipleDataProps {
  filename?: string;
  data: excelDataProps[]
}

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {
  workbook: Workbook;
  worksheet: Worksheet;
  functionCreateSheet: (worksheet: Worksheet, excelData: excelDataProps) => void;
  currencyColumns: number[] = [];

  onInit() {
    this.workbook = new Workbook();
    this.worksheet = null;
  }

  exportExcelFuncionalidad(excelData: excelDataProps, acciones: any[]) {

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Usuarios-Funcionalidad');
    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    let datos: any[] = [];
    let key: string;
    let value: any;
    data.forEach(d => {
      datos.length = 0;
      datos[0] = d[0];
      datos[1] = d[1];

      let j = 2;
      for (let i in acciones) {
        value = Object.values(d[2][i]).toString();
        datos[j] = value;
        j++;
      }
      let row = worksheet.addRow(datos);
    });

    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 10;
    worksheet.getColumn(4).width = 10;
    worksheet.getColumn(5).width = 10;
    worksheet.getColumn(6).width = 10;
    worksheet.getColumn(7).width = 10;
    worksheet.getColumn(8).width = 10;
    worksheet.getColumn(9).width = 10;
    worksheet.getColumn(10).width = 10;
    worksheet.getColumn(11).width = 10;
    worksheet.addRow([]);
    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    })

  }

  exportExcel(excelData: excelDataProps) {
    this.onInit();
    this.createSheet(excelData);
    this.generateExcel(excelData.filename);
  }

  exportExcelWithMultiplesSheets(excelProps: excelMultipleDataProps) {
    this.onInit();
    const data = excelProps.data;
    if (data.length <= 1) {
      this.createSheet(data[0], 'horizontal', 0)
    } else {
      data.forEach((d, index) => index !== 0 ? this.createSheet(d, 'horizontal', index) : this.createSheet(d, 'vertical', index));
    }
    this.generateExcel(excelProps.filename);
  }

  createSheet(excelData: excelDataProps, orientation: string = 'horizontal', index = 0) {
    const title = excelData.title;
    //Create a workbook with a worksheet
    if (index === 0) this.worksheet = this.workbook.addWorksheet(title || 'Consultas');
    //Adding Header Row
    this.functionCreateSheet = orientation == 'horizontal' ? this.createHorizonalSheet : this.createVerticalSheet;
    this.functionCreateSheet(this.worksheet, excelData)
    this.worksheet.addRow([]);

    this.autoFitColumn(this.worksheet);
  }

  createHorizonalSheet(worksheet: Worksheet, { headers, data }: excelDataProps) {
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      this.applyCellStyle(cell)
      if(this.checkCurrencyColumnName(cell.value.toString())) this.setAlignmentCurrencyCell(cell);
    });

    // Adding Data with Conditional Formatting
    data.forEach((d, index) => {
      if(index === 0) this.setCurrencyColumns(d, headers);
      const row = worksheet.addRow(d);

      row.eachCell((cell, colNumber) => {
        if (this.currencyColumns.includes(colNumber)) {
          this.setAlignmentCurrencyCell(cell);
          let value = cell.value.toString();
          let numFmt = value.includes(',') || value == '' ? '#,##0.00' : '#,##0';
          value = value.replace(/\./g, "");
          value = value.replace(/,/g, ".");
          const cellNumber = Number(value);
          if(isNaN(cellNumber)) return;
          cell.value = cellNumber;
          if(cell.value === 0) numFmt = '';
          cell.numFmt = numFmt;
        }
      });
    });
  }

  createVerticalSheet(worksheet: Worksheet, { headers, data, totalRowsByColumn }: excelDataProps) {
    const rows = [];
    let position = 0;
    let count = 0;
    const colsColor = [1];

    headers.forEach((header, index) => {
      if (totalRowsByColumn && totalRowsByColumn[position] && totalRowsByColumn[position] <= count) {
        position++;
        count = 0;
        colsColor.push(colsColor[colsColor.length - 1] + 2);
      }

      const item = data[0][index];
      
      if (!rows[count]) {
        rows[count] = [];
        if (position > 0) rows[count].push('', '');
      }

      rows[count].push(...[header, item]);
      count++;
    });

    rows.forEach(row => {
      const cells = worksheet.addRow(row);
      cells.eachCell((cell, colNumber) => {
        if (cell.value !== '' && colsColor.includes(colNumber)) this.applyCellStyle(cell);

        const regex = /^[0-9]+([,][0-9]+)?$/;
        const onlyNumbers = regex.test(cell.value.toString());

        if (onlyNumbers) {
          this.setAlignmentCurrencyCell(cell);
          let value = cell.value.toString();
          let numFmt = value.includes(',') || value == '' ? '#,##0.00' : '#,##0';
          value = value.replace(/\./g, "");
          value = value.replace(/,/g, ".");
          const cellNumber = Number(value);
          if(isNaN(cellNumber)) return;
          cell.value = cellNumber;
          if(cell.value === 0) numFmt = '';
          cell.numFmt = numFmt;
        }
      });
    });
  }

  generateExcel(filename: string) {
    //Generate & Save Excel File
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, filename + '.xlsx');
    })
  }

  autoFitColumn(worksheet: Worksheet) {
    if (!worksheet.columns) return;
    const maxLengthExcelColumn = 50;
    worksheet.columns.forEach(function (column) {
      let maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength + 5;
        }
        if (columnLength > maxLengthExcelColumn) {
          cell.alignment = { ...cell.alignment, wrapText: true };
        }
      });
      let width = maxLength;
      if (maxLength < 10) width = 10;
      if (maxLength > maxLengthExcelColumn) width = maxLengthExcelColumn;

      column.width = width;
    });
  }

  applyCellStyle(cell: Cell) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4167B8' },
      bgColor: { argb: '' }
    }
    cell.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 12
    }
    return cell;
  }

  setCurrencyColumns(row: DataRow, headers: string[]) {
    row.forEach((cell, index) => {
      if(typeof cell === undefined) return;

      const hasCurrencyColumnName = headers[index] && this.checkCurrencyColumnName(headers[index]);
      const cellString = cell?.toString() || '';
      if (hasCurrencyColumnName || ['.',','].some(char => cellString.includes(char)) || cellString == '0'){
        const cellNumber = Number(this.clearPunctuationMarks(cellString));
        if(hasCurrencyColumnName || !isNaN(cellNumber)) {
          this.currencyColumns.push(index + 1);
        }
      }
    })
  }

  clearPunctuationMarks(cell: string) {
    let cellString = cell;
    cellString = cellString.replace(/\./g, "");
    cellString = cellString.replace(/,/g, "");
    return cellString
  }

  checkCurrencyColumnName(value: string) {
    const valueLowercase = value.toLowerCase();
    return ['monto', 'clp', 'subtotal'].some(x => valueLowercase.includes(x));
  }

  setAlignmentCurrencyCell(cell: Cell, alignment?: Partial<Alignment>) {
    cell.alignment = alignment ?? { vertical: 'middle', horizontal: 'right', wrapText: true };
  }
}