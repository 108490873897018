import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as moment from 'src/assets/plugins/moment/moment';

@Component({
  selector: 'app-periodos-contratos',
  templateUrl: './periodos-contratos.component.html',
  styleUrls: ['./periodos-contratos.component.css']
})
export class PeriodosContratosComponent implements OnInit {

  date = new Date();
  periodos: any[] = [];
  formFechas: FormGroup;
  submitted = false;

  @ViewChild('closeModal') closeModal: ElementRef

  constructor(private contratoService: ContratoService, private notifyService: NotificationService) {

  }

  ngOnInit(): void {
    this.getPeriodos();
    this.resetForm();
  }

  private getPeriodos() {
    this.contratoService.getPeriodos(this.date.getFullYear()).subscribe(resp => {
      this.periodos = JSON.parse(resp);
    });
  }
  private resetForm() {
    this.formFechas = new FormGroup({
      dia_aprobacion: new FormControl(null),
      dia_envio: new FormControl(null),
      dia_hes: new FormControl(null),
      peri_correlativo: new FormControl(null)
    });
    this.submitted = false;
  }
  cargarModal(peri_correlativo: number, mes: number) {
    this.formFechas.controls.peri_correlativo.setValue(peri_correlativo);

    let lastDayFull = new Date(this.date.getFullYear(), mes, 0);
    let lastDay = Number(moment(lastDayFull).format('DD')); 
    
    this.formFechas.controls.dia_aprobacion.setValidators([Validators.required, Validators.min(1), Validators.max(lastDay)]);
    this.formFechas.controls.dia_aprobacion.updateValueAndValidity();
    this.formFechas.controls.dia_envio.setValidators([Validators.required, Validators.min(1), Validators.max(lastDay)]);
    this.formFechas.controls.dia_envio.updateValueAndValidity();
    this.formFechas.controls.dia_hes.setValidators([Validators.required, Validators.min(1), Validators.max(lastDay)]);
    this.formFechas.controls.dia_hes.updateValueAndValidity();
  }
  onClose() {
    this.resetForm();
  }
  onAsignarFechas() {
    this.submitted = true;
    if (this.formFechas.invalid) {
      return;
    }

    const postData: {
      in_peri_correlativo: number,
      in_dia_envio_ep_contratista: number,
      in_dia_aprob_ep_emsa: number,
      in_dia_recep_hes: number,
      in_usua_correlativo_creacion: number
    } = {
      in_peri_correlativo: this.formFechas.value.peri_correlativo,
      in_dia_envio_ep_contratista: this.formFechas.value.dia_envio,
      in_dia_aprob_ep_emsa: this.formFechas.value.dia_aprobacion,
      in_dia_recep_hes: this.formFechas.value.dia_hes, 
      in_usua_correlativo_creacion: 0
    };

    this.contratoService.registraFechasContrato(postData).subscribe(resp=>{
      this.notifyService.showSuccess('Fechas asignadas con exito.', 'Asignar Fechas');
    }, err =>{
      this.notifyService.showError('Error al asignar fechas', 'Asignar Fechas');
      console.log(err);
    }, () => {
      this.getPeriodos();
    });  
    this.resetForm();
    this.closeModal.nativeElement.click();
  }
  get f() { return this.formFechas.controls; }
}
