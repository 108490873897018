import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BoletasService } from 'src/app/shared/services/boletas.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { DataService } from 'src/app/shared/services/data.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as moment from 'src/assets/plugins/moment/moment';
import { validMaxLength } from 'src/app/shared/functions/common';

@Component({
  selector: 'app-estado-pago-incurrido',
  templateUrl: './estado-pago-incurrido.component.html',
  styleUrls: ['./estado-pago-incurrido.component.css']
})
export class EstadoPagoIncurridoComponent implements OnInit {

  corrContrato: number;
  contrato = { NOMBRE_CONTRATO: '', PROVEEDOR: '', TIPO_MONEDA: '', MONTO_REAJUSTE: 0, SALDO_CONTRATO: 0, TIMO_CORRELATIVO: 0 };
  tipoMoneda: any[];
  reajuste: number = 0;
  itemizado: any[] = [];
  cantidades: any[] = [];
  valoresCantidades: any[] = [];
  valoresTotales: any[] = [];
  total: number = 0;
  cantidad: any;
  form: FormGroup
  submitted = false;
  validMaxLength: (e: Event) => void;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private boletasService: BoletasService,
    private contratoService: ContratoService,
    private notifyService: NotificationService,
    private estadoPagoService: EstadoPagoService,
    private router: Router
    ) {
    this. corrContrato = this.dataService.idContrato;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.corrContrato = params['corr_contrato'];
    });

    this.getMonedas();
    this.getContrato();
    this.getItemizado();
    this.resetForm();

    this.validMaxLength = validMaxLength;
  }

  private resetForm() {
    this.form = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      fecha_desde: new FormControl(null, Validators.required),
      fecha_hasta: new FormControl(null, Validators.required),
      moneda: new FormControl({value: 0, disabled: true}),
      reajuste: new FormControl(0),
      retencion_laboral: new FormControl(null),
      rdo_contractual: new FormControl('P'),
      retencion_contractual: new FormControl(null),
      cantidad: new FormControl(null)
    });
    this.submitted = false;
  }
  private getContrato() {
    this.contratoService.getContratoId(this.corrContrato).subscribe(resp => {
      this.contrato = JSON.parse(resp)[0];
    }, error => console.log(error),
    () => {
      this.form.controls.moneda.setValue(this.contrato.TIMO_CORRELATIVO);
      this.form.controls.moneda.updateValueAndValidity();
    });
  }
  private getMonedas() {
    this.boletasService.getTipoDominios('TIMO').subscribe(resp => {
      this.tipoMoneda = JSON.parse(resp);
    });
  }
  private getItemizado() {
    this.estadoPagoService.getItemizadoDetalle(this.corrContrato).subscribe(resp => {
      let response = JSON.parse(resp);

      let idx = 0;
      let cabecera = null;
      // creo estructura del itemizado para mostrar de forma ordenada
      for(let i in response) {
        // si es el primer registro o cambia la cabecera del itemizado: registro cabecera y primer subitem
        if(idx == 0 || response[i]['ITEM_CABECERA'] !== cabecera) {
          // cabecera
          this.itemizado[idx] = {
            Cabecera: response[i]['ITEM_CABECERA'],
            Subpos: null,
            Descripcion: null,
            Unidad: null,
            Cantidad: null,
            PrecioUnitario: null,
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: null,
            Scpq: null
          };
          // primer subitem
          idx++;
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ']
          };
          idx++;
        // de lo contrario sigo registrando subitems hasta que cambie el item principal
        } else {
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ']
          };
          idx++;
        }
        cabecera = response[i]['ITEM_CABECERA'];
        // seteo totales en 0
        this.valoresCantidades[response[i]['ID_CORRELATIVO']] = 0;
        this.valoresTotales[response[i]['ID_CORRELATIVO']] = 0;
      }
    });
  }
  changeCantidad(corrItem: number, value: number, precio: number, r: number, reajuste: number) {
    this.cantidades[corrItem] = value;
    this.valoresCantidades[corrItem] = precio * value;
    if(r == 1) {
      this.valoresTotales[corrItem] = ((this.valoresCantidades[corrItem] * reajuste) / 100) + this.valoresCantidades[corrItem];
    } else {
      this.valoresTotales[corrItem] = precio * value;
    }
    // calculo total del estado de pago (incluido los reajustes)
    this.total = 0;
    for(let i = 0; i < this.valoresTotales.length; i++) {
      if(this.valoresTotales[i]) this.total += this.valoresTotales[i];
    }
  }
  onGenerar() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const postData:{
      in_cont_correlativo: number,
      iv_descripcion: string,
      iv_periodo_desde: string,
      iv_periodo_hasta: string,
      in_reajuste: number,
      in_usuario_correlativo_creacion: number
    } = {
      in_cont_correlativo: this.corrContrato,
      iv_descripcion: this.form.value.nombre,
      iv_periodo_desde: moment(this.form.value.fecha_desde).format('YYYY-MM-DD'),
      iv_periodo_hasta: moment(this.form.value.fecha_hasta).format('YYYY-MM-DD'),
      in_reajuste: this.form.value.reajuste,
      in_usuario_correlativo_creacion: 0
    };

    let corrEstadoPago;
    this.estadoPagoService.registraEstadoPagoIncurrido(postData).subscribe(resp => {
      corrEstadoPago = JSON.parse(resp)[0].EP_CORRELATIVO;
      if(corrEstadoPago === 0) { // ARREGLAR BUG
        this.notifyService.showInfo('Ya existe un estado de pago para el rango de fechas ingresado. Favor revisar', 'Estado de Pago');
        return;
      }else{
        this.notifyService.showSuccess('Estado de pago creado con exito.', 'Estado de Pago');
        this.guardarDetalleEstadoPago(corrEstadoPago);
      }
    }, error => {
      this.notifyService.showError('Error al crear estado de pago', 'Estado de Pago');
      console.log(error);
    });

  }

  private guardarDetalleEstadoPago(corrEstadoPago: number){
    for(let i = 0; i < this.cantidades.length; i++) {
      if(this.cantidades[i]) {
        this.estadoPagoService.registraDetalleEstadoPagoIncurrido({
          in_ep_correlativo: corrEstadoPago, in_id_correlativo: i, in_solicita_cantidad: this.cantidades[i]
        }).subscribe(resp => {
          this.notifyService.showSuccess('Detalle estado de pago creado con exito.', 'Estado de Pago');
        }, error => console.log(error));
      }
    }

    const postDetalle: {
      in_ep_correlativo: number,
      in_retencion_laboral: number,
      in_retencion_contractual: number,
      in_domi_correlativo_tirecon: number,
      in_domi_timo_correlativo: number
    } = {
      in_ep_correlativo: corrEstadoPago,
      in_retencion_laboral: this.form.value.retencion_laboral,
      in_retencion_contractual: this.form.value.retencion_contractual,
      in_domi_correlativo_tirecon: (this.form.value.rdo_contractual == 'P') ? 98 : 99,
      in_domi_timo_correlativo: 9 // CLP
    };
    this.estadoPagoService.registraRetencionEstadoPago(postDetalle).subscribe(resp => {});

    this.router.navigate(['/estado-pago-checklist', corrEstadoPago]);
  }
  get f() { return this.form.controls; }

}
