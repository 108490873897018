<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
                <div class="panel-body rounded p3-rem">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Manuales y Procedimientos</h5>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <!-- para  filtros en el futuro -->
                                </div>
                                <div class="col-12 col-md-4">
                                    <input matinput="" 
                                    (keyup)="applyFilterManualesProcedicimientos($event)"
                                    placeholder="Buscar ..." class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;">
                                        <i matsuffix="" class="fas fa-search" style="position: absolute; top: 0.7rem; right: 1.5rem;"></i>
                                </div>
                            </div>
                            <br>
                            <ng-container *ngIf="manualesProcedimientosFiltered.length > 0">
                                <table class="table table-hover">
                                    <thead class="thead-darkk">
                                        <th>Descripción</th>
                                        <th>Tipo</th>
                                        <th class="text-left">Acciones</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of manualesProcedimientosFiltered">
                                            <ng-container>
                                                <td>
                                                    {{ item.manual_descripcion }}
                                                </td>
                                                <td>
                                                    {{ item.tipo_descripcion }}
                                                </td>
                                                <td class="text-right">
                                                    <div class="row text-right">
                                                        <view-file-button 
                                                            [nombreArchivo]="item.manual_adjunto"
                                                            [tipoArchivo]="'manuales'"
                                                        >
                                                        </view-file-button>
                                                        <div *ngIf="item.manual_adjunto !== null" class="btn rounded-icon mr-2"
                                                            (click)="onDescargar(item.manual_adjunto)">
                                                            <i class="fas fa-file-download"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-container *ngIf="manualesProcedimientosFiltered.length === 0">
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <h5 class="text-muted">No se encontraron resultados</h5>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>