import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SolpeService, PostArticulo, PostZona, PostBlanco, PostGrafo, PostProyecto } from 'src/app/shared/services/solpe.service';
import { ViewExcelButtonsProps } from 'src/app/shared/view-excel-button/view-excel-buttons.component';
import { TableHeaderCell } from 'src/app/shared/components/table/table.component';
import { ActionButtonProps } from 'src/app/shared/components/action-button/action-button.component';
import { NavTab } from 'src/app/shared/components/nav-tabs/components/nav-tab/nav-tab.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-mantenedores',
  templateUrl: './mantenedores.component.html',
  styleUrls: ['./mantenedores.component.css']
})
export class MantenedoresComponent implements OnInit {

  tabs: NavTab[] = [
    {
      id: 'articulos',
      title: 'Articulos',
    },
    {
      id: 'zonas',
      title: 'Zonas',
    },
    {
      id: 'proyectos',
      title: 'Proyectos',
    },
    {
      id: 'blancos',
      title: 'Blancos',
    },
    {
      id: 'grafos',
      title: 'Grafos',
    }
  ]

  selectedTab = 'articulos';

  formArticulo: FormGroup;
  formZona: FormGroup;
  formBlanco: FormGroup;
  formGrafo: FormGroup;
  formProyecto: FormGroup;
  submittedArticulo = false;
  submittedZona = false;
  submittedBlanco = false;
  submittedGrafo = false;
  submittedProyecto = false;

  articulos: any = [];
  dcArticulos: TableHeaderCell[] = [
    {
      title: 'Codigo',
      value: 'CODIGO',
      width: '15%',
    },
    {
      title: 'Nombre',
      value: 'NOMBRE',
      width: '55%',
    },
    {
      title: 'Estado',
      value: 'status',
      width: '15%',
      custom: true,
    },
    {
      title: 'Fecha Creación',
      value: 'FECHA_CREACION',
      width: '15%',
    },
    {
      title: 'Acciones',
      value: 'actions',
      custom: true,
      width: '15%',
    },
  ];
  excelButtonArticulos: ViewExcelButtonsProps = {
    title: 'Articulos',
    filename: 'articulos',
    headers: this.dcArticulos.filter((header) => header.title !== 'Acciones'),
    data: []
  };

  actionButtonArticulos: ActionButtonProps = {
    title: 'Agregar',
    action: () => this.openModalArticulo(),
    icon: 'fa-plus'
  };

  zonas: any;
  dcZonas: TableHeaderCell[] = [
    {
      title: 'Codigo',
      value: 'CODIGO',
      width: '15%',
    },
    {
      title: 'Nombre',
      value: 'NOMBRE',
      width: '55%',
    },
    {
      title: 'Estado',
      value: 'status',
      width: '15%',
      custom: true,
    },
    {
      title: 'Fecha Creación',
      value: 'FECHA_CREACION',
      width: '15%',
    },
    {
      title: 'Acciones',
      value: 'actions',
      custom: true,
      width: '15%',
    },
  ];
  excelButtonZonas: ViewExcelButtonsProps = {
    title: 'Zonas',
    filename: 'zonas',
    headers: this.dcZonas.filter((header) => header.title !== 'Acciones'),
    data: []
  };
  actionButtonZonas: ActionButtonProps = {
    title: 'Agregar',
    action: () => this.openModalZona(),
    icon: 'fa-plus'
  };

  blancos: any;
  dcBlancos: TableHeaderCell[] = [
    {
      title: 'Codigo',
      value: 'CODIGO',
      width: '15%',
    },
    {
      title: 'Nombre',
      value: 'NOMBRE',
      width: '55%',
    },
    {
      title: 'Estado',
      value: 'status',
      width: '15%',
      custom: true,
    },
    {
      title: 'Fecha Creación',
      value: 'FECHA_CREACION',
      width: '15%',
    },
    {
      title: 'Acciones',
      value: 'actions',
      custom: true,
      width: '15%',
    },
  ];
  excelButtonBlancos: ViewExcelButtonsProps = {
    title: 'Blancos',
    filename: 'blancos',
    headers: this.dcBlancos.filter((header) => header.title !== 'Acciones'),
    data: []
  };
  actionButtonBlancos: ActionButtonProps = {
    title: 'Agregar',
    action: () => this.openModalBlanco(),
    icon: 'fa-plus'
  };

  grafos: any = [];
  dcGrafos: TableHeaderCell[] = [
    {
      title: 'Grafo',
      value: 'CODIGO',
      width: '15%',
    },
    {
      title: 'Desc. 1',
      value: 'DESC1',
      width: '45%',
    },
    {
      title: 'Desc. 2',
      value: 'DESC2',
      width: '45%',
    },
    {
      title: 'Usuario',
      value: 'PERS_NOMBRE',
      width: '20%',
    },
    {
      title: 'Proyecto',
      value: 'PROY_NOMBRE',
      width: '20%',
    },
    {
      title: 'Estado',
      value: 'status',
      width: '15%',
      custom: true,
    },
    {
      title: 'Fecha Creación',
      value: 'FECHA_CREACION',
      width: '15%',
    },
    {
      title: 'Acciones',
      value: 'actions',
      custom: true,
      width: '10%',
    },
  ];
  excelButtonGrafos: ViewExcelButtonsProps = {
    title: 'Grafos',
    filename: 'grafos',
    headers: this.dcGrafos.filter((header) => header.title !== 'Acciones'),
    data: []
  };

  actionButtonGrafos: ActionButtonProps = {
    title: 'Agregar',
    action: () => this.openModalGrafo(),
    icon: 'fa-plus'
  };

  proyectos: any = [];
  dcProyectos: TableHeaderCell[] = [
    {
      title: 'Correlativo',
      value: 'id',
      width: '15%',
    },
    {
      title: 'Codigo',
      value: 'CODIGO',
      width: '15%',
    },
    {
      title: 'Nombre',
      value: 'NOMBRE',
      width: '45%',
    },
    {
      title: 'Estado',
      value: 'status',
      width: '15%',
      custom: true,
    },
    {
      title: 'Fecha Creación',
      value: 'FECHA_CREACION',
      width: '15%',
    },
    {
      title: 'Acciones',
      value: 'actions',
      custom: true,
      width: '10%',
    },
  ];
  excelButtonProyectos: ViewExcelButtonsProps = {
    title: 'Proyectos',
    filename: 'proyectos',
    headers: this.dcProyectos.filter((header) => header.title !== 'Acciones'),
    data: []
  };

  actionButtonProyectos: ActionButtonProps = {
    title: 'Agregar',
    action: () => this.openModalProyecto(),
    icon: 'fa-plus'
  };
  responsables: any = [];

  activos: any[] = [
    {
      id: 'S',
      text: 'Activo'
    },
    {
      id: 'N',
      text: 'Inactivo'
    }
  ];

  @ViewChild('openModalGuardarArticulo') openModalGuardarArticulo: ElementRef;
  @ViewChild('closeModalGuardarArticulo') closeModalGuardarArticulo: ElementRef;
  @ViewChild('openModalGuardarZona') openModalGuardarZona: ElementRef;
  @ViewChild('closeModalGuardarZona') closeModalGuardarZona: ElementRef;
  @ViewChild('openModalGuardarBlanco') openModalGuardarBlanco: ElementRef;
  @ViewChild('closeModalGuardarBlanco') closeModalGuardarBlanco: ElementRef;
  @ViewChild('openModalGuardarGrafo') openModalGuardarGrafo: ElementRef;
  @ViewChild('closeModalGuardarGrafo') closeModalGuardarGrafo: ElementRef;
  @ViewChild('openModalGuardarProyecto') openModalGuardarProyecto: ElementRef;
  @ViewChild('closeModalGuardarProyecto') closeModalGuardarProyecto: ElementRef;

  excelButton: ViewExcelButtonsProps = this.excelButtonArticulos;
  actionButton: ActionButtonProps = this.actionButtonArticulos;

  constructor(
    private solpeService: SolpeService,
    private notifyService: NotificationService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.resetFormArticulo();
    this.resetFormZona();
    this.resetFormBlanco();
    this.resetFormGrafo();
    this.resetFormProyecto();

    this.getArticulos();
    this.getZonas();
    this.getBlancos();
    this.getGrafos();
    this.getProyectos();
    this.getResponsablesGrafos();
  }
  onCloseArticulo() {
    this.resetFormArticulo();
  }
  onCloseZona() {
    this.resetFormZona();
  }
  onCloseBlanco() {
    this.resetFormBlanco();
  }
  onCloseGrafo() {
    this.resetFormGrafo();
  }
  onCloseProyecto() {
    this.resetFormProyecto();
  }
  private resetFormArticulo() {
    this.formArticulo = new FormGroup({
      correlativo: new FormControl(null, null),
      codigo: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      activo: new FormControl('S', Validators.required),
    });
    this.submittedArticulo = false;
  }
  private resetFormZona() {
    this.formZona = new FormGroup({
      correlativo: new FormControl(null, null),
      codigo: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      activo: new FormControl('S', Validators.required),
    });
    this.submittedZona = false;
  }
  private resetFormBlanco() {
    this.formBlanco = new FormGroup({
      correlativo: new FormControl(null, null),
      codigo: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      activo: new FormControl('S', Validators.required),
    });
    this.submittedBlanco = false;
  }
  private resetFormGrafo() {
    this.formGrafo = new FormGroup({
      correlativo: new FormControl(null, null),
      codigo: new FormControl(null, Validators.required),
      proyecto: new FormControl(null, Validators.required),
      desc1: new FormControl(null, Validators.required),
      desc2: new FormControl(null, Validators.required),
      responsable: new FormControl(null, Validators.required),
      activo: new FormControl('S', Validators.required),
    });
    this.submittedGrafo = false;
  }
  private resetFormProyecto() {
    this.formProyecto = new FormGroup({
      correlativo: new FormControl(null, null),
      codigo: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      activo: new FormControl('S', Validators.required),
    });
    this.submittedProyecto = false;
  }
  private getArticulos() {
    this.solpeService.getArticulosMantenedor().subscribe(resp => {
      this.parseMaintainerData(resp, 'articulo');
    });
  }
  private getZonas() {
    this.solpeService.getZonasMantenedor().subscribe(resp => {
      this.parseMaintainerData(resp, 'zona');
    });
  }
  private getBlancos() {
    this.solpeService.getBlancosMantenedor().subscribe(resp => {
      this.parseMaintainerData(resp, 'blanco');
    });
  }
  private getGrafos() {
    this.solpeService.getGrafosMantenedor().subscribe(resp => {
      this.parseMaintainerData(resp, 'grafo');
    });
  }
  private getProyectos(proyecto?: string) {
    this.solpeService.getProyectosMantenedor().subscribe(resp => {
      this.parseMaintainerData(resp, 'proyecto');
      if (proyecto) this.formGrafo.controls['proyecto'].setValue(proyecto);
    });
  }
  private getResponsablesGrafos() {
    this.solpeService.getResponsablesGrafos().subscribe(resp => {
      this.responsables = JSON.parse(resp);
    });
  }
  onGuardarArticulo() {
    this.submittedArticulo = true;
    if (this.formArticulo.invalid) {
      return;
    }

    const postData: PostArticulo = {
      iv_codigo: this.formArticulo.value.codigo,
      iv_nombre: this.formArticulo.value.nombre,
      iv_activo: this.formArticulo.value.activo,
    };
    if (this.formArticulo.value.correlativo) {
      postData.in_correlativo = this.formArticulo.value.correlativo;
    }

    this.solpeService.postArticulo(postData).subscribe(resp => {
      this.notifyService.showSuccess('Articulo guardado con exito.', 'Guardar Articulo');
    }, err => {
      this.notifyService.showError('Error al guardar Articulo', 'Guardar Articulo');
      console.log(err);
    });
    this.getArticulos();
    this.resetFormArticulo();
    this.closeModalGuardarArticulo.nativeElement.click();
  }
  onGuardarZona() {
    this.submittedZona = true;
    if (this.formZona.invalid) {
      return;
    }

    const postData: PostZona = {
      iv_codigo: this.formZona.value.codigo,
      iv_nombre: this.formZona.value.nombre,
      iv_activo: this.formZona.value.activo,
    };
    if (this.formZona.value.correlativo) {
      postData.in_correlativo = this.formZona.value.correlativo;
    }

    this.solpeService.postZona(postData).subscribe(resp => {
      this.notifyService.showSuccess('Zona guardada con exito.', 'Guardar Zona');
    }, err => {
      this.notifyService.showError('Error al guardar Zona', 'Guardar Zona');
      console.log(err);
    });
    this.getZonas();
    this.resetFormZona();
    this.closeModalGuardarZona.nativeElement.click();
  }
  onGuardarBlanco() {
    this.submittedBlanco = true;
    if (this.formBlanco.invalid) {
      return;
    }

    const postData: PostBlanco = {
      iv_codigo: this.formBlanco.value.codigo,
      iv_nombre: this.formBlanco.value.nombre,
      iv_activo: this.formBlanco.value.activo,
    };
    if (this.formBlanco.value.correlativo) {
      postData.in_correlativo = this.formBlanco.value.correlativo;
    }

    this.solpeService.postBlanco(postData).subscribe(resp => {
      this.notifyService.showSuccess('Blanco guardado con exito.', 'Guardar Blanco');
    }, err => {
      this.notifyService.showError('Error al guardar Blanco', 'Guardar Blanco');
      console.log(err);
    });
    this.getBlancos();
    this.resetFormBlanco();
    this.closeModalGuardarBlanco.nativeElement.click();
  }
  onGuardarGrafo() {
    this.submittedGrafo = true;
    if (this.formGrafo.invalid) {
      return;
    }

    const postData: PostGrafo = {
      iv_codigo: this.formGrafo.value.codigo,
      iv_proyecto: this.formGrafo.value.proyecto,
      iv_desc1: this.formGrafo.value.desc1,
      iv_desc2: this.formGrafo.value.desc2,
      iv_responsable: this.formGrafo.value.responsable,
      iv_activo: this.formGrafo.value.activo,
    };
    if (this.formGrafo.value.correlativo) {
      postData.in_correlativo = this.formGrafo.value.correlativo;
    }

    this.solpeService.postGrafo(postData).subscribe(resp => {
      this.notifyService.showSuccess('Grafo guardado con exito.', 'Guardar Grafo');
    }, err => {
      this.notifyService.showError('Error al guardar Grafo', 'Guardar Grafo');
      console.log(err);
    });
    this.getGrafos();
    this.resetFormGrafo();
    this.closeModalGuardarGrafo.nativeElement.click();
  }

  onGuardarProyecto() {
    this.submittedProyecto = true;
    if (this.formProyecto.invalid) {
      return;
    }

    const postData: PostProyecto = {
      iv_codigo: this.formProyecto.value.codigo,
      iv_nombre: this.formProyecto.value.nombre,
      iv_activo: this.formProyecto.value.activo,
    };
    if (this.formProyecto.value.correlativo) {
      postData.in_correlativo = this.formProyecto.value.correlativo;
    }

    this.solpeService.postProyecto(postData).subscribe((resp: any) => {
      this.notifyService.showSuccess('Proyecto guardado con exito.', 'Guardar Proyecto');
      const proyecto = resp[0]?.PRO_CORRELATIVO;
      this.getProyectos(proyecto);
      this.resetFormProyecto();
      this.closeModalGuardarProyecto.nativeElement.click();
    }, err => {
      this.notifyService.showError('Error al guardar Proyecto', 'Guardar Proyecto');
      console.log(err);
    });
  }

  openModalArticulo(index?: number): void {
    if (index) {
      const articulo = this.articulos[index];
      this.formArticulo = new FormGroup({
        correlativo: new FormControl(articulo.id, null),
        codigo: new FormControl(articulo.CODIGO, Validators.required),
        nombre: new FormControl(articulo.NOMBRE, Validators.required),
        activo: new FormControl(articulo.ACTIVO, Validators.required),
      });
    }
    this.openModalGuardarArticulo.nativeElement.click();
  }

  openModalZona(index?: number): void {
    if (index) {
      const zona = this.zonas[index];
      this.formZona = new FormGroup({
        correlativo: new FormControl(zona.id, null),
        codigo: new FormControl(zona.CODIGO, Validators.required),
        nombre: new FormControl(zona.NOMBRE, Validators.required),
        activo: new FormControl(zona.ACTIVO, Validators.required),
      });
    }
    this.openModalGuardarZona.nativeElement.click();
  }

  openModalBlanco(index?: number): void {
    if (index) {
      const blanco = this.blancos[index];
      this.formBlanco = new FormGroup({
        correlativo: new FormControl(blanco.id, null),
        codigo: new FormControl(blanco.CODIGO, Validators.required),
        nombre: new FormControl(blanco.NOMBRE, Validators.required),
        activo: new FormControl(blanco.ACTIVO, Validators.required),
      });
    }
    this.openModalGuardarBlanco.nativeElement.click();
  }

  openModalGrafo(index?: number): void {
    if (index) {
      const grafo = this.grafos[index];
      this.formGrafo = new FormGroup({
        correlativo: new FormControl(grafo.id, null),
        codigo: new FormControl(grafo.CODIGO, Validators.required),
        proyecto: new FormControl(grafo.PROY_CORRELATIVO, Validators.required),
        desc1: new FormControl(grafo.DESC1, Validators.required),
        desc2: new FormControl(grafo.DESC2, Validators.required),
        responsable: new FormControl(grafo.USUA_CORRELATIVO, Validators.required),
        activo: new FormControl(grafo.ACTIVO, Validators.required),
      });
    }
    this.openModalGuardarGrafo.nativeElement.click();
  }

  openModalProyecto(index?: number): void {
    if (index) {
      const proyecto = this.proyectos[index];
      this.formProyecto = new FormGroup({
        correlativo: new FormControl(proyecto.id, null),
        codigo: new FormControl(proyecto.CODIGO, Validators.required),
        nombre: new FormControl(proyecto.NOMBRE, Validators.required),
        activo: new FormControl(proyecto.ACTIVO, Validators.required),
      });
    }
    this.openModalGuardarProyecto.nativeElement.click();
  }

  setActionsArticulo(_?: Event): void {
    setTimeout(() => {
      const component = this?.elementRef?.nativeElement;
      if (!component) {
        return;
      }
      const btnEdit = component.querySelectorAll('.btn-edit-articulo');
      btnEdit.forEach(item => {
        this.renderer.listen(item, 'click', () => {
          const index = item.attributes.href.value;
          this.openModalArticulo(index);
        });
      });
    }, 1000);
  }

  setActionsZona(_?: Event): void {
    setTimeout(() => {
      const component = this?.elementRef?.nativeElement;
      if (!component) {
        return;
      }
      const btnEdit = component.querySelectorAll('.btn-edit-zona');
      btnEdit.forEach(item => {
        this.renderer.listen(item, 'click', () => {
          const index = item.attributes.href.value;
          this.openModalZona(index);
        });
      });
    }, 1000);
  }

  setActionsBlanco(_?: Event): void {
    setTimeout(() => {
      const component = this?.elementRef?.nativeElement;
      if (!component) {
        return;
      }
      const btnEdit = component.querySelectorAll('.btn-edit-blanco');
      btnEdit.forEach(item => {
        this.renderer.listen(item, 'click', () => {
          const index = item.attributes.href.value;
          this.openModalBlanco(index);
        });
      });
    }, 1000);
  }

  setActionsGrafo(_?: Event): void {
    setTimeout(() => {
      const component = this?.elementRef?.nativeElement;
      if (!component) {
        return;
      }
      const btnEdit = component.querySelectorAll('.btn-edit-grafo');
      btnEdit.forEach(item => {
        this.renderer.listen(item, 'click', () => {
          const index = item.attributes.href.value;
          this.openModalGrafo(index);
        });
      });
    }, 1000);
  }

  setActions(name: string): void {
    setTimeout(() => {
      const component = this?.elementRef?.nativeElement;
      if (!component) {
        return;
      }
      const btnEdit = component.querySelectorAll(`.btn-edit-${name}`);
      btnEdit.forEach(item => {
        this.renderer.listen(item, 'click', () => {
          const index = item.attributes.href.value;
          this[`openModal${_.capitalize(name)}`](index);
        });
      });
    }, 1000);
  }

  changeTab(id: string) {
    this.selectedTab = id;

    const name = id.charAt(0).toUpperCase() + id.slice(1);
    this.excelButton = this[`excelButton${name}`];
    this.actionButton = this[`actionButton${name}`];
  }

  getStatusProps(status: string) {
    return status === 'S' ? {
      color: 'green',
      text: 'Activo'
    } : {
      color: 'red',
      text: 'Inactivo'
    }
  }

  parseMaintainerData(resp: any, name: string) {
    const data = JSON.parse(resp);
    const tableData = [];
    const excelButtonData = [];
    data.forEach((row, i) => {
      const statusProps = this.getStatusProps(row.ACTIVO);
      const newRow = {
        ...row,
        status: `<div class="tab tab-${statusProps.color} ng-star-inserted">${statusProps.text}</div>`,
        actions: `
        <div class="text-center">
          <span class="btn rounded-icon btn-edit-${name}" title="Editar" href="${i}">
            <i class="fas fa-pen"></i>
          </span>
        </div>
          `
      };
      tableData.push(newRow);
      const newRowExcel = _.cloneDeep(newRow);
      newRowExcel.status = statusProps.text;
      excelButtonData.push(newRowExcel);
    });

    this[name+'s'] = tableData;
    this[`excelButton${_.capitalize(name)}s`].data = excelButtonData;
    this.setActions(name);
  }

  get fa() { return this.formArticulo.controls; }
  get fz() { return this.formZona.controls; }
  get fb() { return this.formBlanco.controls; }
  get fg() { return this.formGrafo.controls; }
  get fp() { return this.formProyecto.controls; }
}
