<div class="px-2">
  <div class="row">
    <div class="col-12 col-md-9">
      <ng-content *ngIf="!excelButton && !actionButton"></ng-content>
      <app-view-excel-buttons [ngClass]="{ 'mr-2': actionButton }" *ngIf="excelButton" [props]="excelButton">
      </app-view-excel-buttons>
      <app-action-button *ngIf="actionButton" [props]="actionButton"></app-action-button>
    </div>
    <div class="col-12 col-md-3 text-right">
      <input class="form-control" style="margin-bottom: 10px;" matInput (keyup)="applyFilter($event)"
        placeholder="Buscar ..." #input>
      <i matSuffix class="fas fa-search" style="position: absolute;top: 0.7rem;right: 1.5rem;"></i>
    </div>
  </div>
  <div class="row" style="padding-top: 1em">
    <div class="col-12">
      <table mat-table [dataSource]="data" matSort (matSortChange)="sortData($event)"
        class="mat-table cdk-table mat-sort table border table-striped table-td-valign-middle table-th-valign-middle">
        <ng-container *ngFor="let item of header" [matColumnDef]="item.value">
          <th class="normalText" style="padding: 10px !important;" [style.width]="item.width ? item.width : undefined"
            mat-header-cell *matHeaderCellDef [mat-sort-header]="item.value">{{item.title}}</th>
          <td style="padding: 10px !important;" mat-cell *matCellDef="let element">
            <div *ngIf="!item.custom">{{ element[item.value] }}</div>
            <div *ngIf="item.custom" [innerHTML]="element[item.value]"></div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headerRow"></tr>
        <tr mat-row *matRowDef="let row; columns: headerRow;" id="row" style="cursor: pointer;"
          (click)="selectionTable.toggle(row)"
          [ngClass]="{hovered: row.hovered, highlighted: selectionTable.isSelected(row)}"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
      </table>
      <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
        (page)="page($event)"></mat-paginator>
    </div>
  </div>
</div>