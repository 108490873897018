import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ContratoService } from '../shared/services/contrato.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationService } from '../shared/services/notification.service';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  emailForm : FormGroup;
  personaEmailForm : FormGroup;
  emails:any;
  sistemas:[];
  visible : boolean = false;
  visiblePreview : boolean = false;

  visibleMantenedor : boolean = false;
  //dcEmails: string[] = ['Correlativo', 'Descripcion', 'Asunto','Sistema','Opciones'];
  dcEmails: string[] = ['Correlativo','Sistema','Descripcion','Opciones'];
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  @ViewChild('descp', {static: false}) descField :  ElementRef;
  @ViewChild('id', {static: false}) idField :  ElementRef;
  @ViewChild('newEmail', {static: false}) newEmail :  ElementRef;
  @ViewChild('nombre', {static: false}) nombre :  ElementRef;
  @ViewChild('apaterno', {static: false}) apaterno :  ElementRef;
  @ViewChild('amaterno', {static: false}) amaterno :  ElementRef;
  modalRef: BsModalRef;
  objEmail:any;
  idEmail: number;
  destinatarios:any[]=[];
  destinatariosUser:any[]=[];
  destinatariosGrupo:any[]=[];


  dcDestinatarios: string[] = ['select','Codigo', 'Tipo', 'Destinatario'];
  dsDestinatarios: any;
  selection = new SelectionModel<any>(true, []);

  dcDesUsuarios: string[] = ['select','Codigo', 'Tipo', 'Destinatario'];
  dsDesUsuarios: any;

  dcDesGrupos: string[] = ['select','Codigo', 'Tipo' , 'Destinatario'];
  dsDesGrupos: any;
  showModuloDest: boolean  = false;

  @ViewChild('paginatorGrupo', {read: MatPaginator}) paginatorGrupo: MatPaginator;
  @ViewChild('paginatorUser', {read: MatPaginator}) paginatorUser: MatPaginator;
  @ViewChild('paginatorEnd', {read: MatPaginator}) paginatorEnd: MatPaginator;


  selectionLstCorreos = new SelectionModel<any>(false, []);


  sortedData: any;
  emailInit: any;

  constructor(private contratoService: ContratoService,
    private modalService: BsModalService,
    private notifyService : NotificationService) { }

  ngOnInit(): void {
    this.resetForm();
    this.resetPersonaEmailForm();
    this.getEmails();
    this.getSistemas();
  }

  onActivarModuloDest(id: number){
    this.idEmail = id;
    this.showModuloDest = true;
    this.visibleMantenedor =  false;
    this.getEmailsUsers();
    this.getGrupos();
    this.notifyService.showInfo('Correo seleccionado con exito.', 'Agregar destinatarios');
  }

  addSelectedRowsUser() {
    this.selection.selected.forEach(item => {
      let index: number = this.destinatariosUser.findIndex(d => d === item);
      this.destinatarios.push(this.destinatariosUser[index]);
      this.dsDestinatarios = new MatTableDataSource<Element>( this.destinatarios);
    });
    this.selection = new SelectionModel<Element>(true, []);
  }
  addSelectedRowsGrupo() {
    this.selection.selected.forEach(item => {
      let index: number = this.destinatariosGrupo.findIndex(d => d === item);
      this.destinatarios.push(this.destinatariosGrupo[index]);
      this.dsDestinatarios = new MatTableDataSource<Element>( this.destinatarios);
    });
    this.selection = new SelectionModel<Element>(true, []);
  }

  private getGrupos(){
    this.contratoService.getDestinatarios().subscribe(resp =>{
      if (JSON.parse(resp)){
        JSON.parse(resp).forEach(element => {
          this.destinatariosGrupo.push(element);
        });
          this.dsDesGrupos = new MatTableDataSource(this.destinatariosGrupo);
          if (this.dsDesGrupos){
            this.dsDesGrupos.data = this.destinatariosGrupo; // this step will refresh the table
          }{
            this.dsDesGrupos = new MatTableDataSource(this.destinatariosGrupo);
          }

          this.createPaginator(this.paginatorGrupo, this.dsDesGrupos);
      }
    });
  }

  private getEmailsUsers(){
    this.contratoService.getDestinatariosUser().subscribe(resp=>{
      if (JSON.parse(resp)){
        JSON.parse(resp).forEach(element => {
          this.destinatariosUser.push(element);
        });
          this.dsDesUsuarios = new MatTableDataSource(this.destinatariosUser);
          if (this.dsDesUsuarios){
            this.createPaginator(this.paginatorUser, this.dsDesUsuarios);
          }
      }
    });
  }
  private getEmails(){
    this.contratoService.getEmails().subscribe(resp=>{
      this.emails=new MatTableDataSource(JSON.parse(resp));
      this.createPaginator(this.paginator, this.emails);
      this.emailInit = JSON.parse(resp).slice();
    });
  }

  private createPaginator(pag : any, table: any ){
    pag._intl.itemsPerPageLabel = 'Registros por página';
    pag._intl.firstPageLabel = 'Primera pagina';
    pag._intl.lastPageLabel = 'Última pagina';
    pag._intl.nextPageLabel = 'Siguiente';
    pag._intl.previousPageLabel = 'Anterior';
    table.paginator = pag;
  }

  sortData(sort: Sort) {
    const data = this.emailInit.slice();
    if (!sort.active || sort.direction === '') {
      this.emails =  new MatTableDataSource(data);
      this.createPaginator(this.paginator, this.emails);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Correlativo': return compare(a.CORRELATIVO, b.CORRELATIVO, isAsc);
        case 'Sistema': return compare(a.SIST_NOMBRE, b.SIST_NOMBRE, isAsc);
        case 'Descripcion': return compare(a.DESCRIPCION, b.DESCRIPCION, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.emails =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginator, this.emails);
    }
  }

  private getSistemas(){
    this.contratoService.getSistemas().subscribe(resp=>{
      this.sistemas = JSON.parse(resp);
    });
  }


  onAddSingleEmail(){
    this.visibleMantenedor = true;
    const postData : { iv_nombre :  string, iv_apaterno :  string, iv_amaterno :  string, iv_email :  string, in_usuario: string }  =
                     {  iv_nombre: this.personaEmailForm.value.nombre,
                        iv_apaterno :this.personaEmailForm.value.apaterno ,
                        iv_amaterno: this.personaEmailForm.value.amaterno,
                        iv_email: this.personaEmailForm.value.newEmail , in_usuario: ''};

    this.contratoService.postEmailPersona(postData).subscribe(resp =>{
        if(JSON.parse(resp)){
          const postNewDes:{CORRELATIVO: number, DESTINATARIO:string} ={ CORRELATIVO:JSON.parse(resp)[0].MAPE_CORRELATIVO, DESTINATARIO:  this.personaEmailForm.value.newEmail };
          this.destinatarios.push(postNewDes);   // I expect the table to have one row added after this.
          if (this.dsDestinatarios){
            this.dsDestinatarios.data = this.destinatarios; // this step will refresh the table
          }{
            this.dsDestinatarios = new MatTableDataSource<Element>(this.destinatarios);
          }
          this.resetPersonaEmailForm();
          this.modalRef.hide();
          this.getEmailsUsers();
        }
      });
   }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dsDestinatarios.data.length;
    return numSelected === numRows;
  }
  removeSelectedRows() {
    this.selection.selected.forEach(item => {
      let index: number = this.destinatarios.findIndex(d => d === item);

      this.destinatarios.splice(index,1)
      this.dsDestinatarios = new MatTableDataSource<Element>(this.destinatarios);
    });
    this.selection = new SelectionModel<Element>(true, []);
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dsDestinatarios.data.forEach(row => this.selection.select(row));
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);

  }
  openModalDestinatario(templateDestinatario: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateDestinatario);
  }

  onAdd(){
    this.visibleMantenedor = true;
    this.visiblePreview = false;
    this.visible = true;
    this.resetForm();
    if (this.visibleMantenedor){
      this.descField.nativeElement.focus();
      this.idField.nativeElement.value='';

    }
  }
  onDestinatarios(){
    this.contratoService.getDestinatarios().subscribe(resp =>{
      if (JSON.parse(resp)){
        JSON.parse(resp).forEach(element => {
          this.destinatarios.push(element);
        });
        if (this.dsDestinatarios){
          this.dsDestinatarios.data = this.destinatarios; // this step will refresh the table
        }{
          this.dsDestinatarios = new MatTableDataSource(this.destinatarios);
        }
      }
    });
  }
  onAddDestinatario(){
    this.destinatarios.forEach(element => {
      let cargo = 0;
      let mape = 0;
      switch (element.TIPO){
        case "CARGO":
          cargo = element.CORRELATIVO ;
          mape  = 0;
          break;
        case "PERSONA":
          cargo = 0;
          mape  =  element.CORRELATIVO;
          break;
      }
      const postData : {
        in_mame_correlativo :  number,
        in_cargo_correlativo :  number,
        in_mape_correlativo :  number,
        in_usuario :  number
      } = {
        in_mame_correlativo :  this.idEmail,
        in_cargo_correlativo :  cargo,
        in_mape_correlativo :  mape,
        in_usuario :  0
      }
      this.contratoService.postRelacionaEmailPersona(postData).subscribe(resp =>{
        this.notifyService.showSuccess('Destinatarios agregados', 'Agregar destinatarios');
      }, err =>{
        this.notifyService.showError('Error al agregar destinatarios', 'Agregar destinatarios');
      });
    });
  }

  applyFilterEmail(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.emails.filter = filterValue.trim().toLowerCase();
  }

  private crearEmail(){
    const postData : {
      in_cod_mantencion: number,
      in_mail_correlativo: number,
      iv_descripcion_email: string,
      iv_cuerpo_email: string,
      iv_pie_email: string,
      iv_asunto: string,
      iv_vigente : string,
      iv_usuario_creacion: string,
      in_sist_correlativo : number,
      iv_uso?: string,
      iv_instancia?: string,
      destinatarios?: string,
      iv_datos_variables?: string
    } = {
      in_cod_mantencion: 1,
      in_mail_correlativo: 1,
      iv_descripcion_email: this.emailForm.value.descripcion,
      iv_cuerpo_email: this.emailForm.value.mensaje,
      iv_pie_email: this.emailForm.value.pie,
      iv_asunto: this.emailForm.value.asunto,
      iv_vigente : 'S',
      iv_usuario_creacion: JSON.parse(localStorage.getItem('userData')).userId,
      in_sist_correlativo : this.emailForm.value.sistema ,
      iv_uso : this.emailForm.value.uso ,
      iv_instancia : this.emailForm.value.instancia ,
      destinatarios : this.emailForm.value.destinatarios  ,
      iv_datos_variables : this.emailForm.value.datosVariables
    };
    this.contratoService.postEmail(postData).subscribe(resp=>{
      this.getEmails();
      this.resetForm();
      this.notifyService.showSuccess('Correo creado con exito.', 'Crear correos');
    });
  }
  onCrear(){
    if(this.emailForm.value.asunto && this.emailForm.value.mensaje && this.emailForm.value.pie && this.emailForm.value.descripcion){
      if (this.emailForm.value.correlativo){
        this.editarEmail();
      }else{
        this.crearEmail();
      }
    }else{
      this.notifyService.showError('Favor llene los campos obligatorios', 'Mantenedor de correos');
    }
  }

  private editarEmail(){
    const postData : {
      in_cod_mantencion: number,
      in_mail_correlativo: number,
      iv_descripcion_email: string,
      iv_cuerpo_email: string,
      iv_pie_email: string,
      iv_asunto: string,
      iv_vigente : string,
      iv_usuario_creacion: string,
      in_sist_correlativo : number,
      iv_uso?: string,
      iv_instancia?: string,
      destinatarios?: string
    } = {
      in_cod_mantencion: 2,
      in_mail_correlativo: this.emailForm.value.correlativo,
      iv_descripcion_email: this.emailForm.value.descripcion,
      iv_cuerpo_email: this.emailForm.value.mensaje,
      iv_pie_email: this.emailForm.value.pie,
      iv_asunto: this.emailForm.value.asunto,
      iv_vigente : 'S',
      iv_usuario_creacion: JSON.parse(localStorage.getItem('userData')).userId,
      in_sist_correlativo : this.emailForm.value.sistema ,
      iv_uso : this.emailForm.value.uso ,
      iv_instancia : this.emailForm.value.instancia ,
      destinatarios : this.emailForm.value.destinatarios
    };
    this.contratoService.postEmail(postData).subscribe(resp=>{
      this.getEmails();
      this.resetForm();
      this.notifyService.showSuccess('Correo editado con exito.', 'Crear correos');
    });
  }

  private resetForm(){
    this.emailForm = new FormGroup({
      'correlativo' : new FormControl(null),
      'asunto' : new FormControl(null),
      'mensaje': new FormControl(null),
      'pie' : new FormControl('ESTE MENSAJE HA SIDO ENVIADO EN FORMA AUTOMATICA NO RESPONDA DIRECTAMENTE AL REMITENTE Este mensaje de correo electronico y sus documentos adjuntos estan dirigidos EXCLUSIVAMENTE a los destinatarios especificados. La informacion contenida puede ser CONFIDENCIAL y/o estar LEGALMENTE PROTEGIDA y no necesariamente refleja la opinion de EMSA. Si usted recibe este mensaje por ERROR, por favor comuniqueselo inmediatamente al remitente y ELIMINELO ya que usted NO ESTA AUTORIZADO al uso, revelacion, distribucion, impresion o copia de toda o alguna parte de la informacion contenida. Gracias.  This e-mail message and any attached files are intended SOLELY for the addressee/s identified herein. It may contain CONFIDENTIAL and/or LEGALLY PRIVILEGED information and may not necessarily represent the opinion of EMSA. If you receive this message in ERROR, please immediately notify the sender and DELETE it since you ARE NOT AUTHORIZED to use, disclose, distribute, print or copy all or part of the contained information. Thank you.'),
      'descripcion' : new FormControl(null),
      'sistema' : new FormControl(null) ,
      'uso' : new FormControl(null) ,
      'instancia' : new FormControl(null)  ,
      'datosVariables' : new FormControl(null)  ,
      'destinatarios' : new FormControl({value: null, disabled: true})
    });
  }

  private resetPersonaEmailForm(){
    this.personaEmailForm = new FormGroup({
      'newEmail' : new FormControl(null),
      'nombre' : new FormControl(null),
      'apaterno': new FormControl(null),
      'amaterno' : new FormControl(null)
    });
  }
  onEliminar(id: number){
    const postData : {
      in_cod_mantencion: number,
      in_mail_correlativo: number
    } = {
      in_cod_mantencion: 3,
      in_mail_correlativo: id
    };
    this.contratoService.postEmail(postData).subscribe(resp=>{
      this.getEmails();
      this.resetForm();
      this.visiblePreview = false;
      this.visible = false;
      this.visibleMantenedor = false;
      this.notifyService.showWarning('Correo eliminado con exito.', 'Eliminar correo');
    });
  }

  onEditar(id: number){
    this.visibleMantenedor  = true;
    this.contratoService.getEmailId(id).subscribe(resp=>{
      this.visible = true;
      this.visiblePreview = true;
      this.objEmail = JSON.parse(resp)[0];
      this.idField.nativeElement.value = JSON.parse(resp)[0].CORRELATIVO;
      this.emailForm = new FormGroup({
        'correlativo' : new FormControl(JSON.parse(resp)[0].CORRELATIVO),
        'asunto' : new FormControl(JSON.parse(resp)[0].ASUNTO),
        'mensaje': new FormControl(JSON.parse(resp)[0].CUERPO_EMAIL),
        'pie' : new FormControl(JSON.parse(resp)[0].PIE_EMAIL),
        'descripcion' : new FormControl(JSON.parse(resp)[0].DESCRIPCION)     ,
        'sistema' : new FormControl(JSON.parse(resp)[0].SIST_CORRELATIVO) ,
        'uso' : new FormControl(JSON.parse(resp)[0].USO) ,
        'instancia' : new FormControl(JSON.parse(resp)[0].INSTANCIA)  ,
        'datosVariables' : new FormControl(JSON.parse(resp)[0].DATOS_VARIABLES)  ,
        'destinatarios' : new FormControl({ value: JSON.parse(resp)[0].DESTINATARIOS, disabled: true})
      });

      this.notifyService.showInfo('Correo seleccionado con exito.', 'Editar correo');
    });
  }

  onEnviarMail(id: number){
    const postData : {
      iv_nombre_proceso: string,
      in_correlativo: number,
      in_usuario: string
    } = { iv_nombre_proceso: 'SISTEMA_CONTRATO', in_correlativo: id , in_usuario: ''}
    this.contratoService.postEnviarEmail(postData).subscribe(resp =>{

      if (JSON.parse(resp).COD_RETORNO){
        switch (JSON.parse(resp).COD_RETORNO){
          case 0:
              console.log('Error db');
            break;
          case 1:
            this.modalRef.hide();
            break;
        }
      }
    });
  }

  applyFilterResponsabilidad(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dsDesGrupos.filter = filterValue.trim().toLowerCase();
  }

  applyFilterPersonas(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dsDesUsuarios.filter = filterValue.trim().toLowerCase();
  }

  applyFilterDestinatarios(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dsDestinatarios.filter = filterValue.trim().toLowerCase();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

