import { Component, Input, OnInit } from '@angular/core';

export interface PrintButtonProps {
  printComponentId?: string;
  cssFile: string | string[];
  title: string;
  variant: string;
  breakInside?: string;
  orientation?: string;
}

@Component({
  selector: 'app-print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.css']
})

export class PrintButtonComponent implements OnInit {

  variant: string;
  @Input() props: PrintButtonProps

  ngOnInit(): void {
    this.variant = this.props.variant;
  }

  createCssImports() {
    const props = this.props;
    if (typeof props.cssFile === 'string') return this.createCssImport(props.cssFile);
    return props.cssFile.map((cssFile) => this.createCssImport(cssFile)).join('');
  }

  createCssImport(cssFile: string) {
    return `<link href="${cssFile}" rel="stylesheet" />`;
  }

  landScapeCss() {
    return `
      @page {
        size: 44.1cm 49.4cm;
        margin: 1cm 1cm 1cm 1cm;
      }
    `
  }

  onImprimir() {
    const props = this.props;
    const printComponentId = props.printComponentId || 'printComponent';
    const breakInside = props.breakInside || 'avoid';
    const orientation = props.orientation || 'portrait';
    const printComponent = document.getElementById(printComponentId).cloneNode(true) as HTMLElement;
    const inputs = printComponent.querySelectorAll('input,select,textarea') as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    for (let i = 0; i < inputs.length; i++) {
      if (['INPUT', 'SELECT'].includes(inputs[i].nodeName)) inputs[i].setAttribute('value', inputs[i].value);
      if (inputs[i].nodeName === 'TEXTAREA') inputs[i].innerHTML = inputs[i].value;
    }
    const WindowPrt = window.open('');
    WindowPrt.document.open();
    WindowPrt.document.write(`
      <html>
        <head>
          <title>${props.title}</title>
          <link href="/assets/css/default/app.min.css" rel="stylesheet" />
          <link href="/assets/css/styles.css" rel="stylesheet" />
          ${this.createCssImports()}
          <style>
            .content {
              margin-left: 0 !important;
              padding: 0 !important;
            }
            .table-main {
              padding: 0 !important;
            }
            body {
              background-color: white !important;
            }
            .panel-body {
              padding: 0 !important;
            }
            div {
              break-inside: ${breakInside};
            }
            ${orientation === 'landscape' && this.landScapeCss()}
          </style>
        </head>
        <body>${printComponent.outerHTML}</body>
      </html>
    `);
    WindowPrt.document.close();
    WindowPrt.focus();
    setTimeout(function () {
      WindowPrt.print();
      setTimeout(function () {
        WindowPrt.close();
      }, 10);
    }, 50);
  }
}
