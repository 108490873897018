import { Component, ViewChild, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Sort, MatSort, MatSortable } from '@angular/material/sort';
import { compare } from 'src/app/shared/functions/common';
import { ViewExcelButtonsProps } from './../../view-excel-button/view-excel-buttons.component';
import { ActionButtonProps } from './../action-button/action-button.component';

export interface TableHeaderCell {
  title: string;
  value: string;
  custom?: any;
  width?: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() header: TableHeaderCell[];
  @Input() dataInit: any;
  @Input() excelButton?: ViewExcelButtonsProps;
  @Input() sortInit?: MatSortable;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() actionButton?: ActionButtonProps;
  @Input() pageSize = 20;
  @Output() reloadActions = new EventEmitter();
  headerRow: string[];
  data: any;
  sortedData: any;
  selectionTable = new SelectionModel<any>(false, []);

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.headerRow = this.header.map(h => h.value);
    if (this.sortInit) {
      this.sort.sort(this.sortInit);
    }
  }

  ngOnChanges(values: SimpleChanges): void {
    this.updateData(this.dataInit);
    if (values.dataInit.firstChange && this.sort) {
      this.sortData(this.sort);
    }
  }

  private createPaginator(pag: any, table: any) {
    if (pag?._intl) {
      pag._intl.itemsPerPageLabel = 'Registros por página';
      pag._intl.firstPageLabel = 'Primera pagina';
      pag._intl.lastPageLabel = 'Última pagina';
      pag._intl.nextPageLabel = 'Siguiente';
      pag._intl.previousPageLabel = 'Anterior';
    }
    table.paginator = pag;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data.filter = filterValue.trim().toLowerCase();
    this.reloadActions.emit();
  }

  sortData(sort: Sort) {
    if (!this.dataInit) return;
    const data = this.dataInit.slice();
    if (!sort.active || sort.direction === '') {
      this.updateData(data);
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return compare(a[sort.active], b[sort.active], isAsc);
    });
    if (this.sortedData) {
      this.updateData(this.sortedData);
    }
  }

  updateData(data: any) {
    this.data = new MatTableDataSource(data);
    this.createPaginator(this.paginator, this.data);
  }

  page(_?: Event) {
    this.reloadActions.emit();
  }
}

