export class User {
    public userId: number;
    public _token: string;
    public _tokenExpiration: Date;
    public expiresIn: number;
    public refreshToken: string;
    public userName: string;
    public rolId: number;
    public rolName: string;
    public cambioClave: boolean;
    public lastConection: string;
    public esEmsa: string;
    constructor(
        userData: User,
    ) {
        for (const [key, value] of Object.entries(userData)) {
            this[key] = value;
        }
    }

    getToken() {
        if (!this._tokenExpiration || new Date() > this._tokenExpiration) {
            return null;
        }

        return this._token;
    }
}