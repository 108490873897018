<div id="content" class="content">
  <div class="row">
    <div class="col-md-12 ui-sortable" [ngClass]="{'d-none': visible}">
      <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="bg-white p3-rem rounded">
          <div class="row mb-4">
            <div class="col-12 col-md-6">
              <h4 class="normalText">Lista de usuarios</h4>
            </div>
            <div class="col-md-6 text-right">
              <button class="btn btn-primary" (click)="onAdd()">
                Agregar
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8"></div>
            <div class="col-12 col-md-4">
              <input class="form-control" style="margin-bottom: 10px; padding-right: 2rem !important;" matInput
                (keyup)="applyFilterUsuario($event)" placeholder="Buscar ..." #input>
              <i matSuffix class="fas fa-search" style="position: absolute;top: 0.7rem;right: 1.5rem;"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <table mat-table [dataSource]="usuarios" matSort (matSortChange)="sortDataUsuarios($event)"
                class="table border table-striped table-td-valign-middle table-th-valign-middle">
                <ng-container matColumnDef="Correlativo">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef mat-sort-header="Correlativo">#</th>
                  <td mat-cell *matCellDef="let element"> {{element.CORRELATIVO}} </td>
                </ng-container>
                <ng-container matColumnDef="Usuario">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef mat-sort-header="Usuario">USUARIO</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element">

                    <div class="d-flex-inline align-center">
                      <div class="round-name">
                        {{element.NOMBRES.slice(0, 2)}}
                      </div>
                      <div class="nombre">
                        <p class="mb-0">{{element.NOMBRES}} {{element.PATERNO}} {{element.MATERNO}}</p>
                        <span class="text-muted">{{element.CORREO}}</span>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Vigente">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef mat-sort-header="Vigente">VIGENTE</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element"> {{element.VIGENTE ==='S' ?
                    'SI': ' NO'}} </td>
                </ng-container>
                <ng-container matColumnDef="emsa">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef mat-sort-header="esEmsa">EMSA</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element"> {{element.esEmsa ==='S' ?
                    'SI': ' NO'}} </td>
                </ng-container>
                <ng-container matColumnDef="Opciones">
                  <th class=" normalText" style=" width: 20%; text-align: center;" mat-header-cell *matHeaderCellDef>
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align: right; padding-left: 20px;">
                    <!-- <a style="cursor: pointer;" title="Asignar funcionalidad">
                      <i class="fas fa-lg fa-fw m-r-10 fa-address-card" (click)="onAddRol(element.CORRELATIVO, element.USERNAME)" style="color: #2C6D80;"></i>
                    </a>
                    <a style="cursor: pointer;" (click)="onEdit(element.CORRELATIVO)" title="Ver">
                      <i class="fas fa-lg fa-fw m-r-10 fa-eye" style="color: #F59C1A;"></i>
                    </a>
                    <a style="cursor: pointer;" (click)="onDel(element.CORRELATIVO)" title="Eliminar">
                      <i class="fas fa-lg fa-fw m-r-10 fa-trash-alt" style="color: #FF5B57;"></i>
                    </a> -->
                    <!-- <div class="btn rounded-icon mr-2" (click)="onAddRol(element.CORRELATIVO, element.USERNAME)" title="Asignar funcionalidad">
                      <i class="fas fa-address-card"></i>
                    </div> -->
                    <div class="btn rounded-icon mr-2"
                      (click)="onEdit(element.CORRELATIVO); onAddRol(element.CORRELATIVO, element.USERNAME)"
                      title="Ver">
                      <i class="fas fa-eye"></i>
                    </div>
                    <div class="btn rounded-icon" (click)="onDelUser(element.CORRELATIVO)" title="Inhabilitar" data-toggle="modal" data-target="#InhabilitarUsuarioModal">
                      <i class="fas fa-trash-alt"></i>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dcUsuarios"></tr>
                <tr mat-row *matRowDef="let row; columns: dcUsuarios;" id="row" style="cursor: pointer;"
                  (click)="selectionUsuarios.toggle(row)"
                  [ngClass]="{hovered: row.hovered, highlighted: selectionUsuarios.isSelected(row)}"
                  (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
              </table>
              <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 30]"
                showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-7 ui-sortable" *ngIf="visible">
      <a (click)="visible = false; listMenus = false" class="btn btn-primary text-white"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-arrow-alt-circle-left"></i> Volver</a>
      <br>
      <br>
      <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="bg-white p3-rem rounder">
          <div class="row mb-4">
            <div class="col-12">
              <h4>Datos de usuario</h4>
            </div>
          </div>
          <div class="row" style="margin-bottom: 10px;">
            <div class="col-12 col-md-12">
              <form [formGroup]="usuarioForm" (ngSubmit)="onCrear()" autocomplete="off">
                <input type="hidden" class="form-control m-b-5" formControlName="correlativo">
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">#</label>
                  <div class="col-md-8 col-12">
                    <input #id type="text" class="form-control m-b-5" disabled>
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Usuario Emsa</label>
                  <div class="col-md-8 col-12">
                    <input type="checkbox" formControlName="isEmsa" style="vertical-align: -webkit-baseline-middle;">
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Correo <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input #userName type="email" [class.is-invalid]="isInvalid('userName')" class="form-control m-b-5"
                      placeholder="usuario@em.codelco.com" formControlName="userName">
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Nombres <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input #nombres type="text" [class.is-invalid]="isInvalid('nombres')" class="form-control m-b-5"
                      placeholder="Nombre de usuario" formControlName="nombres">
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Apellido paterno <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input #paterno type="text" [class.is-invalid]="isInvalid('paterno')" class="form-control m-b-5"
                      placeholder="Apellido paterno" formControlName="paterno">
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Apellido materno <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input #materno type="text" [class.is-invalid]="isInvalid('materno')" class="form-control m-b-5"
                      placeholder="Apellido materno" formControlName="materno">
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Contraseña <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input type="password" [class.is-invalid]="isInvalid('pass')" class="form-control m-b-5"
                      placeholder="Contraseña" formControlName="pass" autocomplete="new-password">
                      <span class="text-danger small" *ngIf="isInvalid('pass')">{{usuarioForm.controls['pass'].errors.message}}</span>
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Rut <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input #rut type="text" [class.is-invalid]="isInvalid('rut')" class="form-control m-b-5"
                      placeholder="Rut" formControlName="rut">
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Telefono <span style="color: red">*</span> </label>
                  <div class="col-md-8 col-12">
                    <input #telefono type="text" class="form-control m-b-5" maxlength="11" placeholder="Telefono"
                      formControlName="telefono">
                      <span *ngIf="usuarioForm.controls['telefono'].value?.length < 11"
                      class="text-danger small"
                      >
                        El numero debe tener por lo menos 11 digitos
                      </span>
                  </div>
                </div>
                <div *ngIf="usuarioForm.controls['isEmsa'].value" class="row form-group">
                  <label style="font-size: 14px;" for="jefe" class="col-md-4 col-form-label col-12">Jefe directo <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <ng-select2 formControlName="jefe" [data]="usuariosJefes" #contrato
                      [class.is-invalid]="isInvalid('jefe')" class="select2-selection select2-selection--single"
                      width="100%">
                    </ng-select2>
                  </div>
                </div>
                <div *ngIf="usuarioForm.controls['isEmsa'].value" class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Direccion <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input #direccion type="text" [class.is-invalid]="isInvalid('direccion')" class="form-control m-b-5"
                      placeholder="Direccion" formControlName="direccion">
                  </div>
                </div>
                <div *ngIf="usuarioForm.controls['isEmsa'].value" class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Fecha nacimiento <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input #fechaNacimiento type="date" [class.is-invalid]="isInvalid('fechaNacimiento')"
                      class="form-control m-b-5" placeholder="Fecha Nacimiento" formControlName="fechaNacimiento">
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Vigente <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input type="checkbox" formControlName="vigente" style="vertical-align: -webkit-baseline-middle;">
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-md-4 col-12">Fecha inicio vigencia <span style="color: red">*</span></label>
                  <div class="col-md-8 col-12">
                    <input type="date" [class.is-invalid]="isInvalid('fechaIni')" class="form-control m-b-5"
                      placeholder="Fecha inicio vigencia" formControlName="fechaIni">
                  </div>
                </div>
                <div class="form-group row m-b-15">
                  <label style="font-size: 14px;" class="col-form-label col-12 col-md-4">Fecha termino vigencia</label>
                  <div class="col-12 col-md-8">
                    <input type="date" class="form-control m-b-5" placeholder="Fecha termino vigencia"
                      formControlName="fechaFin">
                  </div>
                </div>
                <div class="row mt-5">
                  <div  class="col-12 col-md-4">
                    <span>Roles</span>
                  </div>
                  <div class="col-12 col-md-8">
                    <div *ngFor="let item of cargos; let last = last; let first = first"
                      [ngClass]="{'border-top': first}"
                      class="p-15 bg-light-blue d-flex border-left border-right border-bottom">
                      <!-- {{cargo.CORRELATIVO}} {{cargo.DESTINATARIO}} -->
                      <label style="font-size: 14px;" class="mb-0 ml-10" for="inlineCssCheckbox{{item.CORRELATIVO}}">
                        {{item.DESTINATARIO}}</label>
                      <input style="margin-left:auto" type="checkbox" #check id="inlineCssCheckbox{{item.CORRELATIVO}}"
                        [checked]="onCheck(item.CORRELATIVO)"
                        (click)="onCheckAccion(item.CORRELATIVO,check.checked )" />&nbsp;
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-12 text-right">
                    <button class="btn btn-primary mr-2" type="submit" [disabled]="isSubmitted">Guardar</button>
                    <button class="btn btn-light" (click)="visible = false; listMenus = false">
                      Cancelar
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 49px;" class="col-12 col-md-5" *ngIf="listMenus">
      <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
        <div class="p3-rem bg-white rounded">
          <div class="row mb-4">
            <div class="col-12">
              <h4>Modulos</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <label style="font-size: 14px;" for="menu">Seleccione modulo</label>
            </div>
            <div class="col-12 col-md-12">
              <div class="d-flex">
                <select class="form-control" #menu>
                  <option value="0">Seleccione ...</option>
                  <option *ngFor="let item of menus" value="{{ item.MENU_CORRELATIVO}}">
                    {{ item.SIST_DESCRIPCION}} - {{ item.MODULO_DESCRIPCION}} <!-- - {{ item.OPCION_DESCRIPCION}} -->
                  </option>
                </select>
                <button class="btn tag text-primary ml-2" (click)="onGuardarMenu(menu.value)">Agregar</button>
              </div>
            </div>
            <div class="col col-md-4">
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-md-6"></div>
            <div class="col-12 col-md-6">
              <input class="form-control" style="margin-bottom: 10px;" matInput (keyup)="applyFilterMenu($event)"
                placeholder="Buscar ..." #input>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-12">
              <table mat-table [dataSource]="listMenus" matSort (matSortChange)="sortDataMenuUsuario($event)"
                class="table table-striped border table-td-valign-middle table-th-valign-middle">
                <ng-container matColumnDef="Correlativo">
                  <th class=" normalText" mat-header-cell *matHeaderCellDef style="text-align: center;"
                    mat-sort-header="Correlativo">#</th>
                  <td mat-cell *matCellDef="let element" style="padding-right: 10px; text-align: center;">
                    {{element.MENU_USUA_CORRELATIVO}} </td>
                </ng-container>
                <ng-container matColumnDef="Menu">
                  <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef
                    mat-sort-header="Menu">MODULO</th>
                  <td style="padding: 10px !important;" mat-cell *matCellDef="let element"> {{element.SIST_NOMBRE}} -
                    {{element.MODU_NOMBRE}}<!-- -{{element.OPC_NOMBRE}}--> </td>
                </ng-container>
                <ng-container matColumnDef="Opciones">
                  <th class=" normalText" style="padding: 10px !important; width: 10%; text-align: center;"
                    mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element" class="text-right">
                    <div class="btn rounded-icon mr-2" (click)="onDelMenuUsua(element.MENU_USUA_CORRELATIVO)"
                      title="Ver">
                      <i class="fas fa-trash-alt"></i>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="dcMenus"></tr>
                <tr mat-row *matRowDef="let row; columns: dcMenus;" id="row" style="cursor: pointer;"
                  (click)="selectionMenu.toggle(row)"
                  [ngClass]="{hovered: row.hovered, highlighted: selectionMenu.isSelected(row)}"
                  (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No se encontro datos para :"{{input.value}}"</td>
                </tr>
              </table>
              <mat-paginator #paginatorMenu [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal inahabilitar usuario -->
<div class="modal fade" id="InhabilitarUsuarioModal" tabindex="-1" role="dialog" aria-labelledby="InhabilitarUsuarioModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="InhabilitarUsuarioModalLabel">Inhabilitar usuario</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>¿Desea inhabilitar el usuario seleccionado?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="onDel()">Inhabilitar</button>
      </div>
    </div>
  </div>
</div>