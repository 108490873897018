import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { ModalChangePasswordComponent } from './modal-change-password/modal-change-password.component';
import { ParametrosService } from '../shared/services/parametros.service';
import { AddonsService } from '../shared/services/addons.service';
import { NotificationService } from '../shared/services/notification.service';
const FileSaver = require('file-saver');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [ParametrosService, AddonsService]
})
export class HeaderComponent implements OnInit {
  isAuth = false;
  userData: any;
  cargos: any[] = []
  idCargoActual: number = 0
  nombreCargoActual: string = null
  modulos: any[] = []
  menuUsuarioInit: any[] = []
  userId
  @ViewChild('modalChangePassword') modalChangePassword: ModalChangePasswordComponent;
  lastConectionUser: string = '';
  esEmsa:boolean = false;

  constructor(private authService: AuthService, private router: Router, private parametrosService: ParametrosService, 
    private addonsService: AddonsService, private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.authService.user.subscribe(user => {
      this.userData = JSON.parse(JSON.stringify(user));
      this.isAuth = !!user;
      
      if(this.isAuth) {
        if(this.userData.cambioClave) this.modalChangePassword.openModal(true);
        
        this.authService.getCargosPorUsuario(this.userData.userId).subscribe(resp => {
          this.cargos = JSON.parse(resp)
          this.idCargoActual = JSON.parse(localStorage.getItem('userData')).rolId;
          this.nombreCargoActual = JSON.parse(localStorage.getItem('userData')).rolName;
          this.lastConectionUser = JSON.parse(localStorage.getItem('userData')).lastConection;
          this.esEmsa = JSON.parse(localStorage.getItem('userData')).esEmsa == 'S' ? true : false;
        }, error => {
          console.log(error)
        }, () => {
          this.getMenuUsuario(this.userData.userId);
           this.authService.getModulosPorCargo(this.idCargoActual).subscribe(resp => {
             this.modulos = JSON.parse(resp);
           });
        });
      }
    });
  }

  cambiarCargo(rolId: number, rolName: string) {
    this.idCargoActual = rolId
    this.nombreCargoActual = rolName

    let usuario = this.authService.getUserDataStorage()

    usuario.rolId = this.idCargoActual
    usuario.rolName = this.nombreCargoActual

    this.authService.saveUserDataStorage(usuario)

    this.authService.getModulosPorCargo(this.idCargoActual).subscribe(resp => {
      this.modulos = JSON.parse(resp);
    },
    error => console.log(error),
    () => {
      this.router.navigate(['/home']);
    });
  }

  existeModuloCargo(modulo: number) {
    for(let i of this.modulos) {
      if(i.CORRELATIVO == modulo) {
        return true;
      }
    }
    return false;
  }

  existeModulo(modulo: number) {
    for(let i of this.menuUsuarioInit) {
      if(i.MODU_CORRELATIVO == modulo) {
        return true;
      }
    }
    return false;
  }

  private getMenuUsuario(id: number) {
    this.parametrosService.getMenuUsuario(id).subscribe(resp => {
      //this.listMenus = new MatTableDataSource(JSON.parse(resp));
      //this.createPaginator(this.paginatorMenu, this.listMenus);
      this.menuUsuarioInit = JSON.parse(resp);
    });
  }

  generarReporteDistEp()
  {
    
    this.addonsService.generateXlsDistribucionEp().subscribe(resp => {
      FileSaver.saveAs(resp, "Provisión EP");
      this.notifyService.showSuccess('Descargando ...', 'Descargar archivos');
    }, err =>{
      this.notifyService.showError(err.error.message, 'Descargar archivos');
    });

    /* FileSaver.saveAs(resp, fileName);
    this.notifyService.showSuccess('Descargando ...', 'Descargar archivos'); */
  }

  onLogout(){
    this.authService.logout();
  }

}
