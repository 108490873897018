<!-- begin login-cover -->
<div class="login-cover">
    <div class="login-cover-image" style="background-image: url(../assets/img/login-bg/fondo.jpg)" data-id="login-cover-image"></div>
    <div class="login-cover-bg"></div>
</div>
<!-- end login-cover -->
<!-- begin login -->
<div class="login login-v2" data-pageload-addclass="animated fadeIn">
    <!-- begin brand -->
    <div class="login-header">
        <div class="brand" style="text-align: center;">
            <!--<span class="logo"></span>-->
            <b>Sistema Integrado </b>
            <br>de Contratos
            <small>(Correos y Usuarios)</small>
        </div>
        <!--<div class="icon">
            <i class="fa fa-lock"></i>
        </div>-->
    </div>
    <!-- end brand -->
    <!-- begin login-content -->
    <app-login *ngIf="section == 'login'"></app-login>
    <app-forgot-password *ngIf="section == 'forgot-password'"></app-forgot-password>
    <!-- end login-content -->
</div>
<!-- end login -->