<div id="content" class="content">
    <div class="row ">
        <!-- CREAR CONTRATO -->
        <div class="col-xl-12 ">
            <!-- begin panel -->
            <div class="panel panel-inverse ">
                <!-- begin panel-heading -->
                <div class="panel-heading">
                    <h4 class="panel-title ">Crear contratos</h4>
                    <div class="panel-heading-btn ">
                        <a href="javascript:; " class="btn btn-xs btn-icon btn-circle btn-warning " data-click="panel-collapse"><i class="fa fa-minus"></i></a>
                    </div>
                </div>
                <!-- end panel-heading -->
                <!-- begin panel-body -->
                <div class="panel-body ">
                    <form>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-2">Nombre contrato</label>
                            <div class="col-md-4">
                                <input type="text" class="form-control m-b-5" placeholder="Nombre contrato ...">
                            </div>
                            <label class="col-form-label col-md-2">Codigo contrato</label>
                            <div class="col-md-4">
                                <input type="text" class="form-control m-b-5" placeholder="Codigo contrato ...">
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">Example select</label>
                            <div class="col-md-9">
                                <select class="form-control">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">Example multiple select</label>
                            <div class="col-md-9">
                                <select class="form-control">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">Example textarea</label>
                            <div class="col-md-9">
                                <textarea class="form-control" rows="3"></textarea>
                            </div>
                        </div>
                    </form>
                    <button (click)="onCreateContrato()">click</button>
                </div>
                <!-- end panel-body -->
            </div>
            <!-- end panel -->
        </div>
        <!-- end CREAR CONTRATO  -->
    </div>
</div>