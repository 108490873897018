<div id="content" class="content">
    <div class="row">
        <div class="col-xl-12 ui-sortable">
            <div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">
                <div class="rounded p3-rem bg-white">
                    <div class="row mb-4">
                        <div class="col-12 col-md-6">
                            <h4 *ngIf="typeSolpe == 61" class="panel-title normalText">SOLPE - <span class="text-muted">Compras</span></h4>
                            <h4 *ngIf="typeSolpe == 62" class="panel-title normalText">SOLPE - <span class="text-muted">Servicios</span></h4>
                            <h4 *ngIf="typeSolpe == 63" class="panel-title normalText">SOLPE - <span class="text-muted">Servicios profesionales</span></h4>
                            <h4 *ngIf="typeSolpe == 60" class="panel-title normalText">SOLPE - <span class="text-muted">Arriendo</span></h4>
                            <h4 *ngIf="typeSolpe == 82" class="panel-title normalText">SOLPE - <span class="text-muted">Modificaciones de contrato</span></h4>
                        </div>
                        <div class="col-12 col-md-6 text-right">
                            <button *ngIf="existeAccion('17')" class="btn text-primary tag" (click)="exportAsXLSX()"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-file-excel"></i>
                                Exportar
                            </button>
                            <a *ngIf="existeAccion('2') && rolId == 23" class="btn btn-primary ml-2" href="#modal-agregar" data-toggle="modal">
                                Agregar
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!-- <div class="row">
                                
                                <div class="col-md-3">
                                    <label for="startDate" class="col-md-12 mb-0 pl-0">
                                        Fecha Inicio
                                    </label>
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha Inicio" [(ngModel)]="startDate"
                                        [ngClass]="{ 'is-invalid': errors.startDate }" (input)="getSolpesNew()">
                                    <div class="alert" [ngClass]="{ 'alert-danger': errors.startDate }">{{ errors.startDate }}</div>
                                </div>
    
                                <div class="col-md-3">
                                    <label for="endDate" class="col-md-12 mb-0 pl-0">
                                        Fecha Final
                                    </label>
                                    <input type="date" class="form-control m-b-5" placeholder="Fecha Final" [(ngModel)]="endDate"
                                        [ngClass]="{ 'is-invalid': errors.endDate }" (input)="getSolpesNew()">
                                    <div class="alert alert-danger" [ngClass]="{ 'alert-danger': errors.endDate }">{{ errors.endDate }}</div>
                                </div>
                            </div> -->
                        </div>
                        <br>
                        <div class="col-12 col-md-8">
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 1}" class="btn tag mb-1" style="margin-right: 5px;"
                            (click)="filterDataSource(1);  selectedFilter = 1">En Proceso
                            <span [ngClass]="{'bg-light-orange': selectedFilter === 1}" class="counter">
                                {{totales.PARA_GESTION}}
                            </span> 
                            </button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 2}" class="btn tag mb-1" type="button"
                            (click)="filterDataSource(2); selectedFilter = 2" style="margin-right: 5px;">Cerradas
                                <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 2}">
                                    {{totales.CERRADAS}}
                                </span>
                            </button>
                            <button [ngClass]="{'text-primary text-bolder': selectedFilter === 4}" class="btn tag mb-1" type="button"
                            (click)="filterDataSource(4); selectedFilter = 4" style="margin-right: 5px;">Rechazadas
                                <span class="counter" [ngClass]="{'bg-light-orange': selectedFilter === 4}">
                                    {{totales.RECHAZADAS}}
                                </span>
                            </button>
                            <button *ngIf="existeAccion('27')" [ngClass]="{'text-primary text-bolder': selectedFilter === 3}" class="btn tag mb-1" style="margin-right: 5px;"
                            (click)="filterDataSource(3);  selectedFilter = 3">Todas
                            <span [ngClass]="{'bg-light-orange': selectedFilter === 3}" class="counter">
                                {{totalSolpes.total}}
                            </span> 
                            </button>
                            <button 
                                *ngIf="(rolId == 1 || rolId == 15)"
                                [ngClass]="{'text-primary text-bolder': selectedFilter === 5}" 
                                class="btn tag mb-1 text-muted" 
                                style="margin-left: 200px;"
                                (click)="filterDataSource(5);  selectedFilter = 5"
                            >
                                Fuera de proceso <span>{{totales.OCULTAS}}</span>
                            </button>
                        </div>
                        <div class="col-12 col-md-4">
                            <input class="form-control " style="margin-bottom: 10px; " matInput (keyup)="applyFilterSolpesGrl($event)" placeholder="Buscar ... " #input>
                            <i matSuffix class="fas fa-search" style="position: absolute;top: 0.7rem;right: 1.5rem;"></i>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12">
                            <ng-container *ngIf="typeSolpe == 82;
                            then tableModificaciones else tableNormal">
                            </ng-container>

                            <mat-paginator #paginatorSolpesGrl [pageSize]="20" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>

<ng-template #tableNormal>
    <table mat-table [dataSource]="dataSource" matSort
        (matSortChange)="sortData($event)"
        class="table border table-striped table-td-valign-middle table-th-valign-middle ">
        <ng-container matColumnDef="Codigo">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Codigo">Código</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.SOLPE_CODIGO }}</td>
        </ng-container>
        <ng-container matColumnDef="Nombre">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Nombre">Nombre</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <p class="mb-0">
                    {{ element.NOMBRE_SOLPE }}
                </p>
                <p class="mb-0">
                    <span class="text-muted p-2">{{element.TIPO_SOLPE}}</span>
                    <span *ngIf="element.FORMA_SOLPE">-</span>
                    <span class="text-muted p-2">{{element.FORMA_SOLPE}}</span>
                </p>
            </td>
        </ng-container>
        <ng-container matColumnDef="Oc">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaInicio">Documento</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span class="text-muted">
                    {{ element.OC }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="fechaEstimadaInicioServicio">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="fechaEstimadaInicioServicio">Inicio Estimado</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span *ngIf="element.fecha_estimada_inicio_servicio !== null" class="text-muted">{{ element.fecha_estimada_inicio_servicio | date: 'dd/MM/yyyy' }}</span>
                <span *ngIf="element.fecha_estimada_inicio_servicio === null"
                class="text-muted"
                >
                    
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="duracionEstimadaServicio">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="duracionEstimadaServicio">Duración</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span *ngIf="element.duracion_estimada_servicio !== null" class="text-muted">{{ element.duracion_estimada_servicio }} Meses</span>
                <span *ngIf="element.duracion_estimada_servicio === null"
                class="text-muted"
                >
                    
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="Solicitante">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Solicitante">Solicitante</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <p class="mb-0">
                    {{ element.USUA_SOLICITANTE }}
                </p>
            </td>
        </ng-container>
        <ng-container matColumnDef="GCC">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="GCC">Gestionador</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <p class="mb-0">
                    <span *ngIf="element.GCC">
                        {{ element.GCC }}
                    </span>
                    <span class="text-muted" *ngIf="!element.GCC">Gestionador por definir</span>
                </p>
            </td>
        </ng-container>
        <ng-container matColumnDef="Monto">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Monto">Monto</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span class="text-muted" *ngIf="element.MONTO_SOLPE !== null">
                    {{ element.MONTO_SOLPE | currency: element.ISOCode: element.Simbol: element.decimalPipe: 'es-CL' }}
                </span>
                <span *ngIf="element.MONTO_SOLPE === null"
                class="text-muted"
                >No Aplica
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="Estado">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Estado">Estado</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span *ngIf="element.ESTADO_CORRELATIVO !== 81" class="tab tab-green text-center font-size12">
                    {{ element.ESTADO_SOLPE }}
                </span>
                <span *ngIf="element.ESTADO_CORRELATIVO == 81" class="tab tab-red text-center font-size12">
                    {{ element.ESTADO_SOLPE }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                <!-- <div class="btn-group"> -->
                    <a *ngIf="existeAccion('1') && (element.VISIBLE == 1)" href="#modal-archivo" data-toggle="modal" (click)="cargarModalArchivo(element.SOLPE_CORRELATIVO)" class="btn rounded-icon mr-2"  title="Archivos">
                        <i class="fa fa-cloud-upload-alt"></i>
                    </a>
                    <!-- <a *ngIf="existeAccion('1')" class="btn rounded-icon"  title="Ver Detalle" href="#modal-archivo">
                        <i class="fa fa-cloud-upload-alt" (click)="cargarModalArchivo(element.SOLPE_CORRELATIVO)" href="#modal" data-toggle="modal"></i>
                    </a> -->
                    <!-- <a *ngIf="existeAccion('3')" href="#modal-comentario" data-toggle="modal" (click)="cargarModalComentarios(element.SOLPE_CORRELATIVO)" class="btn btn-default btn-sm" style="color: #666;" title="Comentarios">
                        <i class="fa fa-lg fa-fw m-r-10 fa-comment-alt"></i>
                    </a>
                    <a routerLink="/detalle-solpe" (click)="revisarSolpe(element.SOLPE_CORRELATIVO, element.ESTADO_CORRELATIVO)" class="btn btn-default btn-sm" style="color: #666;" title="Revisar">
                        <i class="fa fa-lg fa-fw m-r-10 fa-eye"></i>
                    </a> -->
                    <a *ngIf="existeAccion('3') && (element.VISIBLE == 1)" href="#modal-comentario" data-toggle="modal" (click)="cargarModalComentarios(element.SOLPE_CORRELATIVO)" class="btn rounded-icon mr-2" title="Comentarios">
                        <i class="fa fa-comment-alt"></i>
                    </a>
                    <!-- (click)="revisarSolpe(element.SOLPE_CORRELATIVO, element.ESTADO_CORRELATIVO)" -->
                    <a routerLink="/detalle-solpe/{{element.SOLPE_CORRELATIVO}}" (click)="revisarSolpe(element.SOLPE_CORRELATIVO, element.ESTADO_CORRELATIVO)" class="btn rounded-icon mr-2" title="Revisar">
                        <i class="fa fa-eye"></i>
                    </a>
                    <a 
                        *ngIf="((element.VISIBLE == 1) && (rolId == 15))" 
                        class="btn rounded-icon mr-2" 
                        title="Pasar a Fuera de proceso" 
                        data-toggle="modal" 
                        data-target="#modalOcultarSolpe"
                        (click)="visualizarSolpe(element.SOLPE_CORRELATIVO, element.SOLPE_CODIGO, element.NOMBRE_SOLPE, 0)"
                    >
                        <i class="fa fa-eye-slash"></i>
                    </a>
                    <!-- <a 
                        *ngIf="((element.VISIBLE == 0) && (rolId == 15))" 
                        class="btn rounded-icon mr-2" 
                        title="Quitar de Fuera de proceso" 
                        data-toggle="modal" 
                        data-target="#modalOcultarSolpe"
                        (click)="visualizarSolpe(element.SOLPE_CORRELATIVO, element.SOLPE_CODIGO, element.NOMBRE_SOLPE, 1)"
                    >
                        <i class="fa fa-check"></i>
                    </a> -->
                    <!--<a [routerLink]="" class="btn btn-default btn-sm" style="color: #666;"><i class="fa fa-cloud-download-alt"></i> Ocultar</a>-->
                <!-- </div> -->
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="dataSourceHeader"></tr>
        <tr mat-row *matRowDef="let row; columns: dataSourceHeader;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

        <tr class="mat-row " *matNoDataRow>
            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
        </tr>
    </table>
</ng-template>


<ng-template #tableModificaciones>
    <table mat-table [dataSource]="dataSource" matSort
        (matSortChange)="sortData($event)"
        class="table border table-striped table-td-valign-middle table-th-valign-middle ">
        <ng-container matColumnDef="Codigo">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Codigo">Código</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">{{ element.SOLPE_CODIGO }}</td>
        </ng-container>
        <ng-container matColumnDef="Nombre">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Nombre">Nombre</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <p class="mb-0">
                    {{ element.NOMBRE_SOLPE }}
                </p>
                <p class="mb-0">
                    <span class="text-muted p-2">{{element.TIPO_SOLPE}}</span>
                    <span *ngIf="element.FORMA_SOLPE">-</span>
                    <span class="text-muted p-2">{{element.FORMA_SOLPE}}</span>
                </p>
            </td>
        </ng-container>
        <ng-container matColumnDef="Ctt">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Ctt">Ctt</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span class="text-muted">
                    {{ element.CODIGO_CONTRATO }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="Oc">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaInicio">Documento</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span class="text-muted">
                    {{ element.OC }}
                </span>
            </td>
        </ng-container>
        <!-- <ng-container matColumnDef="FechaTermino">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="FechaTermino">Fecha término</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span *ngIf="element.FECHA_TERMINO_SOLPE !== null" class="text-muted">{{ element.FECHA_TERMINO_SOLPE | date: 'dd/MM/yyyy' }}</span>
                <span *ngIf="element.FECHA_TERMINO_SOLPE === null"
                class="text-muted"
                >
                    No Aplica
                </span>
            </td>
        </ng-container> -->
        <ng-container matColumnDef="fechaEstimadaInicioServicio">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="fechaEstimadaInicioServicio">Inicio Estimado</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span *ngIf="element.fecha_estimada_inicio_servicio !== null" class="text-muted">{{ element.fecha_estimada_inicio_servicio | date: 'dd/MM/yyyy' }}</span>
                <span *ngIf="element.fecha_estimada_inicio_servicio === null"
                class="text-muted"
                >
                    
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="duracionEstimadaServicio">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="duracionEstimadaServicio">Duración</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span *ngIf="element.duracion_estimada_servicio !== null" class="text-muted">{{ element.duracion_estimada_servicio }} Meses</span>
                <span *ngIf="element.duracion_estimada_servicio === null"
                class="text-muted"
                >
                    
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="Solicitante">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Solicitante">Solicitante</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <p class="mb-0">
                    {{ element.USUA_SOLICITANTE }}
                </p>
            </td>
        </ng-container>
        <ng-container matColumnDef="GCC">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="GCC">Gestionador</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <p class="mb-0">
                    <span *ngIf="element.GCC">
                        {{ element.GCC }}
                    </span>
                    <span class="text-muted" *ngIf="!element.GCC">Gestionador por definir</span>
                </p>
            </td>
        </ng-container>
        <ng-container matColumnDef="Monto">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Monto">Monto</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span class="text-muted" *ngIf="element.MONTO_SOLPE !== null">
                    {{ element.MONTO_SOLPE | currency: element.ISOCode: element.Simbol: element.decimalPipe: 'es-CL' }}
                </span>
                <span *ngIf="element.MONTO_SOLPE === null"
                class="text-muted"
                >No Aplica
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="Estado">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header="Estado">Estado</th>
            <td style="padding: 10px !important;" mat-cell *matCellDef="let element ">
                <span *ngIf="element.ESTADO_CORRELATIVO !== 81" class="tab tab-green text-center font-size12">
                    {{ element.ESTADO_SOLPE }}
                </span>
                <span *ngIf="element.ESTADO_CORRELATIVO == 81" class="tab tab-red text-center font-size12">
                    {{ element.ESTADO_SOLPE }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="Acciones">
            <th class=" normalText" style="padding: 10px !important;" mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td class="text-center" style="padding: 10px !important; text-align: center !important;" mat-cell *matCellDef="let element ">
                <!-- <div class="btn-group"> -->
                    <a *ngIf="existeAccion('1') && (element.VISIBLE == 1)" href="#modal-archivo" data-toggle="modal" (click)="cargarModalArchivo(element.SOLPE_CORRELATIVO)" class="btn rounded-icon mr-2"  title="Archivos">
                        <i class="fa fa-cloud-upload-alt"></i>
                    </a>
                    <!-- <a *ngIf="existeAccion('1')" class="btn rounded-icon"  title="Ver Detalle" href="#modal-archivo">
                        <i class="fa fa-cloud-upload-alt" (click)="cargarModalArchivo(element.SOLPE_CORRELATIVO)" href="#modal" data-toggle="modal"></i>
                    </a> -->
                    <!-- <a *ngIf="existeAccion('3')" href="#modal-comentario" data-toggle="modal" (click)="cargarModalComentarios(element.SOLPE_CORRELATIVO)" class="btn btn-default btn-sm" style="color: #666;" title="Comentarios">
                        <i class="fa fa-lg fa-fw m-r-10 fa-comment-alt"></i>
                    </a>
                    <a routerLink="/detalle-solpe" (click)="revisarSolpe(element.SOLPE_CORRELATIVO, element.ESTADO_CORRELATIVO)" class="btn btn-default btn-sm" style="color: #666;" title="Revisar">
                        <i class="fa fa-lg fa-fw m-r-10 fa-eye"></i>
                    </a> -->
                    <a *ngIf="existeAccion('3') && (element.VISIBLE == 1)" href="#modal-comentario" data-toggle="modal" (click)="cargarModalComentarios(element.SOLPE_CORRELATIVO)" class="btn rounded-icon mr-2" title="Comentarios">
                        <i class="fa fa-comment-alt"></i>
                    </a>
                    <!-- (click)="revisarSolpe(element.SOLPE_CORRELATIVO, element.ESTADO_CORRELATIVO)" -->
                    <a routerLink="/detalle-solpe/{{element.SOLPE_CORRELATIVO}}" (click)="revisarSolpe(element.SOLPE_CORRELATIVO, element.ESTADO_CORRELATIVO)" class="btn rounded-icon mr-2" title="Revisar">
                        <i class="fa fa-eye"></i>
                    </a>
                    <a 
                        *ngIf="((element.VISIBLE == 1) && (rolId == 15))" 
                        class="btn rounded-icon mr-2" 
                        title="Pasar a Fuera de proceso" 
                        data-toggle="modal" 
                        data-target="#modalOcultarSolpe"
                        (click)="visualizarSolpe(element.SOLPE_CORRELATIVO, element.SOLPE_CODIGO, element.NOMBRE_SOLPE, 0)"
                    >
                        <i class="fa fa-eye-slash"></i>
                    </a>
                    <!-- <a 
                        *ngIf="((element.VISIBLE == 0) && (rolId == 15))" 
                        class="btn rounded-icon mr-2" 
                        title="Quitar de Fuera de proceso" 
                        data-toggle="modal" 
                        data-target="#modalOcultarSolpe"
                        (click)="visualizarSolpe(element.SOLPE_CORRELATIVO, element.SOLPE_CODIGO, element.NOMBRE_SOLPE, 1)"
                    >
                        <i class="fa fa-check"></i>
                    </a> -->
                    <!--<a [routerLink]="" class="btn btn-default btn-sm" style="color: #666;"><i class="fa fa-cloud-download-alt"></i> Ocultar</a>-->
                <!-- </div> -->
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="dataSourceHeaderMod"></tr>
        <tr mat-row *matRowDef="let row; columns: dataSourceHeaderMod;" id="row" style="cursor: pointer;" (click)="selection.toggle(row)" [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

        <tr class="mat-row " *matNoDataRow>
            <td class="mat-cell " colspan="4 ">No se encontro datos para :"{{input.value}} "</td>
        </tr>
    </table>
</ng-template>


<!-- Modal de crear solpe -->
<div class="modal fade" id="modal-agregar">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <span class="modal-title">Agregar SOLPE</span>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarSolpe" (ngSubmit)="onCrear()" novalidate>
                    <div class="row form-group">
                        <label class="col-12 col-md-3 col-form-label">Solicitante</label>
                        <div class="col-12 col-md-9">
                            <input type="text" formControlName="solicitante" #solicitante class="form-control" value="{{ username }}" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="tipo_solicitud" class="col-12 col-md-3 col-form-label">Tipo Solicitud</label>
                        <div class="col-12 col-md-9">
                            <select class="form-control" formControlName="tipo_solicitud" #tipo_solicitud (change)="onChangeTipo()"
                            [ngClass]="{ 'is-invalid': submitted && f.tipo_solicitud.errors}">
                            <option value="">Seleccione ...</option>
                            <option *ngFor="let item of tipos" [value]="item.correlativo">{{item.descripcion}}</option>
                    </select>
                        </div>
                    </div>
                    <div *ngIf="!tipoContrato">
                        <div class="row form-group">
                            <label for="forma" class="col-12 col-md-3 col-form-label">Forma</label>
                            <div class="col-12 col-md-9">
                                <select class="form-control" formControlName="forma" #forma (change)="onChangeForma()" [ngClass]="{ 'is-invalid': submitted && f.forma.errors}">
                        <option value="">Seleccione ...</option>
                        <option *ngFor="let item of formas" [value]="item.correlativo">{{item.descripcion}}</option>
                      </select>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="formaPagosMis && !tipoContrato">
                        <div class="row form-group">
                            <label class="col-12 col-md-3 col-form-label">RUT Empresa</label>
                            <div class="col-12 col-md-9">
                                <input type="text" formControlName="rut_empresa" #rut_empresa class="form-control" maxlength="10" placeholder="formato: 11222333-4"  [ngClass]="{ 'is-invalid': submitted && f.rut_empresa.errors}" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <label class="col-12 col-md-3 col-form-label">Razón Social</label>
                            <div class="col-12 col-md-9">
                                <input type="text" formControlName="razon_social" #razon_social class="form-control" [ngClass]="{ 'is-invalid': submitted && f.razon_social.errors}" />
                            </div>
                        </div>
                    </div>
                    <!-- <div style="background-color: #f5f5f5; border-radius: 5px; padding: 10px"> -->
                    <div class="row form-group">
                        <label for="fecha_ingreso" class="col-md-3 col-form-label">Fecha Ingreso Solpe</label>
                        <div class="col-md-9 input-group">
                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Emisión" formControlName="fecha_ingreso" #fecha_ingreso [ngClass]="{ 'is-invalid': submitted && f.fecha_ingreso.errors}">
                        </div>
                    </div>
                    <!-- <div class="row form-group" *ngIf="!tipoContrato">
                        <label for="fecha_termino" class="col-12 col-md-3 col-form-label">{{ lblFechaTerminoSolpe }}</label>
                        <div class="col-12 col-md-9">
                            <input type="date" class="form-control m-b-5" placeholder="Fecha de Ingreso" 
                            formControlName="fecha_termino" #fecha_termino 
                            [min]="formAgregarSolpe.get('fecha_ingreso').value"
                            [ngClass]="{ 'is-invalid': submitted && f.fecha_termino.errors}">
                            <p *ngIf="mnjTipoCompras" style="padding: 5px 10px; border-radius: 5px; color: white; background-color: #666;">El proceso de compra es de 10 días aprox.</p>
                            <p>{{dateDiff(formAgregarSolpe.get('fecha_ingreso').value, formAgregarSolpe.get('fecha_termino').value)}}</p>
                        </div>
                    </div> -->
                    <div class="row form-group" *ngIf="verFechasSolpeServicios">
                        <label for="fecha_estimada_inicio_servicio" class="col-12 col-md-3 col-form-label">{{ lblFechaTerminoSolpe }}</label>
                        <div class="col-12 col-md-9">
                            <input 
                                type="date" class="form-control m-b-5" 
                                placeholder="Fecha estimada inicio de servicio" 
                                formControlName="fecha_estimada_inicio_servicio" 
                                #fecha_estimada_inicio_servicio 
                                [min]="formAgregarSolpe.get('fecha_ingreso').value"
                                [ngClass]="{ 'is-invalid': submitted && f.fecha_estimada_inicio_servicio.errors}"
                            >
                        </div>
                    </div>
                    <div class="row form-group" *ngIf="verFechasSolpeServicios">
                        <label for="duracion_estimada_servicio" class="col-12 col-md-3 col-form-label">Duración estimada de servicio (Meses)</label>
                        <div class="col-12 col-md-9">
                            <input 
                                type="number" 
                                class="form-control m-b-5" 
                                placeholder="Duración estimada de servicio (Meses)" 
                                formControlName="duracion_estimada_servicio" 
                                #duracion_estimada_servicio 
                                [ngClass]="{ 'is-invalid': submitted && f.duracion_estimada_servicio.errors}"
                            >
                        </div>
                    </div>
                    <!-- </div> -->
                    <br>
                    <div class="row form-group">
                        <label class="col-12 col-md-3 col-form-label">Nombre Solpe</label>
                        <div class="col-12 col-md-9">
                            <input type="text" formControlName="nombre" #nombre class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors}" />
                        </div>
                    </div>
                    <div class="row form-group" *ngIf="tipoContrato">
                        <label for="contrato" class="col-12 col-md-3 col-form-label">Contrato (Código Interno)</label>
                        <div class="col-12 col-md-9">
                            <select (change)="GetContrato(contrato.value)" class="form-control" formControlName="contrato" #contrato [ngClass]="{ 'is-invalid': submitted && f.contrato.errors}">
                                <option value="">Seleccione ...</option>
                                <option *ngFor="let item of contratos" [value]="item.id">{{item.text}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-group" *ngIf="contratoInfo">
                        <div class="col-12" *ngIf="tipoContrato">
                            <div style="background-color: rgba(75, 168, 75, 0.089); border-radius: 5px; border: 1px solid rgb(75, 168, 75); padding: 20px;" >
                                <h5>DATOS DEL CONTRATO</h5>
                                <hr>
                                <div class="row">
                                    <div class="col-md-2"><strong>Nro. Contrato</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.CODIGO_CONTRATO }}</div>
                                    <div class="col-md-2"><strong>Fecha Inicio</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.FECHA_INICIO_CONTRATO | date: 'dd/MM/yyyy'}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Cantidad de Modificaciones</strong></div>
                                    <div class="col-md-4">0</div>
                                    <div class="col-md-2"><strong>Fecha Término</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.FECHA_TERMINO_CONTRATO | date: 'dd/MM/yyyy'}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Razón Social</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.PROVEEDOR }}</div>
                                    <div class="col-md-2"><strong>Moneda</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.TIPO_MONEDA }}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>RUT</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.RUT_PROVEEDOR }}</div>
                                    <div class="col-md-2"><strong>Monto Original</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.MONTO_ORIGINAL | number : '' :'es-CL'}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Nombre Contrato</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.NOMBRE_CONTRATO }}</div>
                                    <div class="col-md-2"><strong>Monto modificado</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.MONTO_ACTUALIZADO | number : '' :'es-CL'}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4"></div>
                                    <div class="col-md-2"><strong>Modificacion</strong></div>
                                    <div class="col-md-4">{{contratoInfo.MONTO_ACTUALIZADO - contratoInfo.MONTO_ORIGINAL | number : '' : 'es-CL'}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Admin EMSA</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.ADMINISTRADOR }}</div>
                                    <div class="col-md-2"><strong>% Modificaciones</strong></div>
                                    <!-- <div class="col-md-4">{{ contrato.MONTO_REAJUSTE }}</div> -->
                                    <div class="col-md-4">{{((contratoInfo.MONTO_ACTUALIZADO - contratoInfo.MONTO_ORIGINAL)/contratoInfo.MONTO_ORIGINAL)*100 | number : 'es-CL'}}</div>

                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Admin Contratista</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.ADMIN_PROVEEDOR }}</div>
                                    <div class="col-md-2"><strong>Saldo contrato</strong></div>
                                    <div class="col-md-4">{{ contratoInfo.SALDO_CONTRATO | number: 'es-CL'}}</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-2"><strong>Boleta de Garantía</strong></div>
                                    <div class="col-md-4">{{ (contratoInfo.REQUIERE_BG == 1) ? 'Si' : 'No' }}</div>
                                    <div class="col-md-2"><strong>Fecha Vcto - BG</strong></div>
                                    <div class="col-md-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="descripcion" class="col-12 col-md-3 col-form-label">Descripción de la necesidad. Informar Proyecto, Zona o Blanco</label>
                        <div class="col-12 col-md-9">
                            <textarea class="form-control" rows="3" formControlName="descripcion" #descripcion [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors}"></textarea>
                        </div>
                    </div>
                    <div *ngIf="!tipoContrato">
                        <div class="row form-group">
                            <label for="moneda" class="col-12 col-md-3 col-form-label">Moneda</label>
                            <div class="col-12 col-md-9">
                                <select class="form-control" formControlName="moneda" #moneda [ngClass]="{ 'is-invalid': submitted && f.moneda.errors}">
                                    <option value="">Seleccione ...</option>
                                    <option *ngFor="let item of monedas" [value]="item.correlativo">{{item.descripcion}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <label class="col-12 col-md-3 col-form-label">Monto</label>
                            <div class="col-12 col-md-9">
                                <input type="number" formControlName="monto" #monto class="form-control" [ngClass]="{ 'is-invalid': submitted && f.monto.errors}" />
                            </div>
                            <div class="col-12 col-md-12 text-right mt-2">
                                <p>{{ monto.value | number: '1.2-2' : 'es-CL' }}</p>
                            </div>
                        </div>
                        <div class="row form-group rounded " style="background-color: #F3F5F7; padding: 1rem">
                            <label class="col-md-12 col-form-label">Empresas Propuestas</label>
                            <div class="col-md-12">
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="agregar_empresa" #agregar_empresa>
                                    <div class="input-group-append">
                                        <button type="button" (click)="addEmpresaPropuesta()"
                                        class="btn bg-white tag text-orange dropdown-toggle no-caret">
                                            Agregar
                                        </button>
                                    </div>
                                </div>
                                <br>
                                <!-- F3F5F7 -->
                                <div class="" *ngIf="empresas_propuestas.length > 0">
                                    <div class="" style="padding-bottom: 5px;">
                                        <ul style="list-style: none; padding-left: 0;">
                                            <li class="alert bg-white fade show" *ngFor="let item of empresas_propuestas; let i = index">
                                                <div class="row">
                                                    <div class="col-6 text-left">
                                                        {{ item | uppercase }}
                                                    </div>
                                                    <div class="col-6 text-right">
                                                        <a class="btn rounded-icon" (click)="deleteEmpresaSugerida(i)" title="Revisar">
                                                            <i class="fa fa-trash-alt"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="text-center" *ngIf="empresas_propuestas.length == 0">
                                    <span>Sin empresas registradoas</span>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="row mt-5">
                        <div class="col-12 text-right">
                            <!-- <div class="col-md-2"> -->
                                <a href="javascript:;" (click)="onClose()" #closeModalAgregar
                                class="btn tag mr-2 text-orange" data-dismiss="modal">Cerrar</a>
                            <!-- </div> -->
                            <!-- <div class="col-md-4 text-right"> -->
                                <button class="btn btn-primary" type="submit">Agregar</button>
                            <!-- </div> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal de cargar documento -->
<div class="modal fade" id="modal-archivo">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Agregar Documento</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarArchivo" (ngSubmit)="onAgregarArchivo()" novalidate>
                    <div class="row form-group">
                        <label for="grupo" class="col-md-3 col-form-label">Grupo</label>
                        <div class="col-md-9">
                            <select class="form-control" formControlName="grupo" #grupo [ngClass]="{ 'is-invalid': submittedDocumento && fd.grupo.errors}">
                                <option value="0">Seleccione ...</option>
                                <option *ngFor="let item of archivos" [value]="item.correlativo">{{item.descripcion}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="adjunto" class="col-md-3 col-form-label">Documento</label>
                        <div class="col-md-9">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" name="adjunto" id="adjunto" (change)="detectFile($event)" aria-describedby="inputGroupFileAddon01" [ngClass]="{ 'is-invalid': submittedDocumento && fd.adjunto.errors}">
                                <label class="custom-file-label" for="adjunto">{{ fileName }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4 text-right">
                            <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                        </div>
                    </div>
                    <input type="hidden" name="corr_solpe" formControlName="corr_solpe" #corr_solpe value="" />

                    <!-- <div *ngIf="documentosSolpe">
                        <hr>
                        <p *ngFor="let item of documentosSolpe">
                            <span style="color: #999; font-weight: bold !important;" [ngClass]="{ 'text-red': item.OBLIGATORIO == 'S'}">
                                <i class="fa fa-lg fa-fw m-r-10 fa-file"></i> {{ item.descripcion | uppercase }}: &nbsp;
                            </span>
                            <a [routerLink]="" *ngIf="item.descripcion_archivo == null || item.descripcion_archivo == ''" (click)="onDescargar(item.nombre_archivo)">{{ item.nombre_archivo }}</a>
                            <a [routerLink]="" *ngIf="item.descripcion_archivo != null || item.descripcion_archivo != ''" (click)="onDescargar(item.nombre_archivo)">{{ item.descripcion_archivo }}</a>
                        </p>
                    </div> -->
                    <div *ngIf="documentosSolpe">
                        <ng-container *ngFor="let item of documentosSolpe">
                            <div class="file border rounded mb-2 mt-2">
                                <div class="row">
                                    <div class="col-6 content-center">
                                        <div class="file-detail content-center nowrap">
                                            <div class="file-img content-center">
                                                <i class="far fa-file-alt text-primary mr-2"></i>
                                            </div>
                                            <div class="">
                                                <p class="mb-0 nombreArchivoCustom">
                                                    {{item.descripcion_archivo}}
                                                </p>
                                                <p class="mb-0 text-muted">
                                                    {{item.descripcion}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 content-center" style="justify-content: end;">
                                        <view-file-button [nombreArchivo]="item.nombre_archivo"
                                            [tipoArchivo]="'solpe'"></view-file-button>
                                        <div *ngIf="item.nombre_archivo !== null" class="btn rounded-icon mr-2"
                                            (click)="onDescargar(item.nombre_archivo)">
                                            <i class="fas fa-file-download"></i>
                                        </div>
                                        <div *ngIf="item.nombre_archivo !== null" class="btn rounded-icon mr-2"
                                            (click)="onEliminarArchivoSolpe(item.correlativo_archivo, item.nombre_archivo)">
                                            <i class="fas fa-trash"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-10"></div>
                        <div class="col-md-2"><a href="javascript:;" (click)="onCloseArchivo()" #closeModalAgregarArchivo class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal de comentarios -->
<div class="modal fade" id="modal-comentario">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Agregar Cometario</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formAgregarComentario" (ngSubmit)="onAgregarComentario()" novalidate>
                    <div class="row form-group">
                        <label for="comentario" class="col-md-12 col-form-label">Comentario</label>
                        <div class="col-md-8">
                            <input type="text" class="form-control" name="comentario" #comentario formControlName="comentario" [ngClass]="{ 'is-invalid': submittedComentario && fc.comentario.errors}" autocomplete="off">
                        </div>
                        <div class="col-md-4 text-right">
                            <button class="btn btn-primary btn-block" type="submit"><i _ngcontent-yco-c146="" class="fas fa-lg fa-fw m-r-10 fa-archive"></i> Agregar</button>
                        </div>
                    </div>
                    <input type="hidden" name="corr_solpe" formControlName="corr_solpe" #corr_solpe value="" />
                    <div *ngIf="comentariosSolpe">
                        <hr>
                        <p *ngFor="let item of comentariosSolpe">
                            <span style="color: #999; font-weight: bold !important;">
                      <i class="fa fa-lg fa-fw m-r-10 fa-comment-alt"></i> {{ item.FECHA_COMENTARIO }}<br>
                      {{ item.NOMBRE_USUARIO }}:<br>
                    </span> {{ item.COMENTARIO }}
                        </p>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-10"></div>
                        <div class="col-md-2"><a href="javascript:;" (click)="onCloseComentario()" #closeModalAgregarComentario class="btn btn-light btn-block" data-dismiss="modal">Cerrar</a></div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Modal ocultar solpe -->
<div class="modal fade" id="modalOcultarSolpe" tabindex="-1" role="dialog" aria-labelledby="modalOcultarSolpe" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalOcultarSolpe">Pasar Solpe a Fuera de Proceso</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModalOcultar>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p class="text-bolder" style="font-size: 16px;">IMPORTANTE: Este cambio es irreversible, una vez la SOLPE este Fuera del proceso no podrá ser modificada.</p>
            <p *ngIf="visible == 0" style="font-size: 16px;"> ¿Desea pasar a "Fuera de proceso" la SOLPE: <span style="font-weight: bold;">{{ strCodigoSolpe }} - {{ strNombreSolpe }}</span>?</p>
            <p style="font-size: 16px;">Ingrese observación de cierre:</p>
            <textarea class="form-control" name="comentarioFueraProceso" id="comentarioFueraProceso" rows="5" [(ngModel)]="comentarioFueraProceso"></textarea>
            <!-- <p *ngIf="visible == 1" style="font-size: 16px;">¿Desea volver a estado "En Proceso" la SOLPE: <span style="font-weight: bold;">{{ strCodigoSolpe }} - {{ strNombreSolpe }}</span>?</p> -->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="onCambiarEstadoSolpeOcultar()">Confirmar</button>
        </div>
    </div>
</div>