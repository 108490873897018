import { Component, OnInit } from '@angular/core';
import { AuthService, ForgotPasswordRequestData } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { Validators, FormGroup, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { passwordValidation, isInvalidControl } from '../../shared/functions/common';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  isSendingCode: boolean = false;
  isSaving: boolean = false;

  constructor(private authService: AuthService, private router: Router, private notifyService: NotificationService) { }

  isInvalid(input: string) {
    return isInvalidControl(input, this.forgotPasswordForm);
  }

  passwordValidator(pass2?: boolean) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      let valid = true;
      let message = "";

      const validation = passwordValidation(value);
      valid = validation.valid;
      message = validation.message;

      if (valid && pass2) {
        const pass = this.forgotPasswordForm && this.forgotPasswordForm.get('lPassword') ? this.forgotPasswordForm.get('lPassword').value : null;
        if (value !== pass) {
          valid = false;
          message = "Las contraseñas no coinciden";
        }
      }

      return !valid ? { message } : null;
    };
  }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      lEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      lCode: new FormControl('', [
        Validators.required,
      ]),
      lPassword: new FormControl('', [
        this.passwordValidator(),
      ]),
      lPassword2: new FormControl('', [
        this.passwordValidator(true),
      ]),
    });
  }
  onSendCode() {
    this.isSendingCode = true;
    this.authService.sendCode({ username: this.forgotPasswordForm.get('lEmail').value }).subscribe(resp => {
      this.isSendingCode = false;
      this.notifyService.showSuccess('El correo se ha enviado exitosamente, recibirá un código de verificación por correo electrónico.', 'Cambiar contraseña');
    }, err => {
      this.isSendingCode = false;
      this.notifyService.showError('Error al enviar correo', 'Cambiar contraseña');
    });

  }
  onForgotPassword() {
    this.isSaving = true;
    const values = this.forgotPasswordForm.value;
    const postForgotPassword: ForgotPasswordRequestData = {
      username: values.lEmail,
      code: values.lCode,
      password: values.lPassword,
      repassword: values.lPassword2,
    }
    this.authService.forgotPassword(postForgotPassword).subscribe(resp => {
      this.notifyService.showSuccess('La contraseña se ha actualizado exitosamente.', 'Cambiar contraseña');
      this.router.navigate(['/auth']);
    }, err => {
      this.isSaving = false;
      this.notifyService.showError('Error al cambiar contraseña', 'Cambiar contraseña');
    });
  }

  backToLogin() {
    this.router.navigate(['/auth']);
  }
}
