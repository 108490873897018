import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

export interface iFile {
  fileName: string;
  stats: {
    size: number
  }
}

@Injectable({
  providedIn: 'root'
})

export class FileService {
  objHttpOptions;
  authToken = JSON.parse(localStorage.getItem('userData'))._token;
  constructor(private http: HttpClient) { 
    this.objHttpOptions = {
      headers: new  HttpHeaders(
        {
          'authorization': 'Bearer '+this.authToken
        }
      )
    }
  }
  //'Content-Type': 'aplication/json',
  private urlService: string = environment.apiUrl;
  //private fileFolder: string = environment.fileFolder;

  private fileFolderContratos: string = 'file/download/contratos/';
  private fileFolder: string = 'file/download/boletas/';
  private fileFolderDocumentosContrato: string = 'file/download/archivos_modificaciones/';
  private fileFolderSolpe: string = 'file/download/solpe/';
  private fileFolderChecklits: string = 'file/download/checklist/';
  private fileFolderEPIncurrido: string = 'file/download/ep_incurrido/';
  private fileFolderFactuasEP: string = 'file/download/facturas_ep/';
  private fileFolderManualesProcedimientos: string = 'file/download/manuales/';

  getUrlFile(fileName: string, tipo: string){
    switch(tipo)
    {
      case "boleta":{
          return this.urlService + this.fileFolder + fileName;
        break;
      }
      case "contratos":{
        return this.urlService + this.fileFolderContratos + fileName;
        break;
      }
      case "solpe":{
        return this.urlService + this.fileFolderSolpe + fileName;
        break;
      }
      case "checklist":{
        return this.urlService + this.fileFolderChecklits + fileName;
        break;
      }
      case "manuales":{
        return this.urlService + this.fileFolderManualesProcedimientos + fileName;
        break;
      }
      default: { 
        //statements; 
        break; 
      } 
    }
  }

  getExternalUrl(token: string):string
  { 
      if(token){
        return ""+this.urlService + 'file/externalDownload/' + token
      }   
  }

  getFile(postData: {fileName: string, fileType: String})
  {
    let file = postData.fileType + '/' + postData.fileName;
    let HttpOptions = this.objHttpOptions;
    HttpOptions.responseType = 'blob';
    return this.http.get(this.urlService  +'file/download/' + file, HttpOptions);
  }
  getExternalFile(token: string)
  {
    //let file = postData.fileType + '/' + postData.fileName;
    let HttpOptions = this.objHttpOptions;
    HttpOptions.responseType = 'blob';
    return this.http.get(this.urlService  +'file/externalDownload/' + token, HttpOptions);
  }

  deleteFile(postData: {in_file_name: string, in_file_type: String, in_correlativo: number })
  {
    return this.http.post(this.urlService  +'file/delete', postData, this.objHttpOptions);
  }

  generateExternalToken(postData: {fileName: string, fileType: String})
  {
    return this.http.get(this.urlService  +'file/generateToken/' + postData.fileType + '/' + postData.fileName, this.objHttpOptions);
  }
  downloadFile(fileName: string): Observable<any>{
    return this.http.get( this.urlService  + this.fileFolder +fileName, {responseType:'blob'});
  }

  downloadDocumentoContrato(fileName: string): Observable<any>{
    return this.http.get( this.urlService  + this.fileFolderContratos +fileName, {responseType:'blob'});
  }

  downloadDocumentoSolpe(fileName: string): Observable<any>{
    return this.http.get( this.urlService  + this.fileFolderSolpe +fileName, {responseType:'blob'});
  }

  downloadDocumentoChecklist(fileName: string): Observable<any>{
    return this.http.get( this.urlService  + this.fileFolderChecklits +fileName, {responseType:'blob'});
  }
  downloadDocumentoEPIncurrido(fileName: string): Observable<any>{
    return this.http.get( this.urlService  + this.fileFolderEPIncurrido +fileName, {responseType:'blob'});
  }
  downloadDocumentoFacturaEp(fileName: string): Observable<any>{
    return this.http.get( this.urlService  + this.fileFolderFactuasEP +fileName, {responseType:'blob'});
  }

  getFileOnDirGeneral():Observable<iFile[]>
  {
    return this.http.get<iFile[]>(this.urlService  +'file/getFileOnDirGeneral').pipe(
      map(resp => {
        /* let blancos = [];
        for (const item in resp['filesReturn']) {
          blancos.push({ ...resp['blancos'][item] });
        } */
        return resp['filesReturn'];
      }));    
  }

  downloadDocumentoManualesProcedimientos(fileName: string): Observable<any>{
    return this.http.get( this.urlService  + this.fileFolderManualesProcedimientos +fileName, {responseType:'blob'});
  }
}
