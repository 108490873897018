import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BoletasService } from 'src/app/shared/services/boletas.service';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { DataService } from 'src/app/shared/services/data.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { GlobalDataService, ICurrencies } from 'src/app/shared/services/global-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as moment from 'src/assets/plugins/moment/moment';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-estados-pago',
  templateUrl: './estados-pago.component.html',
  styleUrls: ['./estados-pago.component.css'],
  providers: [
    EstadoPagoService,
    BoletasService,
    ContratoService
  ]
})
export class EstadosPagoComponent implements OnInit {

  corrContrato: number;
  contrato = { NOMBRE_CONTRATO: '', PROVEEDOR: '', CODIGO_CONTRATO: '',TIPO_MONEDA: '', MONTO_REAJUSTE: 0, SALDO_CONTRATO: 0, TIMO_CORRELATIVO: 0, MONTO_EP:0, MONTO_ACTUALIZADO: 0, FECHA_INICIO_CONTRATO: '', FECHA_TERMINO_CONTRATO: ''};
  tipoMoneda: any[];
  reajuste: number = 0;
  itemizado: any[] = [];
  cantidades: {} = {};
  valoresCantidades: any[] = [];
  valoresTotales: any[] = [];
  total: number = 0;
  totalSinReajuste: number = 0;
  cantidad: any;
  form: FormGroup;
  submitted = false;

  fechaInicioContrato: string = '';
  fechaTerminoContrato: string = '';
  validMaxLength: (e: Event) => void;
  public contractCurrenci: ICurrencies;

  @ViewChild('modalRegister') modalRegister: TemplateRef<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private boletasService: BoletasService,
    private contratoService: ContratoService,
    private notifyService: NotificationService,
    private estadoPagoService: EstadoPagoService,
    private router: Router,
    private fb: FormBuilder,
    public GloblaDataService: GlobalDataService,
    private dialog: MatDialog
    ) {
    /* this. corrContrato = this.dataService.idContrato;
    this.form = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      fecha_desde: new FormControl(null, Validators.required),
      fecha_hasta: new FormControl(null, Validators.required),
      moneda: new FormControl({value: 0, disabled: true}),
      reajuste: new FormControl(0),
      retencion_laboral: new FormControl(null),
      rdo_contractual: new FormControl('P'),
      retencion_contractual: new FormControl(null),
      cantidad: new FormControl(null)
    }); */

    /* this.form = this.fb.group({
      nombre: new FormControl(null, Validators.required),
      fecha_desde: new FormControl(null, Validators.required),
      fecha_hasta: new FormControl(null, Validators.required),
      moneda: new FormControl({value: 0, disabled: true}),
      reajuste: new FormControl(0),
      retencion_laboral: new FormControl(null),
      rdo_contractual: new FormControl('P'),
      retencion_contractual: new FormControl(null),
      cantidad: this.fb.array([])
    }); */
    this.form = this.fb.group({
      nombre: new FormControl(null, Validators.required),
      fecha_desde: new FormControl(null, Validators.required),
      fecha_hasta: new FormControl(null, Validators.required),
      moneda: new FormControl({value: 0, disabled: true}),
      reajuste: new FormControl(0),
      retencion_laboral: new FormControl(null),
      rdo_contractual: new FormControl('P'),
      retencion_contractual: new FormControl(null),
      cantidad: new FormArray([])
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.corrContrato = params['corr_contrato'];
    });

    this.getMonedas();
    this.getContrato();
    this.getItemizado();
    this.resetForm();

  }
  private resetForm() {
    /* this.form = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      fecha_desde: new FormControl(null, Validators.required),
      fecha_hasta: new FormControl(null, Validators.required),
      moneda: new FormControl({value: 0, disabled: true}),
      reajuste: new FormControl(0),
      retencion_laboral: new FormControl(null),
      rdo_contractual: new FormControl('P'),
      retencion_contractual: new FormControl(null),
      cantidad: new FormControl(null)
    }); */
    /* this.form = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      fecha_desde: new FormControl(null, Validators.required),
      fecha_hasta: new FormControl(null, Validators.required),
      moneda: new FormControl({value: 0, disabled: true}),
      reajuste: new FormControl(0),
      retencion_laboral: new FormControl(null),
      rdo_contractual: new FormControl('P'),
      retencion_contractual: new FormControl(null),
      cantidad: this.fb.array([])
    }); */
    this.form = this.fb.group({
      nombre: new FormControl(null, Validators.required),
      fecha_desde: new FormControl(null, Validators.required),
      fecha_hasta: new FormControl(null, Validators.required),
      moneda: new FormControl({value: 0, disabled: true}),
      reajuste: new FormControl(0),
      retencion_laboral: new FormControl(null),
      rdo_contractual: new FormControl('P'),
      retencion_contractual: new FormControl(null),
      cantidad: new FormArray([])
    });
    this.submitted = false;
  }
  private getContrato() {
    this.contratoService.getContratoId(this.corrContrato).subscribe(resp => {
      this.contrato = JSON.parse(resp)[0];
      this.fechaInicioContrato = this.contrato.FECHA_INICIO_CONTRATO;
      this.fechaTerminoContrato = this.contrato.FECHA_TERMINO_CONTRATO;
      this.contractCurrenci = this.GloblaDataService.CurrenciesList?.find(currencie => currencie.TimoCode == this.contrato.TIMO_CORRELATIVO);
    }, error => console.log(error),
    () => {
      this.form.controls.moneda.setValue(this.contrato.TIMO_CORRELATIVO);
      this.form.controls.moneda.updateValueAndValidity();
    });
  }
  private getMonedas() {
    this.boletasService.getTipoDominios('TIMO').subscribe(resp => {
      this.tipoMoneda = JSON.parse(resp);
    });
  }
  private getItemizado() {
    this.estadoPagoService.getItemizadoDetalle(this.corrContrato).subscribe(resp => {
      let response = JSON.parse(resp);
      //const control = <FormArray>this.form.get('cantidad');
      //console.group("form");
      //console.log(this.form);
      const control = <FormArray>this.form.get('cantidad')['controls'];
      //console.group("control get");
      //console.log(control);
      let idx = 0;
      let cabecera = null;
      // creo estructura del itemizado para mostrar de forma ordenada
      for(let i in response) {
        // si es el primer registro o cambia la cabecera del itemizado: registro cabecera y primer subitem
        if(idx == 0 || response[i]['ITEM_CABECERA'] !== cabecera) {
          // cabecera
          this.itemizado[idx] = {
            Cabecera: response[i]['ITEM_CABECERA'],
            Subpos: null,
            Descripcion: null,
            Unidad: null,
            Cantidad: null,
            AvanceQ: null,
            PrecioUnitario: null,
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: null,
            Scpq: null
          };
          control.push(this.patchValues(this.itemizado[idx].Correlativo, 0, this.itemizado[idx].Scpq, (this.itemizado[idx].cantidad - this.itemizado[idx].AvanceQ)))
          // primer subitem
          idx++;
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            AvanceQ: response[i]['AVANCE_Q'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ']
          };
          control.push(this.patchValues(this.itemizado[idx].Correlativo, 0, this.itemizado[idx].Scpq, (this.itemizado[idx].cantidad - this.itemizado[idx].AvanceQ)))
          idx++;
        // de lo contrario sigo registrando subitems hasta que cambie el item principal
        } else {
          this.itemizado[idx] = {
            Cabecera: null,
            Subpos: response[i]['SUBPOSICION'],
            Descripcion: response[i]['DESCRIPCION'],
            Unidad: response[i]['UNIDAD'],
            Cantidad: response[i]['CANTIDAD'],
            AvanceQ: response[i]['AVANCE_Q'],
            PrecioUnitario: response[i]['PRECIO_UNITARIO'],
            Correlativo: response[i]['ID_CORRELATIVO'],
            R: response[i]['REAJUSTE'],
            Scpq: response[i]['SCPQ']
          };
          control.push(this.patchValues(this.itemizado[idx].Correlativo, 0, this.itemizado[idx].Scpq, (this.itemizado[idx].cantidad - this.itemizado[idx].AvanceQ)))
          idx++;
        }

        for(let item of this.itemizado){
          this.cantidades[`correlativo_${item.Correlativo}`] = 0
        }

        cabecera = response[i]['ITEM_CABECERA'];
        // seteo totales en 0
        //this.valoresCantidades[response[i]['ID_CORRELATIVO']] = 0;
        this.valoresCantidades[i] = { cantidad: 0, correlativo: response[i]['ID_CORRELATIVO']};
        this.valoresTotales[i] = { monto: 0, correlativo: response[i]['ID_CORRELATIVO']};
        //console.log(control);
      }

      //this.patch();
    });
  }
  patch() {
    const control = this.form.get('cantidad') as FormArray;
    //console.group('path');
    //console.log(control);
    //console.log(this.itemizado);
    this.itemizado?.forEach(x => {
      /* control.push(this.patchValues(x.Correlativo, x.Cantidad)) */
      //control.push(this.patchValues(x.Correlativo, 0))
    });
  }
  patchValues(label, value, SinControlQ, Saldo:number) {
    if(!SinControlQ)
    {
      return this.fb.group({
        id: [label],
        value: new FormControl([value], Validators.min(0))
      })
    }

    if(SinControlQ == 1)
    {
      return this.fb.group({
        id: [label],
        value: new FormControl([value], Validators.min(0))
      })
    }
    if(SinControlQ == 0)
    {
      return this.fb.group({
        id: [label],
        value: new FormControl([value], Validators.compose([Validators.min(0), Validators.max(Saldo)]))
      })
    }
   /*  return this.fb.group({
      id: new FormControl[label],
      value: new FormControl[value]
    }) */
  }
  isValidQ(value: number, SinControlQ, saldo: number)
  {
    if(value < 0)
    {
      return false;
    }
    else if(value == 0)
    {
      return false;
    }
    else if(SinControlQ == 0  && saldo < value)
    {
      return false;
    }
    else if(value?.toString() == "")
    {
      return false;
    }
    return true;
  }
  changeCantidad(corrItem: number, value: number, precio: number, r: number, reajuste: number, SinControlQ, saldo: number,input) {
    if(value < 0)
    {
      value = 0;
      input.value = 0;
      this.notifyService.showError('La cantidad debe ser mayor a cero', 'Estado de Pago');
      //return;
    }
    else if(value == 0)
    {
      input.value = "";
      value = 0;
      //return;
    }
    else if(SinControlQ == 0  && saldo < value)
    {
      input.value = saldo;
      value = 0;
      this.notifyService.showError('El monto excede el saldo del item', 'Estado de Pago');
      //return;
    }
    this.cantidades[`correlativo_${corrItem}`] = Number(value);

    this.valoresCantidades[this.getItemCantidadByid(corrItem)].cantidad = Number(value);
    if(r == 1) {
      const calReajuste = ((+reajuste / 100) + 1);
      this.valoresTotales[this.getItemTotalesByid(corrItem)].monto = (precio * calReajuste) * Number(value);
    } else {
      this.valoresTotales[this.getItemTotalesByid(corrItem)].monto = precio * value;
    }
    // calculo total del estado de pago (incluido los reajustes)
    this.total = 0;
    //this.totalSinReajuste = 0;
    this.valoresTotales.forEach( valor => {
      this.total += +valor.monto.toFixed(this.contractCurrenci?.Decimal);
    });
    /* for(let i = 0; i < this.valoresTotales.length; i++) {
      if(this.valoresTotales[i]) this.total += this.valoresTotales[i];
    } */
  }
  montoSinReajuste()
  {
    this.totalSinReajuste = 0;
    /* for(let i of this.valoresCantidades) { */
      /* if(i.Correlativo)
      if(this.valoresCantidades[i.Correlativo])
      { */
        //this.totalSinReajuste += i;
      /* } */
      //this.totalDetalleMontoEP += i.PrecioUnitario * i.CantidadSolicitada;
    /* } */

    for(var i = 0; i < this.itemizado.length; i++)
    {
      if(this.itemizado[i].Subpos !== null)
      {
        this.totalSinReajuste += +(this.valoresCantidades[this.getItemCantidadByid(this.itemizado[i].Correlativo)]?.cantidad * this.itemizado[i].PrecioUnitario).toFixed(this.contractCurrenci?.Decimal);
      }
    }
    return this.totalSinReajuste
  }
  onGenerar() {
    this.submitted = true;
    if (this.form.invalid) {
      this.submitted = false;
      return;
    }

    const montoEpParse: number = parseFloat(this.totalSinReajuste.toFixed(this.contractCurrenci?.Decimal));

    if((this.contrato.MONTO_ACTUALIZADO - this.contrato.MONTO_EP + this.contrato.MONTO_REAJUSTE) - montoEpParse < 0)
    {
      this.notifyService.showError('El monto del estado de pago excede el saldo disponible del contrato', 'Estado de Pago');
      this.submitted = false;
      return;
    }

    const fechaInicioContrato = Date.parse(moment(this.fechaInicioContrato).format('YYYY-MM-DD'));
    const fechaTerminoContrato = Date.parse(moment(this.fechaTerminoContrato).format('YYYY-MM-DD'));
    const fechaDesde = Date.parse(this.form.value.fecha_desde);
    const fechaHasta = Date.parse(this.form.value.fecha_hasta);

    if (fechaDesde < fechaInicioContrato) {
      this.notifyService.showWarning('La fecha de inicio del Estado de pago no puede ser menor a la fecha de inicio del Contrato', 'Estado de Pago');
      this.submitted = false;
      return;
    }

    if (fechaHasta > fechaTerminoContrato) {
      this.notifyService.showWarning('La fecha de termino del Estado de pago no puede ser mayor a la fecha de termino del Contrato', 'Estado de Pago');
      this.submitted = false;
      return;
    }

    const fecha2 = Date.parse(this.form.value.fecha_hasta);
    const fecha1 = Date.parse(this.form.value.fecha_desde)

    if (fecha2 < fecha1) {
      this.notifyService.showWarning('La fecha de termino del Estado de pago no puede ser menor a la fecha de inicio del mismo', 'Estado de Pago');
      this.submitted = false;
      return;
    }

    if (this.total === 0) {
      this.notifyService.showWarning('El Estado de Pago no puede generarse con un total de 0', 'Estado de Pago');
      this.submitted = false;
      return;
    }

    const cantidades = Object.entries(this.cantidades)
      .map(item => {
        const object = { id: Number(item[0].split('_')[1]), value: item[1] }
        return item[1] > 0 && object
      })
      .filter(item => item)

    const postData: {
      idContrato: number,
      descripcion: string,
      desde: string,
      hasta: string,
      reAjuste: number,
      detalle: Array<any>
    } = {
      idContrato: this.corrContrato,
      descripcion: this.form.value.nombre,
      desde: this.form.value.fecha_desde, // moment(this.form.value.fecha_desde).format('DD-MM-YYYY'),
      hasta: this.form.value.fecha_hasta, //moment(this.form.value.fecha_hasta).format('DD-MM-YYYY'),
      reAjuste: this.form.value.reajuste,
      detalle: Object.values(cantidades)
    };

    this.estadoPagoService.registraEstadoPago(postData).then((result) => {
      const corrEstadoPago = result.id
      const isNewEp = result.isNew

      this.notifyService.showSuccess('Estado de pago creado con exito.', 'Estado de Pago');

      const postDetalle: {
        in_ep_correlativo: number,
        in_retencion_laboral: number,
        in_retencion_contractual: number,
        in_domi_correlativo_tirecon: number,
        in_domi_timo_correlativo: number
      } = {
        in_ep_correlativo: corrEstadoPago,
        in_retencion_laboral: this.form.value.retencion_laboral,
        in_retencion_contractual: this.form.value.retencion_contractual,
        in_domi_correlativo_tirecon: (this.form.value.rdo_contractual == 'P') ? 98 : 99,
        in_domi_timo_correlativo: 9 // CLP
      };
      this.estadoPagoService.registraRetencionEstadoPago(postDetalle).subscribe(resp => {});
      if(isNewEp)
      {
        this.router.navigate(['/estado-pago-checklist', corrEstadoPago]);
      }
      else
      {
        this.openDialog(corrEstadoPago);
      }
      //this.router.navigate(['/estado-pago-checklist', corrEstadoPago]);
    })
    .catch(error => {
      this.notifyService.showError('Error al crear estado de pago', 'Estado de Pago');
      this.submitted = false;
    })
  }
  public getItemTotalesByid(idItemizado)
  {
    let index = this.valoresTotales.map(function(el)
    {
      return el.correlativo
    }
    ).indexOf(idItemizado)

    return index;
  }
  public getItemCantidadByid(idItemizado)
  {
    let index = this.valoresCantidades.map(function(el)
    {
      return el.correlativo
    }
    ).indexOf(idItemizado)

    return index;
  }
  openDialog(corrEstadoPago)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    let dialogRef = this.dialog.open(this.modalRegister,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result === 'yes') {
            // TODO: Replace the following line with your code.
            //alert("yes")
            this.router.navigate(['/estado-pago-checklist', corrEstadoPago]);
        } else if (result === 'no') {
            // TODO: Replace the following line with your code.
            alert("no")
        }
      }
    });
  }
  get f() { return this.form.controls; }
}
