import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';

export interface NavTab {
  id: string;
  title: string;
}

@Component({
  selector: 'app-nav-tab',
  templateUrl: './nav-tab.component.html',
  styleUrls: ['./nav-tab.component.css']
})
export class NavTabComponent implements OnInit, OnChanges {
  constructor() { }
  @Input() tab: NavTab;
  @Input() selectedTab: string;
  @Output() changeTab = new EventEmitter<string>();
  selected: boolean = false;

  ngOnInit(): void { }

  onClickTab(id: string) {
    this.changeTab.emit(id);
  }

  ngOnChanges(_: SimpleChanges) {
    this.selected = this.selectedTab === this.tab.id;
  }

}
