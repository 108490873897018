import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

export interface PostProveedor {
  in_correlativo?: number;
  in_rut: number;
  iv_digrut: string;
  iv_nombre: string;
  iv_email: string;
  iv_telefono?: string;
  iv_contacto?: string;
  in_usua_correlativo_creacion?: number;
}

export interface PostArticulo {
  in_correlativo?: number;
  iv_codigo: string;
  iv_nombre: string;
  iv_activo: string;
}

export interface PostZona {
  in_correlativo?: number;
  iv_codigo: string;
  iv_nombre: string;
  iv_activo: string;
}

export interface PostBlanco {
  in_correlativo?: number;
  iv_codigo: string;
  iv_nombre: string;
  iv_activo: string;
}

export interface PostGrafo {
  in_correlativo?: number;
  iv_codigo: string;
  iv_proyecto: string;
  iv_desc1: string;
  iv_desc2: string;
  iv_responsable: string;
  iv_activo: string;
}

export interface PostProyecto {
  in_correlativo?: number;
  iv_codigo: string;
  iv_nombre: string;
  iv_activo: string;
}

@Injectable({
  providedIn: 'root'
})
export class SolpeService {

  constructor(private http: HttpClient) { }
  private urlService: string = environment.apiUrl;

  getSolpes(in_usua_correlativo: number, in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getSolpeAll/' + in_usua_correlativo + '/' + in_cargo_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let solpe = [];
          for (const item in resp['solpe']) {
            solpe.push({ ...resp['solpe'][item] });
          }
          return JSON.stringify(solpe);
        }));
  }
  getSolpesCerradas(in_usua_correlativo: number, in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getSolpesCerradas/' + in_usua_correlativo + '/' + in_cargo_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let solpe = [];
          for (const item in resp['solpe']) {
            solpe.push({ ...resp['solpe'][item] });
          }
          return JSON.stringify(solpe);
        }));
  }
  getSolpe(in_solpe_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getSolpe/' + in_solpe_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let solpes = [];
          for (const item in resp['solpes']) {
            solpes.push({ ...resp['solpes'][item] });
          }
          return JSON.stringify(solpes);
        }));

  }
  getDocumentosSolpe(in_solpe_correlativo: Number, in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getDocumentosSolpe/' + in_solpe_correlativo + '/' + in_cargo_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let documentos = [];
          for (const item in resp['documentos']) {
            documentos.push({ ...resp['documentos'][item] });
          }
          return JSON.stringify(documentos);
        }));

  }
  getComentariosSolpe(in_solpe_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getComentariosSolpe/' + in_solpe_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let comentarios = [];
          for (const item in resp['comentarios']) {
            comentarios.push({ ...resp['comentarios'][item] });
          }
          return JSON.stringify(comentarios);
        }));

  }
  postSolpe(postData: {
    in_usua_correlativo_solicitante: number,
    in_domi_correlativo_tiposolpe: number,
    in_domi_correlativo_forsolpe: number,
    iv_fecha_ingreso_solpe: string,
    iv_fecha_termino_solpe: string,
    iv_nombre_solpe: string,
    iv_descripcion: string,
    in_monto: number,
    in_domi_corrrelativo_timo: number,
    iv_fecha_entrega_oc: string,
    in_rut_empresa: number,
    iv_dig_rut_empresa: string,
    iv_razon_social: string,
    iv_empresas_propuestas: string,
    in_cont_correlativo: number,
    in_cargo_correlativo: number,
    in_fecha_estimada_inicio_servicio: string,
    in_duracion_estimda_servicio: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_solicitante = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postSolpe', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let solpe = [];
          for (const item in resp['solpe']) {
            solpe.push({ ...resp['solpe'][item] });
          }
          return JSON.stringify(solpe);
        }));

  }
  postDocumentoSolpe(
    postData: {
      in_solpe_correlativo: number,
      in_domi_correlativo_docsolpe: number,
      iv_nombre_archivo: string,
      iv_descripcion_archivo: string
      in_usua_correlativo_creacion: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postDocumentoSolpe', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let documento = [];
          for (const item in resp['documento']) {
            documento.push({ ...resp['documento'][item] });
          }
          return JSON.stringify(documento);
        }));

  }
  postComentarioSolpe(postData: {
    in_solpe_correlativo: number,
    iv_comentario: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postComentarioSolpe', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let comentario = [];
          for (const item in resp['comentario']) {
            comentario.push({ ...resp['comentario'][item] });
          }
          return JSON.stringify(comentario);
        }));

  }
  getSolpeSegunMAF(in_cargo_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getSolpeSegunMAF/' + in_cargo_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let solpes = [];
          for (const item in resp['solpes']) {
            solpes.push({ ...resp['solpes'][item] });
          }
          return JSON.stringify(solpes);
        }));

  }
  updateEstadoSolpe(in_solpe_correlativo: Number, in_domi_correlativo_estadosolpe: Number, in_cargo_correlativo: number, iv_observacion = null) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    const in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .get(this.urlService + 'solpe/updateEstadoSolpe/' + in_solpe_correlativo + '/' + in_domi_correlativo_estadosolpe + '/' + in_usua_correlativo_creacion + '/' + in_cargo_correlativo + '/' + iv_observacion, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let solpe = [];
          for (const item in resp['solpe']) {
            solpe.push({ ...resp['solpe'][item] });
          }
          return JSON.stringify(solpe);
        }));

  }
  getArticulos() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getArticulos', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let articulos = [];
          for (const item in resp['articulos']) {
            articulos.push({ ...resp['articulos'][item] });
          }
          return JSON.stringify(articulos);
        }));
  }
  getZonas() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getZonas', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let zonas = [];
          for (const item in resp['zonas']) {
            zonas.push({ ...resp['zonas'][item] });
          }
          return JSON.stringify(zonas);
        }));
  }
  getBlancos() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getBlancos', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let blancos = [];
          for (const item in resp['blancos']) {
            blancos.push({ ...resp['blancos'][item] });
          }
          return JSON.stringify(blancos);
        }));
  }
  postArticulo(postData: PostArticulo) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    //postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postArticulo', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let articulos = [];
          for (const item in resp['articulos']) {
            articulos.push({ ...resp['articulos'][item] });
          }
          return JSON.stringify(articulos);
        }));

  }
  postZona(postData: PostZona) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    //postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postZona', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let zona = [];
          for (const item in resp['zona']) {
            zona.push({ ...resp['zona'][item] });
          }
          return JSON.stringify(zona);
        }));

  }
  postBlanco(postData: PostBlanco) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    //postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postBlanco', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let blanco = [];
          for (const item in resp['blanco']) {
            blanco.push({ ...resp['blanco'][item] });
          }
          return JSON.stringify(blanco);
        }));

  }
  postGrafo(postData: PostGrafo) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'solpe/postGrafo', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grafo = [];
          for (const item in resp['grafo']) {
            grafo.push({ ...resp['grafo'][item] });
          }
          return JSON.stringify(grafo);
        }));

  }
  postProyecto(postData: PostProyecto) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'solpe/postProyecto', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let proyecto = [];
          for (const item in resp['proyecto']) {
            proyecto.push({ ...resp['proyecto'][item] });
          }
          return JSON.stringify(proyecto);
        }));

  }
  getProyectos() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getProyectos', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let proyectos = [];
          for (const item in resp['proyectos']) {
            proyectos.push({ ...resp['proyectos'][item] });
          }
          return JSON.stringify(proyectos);
        }));
  }
  getGrafos() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getGrafos', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grafos = [];
          for (const item in resp['grafos']) {
            grafos.push({ ...resp['grafos'][item] });
          }
          return JSON.stringify(grafos);
        }));
  }

  getGrafosMantenedor() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getGrafosMantenedor', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let grafos = [];
          for (const item in resp['grafos']) {
            grafos.push({ ...resp['grafos'][item] });
          }
          return JSON.stringify(grafos);
        }));
  }

  postDistribucion(postData: {
    in_solpe_correlativo: number
    in_articulo_correlativo: number,
    in_zona_correlativo: number,
    in_blanco_correlativo: number,
    in_proyecto_correlativo: number,
    in_grafos_correlativo: number,
    in_porcentaje_asignado: number,
    iv_vigente: string
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    //postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postDistribucion', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let distribucion = [];
          for (const item in resp['distribucion']) {
            distribucion.push({ ...resp['distribucion'][item] });
          }
          return JSON.stringify(distribucion);
        }));

  }
  getDistribucion(in_solpe_correlativo: Number, in_cargo_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    const in_usua_correlativo = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .get(this.urlService + 'solpe/getDistribucion/' + in_solpe_correlativo + '/' + in_cargo_correlativo + '/' + in_usua_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let distribucion = [];
          for (const item in resp['distribucion']) {
            distribucion.push({ ...resp['distribucion'][item] });
          }
          return JSON.stringify(distribucion);
        }));

  }
  getEstadosSolpe(in_solpe_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getEstadosSolpe/' + in_solpe_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let estados = [];
          for (const item in resp['estados']) {
            estados.push({ ...resp['estados'][item] });
          }
          return JSON.stringify(estados);
        }));

  }
  updateEstadoDistribucion(in_dist_correlativo: Number, iv_vigente: any) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/updateEstadoDistribucion/' + in_dist_correlativo + '/' + iv_vigente, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let distribucion = [];
          for (const item in resp['distribucion']) {
            distribucion.push({ ...resp['distribucion'][item] });
          }
          return JSON.stringify(distribucion);
        }));

  }
  getEncuesta(in_solpe_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getEncuesta/' + in_solpe_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let encuesta = [];
          for (const item in resp['encuesta']) {
            encuesta.push({ ...resp['encuesta'][item] });
          }
          return JSON.stringify(encuesta);
        }));

  }
  postEncuesta(postData: {
    in_solpe_correlativo: number,
    in_domi_correlativo_encuesta1: number,
    iv_respuesta1: string,
    in_domi_correlativo_encuesta2: number,
    iv_respuesta2: string,
    in_domi_correlativo_encuesta3: number,
    iv_respuesta3: string,
    in_domi_correlativo_encuesta4: number,
    iv_respuesta4: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postEncuesta', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let encuesta = [];
          for (const item in resp['encuesta']) {
            encuesta.push({ ...resp['encuesta'][item] });
          }
          return JSON.stringify(encuesta);
        }));

  }
  getProveedores() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getProveedores', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let proveedores = [];
          for (const item in resp['proveedores']) {
            proveedores.push({ ...resp['proveedores'][item] });
          }
          return JSON.stringify(proveedores);
        }));
  }
  getProveedor(in_prov_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getProveedor/' + in_prov_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let proveedor = [];
          for (const item in resp['proveedor']) {
            proveedor.push({ ...resp['proveedor'][item] });
          }
          return JSON.stringify(proveedor);
        }));

  }
  postProveedor(postData: PostProveedor) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postProveedor', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let proveedor = [];
          for (const item in resp['proveedor']) {
            proveedor.push({ ...resp['proveedor'][item] });
          }
          return JSON.stringify(proveedor);
        }));

  }
  getRelacionSolpeProveedor(in_solpe_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getRelacionSolpeProveedor/' + in_solpe_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let relacionProveedor = [];
          for (const item in resp['relacionProveedor']) {
            relacionProveedor.push({ ...resp['relacionProveedor'][item] });
          }
          return JSON.stringify(relacionProveedor);
        }));

  }
  relacionaSolpeProveedor(in_solpe_correlativo: Number, in_proveedor_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    let in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .get(this.urlService + 'solpe/relacionaSolpeProveedor/' + in_solpe_correlativo + '/' + in_proveedor_correlativo + '/' + in_usua_correlativo_creacion, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let relaciona = [];
          for (const item in resp['relaciona']) {
            relaciona.push({ ...resp['relaciona'][item] });
          }
          return JSON.stringify(relaciona);
        }));

  }
  getJustificacion(in_solpe_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getJustificacion/' + in_solpe_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let justificacion = [];
          for (const item in resp['justificacion']) {
            justificacion.push({ ...resp['justificacion'][item] });
          }
          return JSON.stringify(justificacion);
        }));

  }
  postJustificacion(postData: {
    in_solpe_correlativo: number,
    iv_justificacion1: string,
    iv_justificacion2: string,
    iv_justificacion3: string,
    in_usua_correlativo: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postJustificacion', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let justificacion = [];
          for (const item in resp['justificacion']) {
            justificacion.push({ ...resp['justificacion'][item] });
          }
          return JSON.stringify(justificacion);
        }));

  }
  postServiciosProfesionales(postData: {
    in_solpe_correlativo: number,
    iv_area_proyecto: string,
    iv_admin_contrato: string,
    iv_grafo: string,
    iv_tiene_financiemiento: string,
    in_prov_correlativo: number,
    iv_per_cont_fecha_inicio: string,
    iv_per_cont_fecha_termino: string,
    in_plazo_total_meses: number,
    in_tarifa: number,
    in_timo_correlativo: number,
    iv_unidad: string,
    in_cantidad: number,
    in_otros_gastos: number,
    in_costo_total_estimado: number,
    iv_just_otros_gastos: string,
    iv_just_necesidad: string,
    iv_act_ejec_consultor: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postServiciosProfesionales', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let serviciosProfesionales = [];
          for (const item in resp['serviciosProfesionales']) {
            serviciosProfesionales.push({ ...resp['serviciosProfesionales'][item] });
          }
          return JSON.stringify(serviciosProfesionales);
        }));

  }
  getServiciosProfesionales(in_solpe_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getServiciosProfesionales/' + in_solpe_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let servicios = [];
          for (const item in resp['servicios']) {
            servicios.push({ ...resp['servicios'][item] });
          }
          return JSON.stringify(servicios);
        }));

  }
  postModificacionCto(postData: {
    in_solpe_correlativo: number,
    in_domi_timod_correlativo: number,
    in_monto_modificacion: number,
    in_monto_actualizado: number,
    iv_fecha_modificacion: string,
    iv_fecha_inicio: string,
    iv_fecha_termino: string,
    iv_alcance: string
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'solpe/postModificacionCto', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let modificacion = [];
          for (const item in resp['modificacion']) {
            modificacion.push({ ...resp['modificacion'][item] });
          }
          return JSON.stringify(modificacion);
        }));

  }
  getModificacionCto(in_solpe_correlativo: Number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getModificacionCto/' + in_solpe_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let modificacion = [];
          for (const item in resp['modificacion']) {
            modificacion.push({ ...resp['modificacion'][item] });
          }
          return JSON.stringify(modificacion);
        }));

  }
  relacionaSolpeGCC(in_solpe_correlativo: number, in_gcc_usua_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    let in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .get(this.urlService + 'solpe/relacionaSolpeGCC/' + in_solpe_correlativo + '/' + in_gcc_usua_correlativo + '/' + in_usua_correlativo_creacion, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let modificacion = [];
          for (const item in resp['modificacion']) {
            modificacion.push({ ...resp['modificacion'][item] });
          }
          return JSON.stringify(modificacion);
        }));

  }
  updatePorcentajeDistribucion(putData: {
    in_distribucion_correlativo: number,
    in_porcentaje: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'solpe/updatePorcentajeDistribucion', putData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let porcentaje = [];
          for (const item in resp['porcentaje']) {
            porcentaje.push({ ...resp['porcentaje'][item] });
          }
          return JSON.stringify(porcentaje);
        }));

  }
  postTablaJustificacion(postData: {
    in_solpe_correlativo: number,
    in_nro_justificacion: number,
    iv_compra: string,
    iv_servicio: string,
    iv_arriendo: string,
    iv_servicios_profesionales: string,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postTablaJustificacion', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let tableJustificacion = [];
          for (const item in resp['tableJustificacion']) {
            tableJustificacion.push({ ...resp['tableJustificacion'][item] });
          }
          return JSON.stringify(tableJustificacion);
        }));

  }
  getTotalesSolpe(in_usua_correlativo: Number, in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getTotalesSolpe/' + in_usua_correlativo + '/' + in_cargo_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let totales = [];
          for (const item in resp['totales']) {
            totales.push({ ...resp['totales'][item] });
          }
          return JSON.stringify(totales);
        }));

  }
  getCargosFullPrivilegio(in_modulo_correlativo: number, in_cargo_correlativo: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getCargosFullPrivilegio/' + in_modulo_correlativo + '/' + in_cargo_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let cargos = [];
          for (const item in resp['cargos']) {
            cargos.push({ ...resp['cargos'][item] });
          }
          return JSON.stringify(cargos);
        }));
  }
  getContratosProveedor(in_proveedor: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getContratosProveedor/' + in_proveedor, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let contratosProveedor = [];
          for (const item in resp['contratosProveedor']) {
            contratosProveedor.push({ ...resp['contratosProveedor'][item] });
          }
          return JSON.stringify(contratosProveedor);
        }));
  }

  postAdminContratistaContrato(postData: {
    in_cont_correlativo: number,
    in_admin_ec: number,
    in_usua_correlativo_creacion: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postAdminContratistaContrato', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let adminContratistaContrato = [];
          for (const item in resp['adminContratistaContrato']) {
            adminContratistaContrato.push({ ...resp['adminContratistaContrato'][item] });
          }
          return JSON.stringify(adminContratistaContrato);
        }));

  }

  getSolpeModificacionContrato(in_usua_correlativo: number, in_cargo_correlativo: number, in_contrato: number) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getSolpeModificacionContrato/' + in_usua_correlativo + '/' + in_cargo_correlativo + '/' + in_contrato, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let modificaciones = [];
          for (const item in resp['modificaciones']) {
            modificaciones.push({ ...resp['modificaciones'][item] });
          }
          return JSON.stringify(modificaciones);
        }));
  }


  postAplicaModificacionContrato(postData: {
    in_solpe_correlativo: number,
    in_cargo_correlativo: number,
    in_usua_correlativo: number
  }) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postAplicaModificacionContrato', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let solpe = [];
          for (const item in resp['solpe']) {
            solpe.push({ ...resp['solpe'][item] });
          }
          return JSON.stringify(solpe);
        }));

  }

  postEnviarCorreoInfoEmsaAplicacionModificacionContrato(
    postData: {
      in_cont_correlativo: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/postEnviarCorreoInfoEmsaAplicacionModificacionContrato', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          console.log(resp['message']);
        }));

  }

  getResponsablesGrafos() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getResponsablesGrafos', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let responsables = [];
          for (const item in resp['responsables']) {
            responsables.push({ ...resp['responsables'][item] });
          }
          return JSON.stringify(responsables);
        }));
  }

  getSolpeTodasASJAC() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getSolpeTodasASJAC', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let solpe = [];
          for (const item in resp['solpe']) {
            solpe.push({ ...resp['solpe'][item] });
          }
          return JSON.stringify(solpe);
        }));
  }

  getTotalSolpe() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getTotalSolpes', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let totales = [];
          for (const item in resp['totales']) {
            totales.push({ ...resp['totales'][item] });
          }
          return JSON.stringify(totales);
        }));

  }
  

  updateSolpe(
    postData: {
      nombre_solpe: string, 
      descripcion: string, 
      monto:number, 
      fecha_entrega_oc: Date, 
      codigo_solpe: string, 
      cont_correlativo: number, 
      orden_de_compra: string, 
      correlativo: number,
      rut: number, 
      dig_rut: string, 
      razon_social: string, 
      fecha_termino: string, 
      domi_correlativo_timo: number,
      empresas: string
    }
  ) {
    return this.http
      .post(
        this.urlService + 'solpe/updateSolpe', postData, {
      })
      .pipe(
        map(resp => {
          console.log(resp['message']);
        }));

  }

  deleteArchivoSolpe(
    putData: {
      pin_correlativo_archivo: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'solpe/deleteArchivoSolpe', putData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let mensaje = {
            message: resp['message']
          };
          return JSON.stringify(mensaje);
        }));

  }

  postCargosSolpeEstrategia(
    postData: {
      in_solpe_correlativo: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'solpe/postCargosSolpeEstrategia', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let cargosSolpeEstrategia = [];
          for (const item in resp['cargosSolpeEstrategia']) {
            cargosSolpeEstrategia.push({ ...resp['cargosSolpeEstrategia'][item] });
          }
          return JSON.stringify(cargosSolpeEstrategia);
        }));

  }

  postRetornaMontoUsdSolpe(
    postData: {
      pin_monto: number,
      pin_domi_timo_correlativo: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'solpe/postRetornaMontoUsdSolpe', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          return JSON.stringify(resp['montoUsdSolpe']);
        }));

  }

  getArticulosMantenedor() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getArticulosMantenedor', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let articulos = [];
          for (const item in resp['articulos']) {
            articulos.push({ ...resp['articulos'][item] });
          }
          return JSON.stringify(articulos);
        }));
  }
  
  getZonasMantenedor() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getZonasMantenedor', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let zonas = [];
          for (const item in resp['zonas']) {
            zonas.push({ ...resp['zonas'][item] });
          }
          return JSON.stringify(zonas);
        }));
  }

  getBlancosMantenedor() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getBlancosMantenedor', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let blancos = [];
          for (const item in resp['blancos']) {
            blancos.push({ ...resp['blancos'][item] });
          }
          return JSON.stringify(blancos);
        }));
  }

  getProyectosMantenedor() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .get(this.urlService + 'solpe/getProyectosMantenedor', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          let proyectos = [];
          for (const item in resp['proyectos']) {
            proyectos.push({ ...resp['proyectos'][item] });
          }
          return JSON.stringify(proyectos);
        }));
  }

  getSolpesByType(in_usua_correlativo: number, in_cargo_correlativo: number, in_type_correlativo: number, in_init_date: string, in_end_date:string) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    // /:in_usua_correlativo/:in_cargo_correlativo/:in_type_correlativo/:in_init_date/:in_end_date
    return this.http
      .get(this.urlService + 'solpe/getRetornaSolpeByType/' + in_usua_correlativo + '/' + in_cargo_correlativo + '/' + in_type_correlativo + '/' + in_init_date + '/' + in_end_date, {

      })
      .pipe(
        map(resp => {
          /* let solpe = [];
          for (const item in resp['solpe']) {
            solpe.push({ ...resp['solpe'][item] });
          } */
          return JSON.stringify(resp);
        }));

    
  }

  postEnviarCorreoInfoVigenteAplicacionModificacionContrato(
    postData: {
      in_cont_correlativo: number,
      in_cont_fase: number
    }
  ) {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
      .post(
        this.urlService + 'contrato/postEnviarCorreoInfoVigenteAplicacionModificacionContrato', postData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .pipe(
        map(resp => {
          console.log(resp['message']);
        }));
  }
  updateEmpresaPropuestaSolpe(
    postData: {
      empresas: string, 
      solpe_correlativo: number
    }
  ) {
    return this.http
      .post(
        this.urlService + 'solpe/updateEmpresasPropuestas', postData, {
      })
      .pipe(
        map(resp => {
          console.log(resp['message']);
        }));
  }

  updateSolpeOculta(
    postData: {
      solpe_correlativo: number, 
      visible: number
    }
  ) {
    return this.http
      .post(
        this.urlService + 'solpe/updateSolpeOculta', postData, {
      })
      .pipe(
        map(resp => {
          console.log(resp['message']);
        }));
  }

  postEnviaCorreoSolpeFueraProceso(
    postData: {
      pin_solpe_correlativo: number,
      vn_me_correlativo_cargos: number,
      vn_me_correlativo_solicitante: number
    }
  ) {
    return this.http
      .post(
        this.urlService + 'solpe/postEnviaCorreoSolpeEspecial', postData, {
      })
      .pipe(
        map(resp => {
          console.log(resp['message']);
        }));
  }

  postComentarioSolpeFueraProceso(
    postData: {
      in_solpe_correlativo: number,
      iv_comentario: string,
      in_usua_correlativo_creacion: number
    }
  ) {
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
    return this.http
      .post(
        this.urlService + 'solpe/postComentaroioSolpeFueraProceso', postData, {
      })
      .pipe(
        map(resp => {
          console.log(resp['message']);
        }));

  }

}


