import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ParametrosService } from 'src/app/shared/services/parametros.service';

@Component({
  selector: 'app-usuarios-cargos',
  templateUrl: './usuarios-cargos.component.html',
  styleUrls: ['./usuarios-cargos.component.css'],
  providers: [ParametrosService, ExportExcelService]
})
export class UsuariosCargosComponent implements OnInit {
  idUser: number;
  dcRolGrl: string[] = ['CORREO', 'CARGO_DECRIPCION', 'NOMBRE'];
  cabecerasExcel: string[] = ['CORREO', 'CARGO_DECRIPCION','NOMBRE'];
  dataUsers: any;
  dataUsersFilter: any;
  constructor(private parametrosService: ParametrosService, 
    private notifyService: NotificationService, private exportXLS : ExportExcelService) { }

  ngOnInit(): void {
    this.getUsuarios();
  }

  private getUsuarios(){
    this.parametrosService.getUsuariosCargos().subscribe(resp=>{
      this.dataUsers= JSON.parse(resp);
      this.dataUsersFilter= JSON.parse(resp);
    });
  }

  exportAsXLSX():void {
    
    //this.getFuncionalidadesUsuarios(this.idUser);
    let dataEx = this.formatDataExcel(this.dataUsersFilter);
    this.onExport(['CORREO', 'NOMBRES', 'APELLIDO_PATERNO','APELLIDO_MANTERNO','CARGO_DECRIPCION']);
    this.notifyService.showSuccess('Descargando...', 'Descargar archivo'); 
   
  }
  private formatDataExcel(datos: any[]) {
    let new_data: any[] = [];

    for(let i in datos) {
      new_data[i] = {
        Usuarios : datos[i]["CORREO"],
        Nombre : datos[i]["NOMBRES"],
        Paterno : datos[i]["APELLIDO_PATERNO"],
        Materno : datos[i]["APELLIDO_MANTERNO"],
        Rol : datos[i]["CARGO_DECRIPCION"],
      };
    }

    return new_data;
  }

  private  onExport(cabeceras: string[]) {
    let dataXLS = [];
    for(let i in this.dataUsersFilter) {
      dataXLS[i] = {
        CORREO : this.dataUsersFilter[i]["CORREO"],
        NOMBRES : this.dataUsersFilter[i]["NOMBRES"],
        APELLIDO_PATERNO : this.dataUsersFilter[i]["APELLIDO_PATERNO"],
        APELLIDO_MANTERNO : this.dataUsersFilter[i]["APELLIDO_MANTERNO"],
        CARGO_DECRIPCION : this.dataUsersFilter[i]["CARGO_DECRIPCION"]
      };
    }

    //const dataXLS = this.dataUsersFilter;

    if (dataXLS){
      let dataForExcel = [];
      dataXLS.forEach((row: any) => {
        dataForExcel.push(Object.values(row))
      })
      let reportData = {
        title: 'Usuarios Roles',
        data: dataForExcel,
        headers: Object.keys(dataXLS[0])
      }
      this.exportXLS.exportExcel(reportData);
    }
  }


  filterUser(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    //this.dataUsersFilter = this.dataUsers;
    //this.dataUsersFilter.filter = filterValue.trim().toLowerCase();
    this.dataUsersFilter = this.dataUsers.filter(data => {
      return (
        (
          data?.CARGO_DECRIPCION.toLowerCase().includes(filterValue) || 
          data?.NOMBRES.toLowerCase().includes(filterValue) || 
          data?.APELLIDO_MANTERNO.toLowerCase().includes(filterValue) || 
          data?.APELLIDO_PATERNO.toLowerCase().includes(filterValue) || 
          data?.CORREO.toLowerCase().includes(filterValue)
        )
      );
    });
  }

  sortData(sort: Sort) {
    /* const data = this.funcionalidadInit.slice();
    if (!sort.active || sort.direction === '') {
      this.menuFuncionalidadGrl =  new MatTableDataSource(data);
      this.createPaginator(this.paginatorFuncionalidadGrl, this.menuFuncionalidadGrl);
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Username': return compare(a.USERNAME, b.USERNAME, isAsc);
        case 'Funcionalidad': return compare(a.FUNCIONALIDAD, b.FUNCIONALIDAD, isAsc);
        default: return 0;
      }
    });
    if (this.sortedData){
      this.menuFuncionalidadGrl =  new MatTableDataSource(this.sortedData);
      this.createPaginator(this.paginatorFuncionalidadGrl, this.menuFuncionalidadGrl);
    } */
  }
}
