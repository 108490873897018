import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'src/assets/plugins/moment/moment';
import { ExportExcelService, excelDataProps } from '../services/export-excel.service';

interface HeaderExcel {
  title: string;
  value: string;
  type?: string;
}

export interface ExcelSheetProps {
  title: string;
  headers: HeaderExcel[];
  data: any[];
  filename?: string;
  id?: string;
  totalRowsByColumn?: number[];
}

export interface ViewExcelButtonsProps extends ExcelSheetProps {
  infoSheet?: ExcelSheetProps;
}

@Component({
  selector: 'app-view-excel-buttons',
  templateUrl: './view-excel-buttons.component.html',
  styleUrls: ['./view-excel-buttons.component.css']
})
export class ViewExcelButtonsComponent implements OnInit {
  @Input() props: ViewExcelButtonsProps;
  @Input() variant: string = 'primary';
  constructor(private excelService: ExportExcelService) { }

  ngOnInit(): void { }

  onExportar = () => {
    const { title, filename, headers, data, infoSheet } = this.props;

    const aHeaders = headers.filter((aData) => aData.title !== '')
    const aDataSheet = this.processData(data, aHeaders);

    const aDataExcel: excelDataProps[] = [
      {
        headers: aHeaders.map((aData) => aData.title),
        data: aDataSheet,
        title: title,
      }
    ];

    if (infoSheet) {
      const aHeaders = infoSheet.headers.filter((aData) => aData.title !== '')
      const aDataSheet = this.processData(infoSheet.data, aHeaders);

      aDataExcel.unshift({
        headers: aHeaders.map((aData) => aData.title),
        data: aDataSheet,
        title: infoSheet.title,
        totalRowsByColumn: infoSheet?.totalRowsByColumn
      });
    }

    const aDate = new Date()
    const sDate = aDate.getFullYear() + '' + (aDate.getMonth() + 1) + '' + aDate.getDate()
    const sTime = aDate.getHours() + '' + aDate.getMinutes() + '' + aDate.getSeconds()

    const excelData = {
      data: aDataExcel,
      filename: `${filename}_${sDate}${sTime}`
    }
    if(this.props.id) excelData.filename += '_' + this.props.id;

    return this.excelService.exportExcelWithMultiplesSheets(excelData);
  }

  processData(data: any[], headers: any[]): (string | number)[][] {
    const aDataSheet = []

    data.forEach((aRow: any) => {
      const aDataTmp: any = []
      headers.forEach((aHeader) => {
        let value = aRow[aHeader.value]
        if (value) {
          if (typeof value === 'boolean') value = value ? 'Si' : 'No'
          if (aHeader?.type === 'date') value = moment(value).format('DD/MM/YYYY');
        }
        aDataTmp.push(value)
      })
      aDataSheet.push(aDataTmp)
    })
    return aDataSheet;
  }
}
