import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddonsService {

  constructor(private http: HttpClient) { }
  private urlService: string = environment.apiUrl;
  

  postFileBoleta(postData : {
    fileName: string,
    type: string,
    files: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'addons/postFileBoleta', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                let  nombreArchivo ={};
                nombreArchivo = resp['nombreArchivo'];
                return JSON.stringify(nombreArchivo);
              }));

  }
  postFileModificacion(postData : {
    fileName: string,
    type: string,
    files: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'addons/postFileModificacion', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                let  nombreArchivo ={};
                nombreArchivo = resp['nombreArchivo'];
                return JSON.stringify(nombreArchivo);
              }));

  }
  postFileArchivoModificacion(postData : {
    fileName: string,
    type: string,
    files: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'addons/postFileArchivoModificacion', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                let  nombre ={};
                nombre = resp['nombre'];
                return JSON.stringify(nombre);
              }));

  }
  postFileSolpe(postData : {
    fileName: string,
    type: string,
    files: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'addons/postFileSolpe', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                let descripciones ={
                  nombreArchivo: resp['nombre'],
                  descripcionArchivo: resp['descripcionArchivo']
                };
                return JSON.stringify(descripciones);
              }));

  }
  postFileChecklist(postData : {
    fileName: string,
    type: string,
    files: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'addons/postFileChecklist', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                let  nombre ={};
                nombre = resp['nombre'];
                return JSON.stringify(nombre);
              }));

  }
  postFileFacturaEP(postData : {
    fileName: string,
    type: string,
    files: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'addons/postFileFacturaEP', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                let  nombre ={};
                nombre = resp['nombre'];
                return JSON.stringify(nombre);
              }));

  }
  postFileEPIncurrido(postData : {
    fileName: string,
    type: string,
    files: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'addons/postFileEPIncurrido', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                let  nombre ={};
                nombre = resp['nombre'];
                return JSON.stringify(nombre);
              }));

  }
  postFileContrato(postData : {
    fileName: string,
    type: string,
    files: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
      return this.http
          .post(
            this.urlService + 'addons/postFileContrato', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                let  nombre ={};
                nombre = resp['nombreArchivo'];
                return JSON.stringify(nombre);
              }));

  }
  generateXlsDistribucionEp(){
    return this.http.get( this.urlService  + 'addons/generateXlsDistribucionEp' , {responseType:'blob'});
  }

  validateType(type: string)
  {
    //private validFileTypes = ["doc", "pdf", "docx", "csv", "xls","xlsx"]
    switch (type.toLowerCase()) {
      case 'doc':
      case 'pdf':
      case 'docx':
      case 'csv':
      case 'xls':
      case 'xlsx':
        return true;
    }
    return false;
  }
}
