import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  constructor(private http: HttpClient) { }
  private urlService: string = environment.apiUrl;

  getUsuarios() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getUsuarios', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let usuarios = [];
                for (const item in resp['usuarios']) {
                  usuarios.push({...resp['usuarios'][item] });
                }
                return JSON.stringify(usuarios);
            }));
  }
  getUsuariosCargos() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getUsuariosCargos', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let usuarios = [];
                for (const item in resp['usuarios']) {
                  usuarios.push({...resp['usuarios'][item] });
                }
                return JSON.stringify(usuarios);
            }));
  }
  getRoles() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getRoles', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let roles = [];
                for (const item in resp['roles']) {
                  roles.push({...resp['roles'][item] });
                }
                return JSON.stringify(roles);
            }));
  }
  getRetornaRolesRelacion() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getRetornaRolesRelacion', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let roles = [];
                for (const item in resp['roles']) {
                  roles.push({...resp['roles'][item] });
                }
                return JSON.stringify(roles);
            }));
  }
  getSistemas() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getSistemas', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let sistemas = [];
                for (const item in resp['sistemas']) {
                  sistemas.push({...resp['sistemas'][item] });
                }
                return JSON.stringify(sistemas);
            }));
  }
  getAcciones() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getAcciones', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let acciones = [];
                for (const item in resp['acciones']) {
                  acciones.push({...resp['acciones'][item] });
                }
                return JSON.stringify(acciones);
            }));
  }
  getModulos() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getModulos', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let modulos = [];
                for (const item in resp['modulos']) {
                  modulos.push({...resp['modulos'][item] });
                }
                return JSON.stringify(modulos);
            }));
  }
  getOpciones() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getOpciones', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let opciones = [];
                for (const item in resp['opciones']) {
                  opciones.push({...resp['opciones'][item] });
                }
                return JSON.stringify(opciones);
            }));
  }
  getCargos() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getCargos', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let cargos = [];
                for (const item in resp['cargos']) {
                  cargos.push({...resp['cargos'][item] });
                }
                return JSON.stringify(cargos);
            }));
  }
  getPerfiles() {
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
        .get(this.urlService + 'parametros/getPerfiles', {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          })
        .pipe(
            map(resp => {
                let perfiles = [];
                for (const item in resp['perfiles']) {
                  perfiles.push({...resp['perfiles'][item] });
                }
                return JSON.stringify(perfiles);
            }));
  }
  postRegistraMenu(postData : {
    in_sistema :  number,
    in_modulo :  number,
    in_opcion :  number
    in_usuario ?:  number
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usuario = JSON.parse(localStorage.getItem('userData')).userId;

      return this.http
          .post(
            this.urlService + 'parametros/postRegistraMenu', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let menu = [];
                  for (const item in resp['menu']) {
                    menu.push({...resp['menu'][item] });
                  }
                  return JSON.stringify(menu);
              }));

  }

  postRolesUsuario(postData : {
    in_usua_correlativo : number,
    in_rolrelacion_correlativo: number ,
    in_usua_correlativo_creacion :number
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;

      return this.http
          .post(
            this.urlService + 'parametros/postRolesUsuario', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let rolesUsuario = [];
                  for (const item in resp['rolesUsuario']) {
                    rolesUsuario.push({...resp['rolesUsuario'][item] });
                  }
                  return JSON.stringify(rolesUsuario);
              }));

  }

  getRetornaMenus(){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getRetornaMenus' , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let menus = [];
          for (const item in resp['menus']) {
            menus.push({...resp['menus'][item] });
          }
          return JSON.stringify(menus);
      }));
  }

  deleteMenuId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .delete(this.urlService + 'parametros/deleteMenuId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let menu = [];
          for (const item in resp['menu']) {
            menu.push({...resp['menu'][item] });
          }
          return JSON.stringify(menu);
      }));
  }
  deleteMenuAccionId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .delete(this.urlService + 'parametros/deleteMenuAccionId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let menu = [];
          for (const item in resp['menu']) {
            menu.push({...resp['menu'][item] });
          }
          return JSON.stringify(menu);
      }));
  }
  deleteRolesUsuarioId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .delete(this.urlService + 'parametros/deleteRolesUsuarioId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let rolesRelacion = [];
          for (const item in resp['rolesRelacion']) {
            rolesRelacion.push({...resp['rolesRelacion'][item] });
          }
          return JSON.stringify(rolesRelacion);
      }));
  }
  deleteMenuUsuarioId(in_usuario : Number, in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .delete(this.urlService + 'parametros/deleteMenuUsuarioId/'+ in_usuario + '/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let menu = [];
          for (const item in resp['menu']) {
            menu.push({...resp['menu'][item] });
          }
          return JSON.stringify(menu);
      }));
  }
  deleteUserId(in_correlativo: number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .delete(this.urlService + 'parametros/deleteUserId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let usuario = [];
          for (const item in resp['usuario']) {
            usuario.push({...resp['usuario'][item] });
          }
          return JSON.stringify(usuario);
      }));
  }

  postUsuario(postData : {
    in_usua_correlativo: number,
    iv_username: string,
    iv_password: string,
    iv_fecha_inicio_vigencia: string,
    iv_fecha_termino_vigencia?: string,
    in_usua_correlativo_creacion: string,
    iv_vigencia: string,
    iv_tipo: string,
    iv_nombres: string,
    iv_paterno: string,
    iv_materno: string,
    iv_isEmsa: string,
    iv_rut: string,
    iv_telefono: string,
    iv_jefe_directo: number,
    iv_direccion: string,
    iv_fechaNacimiento: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
      return this.http
          .post(
            this.urlService + 'parametros/postUsuario', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let usuario = [];
                  for (const item in resp['usuario']) {
                    usuario.push({...resp['usuario'][item] });
                  }
                  return JSON.stringify(usuario);
              }));

  }
  postRegistraMenuUsuario(postData : {
    in_usua_correlativo: number,
    in_menu_correlativo: number,
    in_usua_correlativo_crea: number
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_crea = JSON.parse(localStorage.getItem('userData')).userId;
      return this.http
          .post(
            this.urlService + 'parametros/postRegistraMenuUsuario', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let usuario = [];
                  for (const item in resp['usuario']) {
                    usuario.push({...resp['usuario'][item] });
                  }
                  return JSON.stringify(usuario);
              }));

  }
  postRegistraAccionUsuario(postData : {
    in_mdu_correlativo: number,
    in_accion_correlativo: number ,
    in_usua_correlativo_creacion : number,
    iv_activo: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
      return this.http
          .post(
            this.urlService + 'parametros/postRegistraAccionUsuario', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let accion = [];
                  for (const item in resp['accion']) {
                    accion.push({...resp['accion'][item] });
                  }
                  return JSON.stringify(accion);
              }));

  }

  postCargosUsuarios(postData : {
    pin_cargo_correlativo: number,
    pin_usua_correlativo: number ,
    pin_usua_crea : number,
    piv_activo: string
  } ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    //postData.in_usua_correlativo_creacion = JSON.parse(localStorage.getItem('userData')).userId;
      return this.http
          .post(
            this.urlService + 'parametros/postCargosUsuarios', postData, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            })
            .pipe(
              map(resp => {
                  let accion = [];
                  for (const item in resp['accion']) {
                    accion.push({...resp['accion'][item] });
                  }
                  return JSON.stringify(accion);
              }));

  }

  getUsuarioId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getUsuarioId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let usuario = [];
          for (const item in resp['usuario']) {
            usuario.push({...resp['usuario'][item] });
          }
          return JSON.stringify(usuario);
      }));
  }
  getAccionesUsuarioMenu(in_usua_correlativo  : number, in_menu_correlativo : number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getAccionesUsuarioMenu/' + in_usua_correlativo + '/' + in_menu_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let acciones = [];
          for (const item in resp['acciones']) {
            acciones.push({...resp['acciones'][item] });
          }
          return JSON.stringify(acciones);
      }));
  }
  getRolUsuarioId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getRolUsuarioId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let roles = [];
          for (const item in resp['roles']) {
            roles.push({...resp['roles'][item] });
          }
          return JSON.stringify(roles);
      }));
  }
  getModulosSistemaId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getModulosSistemaId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let modulos = [];
          for (const item in resp['modulos']) {
            modulos.push({...resp['modulos'][item] });
          }
          return JSON.stringify(modulos);
      }));
  }
  getOpcionesModuloId(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getOpcionesModuloId/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let opciones = [];
          for (const item in resp['opciones']) {
            opciones.push({...resp['opciones'][item] });
          }
          return JSON.stringify(opciones);
      }));
  }
  getMenuUsuario(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))?._token;
    return this.http
    .get(this.urlService + 'parametros/getMenuUsuario/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let menus = [];
          for (const item in resp['menus']) {
            menus.push({...resp['menus'][item] });
          }
          return JSON.stringify(menus);
      }));
  }
  getMenuAccionesUsuario(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getMenuAccionesUsuario/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let menusAcciones = [];
          for (const item in resp['menusAcciones']) {
            menusAcciones.push({...resp['menusAcciones'][item] });
          }
          return JSON.stringify(menusAcciones);
      }));
  }
  getAccionesUsuario(in_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getAccionesUsuario/' + in_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let acciones = [];
          for (const item in resp['acciones']) {
            acciones.push({...resp['acciones'][item] });
          }
          return JSON.stringify(acciones);
      }));
  }
  getFuncionalidadesUsuario(in_usua_correlativo : Number ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getFuncionalidadUsuarios/' + in_usua_correlativo , {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let funcionalidades = [];
          for (const item in resp['funcionalidades']) {
            funcionalidades.push({...resp['funcionalidades'][item] });
          }
          return JSON.stringify(funcionalidades);
      }));

  }
  getAccionesMenuUsuario(in_correlativo : Number, in_menu_correlativo ){
    const token = JSON.parse(localStorage.getItem('userData'))._token;
    return this.http
    .get(this.urlService + 'parametros/getAccionesMenuUsuario/' + in_correlativo + '/' + in_menu_correlativo, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
    .pipe(
      map(resp => {
          let acciones = [];
          for (const item in resp['funcionalidades']) {
            acciones.push({...resp['funcionalidades'][item] });
          }
          return JSON.stringify(acciones);
      }));
  }

}
