import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { findLastKey } from 'lodash';
import { ToastInjector } from 'ngx-toastr';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {

  grupos: any[] = [];
  items: any[] = [];
  formGrupo: FormGroup;
  submitted = false;
  formItem: FormGroup;
  submittedItem = false;
  corrGrupo = 0;
  nombreGrupo = null;
  editar = false;
  editarItem = false;
  corrGrupoEditar = 0;
  corrItem = 0;

  constructor(private estadoPagoService: EstadoPagoService, private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.getGrupos();
    this.resetForm();
    this.resetFormItem();
  }
  private resetForm() {
    this.formGrupo = new FormGroup({
      grupo: new FormControl(null, Validators.required)
    });
    this.submitted = false;
  }
  private resetFormItem() {
    this.formItem = new FormGroup({
      item: new FormControl(null, Validators.required),
      obligatorio: new FormControl(0)
    });
    this.submittedItem = false;
  }
  private getGrupos() {
    this.estadoPagoService.getGruposMantenedor().subscribe(resp => {this.grupos = JSON.parse(resp)});
  }
  private getItems(in_grupo_corr: number) {
    this.estadoPagoService.getItemsMantenedor(in_grupo_corr).subscribe(resp => this.items = JSON.parse(resp));
  }
  desplegarItems(in_grupo_corr: number, nombre_grupo: string) {
    this.getItems(in_grupo_corr);
    this.corrGrupo = in_grupo_corr;
    this.nombreGrupo = nombre_grupo;
  }
  onAgregarGrupo() {
    this.submitted = true;
    if (this.formGrupo.invalid) {
      return;
    }

    if(this.editar) {
      this.editarGrupo();
    } else {
      this.agregarGrupo();
    }
    
  }
  onAgregarItem() {
    this.submittedItem = true;
    if (this.formItem.invalid) {
      return;
    }    
    
    if(this.editarItem) {
      this.editItem();
    } else {
      this.agregarItem();
    }
  }
  private agregarGrupo() {
    const post: {
      iv_nombre: string,
      in_usua_correlativo_creacion: number
    } = {
      iv_nombre: this.formGrupo.value.grupo,
      in_usua_correlativo_creacion: 0
    };
    this.estadoPagoService.creaGrupoChk(post).subscribe(resp => {
      if(JSON.parse(resp)[0].GRUPO_CHK_CORRELATIVO == 0)
        this.notifyService.showInfo('Grupo ya existe.', 'Checklist');
      else
        this.notifyService.showSuccess('Grupo creado con exito.', 'Checklist');
    }, error => {
      this.notifyService.showError('Error al crear grupo', 'Checklist');
      console.log(error);
    }, () => {
      this.getGrupos();
      this.resetForm();
    });
  }
  private editarGrupo() {
    const post: {
      in_grupochk_correlativo: number,
      iv_nombre: string,
      in_usua_correlativo_creacion: number
    } = {
      in_grupochk_correlativo: this.corrGrupoEditar,
      iv_nombre: this.formGrupo.value.grupo,
      in_usua_correlativo_creacion: 0
    };
    this.estadoPagoService.modificaGrupoChk(post).subscribe(resp => {
        this.notifyService.showSuccess('Grupo modificado con exito.', 'Checklist');
    }, error => {
      this.notifyService.showError('Error al modificar grupo', 'Checklist');
      console.log(error);
    }, () => {
      this.getGrupos();
      this.resetForm();
      this.editar = false;
    });
  }
  private agregarItem() {
    const post: {
      in_grupo_correlativo: number,
      iv_nombre: string,
      iv_obligatorio: string,
      in_usua_correlativo_creacion: number
    } = {
      in_grupo_correlativo: this.corrGrupo,
      iv_nombre: this.formItem.value.item,
      iv_obligatorio: (this.formItem.value.obligatorio == 1) ? 'S' : 'N',
      in_usua_correlativo_creacion: 0
    };
    this.estadoPagoService.creaItemChk(post).subscribe(resp => {
      if(JSON.parse(resp)[0].ITEM_DEL_GRUPOCHK_CORRELATIVO == 0)
        this.notifyService.showInfo('Item ya existe.', 'Checklist');
      else
        this.notifyService.showSuccess('Item creado con exito.', 'Checklist');
    }, error => {
      this.notifyService.showError('Error al crear Item', 'Checklist');
      console.log(error);
    }, () => {
      this.desplegarItems(this.corrGrupo, this.nombreGrupo);
      this.resetFormItem();
    });
  }
  private editItem() {
    const post: {
      in_item_grupochk_correlativo: number,
      iv_valor_nombre: string,
      iv_valor_obligatorio: string,
      in_usua_correlativo_creacion: number
    } = {
      in_item_grupochk_correlativo: this.corrItem,
      iv_valor_nombre: this.formItem.value.item,
      iv_valor_obligatorio: (this.formItem.value.obligatorio == 1) ? 'S' : 'N',
      in_usua_correlativo_creacion: 0
    };
    this.estadoPagoService.modificaItemChk(post).subscribe(resp => {
        this.notifyService.showSuccess('Item editado con exito.', 'Checklist');
    }, error => {
      this.notifyService.showError('Error al editar Item', 'Checklist');
      console.log(error);
    }, () => {
      this.desplegarItems(this.corrGrupo, this.nombreGrupo);
      this.resetFormItem();
      this.editarItem = false;
    });
  }
  onEditarGrupo(in_grupo_corr: number, grupo: string) {
    this.corrGrupoEditar = in_grupo_corr;
    this.formGrupo.controls.grupo.setValue(grupo);
    this.editar = true;
  }
  onEditarItem(in_item_corr: number, nombre: string, obligatorio: string) {
    this.corrItem = in_item_corr;
    this.formItem.controls.item.setValue(nombre);
    let obl = (obligatorio == 'S') ? 1 : 0;
    this.formItem.controls.obligatorio.setValue(obl);
    this.editarItem = true;
  }
  onEliminarGrupo(in_grupo_corr: number) {
    this.estadoPagoService.eliminarGrupoMantenedor(in_grupo_corr).subscribe(resp => {
        this.notifyService.showSuccess('Grupo eliminado con exito.', 'Checklist');
    }, error => {
      this.notifyService.showError('Error al eliminar Grupo', 'Checklist');
      console.log(error);
    }, () => {
      this.getGrupos();
      this.desplegarItems(this.corrGrupo, this.nombreGrupo);
      this.corrGrupo = 0;
    });
  }
  onEliminarItem(in_item_corr) {
    this.estadoPagoService.eliminarItemMantenedor(in_item_corr).subscribe(resp => {
      this.notifyService.showSuccess('Item eliminado con exito.', 'Checklist');
    }, error => {
      this.notifyService.showError('Error al eliminar Item', 'Checklist');
      console.log(error);
    }, () => {
      this.desplegarItems(this.corrGrupo, this.nombreGrupo);
    });
  }
  get f() { return this.formGrupo.controls; }
  get fi() { return this.formItem.controls; }
}
