import { User } from './../models/user.model';
import { FormGroup } from '@angular/forms';
const MINIMUN_CHARACTERS = 8;

export const getUser = (): User => JSON.parse(localStorage.getItem('userData'));

export const validRegex = (value: string, regex: RegExp) => {
  return new RegExp(regex).test(value);
}

export const passwordValidation = (value?: string) => {
  let valid = true;
  let message = "";
  let regex: RegExp;

  // Caracter especial
  regex = /^(?=.*?[^\w\s])/g
  if (!validRegex(value, regex)) {
    valid = false;
    message = "Tu contraseña debe tener al menos 1 caracter especial";
  }

  // Mayuscula
  regex = /^(?=.*[A-Z]).+$/g
  if (!validRegex(value, regex)) {
    valid = false;
    message = "Tu contraseña debe tener al menos 1 letra mayuscula";
  }

  // Minuscula
  regex = /^(?=.*[a-z]).+$/g
  if (!validRegex(value, regex)) {
    valid = false;
    message = "Tu contraseña debe tener al menos 1 letra minuscula";
  }

  // Numero
  regex = /^(?=.*\d).+$/g
  if (!validRegex(value, regex)) {
    valid = false;
    message = "Tu contraseña debe tener al menos 1 numero";
  }

  // Minimo caracteres
  if (value && value.length < MINIMUN_CHARACTERS) {
    valid = false;
    message = "Tu contraseña debe tener al menos 8 caracteres";
  }
  return { valid, message }
}

export const isInvalidControl = (input: string, form: FormGroup) => {
  const control = form.get(input);
  return (control.touched || control.dirty) && control.invalid;
}

export const compare = (a: number | string, b: number | string, isAsc: boolean) => {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export const validMaxLength = (e: Event) => {
  const target = e.target as HTMLInputElement;
  let value = target.value;
  const maxLength = +target.getAttribute('maxLength');
  if(maxLength) {
    if(value.length > maxLength) value = value.slice(0, maxLength);
    target.value = value;
  }
}

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
export const formatCurrency = function(value: number, n: number = 2, x: number = 3, s: string = '.', c: string = ',') {
  if(!value) value = 0;
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
  const num = value.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s));
};

export const delay = async (ms: number) => new Promise(res => setTimeout(res, ms));
