import { Component, OnInit, ViewChild } from '@angular/core';
import { ParametrosService } from '../shared/services/parametros.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormControl } from '@angular/forms';
import { NotificationService } from '../shared/services/notification.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  roles:any;
  rolesGrid:any;
  dcRoles: string[] = ['Eliminar','Correlativo', 'RolDescripcion', 'SistemaDescripcion', 'ModuloDescripcion', 'OpcionDescripcion'];

  sistemas:any;
  acciones:any;
  modulos:any;

  @ViewChild('paginatorRol', {static: true}) paginatorRol: MatPaginator;
  opciones:any;

  rolesForm : FormGroup;

  accionSelected:any[] =[] ;
  
  selection = new SelectionModel<any>(false, []);
  
  constructor(private parametrosService: ParametrosService, private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getRolesGrilla();
    this.getRoles();
    this.getSistemas();
    //this.getModulos();
    this.getAcciones();
    //this.getOpciones();
  }

  private getRoles(){
    this.parametrosService.getRoles().subscribe(resp=>{      
      this.roles=JSON.parse(resp);
    });
  }

  onBuscarModulo(idSistema: number){
    this.getModulos(idSistema);
  }
  onBuscarOpcion(idModulo: number){
    this.getOpciones(idModulo);
  }

  private getRolesGrilla(){
    this.parametrosService.getRetornaMenus().subscribe(resp=>{      
      this.rolesGrid=new MatTableDataSource(JSON.parse(resp));
      this.paginatorRol._intl.itemsPerPageLabel = 'Registros por página';
      this.paginatorRol._intl.firstPageLabel = 'Primera pagina';
      this.paginatorRol._intl.lastPageLabel = 'Última pagina';
      this.paginatorRol._intl.nextPageLabel = 'Siguiente';
      this.paginatorRol._intl.previousPageLabel = 'Anterior';
      this.rolesGrid.paginator = this.paginatorRol; 
    });
  }

  private getSistemas(){
    this.parametrosService.getSistemas().subscribe(resp=>{      
      this.sistemas=JSON.parse(resp);
    });
  }

  private getAcciones(){
    this.parametrosService.getAcciones().subscribe(resp=>{      
      this.acciones=JSON.parse(resp);
    });
  }

  private getModulos(idSistema: number){
    this.parametrosService.getModulosSistemaId(idSistema).subscribe(resp=>{      
      this.modulos=JSON.parse(resp);
    });
  }
  
  private getOpciones(idModulo: number){
    this.parametrosService.getOpcionesModuloId(idModulo).subscribe(resp=>{      
      this.opciones=JSON.parse(resp);
    });
  }


  applyFilterRol(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.rolesGrid.filter = filterValue.trim().toLowerCase();
  }

  onCheckAccion(id: number){
    this.accionSelected.push({ idAccion: id });
  }

  private resetForm(){
    this.rolesForm = new FormGroup({
      'rol' : new FormControl(0),
      'sistema' : new FormControl(0),
      'modulo': new FormControl(0),
      'opciones' : new FormControl(0)
    });
  }
  onCrear(){
    if ( this.rolesForm.value.sistema!== 0 &&  this.rolesForm.value.modulo!==0 && this.rolesForm.value.opcion!==0 ){
      const postData : {
        in_sistema :  number,
        in_modulo :  number,
        in_opcion :  number
      } = {
        in_sistema : this.rolesForm.value.sistema,
        in_modulo :  this.rolesForm.value.modulo,
        in_opcion :  this.rolesForm.value.opciones
      }
      this.parametrosService.postRegistraMenu(postData).subscribe(resp=>{
        this.getRolesGrilla();
        this.resetForm();
        this.notifyService.showSuccess('Menu agregado con exito', 'Agregar menu'); 
      }, err =>{
      this.notifyService.showError(err, 'Agregar menu');
      });


    }else{     
      this.notifyService.showError('Favor seleccione toda la informacion del formulario', 'Agregar menu');
    }
  }

  onDel(id: number){
    this.parametrosService.deleteMenuId(id).subscribe(resp =>{    
      this.getRolesGrilla(); 
      this.notifyService.showSuccess('Menu borrado correctamente.', 'Mantenedor de menu');
    });
  }

}
