import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContratoService } from 'src/app/shared/services/contrato.service';
import { EstadoPagoService } from 'src/app/shared/services/estado-pago.service';
import { GlobalDataService, ICurrencies } from 'src/app/shared/services/global-data.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-itemizado',
  templateUrl: './itemizado.component.html',
  styleUrls: ['./itemizado.component.css']
})
export class ItemizadoComponent implements OnInit {

  contrato = { NOMBRE_CONTRATO: '', PROVEEDOR: '', TIPO_MONEDA: '', MONTO_REAJUSTE: 0, SALDO_CONTRATO: 0, TIMO_CORRELATIVO: 0, MONTO_CONTRATO: 0, SOLPE_CORRELATIVO: 0, CONTRATO_INTERNO: '' };
  corrContrato: number;
  formItemizado: FormGroup;
  submitted = false;
  fileName :string;
  arrayBuffer:any;
  file:File;
  data: any[] = [];
  totalMontoItemizado: number = 0;
  itemizadoExistente: any[] = [];
  isItemizadoExiste: boolean = false;
  solpe = { MONTO_SOLPE: 0 };
  itemizado: any[] = [];
  public contractCurrenci: ICurrencies;
  mensajeValdiacionItemizado: string = '';

  constructor(
    private contratosService: ContratoService, 
    private notifyService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private contratoService: ContratoService,
    private estadoPagoService: EstadoPagoService,
    public GloblaDataService: GlobalDataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.corrContrato = params['corr_contrato'];
    });

    this.getContrato();
    this.resetForm();
    this.validaExisteItemizadoContrato();
  }

  mensajeValidarMontosItemizado(){
    if (this.contrato.MONTO_CONTRATO === this.solpe.MONTO_SOLPE && this.contrato.MONTO_CONTRATO === this.totalMontoItemizado) {
      this.mensajeValdiacionItemizado = 'Los montos coinciden';
    } else {
      this.mensajeValdiacionItemizado = 'Los montos son diferentes';
    }
  }

  getItemizado() {
    /* this.contratoService.getFootItemizado(corrContrato).subscribe(resp=>{
      this.Footitemizados = JSON.parse(resp);
    }); */
    this.estadoPagoService.getItemizadoDetalle(this.corrContrato).subscribe(resp => {
      let response = JSON.parse(resp);

      if (response.length > 0) {
        let idx = 0;
        let cabecera = null;
        this.itemizado = [];
        // creo estructura del itemizado para mostrar de forma ordenada
        for(let i in response) {        
          // si es el primer registro o cambia la cabecera del itemizado: registro cabecera y primer subitem
          if(idx == 0 || response[i]['ITEM_CABECERA'] !== cabecera) {
            // cabecera
            this.itemizado[idx] = {
              Cabecera: response[i]['ITEM_CABECERA'],
              Subpos: null,
              Descripcion: null,
              Unidad: null,
              Cantidad: null,
              PrecioUnitario: null,
              Correlativo: response[i]['ID_CORRELATIVO'],
              R: null,
              Scpq: null,
              Consumo: null
            };
            // primer subitem
            idx++;
            this.itemizado[idx] = {
              Cabecera: null,
              Subpos: response[i]['SUBPOSICION'],
              Descripcion: response[i]['DESCRIPCION'],
              Unidad: response[i]['UNIDAD'],
              Cantidad: response[i]['CANTIDAD'],
              PrecioUnitario: response[i]['PRECIO_UNITARIO'],
              Correlativo: response[i]['ID_CORRELATIVO'],
              R: response[i]['REAJUSTE'],
              Scpq: response[i]['SCPQ'],
              ConsumoQ: response[i]['AVANCE_Q'],
              Consumo: response[i]['AVANCE']
            };
            idx++;
          // de lo contrario sigo registrando subitems hasta que cambie el item principal
          } else {
            this.itemizado[idx] = {
              Cabecera: null,
              Subpos: response[i]['SUBPOSICION'],
              Descripcion: response[i]['DESCRIPCION'],
              Unidad: response[i]['UNIDAD'],
              Cantidad: response[i]['CANTIDAD'],
              PrecioUnitario: response[i]['PRECIO_UNITARIO'],
              Correlativo: response[i]['ID_CORRELATIVO'],
              R: response[i]['REAJUSTE'],
              Scpq: response[i]['SCPQ'],
              ConsumoQ: response[i]['AVANCE_Q'],
              Consumo: response[i]['AVANCE']
            };
            idx++;
          } 
          cabecera = response[i]['ITEM_CABECERA'];
        }
      }
    }); 
  }

  validaExisteItemizadoContrato(){
    this.contratoService.postEnviarCorreoInfoEmsaAplicacionModificacionContrato(
      {
        pin_cont_correlativo : this.corrContrato
      }
    ).subscribe(resp => {
      this.itemizadoExistente = JSON.parse(resp);
      this.getItemizado();

      if (this.itemizadoExistente.length > 0) {
        this.notifyService.showSuccess('El itemizado original para este contrato ya existe.', 'Itemizado.');
        this.isItemizadoExiste = true;
      } else {
        this.isItemizadoExiste = false;
      }

    }, error => console.log(error));
  }
  
  private getContrato() {
    this.contratoService.getContratoId(this.corrContrato).subscribe(resp => {
      this.contrato = JSON.parse(resp)[0];
      this.contractCurrenci = this.GloblaDataService.CurrenciesList?.find(currencie => currencie.TimoCode == this.contrato.TIMO_CORRELATIVO);

      if (this.contrato.CONTRATO_INTERNO === 'N') {
        this.contratoService.postSolpePorCorrelativo({pin_solpe_correlativo: this.contrato.SOLPE_CORRELATIVO}).subscribe(resp => {
          this.solpe = JSON.parse(resp)[0];
        }, error => console.log(error)); 
      }
    }, error => console.log(error));
  }

  private resetForm() {
    this.formItemizado = new FormGroup({
      contrato: new FormControl(null, Validators.required),
      adjunto: new FormControl(null, Validators.required)
    });
    this.fileName = null;
    this.submitted = false;
  }
  get f() { return this.formItemizado.controls; }
  incomingfile(event) {
    this.file = event.target.files[0]; 
    this.fileName = this.file.name; 
  }
  cargarExcel() {
    let fileReader = new FileReader();
    let totalItemizado: number = 0;
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type:"binary"});
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let sheetData = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      this.data = this.limpiarEspaciosItemizado(sheetData);

      console.log(this.data);

      if(this.data.length > 0){
        for (let index = 0; index < this.data.length; index++) {
          const element = this.data[index];
          if (element.CANTIDAD && element.PRECIO) {
            let totalCorrida = (element.CANTIDAD * element.PRECIO);
            totalItemizado = totalItemizado + totalCorrida;
          }
        }
      }

      //this.totalMontoItemizado = totalItemizado;
      this.totalMontoItemizado = Number(totalItemizado.toFixed(2));
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  limpiarEspaciosItemizado(datosItemizado: any[]){
    try {
      for (let index = 0; index < datosItemizado.length; index++) {
        const element = datosItemizado[index];
        if (element.POS) {
          element.SPOS = element.SPOS.trim();
        }else{
          element.ITEM = element.ITEM.trim();
          element.UNIDAD = element.UNIDAD.trim();
  
          if (element.SCPQ) {
            element.SCPQ = element.SCPQ.trim();
          }

          if (element.REAJUSTE) {
            element.REAJUSTE = element.REAJUSTE.trim();
          }
        }
      }
  
      return datosItemizado;

    } catch (error) {
      this.notifyService.showWarning('El Itemizado ingresado no es valido, por favor verifiquelo e intente nuevamente', 'Itemizado');
      return [];
    }
  }

  validateAndSave() {    
    let postDataDetalle : {
      in_item_correlativo: number,
      in_subposicion: number,
      iv_descripcion: string,
      iv_unidad: string,
      in_cantidad: number,
      in_precio_unitario: number,
      in_total: number,
      in_reajuste: number,
      in_scpq: number
    };

    if (this.totalMontoItemizado > this.contrato.MONTO_CONTRATO) {
      this.notifyService.showWarning('El monto total del itemizado no puede ser mayor al monto del contrato', 'Itemizado.');
    }else{
      let item: number;

      for(let i = 0; i < this.data.length; i++) {      
        if(this.data[i]['POS']) {
          this.contratosService.crearCabeceraItemizado({in_subposicion: this.data[i]['POS'], iv_descripcion: this.data[i]['SPOS'], in_precio_unitario:this.data[i]['PRECIO']}).subscribe(resp => {
            item = JSON.parse(resp)[0].ITEM_CORRELATIVO;      
            this.notifyService.showSuccess('Cabecera de itemizado creado con exito.', 'Itemizado');
          }, error => console.log(error),
          () => {
            this.contratosService.relacionaContratoItemizado({in_cont_correlativo: this.corrContrato, in_item_correlativo: item, in_usua_correlativo_creacion: 0}).subscribe(resp => {});
            
            for(let j = i + 1; j < this.data.length; j++) {
              if(!this.data[j]['POS']) {
                postDataDetalle = {
                  in_item_correlativo: item,
                  in_subposicion: this.data[j]['SPOS'],
                  iv_descripcion: this.data[j]['ITEM'],
                  iv_unidad: this.data[j]['UNIDAD'],
                  in_cantidad: this.data[j]['CANTIDAD'],
                  in_precio_unitario: this.data[j]['PRECIO'],
                  in_total: 0,
                  in_reajuste: (this.data[j]['REAJUSTE'] == 'x' || this.data[j]['REAJUSTE'] == 'X') ? 1 : 0,
                  in_scpq: (this.data[j]['SCPQ'] == 'x' || this.data[j]['SCPQ'] == 'X') ? 1 : 0
                };
                this.contratosService.registraDetalleItemizado(postDataDetalle).subscribe(resp => {
                  this.notifyService.showSuccess('Detalle de itemizado creado con exito.', 'Detalle Itemizado');
                }, error => {
                  console.log(error)
                });
              } else {
                break;
              }
            }          
          });     
        }       
      }
      
      this.contratoService.validaEtapaCorreoContrato({in_cont_correlativo: this.corrContrato, in_domi_correlativo_esdo_itemizado: 116}).subscribe(resp => {
        this.validaExisteItemizadoContrato();
        // this.data = [];
        this.router.navigate(['/dashboard-itemizado']);
      });
    }          
  }
  
  validateAndSaveModificacion() {    
    let postDataDetalle : {
      in_item_correlativo: number,
      in_subposicion: number,
      iv_descripcion: string,
      iv_unidad: string,
      in_cantidad: number,
      in_precio_unitario: number,
      in_total: number,
      in_reajuste: number,
      in_scpq: number
    };

    if (this.totalMontoItemizado > this.contrato.MONTO_CONTRATO) {
      this.notifyService.showWarning('El monto total del itemizado no puede ser mayor al monto del contrato', 'Itemizado.');
    }else{
      // eliminar el itemizado anterior
      this.contratoService.deleteItemizadoContrato(
        this.corrContrato
      ).subscribe(resp => {
        let item: number;

        for(let i = 0; i < this.data.length; i++) {      
          if(this.data[i]['POS']) {
            this.contratosService.crearCabeceraItemizado({in_subposicion: this.data[i]['POS'], iv_descripcion: this.data[i]['SPOS'], in_precio_unitario:this.data[i]['PRECIO']}).subscribe(resp => {
              item = JSON.parse(resp)[0].ITEM_CORRELATIVO;      
              this.notifyService.showSuccess('Cabecera de itemizado creado con exito.', 'Itemizado');
            }, error => console.log(error),
            () => {
              this.contratosService.relacionaContratoItemizado({in_cont_correlativo: this.corrContrato, in_item_correlativo: item, in_usua_correlativo_creacion: 0}).subscribe(resp => {});
              
              for(let j = i + 1; j < this.data.length; j++) {
                if(!this.data[j]['POS']) {
                  postDataDetalle = {
                    in_item_correlativo: item,
                    in_subposicion: this.data[j]['SPOS'],
                    iv_descripcion: this.data[j]['ITEM'],
                    iv_unidad: this.data[j]['UNIDAD'],
                    in_cantidad: this.data[j]['CANTIDAD'],
                    in_precio_unitario: this.data[j]['PRECIO'],
                    in_total: 0,
                    in_reajuste: (this.data[j]['REAJUSTE'] == 'x' || this.data[j]['REAJUSTE'] == 'X') ? 1 : 0,
                    in_scpq: (this.data[j]['SCPQ'] == 'x' || this.data[j]['SCPQ'] == 'X') ? 1 : 0
                  };
                  this.contratosService.registraDetalleItemizado(postDataDetalle).subscribe(resp => {
                    this.notifyService.showSuccess('Detalle de itemizado creado con exito.', 'Detalle Itemizado');
                  }, error => {
                    console.log(error)
                  });
                } else {
                  break;
                }
              }          
            });     
          }       
        }
        
        this.contratoService.validaEtapaCorreoContrato({in_cont_correlativo: this.corrContrato, in_domi_correlativo_esdo_itemizado: 116}).subscribe(resp => {
          this.validaExisteItemizadoContrato();
        });
      }, error => console.log(error));
    }          
  }
}
 