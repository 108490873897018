<ng-container *ngIf="nombreArchivo"> 
      <a class="" (click)="onVisualizar(modalArchivo)" title="Visualizar">
        {{descripcionArchivo}}
      </a>
</ng-container>

<ng-template #modalArchivo>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Vista Previa</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" data-dismiss="modal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <iframe 
            [src]="urlVisualizador | safe" 
            frameborder="0" 
            allowfullscreen
            style="position:absolute;top:0;left:0;width:100%;height:85vh;"
        ></iframe>
    </div>
</ng-template>